import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getLabel } from '../../js/api';



const NoInternetAccessStatus = (props)=>{
	const [ bottom, setBottom ] = useState('-100px');
	const [ opacity, setOpacity ] = useState(0);


	useEffect(()=>{
		setBottom(props.internetAccessStatus.show ? '0' : '-100px');
		setOpacity(props.internetAccessStatus.show ? 1 : 0);
	}, [props.internetAccessStatus.show])

	const lang = props.appLanguage.lang;
	const noInternetAccessStyle = {
		position: 'fixed',
		bottom: bottom,
		opacity:  opacity,
		zIndex: 999,
		width: '100%',
		background: '#c0392b',
		width: '100%',
		padding: '5px',
		color: '#fff',
		transition: '0.5s'
	};
	return null;
	return (
		<div>
			<div className="text-center" style={noInternetAccessStyle}>
				<table style={{ marginLeft: 'auto', marginRight: 'auto' }}>
					<tbody>
						<tr>
							<td>
								<img src="./images/no-internet.png" />
							</td>
							<td style={{ paddingLeft: '10px' }}>
								{props.internetAccessStatus.message || 'No internet connection'}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}


export default connect(store => {
	return {
		internetAccessStatus: store.internetAccessStatus,
		appLanguage: store.appLanguage
	};
})(NoInternetAccessStatus)
