import { store } from '../store';
import { handleBarcode } from '../actions';

const barCodeValiidity = (j)=> {
	let isBarCode = "";
	let diff = 0;
	for(let i = 0; i < j.length; i++) {
		let k1 = j[i];
		let k2 = j[i+1];

		isBarCode = isBarCode+k1.key

		if(k2 && k2.key != 'Enter') {
			let _diff = k2.timestamp-k1.timestamp;
			diff = diff+_diff

		}


		if(k2 && k2.key == 'Enter') {
			let isBarCodeNumber = parseInt(isBarCode);
			// NOTE: isBarCodeNumber > 1000 is a hack
			if((diff/(i+1)) < 10 && isBarCodeNumber && isBarCodeNumber > 1000) {
				if(store.getState().barcodeSatus.state == 'in-progress') {
					alert('Sorry, device is busy');
				}else {
					store.dispatch(handleBarcode(isBarCodeNumber))
				}
			}else {
				return false
			}
		}
	}
}


const barcodeHandler = ()=>{
	let barcodeStarted = false;
	let barcodes = [];

	window.addEventListener('keyup', (e)=>{
		// console.log(e)
		if(barcodeStarted) {
			barcodes.push({
				timestamp: new Date().getTime(),
				key: e.key
			})
		}else {
			barcodeStarted = true;
			barcodes = [{
				timestamp: new Date().getTime(),
				key: e.key
			}];
			setTimeout(()=>{
				barcodeStarted = false;
				barCodeValiidity(barcodes);
			},200)
		}
	})
}

export default barcodeHandler;
