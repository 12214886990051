import React, { Component } from 'react';
import { store } from '../store.js';
import { connect } from 'react-redux';

import {
	getInventory,
	searchItemsForInventory,
	setActivePage,
	getStores,
} from '../actions.js';

import {
	TippyComponent,
	FoodType,
	PreLoaderBg
} from '../reactComp.jsx';

import InventorySwitch from './InventorySwitch';
import StoreDropdown from './StoreDropDown';
import RememberHQAction from './RememberHQAction';
import RefreshInventory from './RefreshInventoryBtn';
import CategoryDropDown from './CategoryDropDown';
import TagsDropDown from './TagsDropDown';
import BizDropdown from './BizDropdown';

var _ = require('lodash');
var sat_api = require('../../js/api.js');

@connect((store)=>{
	return{
		loginReducer:store.loginReducer
	}
})
class InventoryItemRow extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			inventoryColWidth: 0,
			switchWidth: 0
		}
	}
	componentDidMount(){
		var w  = this.sc.clientWidth;
		var platforms =  this.props.loginReducer.data.biz.external_platforms;
		var switchWidth = w/platforms.length;
		if(switchWidth <= 60){
			this.setState({
				switchWidth:"60px"
			})
		}else {
			this.setState({
				switchWidth:(100/(platforms.length))+"%"
			})
		}
	}
	render(){
		var platforms =  this.props.loginReducer.data.biz.external_platforms;



		let up_availability = _.find(this.props.availability, {name:'urbanpiper'}).is_available;
		let stockText = ""
		if(up_availability){
			if(this.props.current_stock == 0){
				stockText = `( Out of stock )`
			}else{
				stockText = `( ${this.props.current_stock != -1 ? this.props.current_stock+" in ": "In"} stock )`
			}
		}else{
			//if its false, stock will always come as 0
			if(this.props.current_stock == 0){
				stockText = ``
			}else{
				stockText = `( ${this.props.current_stock != -1 ? this.props.current_stock+" in ": "In"} stock )`
			}
		}

		var categoryName = (this.props.item.category ? this.props.item.category.name : (this.props.item.item_category ? this.props.item.item_category.name : ""))

		return(
			<div className="inventory-item-row">
				<div className="inventory-item-row-item-name">
					<span className="inventory-item-title">
						{this.props.item_title}
					</span>
					{
						this.props.item.has_options &&
						<span
							className="inventory-item-option-button"
							onClick={() => this.props.handleInventoryOption(this.props.item)}
						>
							+ Options
						</span>
					}
					<span className="secondary-text small-text stock-text">
						<i>{stockText}</i>
					</span>
				</div>
				<div className="stocks-control-container" ref={sc=>this.sc = sc}>
					<InventorySwitch
						availability={this.props.availability}
						item_id={this.props.item.id}
						platform={'urbanpiper'}
						current_stock={this.props.current_stock}
					/>
				</div>
			</div>
		)
	}
}


class InventoryItemOptions extends Component {
	constructor(props) {
		super(props);
		this.state = this.getInitialState();
	}

	getInitialState = () => ({
		loading: false,
		options: [],
		error: false,
		updatedInventory: {},
		hasChanged: false,
	});

	fetchOptionsSuccess = (response) => {
		this.setState({
			options: response.data.options,
			loading: false,
		});
	}

	fetchOptionsError = (error) => {
		console.log(error);
		this.setState({
			loading: false,
			error: true,
		});
		alert("There was an error while fetching item options");
	}

	fetchOptions = (itemId) => {
		this.setState({
			loading: true,
			options: [],
			error: false,
		});
		const data = {
			item_id: itemId,
			location_id: this.props.location_id,
		};
		sat_api.getItemOptions(data, this.fetchOptionsSuccess, this.fetchOptionsError);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.item !== this.props.item) {
			if (nextProps.item && nextProps.item.id) {
				this.fetchOptions(nextProps.item.id);
			} else {
				// reset the state
				this.setState({
					...this.getInitialState(),
				});
			}
		}
	}

	handleClick = (opt) => {
		const options = this.state.options.slice();
		const toChange = options.find((o) => o.option_id === opt.option_id)
		if (!toChange) {
			return;
		}
		toChange.available = !opt.available;
		this.setState({
			options,
			hasChanged: true,
			updatedInventory: {
				...this.state.updatedInventory,
				[toChange.option_id]: toChange,
			},
		});
	}

	renderInventoryOptions = (options) => {
		return options.map((opt) => {
			return (
				<div className="inventory-item-row" key={opt.option_id}>
					<div className="inventory-item-row-item-name">
						<span className="inventory-item-title">
							{opt.option_title}
						</span>
					</div>
					<div className="stocks-control-container">
						<div  className="InventorySwitch-comp">
							<div className={"switch-rail-cont " + (opt.available ? "active" : "")}>
								<div className="switch-rail" onClick={(e)=>{this.handleClick(opt)}}>
									<div className={(opt.available ? "active" : "") + " switch-ball"}></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		})
	}

	handleConfirm = () => {
		this.setState({
			loading: true,
		});
		const availableTrue = [];
		const availableFalse = [];
		for (let optionId in this.state.updatedInventory) {
			const option = this.state.updatedInventory[optionId];
			if (option.available) {
				availableTrue.push(optionId);
			} else {
				availableFalse.push(optionId);
			}
		}
		const toWait = [];
		if (availableTrue.length > 0) {
			const data = {
				location_id: this.props.location_id,
				options: availableTrue,
				available: true,
			};
			toWait.push(sat_api.updateItemOptionsAvailability(data));
		}
		if (availableFalse.length > 0) {
			const data = {
				location_id: this.props.location_id,
				options: availableFalse,
				available: false,
			};
			toWait.push(sat_api.updateItemOptionsAvailability(data));
		}

		if (toWait.length > 0) {
			// handle the async requests
			Promise.all(toWait).then((response) => {
				if (response[0].data.status === 'success') {
					this.setState({
						loading: false,
						updatedInventory: {},
					});
					this.props.hideInventoryOption();
				}
			}).catch((error) => {
				console.log(error);
				alert("There was an error while updating availability for item options");
			});
		}
	}

	render() {
		const { item } = this.props;
		const { options, hasChanged, loading } = this.state;
		return (
			<div className="inventory-options-container cart-container">
				<div className="cart-header text-center">
					<div className="close" onClick={this.props.hideInventoryOption}>
						<a>x</a>
					</div>
					<div>{item && item.item_title}</div>
				</div>
				<div className="cart-item-container">
					{this.renderInventoryOptions(options)}
				</div>
				<div className="inventory-options-buttons">
					<div
						className={
							"confirm-button uppercase large-text " +
							(loading || !hasChanged ? "disabled": "")
						}
						onClick={this.handleConfirm}
					>
						<a>Confirm</a>
					</div>
				</div>
			</div>
		);
	}
}

@connect((store)=>{
	return{
		inventory:store.inventory,
		inventorySearch:store.inventorySearch,
		loginReducer:store.loginReducer,
		bizStoresReducer: store.bizStoresReducer,
		appLanguage: store.appLanguage,
	}
})
export default class InventoryContainer extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			hq:false,
			showInventoryOptions: false,
			inventoryOptionsItem: undefined,
			inventoryOptionsStyle: undefined,
		}
	}



	componentDidMount() {
		const inventoryOptionsStyle = {
			height: window.innerHeight - 100 - 44
		}
		this.setState({
			inventoryOptionsStyle,
		});

    store.dispatch(setActivePage({page:"inventory"}))
		store.dispatch({
			type:'INVENTORY_UPDATE_SETTINGS',
			payload:false
		});
		store.dispatch({
			type:"TOGGLE_BURGER_MENU",
			payload: false
		});

		const { selectedStore } = this.props.bizStoresReducer;
		if (this.props.loginReducer.data.flag == 'HQ') {
			this.setState({hq:true});
		}
		if(selectedStore.value) {
			store.dispatch(getInventory({
				location_id: selectedStore.value,
			}));
		}

		let defaultBiz = this.props.bizStoresReducer.selectedBiz;
		if(!defaultBiz.value || !defaultBiz.label) {
			defaultBiz = {
				label: this.props.loginReducer.data.biz.name,
				value: this.props.loginReducer.data.biz.biz_id,
			};
			this.props.dispatch({
				type: 'SELECT_BIZ',
				payload: defaultBiz,
			});
		}

		// set/get stores according to user and settings
		const userId = this.props.loginReducer.data.user_id;
		const isMultiBiz = this.props.loginReducer.data ? this.props.loginReducer.data.multi_biz : false;
		const settings = sat_api.getLocalSettings(userId);
		if (isMultiBiz && settings.selectedStores.length > 0) {
			defaultBiz = {
				label: settings.selectedStores[0].biz_name,
				value: settings.selectedStores[0].biz_id,
			}
			this.props.dispatch({
				type: 'SELECT_BIZ',
				payload: defaultBiz,
			});
			const bizStore = settings.selectedStores.find((s) => s.biz_id === defaultBiz.value);
			store.dispatch({
				type: 'SELECT_BIZ_STORE',
				payload: bizStore,
			});
			this.handleStoreSelect(bizStore.value);
		} else {
			this.props.dispatch(getStores({biz_id: defaultBiz.value}));
		}

		// set available height and offset
		const offset = this.sc.getBoundingClientRect();
		const availableHeight = window.innerHeight - offset.top - 15;
		if(sat_api.isMobile) availableHeight -= 30;
		this.setState({
			'top':offset.top,
			'availableHeight':availableHeight
		});
	}

	handleStoreSelect = (location_id) => {
		store.dispatch(getInventory({
			location_id,
		}));
	}

	inventorySearch(e, settings) {
		store.dispatch({
			type: 'INVENTORY_SEARCH_INPUT',
			payload: e.target.value,
		});
		this.inventorySearchDebounced(e.target.value, settings);
	}

	inventorySearchDebounced = _.debounce((kw, settings) => {
		let bizId = this.props.bizStoresReducer.selectedBiz.value;
		if (settings.selectedStores.length > 0) {
			const store = settings.selectedStores.find((s) => s.value === this.props.inventory.location_id);
			if (store) {
				bizId = store.biz_id
			}
		}
		store.dispatch(searchItemsForInventory({
			kw,
			location_id: this.props.inventory.location_id,
			biz_id: bizId,
		}));
	}, 300);

	searchActive() {
		store.dispatch({
			type:"INVENTORY_SEARCH_ENABLE",
			payload:null
		})
	}

	closeInventorySearch(e) {
		store.dispatch({
			type:"INVENTORY_SEARCH_DISABLE",
			payload:null
		})

		// var _stores = this.props.loginReducer.data.sub_admin;
		// store.dispatch(getInventory({location_id:this.props.inventory.location_id}))
	}

	handleInventoryOption = (item) => {
		this.setState({
			showInventoryOptions: true,
			inventoryOptionsItem: item,
		});
	}

	hideInventoryOption = () => {
		this.setState({
			showInventoryOptions: false,
			inventoryOptionsItem: undefined,
		});
	}

	render() {
		return null;
		let widgetStyles = {
			paddingTop: this.props.location.pathname === "/w/inventory" ? '10px':'80px'
		}
		if(this.props.inventory.error){
			return(
				<div className="react-components inventory-control-cont" style={widgetStyles}>
					<div className="text-center UP-ERROR-TEXT padding-10 m-t-20 large-text">
						{this.props.inventory.error_message}
					</div>
				</div>
			)
		}

		if(!this.props.inventory.loading && this.props.inventory.data){
			var data = this.props.inventory.data;
			if(data.length == 0){
				var inventory_item_row = (()=>{
					return (
						<div className="text-center UP-ERROR-TEXT padding-10 m-t-20 m-b-20 large-text">
							{sat_api.getLabel(this.props.appLanguage.lang, "no_item_found") || "No item found!"}
						</div>
					)
				})()
			}else{
				var inventory_item_row = _.map(data, (i,d)=>{
					return <InventoryItemRow
						key={d}
						item={i}
						item_title={i.item_title}
						current_stock={i.current_stock}
						availability={i.availability}
						handleInventoryOption={this.handleInventoryOption}
						/>
				})
			}

			var platforms = this.props.loginReducer.data.biz.external_platforms;
			let _styleObj = {
				width:(100/platforms.length)+"%",
				display:"inline-block",
				boxSizing:"border-box",
				textTransform:"uppercase",
				height:"50px",
				lineHeight:"50px",
				borderLeft:"1px solid #ddd",
				paddingLeft: "27px"
			}
			function getImg(channel_ico){
				return(
					<img style={{'width':'20px', 'borderRadius':'4px', 'transform': 'translateY(5px)'}} src={"/images/"+channel_ico} />
				)
			}
			var platformLabel = (()=>{
				return (
					<div style={_styleObj}>
						{sat_api.getLabel(this.props.appLanguage.lang, "status") || "Status"}
					</div>
				)
			})()
		}

		var dropDownStyle = {
			"display": "inline-block",
			"verticalAlign": "top",
			"marginTop": "7px",
			"boxSizing": "border-box",
			"marginLeft":"1%",
			"marginRight":"1%"
		}
		const userId = this.props.loginReducer.data.user_id;
		const settings = sat_api.getLocalSettings(userId);
		const isMultiBiz = this.props.loginReducer.data ? this.props.loginReducer.data.multi_biz : false;
		const hasStoreSubs = settings.selectedStores.length > 0;
		return (
			<div className="react-components inventory-control-cont" style={widgetStyles}>
				<div style={{marginBottom:"10px", position: "relative", zIndex: 3}}>
					<div className="inventory-biz-store-selector">
						<div className="flex-container">
							{
								isMultiBiz &&
								<BizDropdown
									showOnlySubscribed={hasStoreSubs}
									settings={settings}
								/>
							}
							<StoreDropdown
								handleStoreSelect={this.handleStoreSelect}
								showOnlySubscribed={hasStoreSubs}
								settings={settings}
							/>
						</div>
					</div>
					{this.state.hq && !hasStoreSubs &&
						<RememberHQAction data={this.props.inventory} />
					}
					<RefreshInventory />
				</div>
				<div className="inventory-table-container" style={{position: "relative", zIndex: 2}}>
					<div style={{"borderBottom":"1px solid #ddd","minHeight":"50px"}}>
						<div className={(this.props.inventorySearch.active ? "searching" : "")+ " float-left inventory-table-container-header col-1"}>
							<div style={dropDownStyle} className="inventory-item-search relative-pos inp-field-custom-label">
								<div className="custom-label">
									{sat_api.getLabel(this.props.appLanguage.lang, "search") || "Search"}
								</div>
								<input
									type="text"
									onClick={(e)=>{this.searchActive(e)}}
									placeholder={sat_api.getLabel(this.props.appLanguage.lang, "search_an_item") || "Search an item"}
									onChange={(e)=>this.inventorySearch(e, settings)}
									value={this.props.inventorySearch.kw}
								/>
								<div className="cls-inventory-search" onClick={(e)=>this.closeInventorySearch(e)}></div>
							</div>
							<div style={dropDownStyle} className="category-dropdown inp-field-custom-label">
								<div className="custom-label">
									{sat_api.getLabel(this.props.appLanguage.lang, "category") || "Category"}
								</div>
								<CategoryDropDown/>
							</div>
							<div style={dropDownStyle} className="category-dropdown inp-field-custom-label">
								<div className="custom-label">
									{sat_api.getLabel(this.props.appLanguage.lang, "tags") || "Tags"}
								</div>
								<TagsDropDown/>
							</div>
						</div>
						<div className="float-left inventory-table-container-header col-2">
							{platformLabel}
						</div>
						<div className="clearfix"></div>
					</div>
					<div className="inventory-table-container-body" ref={sc=>this.sc = sc} style={{"maxHeight":this.state.availableHeight, "overflow":"overlay"}}>
						{(this.props.inventorySearch.loading ? <div className="text-center padding-10 m-t-20 m-b-20 large-text">Searching <i className="UP-ERROR-TEXT">{this.props.inventorySearch.kw}</i>..</div> : null)}
						{((!this.props.inventory.data || this.props.inventory.loading) ? <div className="padding-10"><PreLoaderBg no={3} /></div> : (this.props.inventorySearch.loading ? "" : inventory_item_row))}
					</div>
					<div
						className={(this.state.showInventoryOptions ? "expand" : "") + " floating-cart floating-inventory-options"}
						style={this.state.inventoryOptionsStyle}
					>
						<InventoryItemOptions
							item={this.state.inventoryOptionsItem}
							location_id={this.props.inventory.location_id}
							hideInventoryOption={this.hideInventoryOption}
						/>
					</div>
				</div>
			</div>
		);
	}
}
