const lang_jp =  require('../lang/ja.json');
const lang_es = require('../lang/es.json');

export const getLabel = (lang, label)=>{
	switch (lang) {
		case "ja":
			return lang_jp[label] || "";
		case "es":
			return lang_es[label] || "";
		default:
			return undefined
	}
}
