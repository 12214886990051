import React, { Component } from 'react';

import { store } from '../store.js';
import { connect } from 'react-redux';

import MainTable from '../MainTable/MainTable';

import {
	CloseBtn,
	ColorIns,
	PreLoaderBg,
	CurrCode,
	AppHeader,
	ArrowUP,
	ArrowDown,
	ArrowDefault,
	// UPList,
	TippyComponent
} from '../reactComp.jsx';

import { FixedTableLoader } from '../siteComps';

import {
	setActivePage,
	fetchAllOrder,
	statusChange
} from '../actions.js';

import OrderDetailCont from './OrderDetail.jsx';

import { Link } from 'react-router-dom';

import { trackPage } from '../../socket_handlers';
import Moment from '../clientTime';

var PubSub = require('pubsub-js');
var _ = require('lodash');
var sat_api = require('../../js/api.js');





@connect(store => {
	return {
		activeOrderDetail: store.activeOrderDetail,
		menuActive: store.menuActive,
		appLanguage: store.appLanguage,
		orderList: store.orderList,
		allOrderList: store.allOrderList,
		orderTrackerNavState: store.orderTrackerNavState
	};
})
export default class OrderListContainerParent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.statesOrder = [
			'order-in-progress',
			'completed-order',
			'cancelled-order',
			'future-order'
		];
	}

	componentWillUnmount() {
		store.dispatch({
			type: 'UPDATE_NAV_STATE',
			payload: {
				search_keyword: '',
				search_results: []
			}
		});
	}

	componentDidMount() {
		if(this.props.match.params.orderId) {
			store.dispatch({
				type: 'SHOW_HIDE_ORDER_DETAIL_MODAL',
				payload: this.props.match.params.orderId
			});
		}
		store.dispatch({
			type: 'UPDATE_NAV_STATE',
			payload: {
				active_tab: this.props.match.params.type || 'placed'
			}
		});

		if(this.props.match.params.type == 'past-order') {
			store.dispatch(setActivePage({ page: 'past-order' }));
			const _date = this.props.allOrderList.dateFilter;
			let noCache = false;

			if (_date > new Date().getTime()) {
				noCache = true;
			}
			store.dispatch(fetchAllOrder(_date, null, noCache));
		}else {
			store.dispatch(setActivePage({ page: 'active-tracker' }));
		}

		store.dispatch({
			type: 'TOGGLE_BURGER_MENU',
			payload: false
		});

		sat_api.updateCheck();



	}

	componentWillReceiveProps(newProps) {
		if (this.props.match.params.type != newProps.match.params.type) {
			store.dispatch({
				type: 'UPDATE_NAV_STATE',
				payload: {
					active_tab: newProps.match.params.type
				}
			});

			store.dispatch({
				type: 'TOGGLE_BURGER_MENU',
				payload: false
			});

			if (newProps.match.params.type == 'past-order') {
				store.dispatch(setActivePage({ page: 'past-order' }));

				const _date = newProps.allOrderList.dateFilter;

				let noCache = false;

				if (_date > new Date().getTime()) {
					noCache = true;
				}

				store.dispatch(fetchAllOrder(_date, null, noCache));
			} else {
				store.dispatch(setActivePage({ page: 'active-tracker' }));
			}

			sat_api.updateCheck();
		}
	}

	handleLoadMorePastData = () => {
		const _date = this.props.allOrderList.dateFilter;
		let noCache = false;

		if (_date > new Date().getTime()) {
			noCache = true;
		}

		store.dispatch(
			fetchAllOrder(
				this.props.allOrderList.dateFilter,
				this.props.allOrderList.data.meta,
				noCache
			)
		);
	};

	render() {
		const lang = this.props.appLanguage.lang;
		const type = this.props.match.params.type;

		switch (type) {
			case 'cancelled':
				var status = 'cancelled';
				break;
			case 'in-progress':
				var status = 'other';
				break;
			case 'completed':
				var status = 'completed';
				break;
			case 'future':
				var status = 'future';
				break;
			case 'past-order':
				var status = 'past-order';
				break;
			default:
				var status = 'new';
		}

		// NOTE: bad code ahead!!

		if (status == 'past-order') {
			return (
				<div>
					<OrderDetailModal id={this.props.activeOrderDetail.show} />
					{this.props.allOrderList &&
					this.props.allOrderList.loading === false ? (
						<div>
							<OrderListDynamicComp
								status={status}
								orderData={this.props.allOrderList.data}
								lang={lang}
							/>
							{this.props.allOrderList.data.meta.next &&
							!this.props.orderTrackerNavState.search_keyword ? (
								<LoadMorePastOrder
									onClick={this.handleLoadMorePastData}
									meta={this.props.allOrderList.data.meta}
									loading_more={this.props.allOrderList.loading_more}
								/>
							) : null}
						</div>
					) : (
						<PreLoaderBg no={3} />
					)}
				</div>
			);
		}

		return (
			<div>
				<OrderDetailModal id={this.props.activeOrderDetail.show} />
				{this.props.orderList && this.props.orderList.data ? (
					<div>
						<OrderListDynamicComp
							status={status}
							orderData={this.props.orderList.data}
							lang={lang}
						/>
					</div>
				) : (
					<PreLoaderBg no={3} />
				)}
			</div>
		);
	}
}

function getUserDetails() {
	const loginData = store.getState().loginReducer.data;
	const { user_id, name } = loginData;
	const machine_id = localStorage.getItem('machine_id');
	return { user_id, machine_id, name };
}

@connect(store => {
	return {
		orderStateReducer: store.orderStateReducer,
		activeOrderDetail: store.activeOrderDetail
	};
})
class OrderDetailModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sc: 0
		};
	}

	_handleScroll = _.debounce(() => {
		console.log('getting called');
		this.setState({ sc: this.sc.scrollTop });
	}, 250);

	handleScroll = this._handleScroll;

	componentDidMount() {
		this.sc.addEventListener('scroll', e => this.handleScroll(e));
	}

	componentWillUnmount() {
		this.sc.removeEventListener('scroll', e => this.handleScroll(e));
	}

	closeOrderDetailModal() {
		trackPage('user_exited_url', this.props.id);
		store.dispatch({
			type: 'SHOW_HIDE_ORDER_DETAIL_MODAL',
			payload: false
		});
		store.dispatch({
			type: 'STATUS_CHANGE_MODAL',
			payload: {
				showDeliveryExecModal: false
			}
		});
	}
	render() {
		var closeStyleObj = {
			position: 'fixed',
			top: '0px',
			right: '65%',
			zIndex: 999,
			width: '50px',
			height: '50px',
			background: 'rgba(0,0,0,.5)',
			color: '#fff',
			boxShadow: '-1px 0px 9px 0px rgba(50, 50, 50, 0.67)'
		};
		// if (window.innerWidth <= 768) {
		// 	closeStyleObj.top = '50px';
		// }
		if (this.props.activeOrderDetail.show) {
			var styleObj = {
				transition: '180ms',
				transform: 'translateX(0%)'
			};
			closeStyleObj.visibility = 'visible';
		} else {
			var styleObj = {
				transition: '180ms',
				transform: 'translateX(100%)'
			};
			closeStyleObj.visibility = 'hidden';
		}

		if (this.props.orderStateReducer.showModal || this.props.orderStateReducer.showDeliveryExecModal) {
			styleObj.overflow = 'hidden';
		}
		// var rmStyle = { translateX: spring(0, { stiffness: 210, damping: 25 })};
		if (window.outerWidth <= 768) {
			closeStyleObj.right = 'auto';
			closeStyleObj.left = '0px';
			closeStyleObj.zIndex = 1000;
			// var rmStyle = { translateX: 0};
		}
		return (
			<div>
				<div
					onClick={e => {
						this.closeOrderDetailModal(e);
					}}
					style={closeStyleObj}
				>
					<CloseBtn height="50px" />
				</div>
				<div
					ref={sc => {
						this.sc = sc;
					}}
					className="order-detail-container-styling-class"
					style={{...styleObj, scrollBehavior: "smooth"}}
				>
					{this.props.id ? (
						<OrderDetailCont
							scrollPos={this.state.sc}
							params={{ orderId: this.props.id }}
							layout="slide-right"
						/>
					) : null}
				</div>
			</div>
		);
	}
}


const TableCurrCode = props => {
	return (
		<span>
			<CurrCode />
			{props.amount}
		</span>
	);
};

const CustomerInfo = props => {
	return (
		<div>
			<div className="inline-block">{props.name}</div>
			<div
				className="inline-block"
				style={sat_api.isMobile ? { fontSize: '10px' } : { marginLeft: '5px' }}
			>
				{props.phone}
			</div>
		</div>
	);
};

class Channel extends React.PureComponent {
	render() {
		const { orderData, ...props } = this.props;

		if (sat_api.MISC_CONFIG.CHANNEL_IMG[orderData.channel]) {
			var channel_ico = sat_api.MISC_CONFIG.CHANNEL_IMG[orderData.channel];
		} else {
			var channel_ico = 'web.svg';
		}
		var channelJSX = (function() {
			return (
				<img
					style={{ width: '20px', borderRadius: '4px' }}
					src={'images/' + channel_ico}
				/>
			);
		})();

		return <span>{channel_ico ? channelJSX : orderData.channel}</span>;
	}
}

const TimeLeft = props => {
	var { delivery_datetime, timer } = props;
	var timeLeft = sat_api.timeDiffHumanize(delivery_datetime - timer);
	return <div>{timeLeft ? timeLeft : '--'}</div>;
};



function reverseSortDirection(sortDir) {
	return sortDir === 'DESC' ? 'ASC' : 'DESC';
}



@connect(store => {
	return {
		// loggedIn: store.loginReducer.loggedIn,
		orderTrackerNavState: store.orderTrackerNavState
	};
})
class OrderListDynamicComp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tableWidth:
				window.outerWidth > 768
					? window.innerWidth - 160 - 20 - sat_api.getScrollbarWidth()
					: window.innerWidth - 20 - sat_api.getScrollbarWidth(),
			searchKeyword: ''
		};
	}

	render() {
		const { status, lang, orderData, orderTrackerNavState } = this.props;

		if (orderTrackerNavState.search_keyword) {
			var listData = {
				orders: orderTrackerNavState.search_results
			};
		} else if (status == 'past-order') {
			var listData = this.props.orderData;
			console.log(listData);
		} else {
			const statusMap = [];
			for (let x in orderData) {
				if (x == 'placed') {
					statusMap[0] = {
						key: 'Placed',
						count: orderData[x].orders.length,
						path: 'placed',
						selected: status == 'new'
					};
				}

				if (x == 'other') {
					statusMap[1] = {
						key: 'In progress',
						count: orderData[x].orders.length,
						path: 'in-progress',
						selected: status == 'other'
					};
				}

				if (x == 'completed') {
					statusMap[2] = {
						key: 'Completed',
						count: orderData[x].orders.length,
						path: 'completed',
						selected: status == 'completed'
					};
				}

				if (x == 'cancelled') {
					statusMap[3] = {
						key: 'Cancelled',
						count: orderData[x].orders.length,
						path: 'cancelled',
						selected: status == 'cancelled'
					};
				}

				if (x == 'future') {
					statusMap[4] = {
						key: 'Future',
						count: orderData[x].orders.length,
						path: 'future',
						selected: status == 'future'
					};
				}
			}
			switch (status) {
				case 'new':
					var listData = orderData ? orderData.placed : null;
					break;
				case 'future':
					var listData = orderData ? orderData.future : null;
					break;
				case 'completed':
					var listData = orderData ? orderData.completed : null;
					break;
				case 'cancelled':
					var listData = orderData ? orderData.cancelled : null;
					break;
				case 'other':
					var listData = orderData ? orderData.other : null;
					break;
				default:
			}
		}

		return (
			<div className="react-components" id="fixed_table_container">
				{listData ? (
					<div className=" order-list-section active">
						<MainTable status={status} />
						{this.props.status == 'new' ? <ColorIns /> : ''}
					</div>
				) : (
					<PreLoaderBg no={3} />
				)}
			</div>
		);
	}
}

const LoadMorePastOrder = props => {
	return (
		<div
			onClick={props.onClick}
			className={
				(props.loading_more ? 'disabled' : '') + ' load-more-past-order'
			}
		>
			<div className="btn">
				{props.loading_more ? 'Loading ..' : 'Load More'}
			</div>
		</div>
	);
};
