import React from 'react';
import { Link } from 'react-router-dom';

export default class ChangeLog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			logs : {}
		}
	}
	componentDidMount() {
		fetch('/change_log.json')
			.then(function(response) {
				return response.json();
			})
			.then((myJson)=> {
				this.setState({
					logs: myJson
				})
			});
	}

	renderLogs = (logs)=>{
		const comps = [];
		for(let x in logs) {
			let obj = {}
			obj.version = x
			obj.changes = logs[x].changes;
			comps.push(<LogRow key={x} {...obj} />)
		}
		return comps
	}
	render() {
		return(
			<div className="change-log-page">
				<div className="header">
					<Link to="/"><img src="/images/sat.png" /></Link>
				</div>
				<div className="body">
					{this.renderLogs(this.state.logs)}
				</div>
			</div>
		)
	}
}


const LogRow = (props)=>{
	const changeNodes = props.changes.map((i, k)=>{
		return(
			<div className="change-node">
				<div className="title">{i.title}</div>
				<div className="description">{i.description}</div>
			</div>
		)
	})
	return(
		<div className="change-log-version-row">
			<div className="version">Version : {props.version}</div>
			{changeNodes}
		</div>
	)
}
