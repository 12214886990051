import React from 'react';
import {store} from '../store.js';
import { connect } from 'react-redux';
import {
	syncUserData,
	setActivePage
} from '../actions.js';

import {
	DropOrder
} from '../reactComp.jsx';


import ThankyouPage from './Thankyou';
import CheckOutPage from './Checkout';
import StartOrder from './Startorder';
import OrderingMenu from './Orderingmenu';

var _ = require('lodash');
var sat_api = require('../../js/api.js');


@connect((store) => {
	return {
		currentOrderSession:store.currentOrderSession,
		loginReducer:store.loginReducer,
		userSync:store.userSync
	}
})
export default class OrderingModule extends React.Component{
	componentDidMount(){
		store.dispatch(syncUserData())
        sat_api.MP_TRACK('view_order_taker',{})
        store.dispatch(setActivePage({page:'start-order'}));

		store.dispatch({
			type:"TOGGLE_BURGER_MENU",
			payload: false
		})
	}
	render(){
		var page = getPage(this.props.currentOrderSession.nextPage)

		if(this.props.loginReducer.data.sub_admin && this.props.loginReducer.data.sub_admin.length > 0){
			return(
				<div className="ordering-module">
					<div className="react-components">
						{page}
					</div>
					<DropOrder/>
				</div>
			)
		}else{
			return(
				<div className="ordering-module">
					<div className="react-components text-center relative-pos">
						<div className="border-curve m-t-20 large-text inline-block error-bg padding-10">
							Make sure you have permissions, try loggin in again!
						</div>
					</div>
				</div>
			)
		}
	}
}

const getPage = (page)=>{
	let _page = <StartOrder />
	switch (page) {
		case "init":
			_page = <StartOrder />
			break;
		case "menu":
			_page = <OrderingMenu />
			break;
		case "checkout":
			_page = <CheckOutPage />
			break;
		case "thankyou":
			_page = <ThankyouPage />
			break;
		default:
			_page = <StartOrder />
	}

	return _page;
}
