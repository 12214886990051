import _ from 'lodash';

const sat_api = require('./api.js');

export const NUM_MOBILE_HEADER_COLUMNS = 3;
export const IS_MOBILE = sat_api.isMobile;
export const MAX_SORT_ORDER = 6;


// TODO: Get Labels from sat_api to support other languages
const columns = {
	id: {
		label: 'Order ID',
		columnKey: 'id',
		storeKey: 'id',
		sortable: true,
		sortOrder: 1
	},
	created: {
		label: 'Placed At',
		columnKey: 'created',
		storeKey: 'created',
		sortable: true,
		sortOrder: 7
	},
	delivery_datetime: {
		label: 'Delivery Time',
		columnKey: 'delivery_datetime',
		storeKey: 'delivery_datetime',
		sortable: true,
		sortOrder: 2
	},
	biz_location_name: {
		label: 'Store',
		columnKey: 'biz_location_name',
		storeKey: 'biz_location_name',
		sortOrder: 6
	},
	time_left: {
		label: 'Time Left',
		columnKey: 'time_left',
		storeKey: 'time_left',
		sortable: true,
		sortOrder: 4
	},
	customer: {
		label: 'Customer',
		columnKey: 'customer',
		storeKey: ['customer_name', 'phone'],
		sortOrder: 5
	},
	order_total: {
		label: 'Amount',
		columnKey: 'order_total',
		storeKey: 'order_total',
		sortOrder: 3
	},
	order_state: {
		label: 'Status',
		columnKey: 'order_state',
		storeKey: 'order_state',
		sortOrder: 8
	}
};

const mobileFields = Object.keys(columns).reduce(
	(acc, columnKey) => {
		const { sortOrder } = columns[columnKey];

		if (sortOrder <= NUM_MOBILE_HEADER_COLUMNS) {
			return {
				headerFields: acc.headerFields.concat(columns[columnKey]),
				bodyFields: [...acc.bodyFields]
			};
		} else if (sortOrder <= MAX_SORT_ORDER) {
			return {
				headerFields: [...acc.headerFields],
				bodyFields: acc.bodyFields.concat(columns[columnKey])
			};
		}

		return acc;
	},
	{
		headerFields: [],
		bodyFields: []
	}
);

export const getMobileHeaderFields = () => mobileFields.headerFields;
export const getMobileBodyFields = () => mobileFields.bodyFields;

const getLabel = (lang, columnKey) => {
	switch (columnKey) {
		case 'id':
			return sat_api.getLabel(lang, 'order_id');

		case 'delivery_datetime':
			return sat_api.getLabel(lang, 'delivery_time');

		case 'customer':
			return sat_api.getLabel(lang, 'customer');

		case 'biz_location_name':
			return sat_api.getLabel(lang, 'store');

		case 'order_total':
			return sat_api.getLabel(lang, 'amount');

		case 'time_left':
			return sat_api.getLabel(lang, 'time_left');

		case 'created':
			return sat_api.getLabel(lang, 'order_placed');

		case 'order_state':
			return sat_api.getLabel(lang, 'status');

		default:
			return '';
	}
};

export const getFields = (orderType, lang) => {
	const mapKeysToFields = fieldNames => {
		return fieldNames.map(columnKey => {
			const sortInfo = orderType === 'past-order' ? { sortable: false } : {};

			return {
				...columns[columnKey],
				label: getLabel(lang, columnKey) || columns[columnKey].label,
				...sortInfo
			};
		});
	};

	switch (orderType) {
		case 'placed':
			return mapKeysToFields([
				'id',
				'created',
				'delivery_datetime',
				'biz_location_name',
				'time_left',
				'customer',
				'order_total'
			]);

		case 'other':
			return mapKeysToFields([
				'id',
				'created',
				'delivery_datetime',
				'biz_location_name',
				'time_left',
				'customer',
				'order_total',
				'order_state'
			]);

		case 'completed':
			return mapKeysToFields([
				'id',
				'created',
				'delivery_datetime',
				'biz_location_name',
				'customer',
				'order_total'
			]);

		case 'cancelled':
			return mapKeysToFields([
				'id',
				'created',
				'delivery_datetime',
				'biz_location_name',
				'customer',
				'order_total'
			]);

		case 'future':
			return mapKeysToFields([
				'id',
				'created',
				'delivery_datetime',
				'biz_location_name',
				'time_left',
				'customer',
				'order_total'
			]);

		case 'past-order':
			return mapKeysToFields([
				'id',
				'created',
				'delivery_datetime',
				'biz_location_name',
				'customer',
				'order_total'
			]);

		default:
			return mapKeysToFields([
				'id',
				'created',
				'delivery_datetime',
				'biz_location_name',
				'customer',
				'order_total'
			]);
	}
};

export const getDefaultSortOptions = orderType => {
	switch (orderType) {
		case 'placed':
			return {
				sortBy: 'delivery_datetime',
				sortDirection: 'ASC'
			};

		case 'other':
			return {
				sortBy: 'delivery_datetime',
				sortDirection: 'ASC'
			};

		case 'completed':
			return {
				sortBy: 'delivery_datetime',
				sortDirection: 'DESC'
			};

		case 'cancelled':
			return {
				sortBy: 'delivery_datetime',
				sortDirection: 'DESC'
			};

		case 'future':
			return {
				sortBy: 'delivery_datetime',
				sortDirection: 'ASC'
			};

		default:
			return {
				sortBy: 'delivery_datetime',
				sortDirection: 'ASC'
			};
	}
};

export const getSortedData = (data, sortBy, sortDirection) => {
	let sortedData = _.sortBy(data, field => field[sortBy]);

	if (sortDirection === 'DESC') {
		return sortedData.reverse();
	}

	return sortedData;
};

export const getMinColumnWidth = columnKey => {
	switch (columnKey) {
		case 'id':
			return 200;
		case 'created':
			return 125;
		case 'delivery_datetime':
			return 125;
		case 'biz_location_name':
			return 250;
		case 'time_left':
			return 100;
		case 'customer':
			return 175;
		case 'order_total':
			return 100;
		case 'order_state':
			return 125;
		default:
			return 100;
	}
};

export const getMinTableWidth = fields => {
	const headerFields = IS_MOBILE ? mobileFields.headerFields : fields;

	return headerFields.reduce(
		(acc, { columnKey }) => getMinColumnWidth(columnKey) + acc,
		0
	);
};
