import React, { Component } from 'react';

// third party
import { connect } from 'react-redux';
import Select from 'react-select';

// redux
import { store } from '../store.js';
import { getStores } from '../actions';

@connect((store)=>({
	loginReducer: store.loginReducer,
	bizStoresReducer: store.bizStoresReducer,
}))
export default class BizDropdown extends Component {
	cbSelect = (selectedOption) => {
		store.dispatch({
			type: 'SELECT_BIZ_STORE',
			payload: {
				value: '',
				label: '',
			},
		});
		store.dispatch({
			type: 'SELECT_BIZ',
			payload: selectedOption,
		});
		if (this.props.showOnlySubscribed) {
			const { selectedStores } = this.props.settings;
			const bizStore = selectedStores.find((s) => s.biz_id === selectedOption.value)
			store.dispatch({
				type: 'SELECT_BIZ_STORE',
				payload: bizStore,
			});
		} else {
			store.dispatch(getStores({biz_id: selectedOption.value}));
		}
	}

	renderAllBizs = () => {
		const { selectedBiz } = this.props.bizStoresReducer;
		var bizs = this.props.loginReducer.data.bizs;
		const bizs_for_select = bizs.map(biz => ({
			label: biz.name,
			value: biz.biz_id,
		}));
		return (
			<Select
				className="inventory-control-store custom-label-name"
				optionClassName="needsclick"
				onChange={this.cbSelect}
				value={selectedBiz}
				options={bizs_for_select}
				placeholder= 'Select Biz'
				autoBlur={true}
			/>
		);
	}

	renderSubscribedBizs = () => {
		const { selectedBiz } = this.props.bizStoresReducer;
		const { selectedStores } = this.props.settings;
		const subscribedBizs = [];
		const map = new Map();
		for (const item of selectedStores) {
			if (!map.has(item.biz_id)) {
				map.set(item.biz_id, true);
				subscribedBizs.push({
					label: item.biz_name,
					value: item.biz_id,
				});
			}
		}
		return (
			<Select
				className="inventory-control-stor"
				optionClassName="needsclick"
				onChange={this.cbSelect}
				value={selectedBiz}
				options={subscribedBizs}
				placeholder= 'Select Biz'
				autoBlur={true}
			/>
		);
	}

	render() {
		const { showOnlySubscribed } = this.props;
		return (
			<div className="store-dropdown-hq biz-dropdown-hq inp-field-custom-label">
				{
					this.props.hasLabel &&
					<div className="custom-label">Business</div>
				}
				<div>
				{
					showOnlySubscribed
					?
					this.renderSubscribedBizs()
					:
					this.renderAllBizs()
				}
				</div>
			</div>
		);
	}
}
