export const burgerMenu = (state = {
	open: false
}, action)=>{
	switch (action.type) {
		case "TOGGLE_BURGER_MENU":
			return {
				open: action.payload
			 }
			break;
		default:
			return state;

	}
}

export default burgerMenu;
