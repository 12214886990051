export const barcodeSatus = (state = {
	barcode: null,
	state: null,
	visible: false
}, action)=>{
	switch (action.type) {
		case "BARCODE_SCANNED_INIT":
			return {
				...state,
				state: "in-progress",
				barcode: action.payload,
				visible: true
			}
			break;
		case "BARCODE_SCANNED_FINISHED":
			return {
				...state,
				state: "success",
				visible: true
			}
			break;
		case "BARCODE_SCANNED_FAILED":
			return {
				...state,
				state: "failed",
				visible: true
			}
			break;
		case "HIDE_BARCODE_SCANNED_SECTION":
			return {
				...state,
				visible: false
			}
			break;
		default:
			return state;

	}
}

export default barcodeSatus
