import React from 'react';
import { store } from './store.js';
import history from './history.js';
import { connect } from 'react-redux';
import { set as IDBset, get as IDBget, clear as IDBclear } from 'idb-keyval';

// import MainTable from './MainTable/MainTable';

import {
	itemSearchFromServer,
	reOrder,
	getReco,
	addItemToCart,
	getOrderDetail,
	fetchActiveOrder,
	fetchInActiveOrder,
	dropOrder,
	fetchAllOrder
} from './actions.js';

import { Link } from 'react-router-dom';
import {
	setReceivedPhoneno,
	logoutAction,
	orderStateUpdate,
	dismissCallModal,
	addComplexItemToCart,
	reqCallback,
	setDeliveryExec
} from './actions.js';

import Moment from './clientTime';
import Geosuggest from 'react-geosuggest';
import Select from 'react-select';

import Sound from 'react-sound';
import { extractCityPinFormGoogle } from '../js/utils';

var sat_api = require('../js/api.js');
var _ = require('lodash');


@connect(store => {
	return {
		loginReducer: store.loginReducer
	};
})
export class StoreSelector extends React.Component {
	constructor(props) {
		super(props);
		var defOption = this.props.loginReducer.data.sub_admin[0];
		this.state = {
			selectedOption: {
				label: defOption.name,
				value: defOption.biz_location_id
			}
		};
	}
	handleChange(selectedOption) {
		this.setState({
			selectedOption
		});
		if (!selectedOption) return;
		this.props.cbSelect(selectedOption);
	}
	render() {
		var _stores = this.props.loginReducer.data.sub_admin;
		var _store_for_select = [];
		_stores.map(o => {
			_store_for_select.push({
				label: o.name,
				value: o.biz_location_id
			});
		});

		if (_store_for_select.length > 1) {
			return (
				<Select
					optionClassName="needsclick"
					options={_store_for_select}
					placeholder="Select store"
					onChange={this.handleChange.bind(this)}
					value={this.state.selectedOption ? this.state.selectedOption : {}}
					autoBlur={true}
				/>
			);
		} else {
			return <div>{_store_for_select[0].name}</div>;
		}
	}
}

export class OrderStatusSelection extends React.Component {
	setNextState(e) {
		this.props.setNextState(e.target.getAttribute('data-value'));
	}

	render() {
		var status = this.props.next_states;
		var statusObj = sat_api.getAvailableStatusObj(status);

		var option_nodes = statusObj.map(o => {
			return (
				<div key={o.key} className="m-b-10">
					<input
						onChange={e => this.setNextState(e)}
						type="radio"
						className="m-r-10"
						data-value={o.status_param}
						checked={o.status_param == this.props.selected_status}
					/>
					<a
						className="cursor-pointer"
						data-value={o.status_param}
						onClick={e => this.setNextState(e)}
					>
						{o.title}
					</a>
				</div>
			);
		});
		return <div className="padding-10">{option_nodes}</div>;
	}
}

export class OrderCancelReasonSelection extends React.Component {
	setReason = (e) => {
		this.props.setReason(e.target.getAttribute('data-value'));
	}
	render() {
		const { options, cancelReason } = this.props;
		const option_nodes = options.map(opt => {
			return (
				<div key={opt.value} className="m-b-10">
					<input
						onChange={e => this.setReason(e)}
						type="radio"
						className="m-r-10"
						data-value={opt.value}
						checked={opt.value === cancelReason}
					/>
					<a
						className="cursor-pointer"
						data-value={opt.value}
						onClick={e => this.setReason(e)}
					>
						{opt.label}
					</a>
				</div>
			);
		});
		return <div className="padding-10">{option_nodes}</div>;
	}
}

export class TippyComponent extends React.Component {
	componentDidMount() {
		var tippyClass = this.props.classname;
		new Tippy('.' + tippyClass, {
			arrow: true,
			animation: 'perspective'
		});
	}
	render() {
		return (
			<div title={this.props.title} className={this.props.classname}>
				{this.props.children}
			</div>
		);
	}
}


export class DropOrder extends React.Component {
	constructor(props) {
		super(props);
		this.state = { confirm: false };
	}
	confirmDrop() {
		this.setState({ confirm: true });
		setTimeout(() => {
			this.setState({ confirm: false });
		}, 5000);
	}
	dropOrder() {
		//if(confirm('Are you sure?')){
		store.dispatch(dropOrder());
		//}
	}
	canceldropOrder() {
		this.setState({ confirm: false });
	}
	render() {
		var footerActiveClass = store.getState().currentOrderSession.orderStarted
			? 'active'
			: '';
		var confirm = this.state.confirm;
		if (confirm) {
			var _markup = (() => {
				return (
					<div>
						<a
							onClick={e => this.canceldropOrder(e)}
							className="up-button secondary-2 default m-r-10 small"
						>
							No
						</a>
						<a
							onClick={e => this.dropOrder(e)}
							className="up-button default dismiss small "
						>
							Yes
						</a>
					</div>
				);
			})();
		} else {
			var _markup = (() => {
				return (
					<a
						onClick={e => this.confirmDrop(e)}
						className="up-button default dismiss small"
					>
						Drop Order
					</a>
				);
			})();
		}
		return (
			<div className={footerActiveClass + ' app-footer'}>
				<div>{_markup}</div>
			</div>
		);
	}
}


// not being used anywhere.
@connect(store => {
	return {
		currentOrderSession: store.currentOrderSession,
		callReducer: store.callReducer
	};
})
export class NewCallNotiInFooter extends React.Component {
	openCallModal() {
		store.dispatch({
			type: 'OPEN_CALLMODAL',
			payload: null
		});
	}
	repeat() {
		this.forceUpdate();
	}
	render() {
		var isActive =
			!this.props.currentOrderSession.orderStarted &&
			this.props.callReducer.show &&
			!this.props.callReducer.modalactive
				? true
				: false;
		return (
			<div
				onClick={e => this.openCallModal(e)}
				className={(isActive ? 'active' : '') + ' app-footer'}
			>
				<div>
					<div className="up-button default dismiss small">
						New Incoming Call
					</div>
				</div>
			</div>
		);
	}
}

@connect(store => {
	return {
		loginReducer: store.loginReducer
	};
})
export class CurrCode extends React.Component {
	render() {
		let sign = '';
		if (this.props.loginReducer.loggedIn) {
			const code = this.props.loginReducer.data.biz.currency_code;
			if (code === 'INR') {
				sign = <span>Rs.</span>
			} else {
				sign = code;
			}
		}
		return (
			<span>
				{sign}
			</span>
		);
	}
}
export const ArrowUP = () => <span>&#x2191;</span>;

export const ArrowDefault = () => (
	<span className="secondary-text">&#x21c5;</span>
);

export const ArrowDown = () => <span>&#x2193;</span>;

@connect(store => {
	return {
		orderList: store.orderList,
		allOrderList: store.allOrderList
	};
})
export class RefreshList extends React.Component {
	constructor(props) {
		super(props);
	}
	refresh() {
		store.dispatch(fetchInActiveOrder());
		store.dispatch(fetchActiveOrder());

		IDBclear();

		const _date = this.props.allOrderList.dateFilter;

		let noCache = false;
		if (_date > new Date().getTime()) {
			noCache = true;
		}

		store.dispatch(fetchAllOrder(new Date(_date).getTime(), null, noCache));

		sat_api.MP_TRACK('list_refresh', {});
	}
	render() {
		var className = this.props.orderList.loading ? 'rotate-infinite' : '';
		return (
			<div
				style={{
					backgroundImage: 'url(/images/refresh.png)',
					backgroundPosition: 'center',
					backgroundSize: '22px',
					backgroundRepeat: 'no-repeat',
					height: '44px',
					width: '44px'
				}}
				onClick={() => this.refresh()}
				className={className + ' refresh-list-button'}
			/>
		);
	}
}


@connect(store => {
	return {
		appLanguage: store.appLanguage.lang,
	};
})
export class PrintBtn extends React.Component {
	render() {
		return (
			<div onClick={this.props.onClick} className="print-button">
				&#9113;&nbsp;{sat_api.getLabel(this.props.appLanguage, 'print') || 'Print'}
			</div>
		);
	}
}

export class Pod extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className="m-t-10">
				<div className="secondary-text m-b-5">POD:</div>
				<div>{this.props.data.title}</div>
			</div>
		);
	}
}
@connect(store => {
	return {
		status: store.gReducer
	};
})
export class PageRefresh extends React.Component {
	constructor(props) {
		super(props);
	}
	refetch() {
		if (this.props.status.id) {
			store.dispatch(getOrderDetail({ id: this.props.status.id }));
		} else {
			window.location.reload(true);
		}
	}

	render() {
		if (this.props.status.active) {
			var className = 'active';
		} else {
			var className = '';
		}
		return (
			<div className={className + ' err-modal-full-screen'}>
				<div className="content">
					<div>{this.props.status.msg}</div>
					<a className="up-button m-t-10" onClick={this.refetch.bind(this)}>
						Try Again
					</a>
				</div>
			</div>
		);
	}
}

@connect(store => {
	return {
		appLanguage: store.appLanguage
	};
})
export class ColorIns extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const lang = this.props.appLanguage.lang;
		return (
			<div className="color-instructions">
				<div className="color-codes">
					<span className="ripple new-order" />
					<span className="color-desc">
						{sat_api.getLabel(lang, 'new_order') || 'New Order'}
					</span>
				</div>
				<div className="color-codes">
					<span className="ripple mid" />
					<span className="color-desc">
						&lt; {sat_api.getLabel(lang, '50_time_left') || '50% time left'}
					</span>
				</div>
				<div className="color-codes">
					<span className="ripple high" />
					<span className="color-desc">
						{sat_api.getLabel(lang, 'delivery_time_violation') ||
							'Delivery time violation'}
					</span>
				</div>
			</div>
		);
	}
}


@connect(store => {
	return {
		orderStateReducer: store.orderStateReducer,
		deliveryExecInfo: store.deliveryExecInfo
	};
})
export class DeliveryExecModal extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state =  {
			loading: false,
			comments: "",
			agent: null
		}
	}
	onChange =(e)=>{
		this.setState({
			comments: e.target.value
		})
	}

	setAgent = (id)=>{

		this.setState({
			agent: id
		})
	}

	closeModal =()=> {
		store.dispatch({
			type: 'STATUS_CHANGE_MODAL',
			payload: {
				showDeliveryExecModal: false
			}
		});
	}

	assignDelivery =()=>{
		store.dispatch(setDeliveryExec({
			order_id:this.props.id,
			user_id: this.state.agent,
			message: this.state.comments,
			action: 'assign'
		}))
	}
	render(){
		const agents = this.props.deliveryExecInfo.delivery_agents
		const agentsMap = this.props.deliveryExecInfo.delivery_agents.map((i)=>{
			return(
				<div onClick={()=>this.setAgent(i.user_id)} className="modal-content delivery-executive" key={i.user_id}>
					<input readOnly type="radio" name={i.user_id} value="" checked={i.user_id === this.state.agent}/>
					<div>
						<span className="user-info--name">{i.name}</span>
						<span className="user-info--phone">{i.phone}</span>
					</div>
					<div className="active-order-info">
						<span>{i.assigned_deliveries_count} active order(s)</span>
					</div>
				</div>
			)
		})

		if(!this.props.orderStateReducer.showDeliveryExecModal) {
			return null
		}
		return(
			<Modal
				closeModal={this.closeModal}
				scrollPos={this.props.scrollPos}
				title={"Assign delivery executive"}
			>
				<div className="modal-container--delivery-exec">
					{agentsMap}
				</div>
				<textarea
					value={this.state.comments}
					autoFocus
					onChange={this.onChange}
					placeholder="Comments"
				/>
				<div
					className={
						(this.props.deliveryExecInfo.loading ? 'disabled' : '') +
						' text-right status-change-btn-container'
					}
				>
					<a
						className="up-button  default secondary half-width"
						onClick={this.closeModal}
					>
						No
					</a>
					<a
						className="up-button  default primary half-width"
						onClick={this.assignDelivery}
					>
						Yes
					</a>
				</div>
			</Modal>
		)
	}
}

@connect(store => {
	return {
		orderStateReducer: store.orderStateReducer,
		deliveryExecInfo: store.deliveryExecInfo
	};
})
export class RiderTempModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.thresholdTemp = 99;
		this.state =  {
			loading: false,
			openTempModal: false,
			temp: '',
			phone: '',
			sendBlankTempCall: false,
			tempHigh: false,
		};
	}

	handleTemp = (e) => {
		this.setState({
			temp: e.target.value,
		});
	}

	handlePhone = (e) => {
		if (e.target.value) {
			const lastChar = e.target.value[e.target.value.length - 1];
			if (!lastChar.match(/[0-9\+]/)) {
				return;
			}
		}
		this.setState({
			phone: e.target.value,
		});
	}

	handleTempHigh = (tempHigh) => {
		this.setState({
			openTempModal: true,
			tempHigh,
			sendBlankTempCall: !tempHigh,
		});
	}

	closeModal = (event, riderTempCall=false) => {
		if (riderTempCall) {
			const data = {
				action: 'rider_body_temp',
				order_id: this.props.order_id,
			};
			sat_api.setRiderTemp(data, () => {}, () => {});
		}
		this.setState({
			openTempModal: false,
			temp: '',
			phone: '',
		});
		store.dispatch({
			type: 'STATUS_CHANGE_MODAL',
			payload: {
				showRiderTempModal: false,
			},
		});
	}

	submitTemp = () => {
		const { delivery_info, order_id } = this.props;
		const { phone, temp } = this.state;
		let rider_name = 'delivery person';
		if (delivery_info && delivery_info.delivery_person_details) {
			rider_name = delivery_info.delivery_person_details.name;
		}
		const data = {
			action: 'rider_body_temp',
			temp,
			order_id,
		};
		if (phone) {
			if ((phone[0] === '+' && phone.length < 12) ||
				phone.length < 10) {
				alert('Please enter a valid phone number');
				return;
			}
			data.phone = phone;
		}
		this.setState({
			loading: true,
		});
		sat_api.setRiderTemp(data, (resp) => {
			this.setState({
				loading: false,
			});
			if (temp >= this.thresholdTemp) {
				alert(`Please don't hand over the food to ${rider_name}. Contact Zomato Support on 011-49894800 to get a new rider assigned.`);
			} else {
				alert('Rider temperature has been submitted successfully');
			}
			this.closeModal();
		}, (err) => {
			console.log(err);
			this.setState({
				loading: false,
			});
			alert('There was an error while submitting temperature to zomato, please try again.');
		});
	}

	handleCloseModal = (e) => {
		this.closeModal(e, this.state.sendBlankTempCall);
	}

	render() {
		const { openTempModal, temp, phone } = this.state;
		if(!this.props.orderStateReducer.showRiderTempModal) {
			return null
		}
		return (
			<Modal
				closeModal={this.handleCloseModal}
				scrollPos={this.props.scrollPos}
				title={"Rider temperature"}
			>
			{
				openTempModal ?
				<div className="modal-container--rider-temp">
					<div className="title">
						Is the rider temperature normal (below {this.thresholdTemp}&deg;F)?
					</div>
					<div>
						<input
							value={temp}
							placeholder="Enter temp here"
							onChange={this.handleTemp}
							className="rider-temp-inp"
							type="number"
						/>
					</div>
					<div>
						<input
							placeholder="Enter phone (if self-delivery)"
							onChange={this.handlePhone}
							value={phone}
							className="rider-temp-inp"
							type="text"
						/>
					</div>
					<div
						className={
							(this.state.loading || !this.state.temp ? 'disabled no-click' : '') +
							' text-right rider-temp-btn-container'
						}
					>
						<a
							className="up-button default primary full-width"
							onClick={this.submitTemp}
						>
							Submit
						</a>
					</div>
				</div>
				:
				<>
					<div className="modal-container--rider-temp">
						Is the rider temperature normal (below {this.thresholdTemp}&deg;F)?
					</div>
					<div
						className={
							(this.props.deliveryExecInfo.loading ? 'disabled' : '') +
							' text-right status-change-btn-container'
						}
					>
						<a
							className="up-button  default secondary half-width"
							onClick={() => this.handleTempHigh(true)}
						>
							No
						</a>
						<a
							className="up-button  default primary half-width"
							onClick={(e) => this.handleTempHigh(false)}
						>
							Yes
						</a>
					</div>
				</>
			}
			</Modal>
		);
	}
}

class Modal extends React.PureComponent{
	constructor(props){
		super(props);
	}

	render() {
		return(
			<div
				className="up-overlay"
				id="up_custom_popup"
				onClick={this.props.closeModal}
				style={{top: this.props.scrollPos}}
			>
				<div
					className="up-modal stop-propagation custom-popup-modal rounded"
					onClick={e => e.stopPropagation(e)}
				>
					<div>
						<div className="status-change-header">
							{this.props.title}
						</div>
						<div className="up-modal-content">
							{this.props.children}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

@connect((store) => ({
	orderStateReducer: store.orderStateReducer,
	cancellation_reasons: store.loginReducer.data.cancellation_reasons,
	appLanguage: store.appLanguage.lang,
}))
export class StatusChangeconfirmModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			next_state: null,
			cancelReason: 'store_busy',
		};
	}

	handleCancelReason = (cancelReason) => {
		this.setState({
			cancelReason,
		});
	}

	statusChange(data) {
		let orderStateReducer = this.props.orderStateReducer;
		store.dispatch(
			orderStateUpdate({
				id: data.id,
				new_state: this.state.next_state,
				changeStatus: true,
				message: orderStateReducer.new_state === 'cancelled' ? this.state.cancelReason : this.state.msg,
				curr_state: orderStateReducer.curr_state,
				channel: orderStateReducer.channel
			})
		);
	}

	componentWillReceiveProps(d) {
		if (
			this.props.orderStateReducer.showModal != d.orderStateReducer.showModal &&
			d.orderStateReducer.showModal == true
		) {
			let orderStateReducer = d.orderStateReducer;
			if (orderStateReducer.next_states) {
				var statusObj = sat_api.getAvailableStatusObj(
					orderStateReducer.next_states
				);
				this.setState({ next_state: statusObj[0].status_param });
			}
			if (d.orderStateReducer.new_state) {
				this.setState({
					next_state: d.orderStateReducer.new_state
				});
			}
		}
	}
	closeModal() {
		this.props.dispatch({
			type: 'STATUS_CHANGE_MODAL',
			payload: { showModal: false }
		});
	}

	onChange(e) {
		this.setState({
			msg: e.target.value
		});
	}
	setNextState(e) {
		this.setState({ next_state: e });
	}
	componentDidMount() {
		window.addEventListener('keydown', e => {
			if (e.key === 'Escape' || e.keyCode === 27) {
				this.closeModal();
			}
		});
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.closeModal);
	}

	render() {
		if (!this.props.orderStateReducer.showModal) {
			return null;
		} else {
			let orderStateReducer = this.props.orderStateReducer;
			if (orderStateReducer.new_state) {
				var new_stateObj = _.find(sat_api.MISC_CONFIG.statusMap, {
					param: this.props.orderStateReducer.new_state
				});
			}
			return (
				<div
					className="up-overlay"
					id="up_custom_popup"
					onClick={e => this.closeModal(e)}
					style={{ top: this.props.scrollPos }}
				>
					<div
						className="up-modal stop-propagation custom-popup-modal rounded"
						onClick={e => e.stopPropagation(e)}
					>
						<div>
							<div className="status-change-header">
								{(new_stateObj && sat_api.getLabel(this.props.appLanguage, new_stateObj.title)) ? (sat_api.getLabel(this.props.appLanguage, new_stateObj.title) + ' ?') : 'Set order status'}
							</div>
							<div className="up-modal-content">
								{orderStateReducer.new_state ? (
									''
								) : (
									<OrderStatusSelection
										next_states={this.props.orderStateReducer.next_states}
										selected_status={this.state.next_state}
										setNextState={e => {
											this.setNextState(e);
										}}
									/>
								)}
								{
									orderStateReducer.new_state === 'cancelled' ?
									<OrderCancelReasonSelection
										setReason={this.handleCancelReason}
										options={this.props.cancellation_reasons}
										cancelReason={this.state.cancelReason}
									/>
									:
									<textarea
										autoFocus
										onChange={this.onChange.bind(this)}
										placeholder="Comments"
									/>
								}
								<div
									className={
										(this.state.next_state ? '' : 'disabled') +
										' text-right status-change-btn-container'
									}
								>
									<a
										className="up-button  default secondary half-width"
										onClick={this.closeModal.bind(this)}
									>
										No
									</a>
									<a
										className="up-button  default primary half-width"
										onClick={this.statusChange.bind(this, {
											id: this.props.orderStateReducer.id,
											new_state: new_stateObj ? new_stateObj.param : null
										})}
									>
										Yes
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

@connect(store => {
	return {
		callReducer: store.callReducer
	};
})
export class CallModal extends React.Component {
	constructor(props) {
		super(props);
	}
	closePopup() {
		store.dispatch(dismissCallModal());
	}
	minimize() {
		store.dispatch({
			type: 'MINIMIZE_CALLMODAL',
			payload: null
		});
	}
	pushCallerNo() {
		let caller_no = this.props.callReducer.data.phone;
		caller_no = caller_no.substr(caller_no.length - 10);
		store.dispatch(setReceivedPhoneno({ phone_no: caller_no }));
		store.dispatch(dismissCallModal());
		history.push('/start-order');
	}

	render() {
		if (this.props.callReducer.show) {
			//do nothing
		} else {
			return null;
		}
		let customer_phone = this.props.callReducer.data.phone;

		let _customer_data = this.props.callReducer.caller_data;
		let customer_name = _customer_data.name;

		let no_of_order = _customer_data.order_count;
		let total_amount = _customer_data.total_order_value;
		let average_order_value = _customer_data.average_order_value;
		let cvi = _customer_data.cvi;
		return (
			<div
				className={
					(!this.props.callReducer.modalactive ? 'minimize' : '') +
					' up-overlay'
				}
				id="call_popup"
			>
				<div className="up-modal stop-propagation custom-popup-modal rounded">
					<div className="caller-header text-center padding-20 m-b-20">
						<div onClick={e => this.minimize(e)} className="minimize" />
						<div className="uppercase theme-color-3-text m-b-20">
							incoming call
						</div>
						<div className="uppercase large-text m-b-10">{customer_name}</div>
						<div className="uppercase large-text m-b-20">{customer_phone}</div>
					</div>
					<div className="caller-detail">
						<div className="call-icon float-left">
							<div className="call-animation-container" />
						</div>
						<div className="float-left">
							<div className="div-table caller-detail-table">
								<div className="div-table-row">
									<div className="div-table-cell">No of orders</div>
									<div className="div-table-cell">{no_of_order}</div>
								</div>
								<div className="div-table-row">
									<div className="div-table-cell">Total Amount</div>
									<div className="div-table-cell">{total_amount}</div>
								</div>
								<div className="div-table-row">
									<div className="div-table-cell">Average order value</div>
									<div className="div-table-cell">{average_order_value}</div>
								</div>
								<div className="div-table-row">
									<div className="div-table-cell">CVI</div>
									<div className="div-table-cell">{cvi}%</div>
								</div>
							</div>
							<div className="padding-10">
								<div className="cvi-bar">
									<div className="cvi-indicator" style={{ width: cvi + '%' }} />
								</div>
							</div>
						</div>
						<div className="clearfix" />
					</div>
					<div className="call-button-container padding-20 text-center">
						<a
							className="up-button  large secondary default fixed-width"
							onClick={this.closePopup.bind(this)}
						>
							Reject
						</a>
						<a
							className="up-button large primary default fixed-width m-l-10"
							onClick={e => this.pushCallerNo(e)}
						>
							Accept
						</a>
					</div>
				</div>
			</div>
		);
	}
}

class DeliveryAddress extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className="div-table delivery-address order-history-sub-row">
				<div className="div-table-cell secondary-text sub-row-cat">
					Delivery Address
				</div>
				<div className="div-table-cell">
					<div className="div-table">
						<div className="div-table-cell">{this.props.address}</div>
					</div>
				</div>
			</div>
		);
	}
}
class ItemNodeOrderHistory extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		var items = this.props.items;
		var itemMarkup = items.map(o => (
			<div className="div-table-row" key={o.id}>
				<div className="div-table-cell">{o.title}</div>
				<div className="div-table-cell">{o.quantity}</div>
				<div className="div-table-cell">
					<CurrCode /> {o.price * o.quantity}
				</div>
			</div>
		));

		return (
			<div className="order-history-sub-row">
				<div className="div-table">
					<div className="secondary-text div-table-cell sub-row-cat">
						Item Details
					</div>
					<div className="div-table-cell">
						<div className="div-table">{itemMarkup}</div>
					</div>
				</div>
			</div>
		);
	}
}

@connect(store => {
	return {
		currentOrderSession: store.currentOrderSession
	};
})
export class OrderHistoryComp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false
		};
	}
	viewDetail() {
		this.setState({ active: !this.state.active });
	}
	reOrder(e, id) {
		store.dispatch(
			reOrder({
				id: id,
				phone: this.props.currentOrderSession.user.phone,
				location_id: this.props.currentOrderSession.store.biz_location_id
			})
		);
	}
	reorderMsg(msg) {
		return <div className="padding-10">{msg}</div>;
	}
	render() {
		var selected_store = this.props.currentOrderSession.store;
		var lastOrder = this.props.lastOrder;
		var reorder = this.props.currentOrderSession.reorder;
		if (lastOrder) {
			return (
				<div>
					<div className="padding-10 large-text uppercase text-center">
						Last Order{' '}
						{this.props.currentOrderSession.user &&
						/^[a-zA-Z\s]+$/.test(this.props.currentOrderSession.user.name)
							? 'of ' + this.props.currentOrderSession.user.name
							: ''}
					</div>
					<div className="order-history-row">
						<div className="order-history-header">
							<div className="float-left">
								Placed on {Moment(lastOrder.time).format('D MMM, Y, h:mm a')}
							</div>
							<div className="clearfix" />
						</div>
						<div className="order-history-summary">
							<div className="div-table">
								<div className="div-table-cell">
									<span className="large-text">
										Order ID:{lastOrder.extras.id}
									</span>
								</div>
								<div className="div-table-cell">
									<div
										className={
											lastOrder.extras.status.toLowerCase() +
											' order-history-status large-text'
										}
									>
										{lastOrder.extras.status}
									</div>
									<div className="secondary-text">
										on{' '}
										{Moment(lastOrder.extras.delivery_datetime).format(
											'D MMM, Y, h:mm a'
										)}
									</div>
								</div>
								<div className="div-table-cell">
									<div className="large-text">
										<CurrCode /> {lastOrder.extras.amount}
									</div>
									<div className="secondary-text">
										Payment Mode: {lastOrder.extras.payment_mode}
									</div>
								</div>
							</div>
						</div>
						<div
							className={
								(this.state.active ? 'active' : '') +
								' order-history-items-detail'
							}
						>
							<DeliveryAddress address={lastOrder.extras.address} />
							<ItemNodeOrderHistory items={lastOrder.extras.items} />
							<div className="text-center">
								<a
									className={
										(selected_store ? '' : 'disabled') +
										(reorder.loading ? ' progress-status' : '') +
										' up-button default small primary'
									}
									onClick={e => this.reOrder(e, lastOrder.extras.id)}
								>
									Reorder
								</a>
							</div>
							<div className="small-text secondary-text text-center">
								{!selected_store
									? this.reorderMsg('Please select a store first to reorder')
									: ''}
								{reorder.error ? this.reorderMsg(reorder.msg) : ''}
							</div>
						</div>
						<div
							className="view-order-history-detail text-center"
							onClick={e => this.viewDetail(e)}
						>
							<a>{this.state.active ? 'Hide' : 'View'} Detail</a>
						</div>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

class ItemAddButton extends React.Component {
	constructor(props) {
		super(props);
	}
	addItemToCart(e, d) {
		if (d.item.option_groups && d.item.option_groups.length > 0) {
			store.dispatch(addComplexItemToCart(d));
		} else {
			var _cart = { ...store.getState().currentOrderSession.cart };
			var processd_data = sat_api.processCart({ cart: _cart, item_data: d });
			store.dispatch(addItemToCart(processd_data));
		}
	}
	render() {
		var item = this.props.item;
		return (
			<a
				onClick={e =>
					this.addItemToCart(e, { item: item, action: 'add-to-cart' })
				}
				className="item-button add-button"
			>
				Add
			</a>
		);
	}
}

class ItemAddDelete extends React.Component {
	constructor(props) {
		super(props);
	}
	addItemToCart(e, d) {
		if (d.item.option_groups && d.item.option_groups.length > 0) {
			d.item.type = 'complex-item';
			var _cart = { ...store.getState().currentOrderSession.cart };

			if (d.action == 'add-to-cart') {
				if (
					d.item.current_stock != -1 &&
					this.props.count >= d.item.current_stock
				) {
					return false;
				}
				if (this.props.source == 'cart') {
					var processd_data = sat_api.processCart({
						cart: _cart,
						item_data: d
					});
					store.dispatch(addItemToCart(processd_data));
				} else {
					store.dispatch(addComplexItemToCart(d));
				}
			} else {
				var processd_data = sat_api.processCart({ cart: _cart, item_data: d });
				store.dispatch(addItemToCart(processd_data));
			}
		} else {
			if (d.action == 'add-to-cart') {
				if (
					d.item.current_stock != -1 &&
					this.props.count >= d.item.current_stock
				) {
					return false;
				}
			}
			var _cart = { ...store.getState().currentOrderSession.cart };
			var processd_data = sat_api.processCart({ cart: _cart, item_data: d });
			store.dispatch(addItemToCart(processd_data));
		}
	}

	render() {
		var item = this.props.item;
		var current_stock = item.current_stock;
		return (
			<div className="item-button add-delete-button">
				<a
					className="item-delete"
					onClick={e =>
						this.addItemToCart(e, { item: item, action: 'delete-from-cart' })
					}
				>
					-
				</a>
				<a className="item-count">{this.props.count}</a>
				<a
					className={
						(current_stock != -1 && this.props.count >= current_stock
							? 'disabled'
							: '') + ' item-add'
					}
					onClick={e =>
						this.addItemToCart(e, { item: item, action: 'add-to-cart' })
					}
				>
					+
				</a>
			</div>
		);
	}
}

export class FoodType extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		var food_type = this.props.type;
		switch (food_type) {
			case '1':
				var className = 'veg';
				break;
			case '2':
				var className = 'non-veg';
				break;
			case '3':
				var className = 'egg';
				break;
			default:
				var className = 'no-type';
		}
		if (className == 'no-type') {
			return null;
		} else {
			return <div className={className + ' food-type'}>&#9673;</div>;
		}
	}
}
export class ItemCard extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		var inCart = this.props.incart;
		var item = this.props.item;
		var stock = item.current_stock;
		var recommended = this.props.recommended;

		var OutOfStock = () => {
			return <div className="out-of-stock">Out of stock</div>;
		};

		return (
			<div
				className={(recommended ? 'recommended' : '') + ' item-card'}
				key={item.id}
			>
				<div className="item-container">
					<FoodType type={item.food_type} /> {item.item_title}
				</div>
				<div className="item-control">
					<div
						className={
							(item.item_price == 0 ? 'hide' : '') + ' float-left price'
						}
					>
						<CurrCode />
						{item.item_price}{' '}
						{item.price_descriptor ? item.price_descriptor : ''}
					</div>
					<div className="float-right">
						{stock == 0 ? (
							<OutOfStock />
						) : inCart ? (
							<ItemAddDelete item={item} count={this.props.quant} />
						) : (
							<ItemAddButton item={item} />
						)}
					</div>
					<div className="clearfix" />
				</div>
			</div>
		);
	}
}

@connect(store => {
	return {
		currentOrderSession: store.currentOrderSession,
		loginReducer: store.loginReducer,
		itemSearchStatus: store.itemSearchStatus
	};
})
export class SearchItems extends React.Component {
	constructor(props) {
		super(props);
	}
	searchIteminServer(e) {
		if (e.nativeEvent.keyCode === 13) {
			var payload = {
				kw: e.target.value,
				location_id: this.props.currentOrderSession.store.biz_location_id
			};
			store.dispatch(itemSearchFromServer(payload));
		}
	}
	searchItem(e) {
		store.dispatch({
			type: 'RESET_SERVER_SEARCH_STATUS',
			payload: null
		});
		var keyw = e.target.value.toLowerCase();

		let location_id = this.props.currentOrderSession.store.biz_location_id;

		if (keyw === '') {
			let cat_id = this.props.currentOrderSession.category.data[0].id;

			//let _fulfillment_mode = this.props.currentOrderSession.delivery_mode;
			var cache_key = 'items_' + cat_id + '_' + location_id;

			var itemsOfFirstCategory = _.find(sat_api.ITEM_LOCATION_CATEGORY_CACHE, {
				key: cache_key
			});
			store.dispatch({
				type: 'SET_SELECTED_CAT',
				payload: cat_id
			});
			store.dispatch({
				type: 'MENU_LOADED',
				payload: itemsOfFirstCategory.data
			});
			return;
		}

		// var options = {
		// 	shouldSort: true,
		// 	threshold: 0.3,
		// 	location: 0,
		// 	distance: 100,
		// 	maxPatternLength: 32,
		// 	minMatchCharLength: 1,
		// 	keys: [
		// 		"item_title",
		// 		"item_desc"
		// 	]
		// };
		// var fuse = new Fuse(_store_menu.items, options); // "list" is the item array
		// var searchedItems = fuse.search(keyw);
		// let searched_category_item = {..._store_menu}
		// searched_category_item.categories = _store_menu.categories
		// searched_category_item.items = searchedItems;
		// searched_category_item.selected_cat = "none";

		// store.dispatch({
		// 	type:'MENU_LOADED',
		// 	payload:searched_category_item
		// })

		var payload = {
			kw: keyw,
			location_id: location_id,
			biz_id: this.props.loginReducer.data.biz.biz_id
		};
		store.dispatch(itemSearchFromServer(payload));
	}
	render() {
		return (
			<div className="search-item-container relative-pos">
				<div
					className={
						(this.props.itemSearchStatus.loading ? 'active' : '') +
						' item-search-loader'
					}
				/>
				<div className="search-icon" />
				<div className="search-input">
					<input
						ref={input => {
							this.searchInput = input;
						}}
						type="text"
						placeholder="Search Items"
						onKeyPress={e => this.searchIteminServer(e)}
						onChange={e => this.searchItem(e)}
					/>
				</div>
			</div>
		);
	}
}

export class CartPreview extends React.Component {
	constructor(props) {
		super(props);
	}
	expandCart() {
		store.dispatch({
			type: 'SHOW_CART',
			payload: null
		});
	}
	render() {
		var cart = this.props.data.cart;
		return (
			<div
				className={
					(this.props.visibility ? 'hide-cart-preview' : '') +
					' cart-preview-container text-right'
				}
				onClick={e => this.expandCart(e)}
			>
				{cart.meta.item_count} Item(s) . <CurrCode /> {cart.meta.total}
			</div>
		);
	}
}

export class StaticOptions extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return <div className="static-optios-node">{this.props.option.title}</div>;
	}
}

export class StaticItemNode extends React.Component {
	constructor(props) {
		super(props);
	}

	deleteItemFromCart(e, d) {
		var item = { ...d };
		var item_data = {
			item: item,
			action: 'delete-all'
		};
		var _cart = { ...store.getState().currentOrderSession.cart };
		var processd_data = sat_api.processCart({
			cart: _cart,
			item_data: item_data
		});
		store.dispatch(addItemToCart(processd_data));
	}

	addItemInstructions (instructions) {
		const { item } = this.props;
		const data = {
			item: item,
			action: 'add-item-instructions',
			instructions: instructions,
		};
		if (data.item.option_groups && data.item.option_groups.length > 0) {
			data.item.type = 'complex-item';
		}
		const _cart = { ...store.getState().currentOrderSession.cart };
		const processd_data = sat_api.processCart({ cart: _cart, item_data: data });
		store.dispatch(addItemToCart(processd_data));
	}

	render() {
		var item = this.props.item;
		var item_total_price =
			item.item_price +
			_.reduce(
				item.options,
				function(s, entry) {
					return s + parseFloat(entry.price);
				},
				0
			);
		var optionNodes = item.options.map((o, i) => {
			return <StaticOptions option={o} key={i} />;
		});
		return (
			<div className="static-item-nodes-row">
				<div className="static-item-nodes">
					<div className="static-item-title float-left">
						<div className="med-text m-b-5">
							<FoodType type={item.food_type} /> {item.item_title}
						</div>
						<div className="m-l-10">{optionNodes}</div>
					</div>
					<div
						className="static-item-price med-text float-right cursor-pointer delete-all-from-cart"
						onClick={e => this.deleteItemFromCart(e, item)}
					>
						<a>x</a>
					</div>
				</div>
				<div className="add-delete-button-cart m-t-10">
					<div className="div-table">
						<div className="div-table-cell">
							<ItemAddDelete item={item} count={item.quantity} source="cart" />
						</div>
						<div className="div-table-cell">
							<span className="secondary-text">Qty:{item.quantity}</span>
						</div>
						<div className="div-table-cell text-right">
							<CurrCode />
							{item.quantity * item_total_price}
						</div>
					</div>
				</div>
				<input
					style={{
						width: "97%",
						margin: "10px 0",
						height: "25px"
					}}
					type="text"
					placeholder="Instructions"
					value={item.instructions || ''}
					onChange={(e) => this.addItemInstructions(e.target.value)}
				/>
			</div>
		);
	}
}

@connect(store => {
	return {
		currentOrderSession: store.currentOrderSession
	};
})
export class Cart extends React.Component {
	constructor(props) {
		super(props);
	}
	hideCart() {
		store.dispatch({
			type: 'HIDE_CART',
			payload: null
		});
	}
	proceedToCheckout() {
		var items = this.props.currentOrderSession.cart.items;
		if (items.length > 0) {
			store.dispatch({
				type: 'START_CHECKOUT',
				payload: null
			});
		}
	}
	render() {
		var cart = this.props.currentOrderSession.cart;
		var pricing = sat_api.getCartToPricing(cart);
		if (cart.items.length > 0) {
			var itemNodes = cart.items.map((o, i) => {
				return <StaticItemNode item={o} key={i} />;
			});
		} else {
			var itemNodes = (() => {
				return (
					<div className="text-center bold padding-10 large-text">
						No item in your cart
					</div>
				);
			})();
		}
		return (
			<div className="cart-container">
				<div className="cart-header text-center">
					<div className="close" onClick={e => this.hideCart(e)}>
						<a>x</a>
					</div>
					<div>Your bag</div>
				</div>
				<div className="cart-item-container">{itemNodes}</div>
				<div className="cart-item-buttons">
					<div>
						<div>
							<div className="float-left">Subtotal</div>
							<div className="float-right">
								<CurrCode />
								{pricing.sub_total}
							</div>
							<div className="clearfix" />
						</div>
					</div>
					<div
						className="checkout-button uppercase large-text"
						onClick={e => this.proceedToCheckout(e)}
					>
						<a>checkout</a>
					</div>
				</div>
			</div>
		);
	}
}

@connect(store => {
	return {
		currentOrderSession: store.currentOrderSession
	};
})
export class TimePicker extends React.Component {
	constructor(props) {
		super(props);
		this.delivery_min_offset_time = this.props.currentOrderSession.store.delivery_min_offset_time;
		this.delivery_min_offset_time_derived = this.delivery_min_offset_time
			? this.delivery_min_offset_time
			: 45 * 60 * 1000;
		this.state = sat_api.miliTo12Hrs({
			timeinmilli: new Date().getTime() + this.delivery_min_offset_time_derived,
			error: ''
		});
	}
	componentWillReceiveProps(newProps) {
		if (this.props.mounted != newProps.mounted && newProps.mounted) {
			this.setState(
				sat_api.miliTo12Hrs({
					timeinmilli:
						new Date().getTime() + this.delivery_min_offset_time_derived,
					error: ''
				})
			);
		}
	}
	validate(e, s) {
		let error = '';
		let { hr, min, mer } = this.state;

		let num = /^[0-9]{1,2}$/;

		if (!num.test(hr) || parseInt(hr) > 12) error = 'Invalid Hour';

		if (!num.test(min) || parseInt(min) > 59) error = 'Invalid Minute';

		if (!_.includes(['AM', 'PM'], mer)) error = 'Choose AM/PM';

		this.setState({ error });
	}
	onBlur(e, s) {
		this.validate(e, s);
	}

	onChange(e, s) {
		e.preventDefault();
		let _state;
		let val = e.target.value;

		switch (s) {
			case 'hr':
				_state = { ...this.state, hr: val };
				break;
			case 'min':
				_state = { ...this.state, min: val };
				break;
			case 'mer':
				_state = { ...this.state, mer: e.target.value.toUpperCase() };
				break;
			default:
		}
		this.setState(_state);
		this.props.onChange(_state);
	}

	handleChange(e, s) {
		var val = parseInt(e.target.value);
		if (e.nativeEvent.keyCode === 38) {
			//increase
			switch (s) {
				case 'hr':
					if (val == 12) {
						val = 0;
					}
					var _state = { ...this.state, hr: val + 1 };
					break;
				case 'min':
					if (val == 59) {
						val = -1;
					}
					var _state = {
						...this.state,
						min: (val + 1 < 10 ? '0' : '') + (val + 1)
					};
					break;
				case 'mer':
					var _state = {
						...this.state,
						mer: e.target.value == 'AM' ? 'PM' : 'AM'
					};
					break;
				default:
			}
			this.setState(_state);
		}
		if (e.nativeEvent.keyCode === 40) {
			//decrease
			switch (s) {
				case 'hr':
					if (val == 1) {
						val = 13;
					}
					var _state = { ...this.state, hr: val - 1 };
					break;
				case 'min':
					if (val == 0) {
						val = 60;
					}
					var _state = {
						...this.state,
						min: (val - 1 < 10 ? '0' : '') + (val - 1)
					};
					break;
				case 'mer':
					var _state = {
						...this.state,
						mer: e.target.value == 'AM' ? 'PM' : 'AM'
					};
					break;
				default:
				//do nothing
			}
			this.setState(_state);
		}
		if (_state !== undefined) {
			this.props.onChange(_state);
		}
	}
	render() {
		let ctpClass = 'custom-time-picker';
		if (this.state.error) ctpClass += ' error';
		return (
			<div className="custom-time-picker-cont">
				<div className={ctpClass}>
					<div>
						<input
							type="text"
							value={this.state.hr}
							onKeyDown={e => this.handleChange(e, 'hr')}
							onBlur={e => this.onBlur(e, 'hr')}
							onChange={e => this.onChange(e, 'hr')}
						/>
					</div>
					<div>
						<input
							type="text"
							value={this.state.min}
							onKeyDown={e => this.handleChange(e, 'min')}
							onBlur={e => this.onBlur(e, 'min')}
							onChange={e => this.onChange(e, 'min')}
						/>
					</div>
					<div>
						<input
							type="text"
							value={this.state.mer}
							onKeyDown={e => this.handleChange(e, 'mer')}
							onBlur={e => this.onBlur(e, 'mer')}
							onChange={e => this.onChange(e, 'mer')}
						/>
					</div>
				</div>
			</div>
		);
	}
}

@connect(store => {
	return {
		currentOrderSession: store.currentOrderSession,
		deliveryFeasibility: store.deliveryFeasibility
	};
})
export class NewAddressForm extends React.Component {
	constructor(props) {
		super(props);
		var cSession = this.props.currentOrderSession;
		let { locality, address_1, landmark, pincode, lat, lng } = JSON.parse(
			localStorage.getItem('address.delivery_info') || '{}'
		);
		this.state = {
			locality: locality ? locality :'',
			address_1: address_1 || '',
			landmark: landmark || '',
			city: cSession.store.city,
			pincode: pincode || '',
			lat: lat ? lat : null,
			lng: lng ? lng : null
		};
	}
	componentDidMount() {
		var cSession = this.props.currentOrderSession;
		var delivery_info = cSession.delivery_info;
		delivery_info.guest_address = this.state;
		delivery_info.address_id = null;
		store.dispatch({
			type: 'SET_DELIVERY_INFO',
			payload: delivery_info
		});
	}

	componentWillUnmount() {
		var cSession = this.props.currentOrderSession;
		var delivery_info = cSession.delivery_info;

		localStorage.setItem('address.delivery_info', JSON.stringify(this.state));
		delivery_info.guest_address = null;
		store.dispatch({
			type: 'SET_DELIVERY_INFO',
			payload: delivery_info
		});
	}
	handleChange(e) {
		var key = e.target.getAttribute('name');
		var value = e.target.value;
		var _state = { ...this.state };
		_state[key] = value;
		this.setState(_state);

		var cSession = this.props.currentOrderSession;
		var delivery_info = { ...cSession.delivery_info };
		delivery_info.guest_address = _state;
		store.dispatch({
			type: 'SET_DELIVERY_INFO',
			payload: delivery_info
		});
	}

	resetLatLng() {
		var _state = { ...this.state, lat: null, lng: null };
		this.setState(_state);

		var cSession = this.props.currentOrderSession;
		var delivery_info = { ...cSession.delivery_info };
		delivery_info.guest_address = _state;
		store.dispatch({
			type: 'SET_DELIVERY_INFO',
			payload: delivery_info
		});
	}

	onSuggestSelect(e) {
		if (e && e.gmaps && e.gmaps.address_components) {
			var { pincode, city } = extractCityPinFormGoogle(
				e.gmaps.address_components
			);
			var _state = {
				...this.state,
				pincode: pincode,
				city: city,
				locality: e.label,
				lat: e.location.lat,
				lng: e.location.lng
			};
			this.setState(_state);

			var cSession = this.props.currentOrderSession;
			var delivery_info = { ...cSession.delivery_info };
			delivery_info.guest_address = _state;
			store.dispatch({
				type: 'SET_DELIVERY_INFO',
				payload: delivery_info
			});
		} else {
			alert('Sorry, please search your location again.');
		}
	}
	render() {
		var cSession = this.props.currentOrderSession;
		return (
			<div className="save-new-address">
				<div>
					<Geosuggest
						onChange={() => {
							this.resetLatLng();
						}}
						ref={input => {
							this.textInput = input;
						}}
						placeholder="Enter locality"
						onSuggestSelect={e => this.onSuggestSelect(e)}
						initialValue={this.state.locality || ''}
					/>
					<input
						onChange={e => this.handleChange(e)}
						type="text"
						name="address_1"
						placeholder="Address"
						value={this.state.address_1}
					/>
					<input
						onChange={e => this.handleChange(e)}
						type="text"
						name="landmark"
						placeholder="Landmark"
						value={this.state.landmark}
					/>
					<input
						onChange={e => this.handleChange(e)}
						type="text"
						name="city"
						placeholder="City"
						value={this.state.city}
					/>
					<input
						onChange={e => this.handleChange(e)}
						type="text"
						name="pincode"
						placeholder="Pincode"
						value={this.state.pincode}
					/>
				</div>
			</div>
		);
	}
}

export const CloseBtn  = (props)=>{
	const { height } = props;
	const style = {
		width: height || '20px',
		height: height || '20px',
		backgroundImage: "url('/images/close.png')",
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '50%',
		cursor: 'pointer'
	};

	return(
		<div style={style} className="close-btn-order-detail-modal"/>
	)
}

export const HorLoadAnim = (props)=>{
	if(props.loading) {
		return <div className="hload-anim" />;
	}else {
		return null;
	}
}

export class PreLoaderBg extends React.Component {
	render() {
		var count = this.props.no;
		const PreLoaderBgEl = props => {
			var styleClass = props.styleClass;
			if (styleClass) {
				var styleClassFlat = styleClass.join(' ');
			} else {
				styleClassFlat = '';
			}

			return <div className={styleClassFlat + ' animated-background'} />;
		};

		if (count) {
			var i = 0;
			var animBg = [];

			do {
				i++;
				animBg.push(<PreLoaderBgEl key={i} styleClass={['m-b-5']} />);
			} while (i < count);

			return <div>{animBg}</div>;
		} else {
			return <PreLoaderBgEl />;
		}
	}
}



@connect(store => {
	return {
		audioInteraction: store.audioInteraction
	};
})
export class PseudoSound extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false
		};
	}

	// repeat =()=>{
	// 	this.setState({toggle:this.state.toggle})
	// }

	componentDidMount() {
		this.setState({
			mounted: true
		});

		const audio = document.querySelector('#music');

		if (this.props.play) {
			startPlayback()
				.then(function() {})
				.catch(function(error) {
					store.dispatch({
						type: 'SHOW_AUDIO_PROMPT',
						payload: null
					});
				});
		}

		function startPlayback() {
			return audio.play();
		}
	}

	componentWillReceiveProps(newProps) {
		const audio = document.querySelector('#music');

		if (this.props.play === false && newProps.play === true) {
			startPlayback()
				.then(function() {})
				.catch(function(error) {
					store.dispatch({
						type: 'SHOW_AUDIO_PROMPT',
						payload: null
					});
				});

			function startPlayback() {
				return audio.play();
			}
		}

		if (this.props.play === true && newProps.play === false) {
			audio.pause();
		}

		if (this.props.audioInteraction.tone != newProps.audioInteraction.tone) {
			audio.pause();
			audio.src = `/audio/${newProps.audioInteraction.tone}`;
			// audio.currentTime = 0;
			audio.load();
			audio.oncanplay = () => {
				if (this.props.play === true) {
					audio.play();
				}
			};
		}
	}

	render() {
		let src = `/audio/${this.props.audioInteraction.tone}`;
		return <audio id="music" src={src} loop={true} />;
	}
}

@connect(store => {
	return {
		barcodeSatus: store.barcodeSatus
	};
})
export class BarCodeHandler extends React.Component {
	closeBarcodeHandler() {
		store.dispatch({
			type: 'HIDE_BARCODE_SCANNED_SECTION',
			payload: null
		});
	}
	render() {
		if (!this.props.barcodeSatus.visible) {
			return null;
		}
		const barcodeStyle = {
			position: 'fixed',
			bottom: '10px',
			right: '10px',
			width: '200px',
			zIndex: 9999,
			fontSize: '12px',
			boxShadow: 'rgba(93, 93, 93, 0.2) -3px 1px 4px 0px',
			borderRadius: '4px'
		};

		const statusStyle = {
			borderTopLeftRadius: '4px',
			borderTopRightRadius: '4px',
			background: '#3498db',
			color: '#fff',
			fontSize: '12px',
			padding: '10px',
			textTransform: 'uppercase',
			transition: '180ms'
		};

		if (this.props.barcodeSatus.state == 'failed') {
			statusStyle.background = '#c0392b';
		} else if (this.props.barcodeSatus.state == 'success') {
			statusStyle.background = '#27ae60';
		} else if (this.props.barcodeSatus.state == 'in-progress') {
			statusStyle.background = '#3498db';
		} else {
			statusStyle.background = '#ddd';
		}

		const imageStyle = {
			position: 'absolute',
			right: '11px',
			height: '13px',
			cursor: 'pointer'
		};

		return (
			<div style={barcodeStyle} className="bar-code-handler-container">
				<div style={statusStyle}>
					Status:&nbsp;{this.props.barcodeSatus.state || 'inactive'}
					<img
						onClick={this.closeBarcodeHandler}
						style={imageStyle}
						src="/images/close.png"
					/>
				</div>
				<div
					style={{
						padding: '10px',
						fontSize: '20px',
						background: '#fff',
						borderBottomLeftRadius: '4px',
						borderBottomRightRadius: '4px'
					}}
				>
					ID: {this.props.barcodeSatus.barcode}
				</div>
			</div>
		);
	}
}
