import * as firebase from 'firebase/app';
import 'firebase/database';

// api/utils
import moment from 'moment';
import { store } from './app/store';
const sat_api = require('./js/api.js');


// initialize firebase and get settings
export const initFirebase = async () => {
	const firebaseConfig = {
		apiKey: "AIzaSyBbPJUT182D-LtkCxIt8fiIqLmxfm6wysA",
		authDomain: "satellite-159608.firebaseapp.com",
		databaseURL: "https://satellite-159608.firebaseio.com",
		projectId: "satellite-159608",
		storageBucket: "satellite-159608.appspot.com",
		messagingSenderId: "824681427866",
		appId: "1:824681427866:web:12b42d09a523d5042b8023"
	};
	if (!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
	}
	const userId = store.getState().loginReducer.data.user_id;
	const userSettingsRef = firebase.database().ref(`/settings/${userId}`);
	userSettingsRef.on('value', (resp) => {
		let settings = resp.val();
		if (!settings) {
			settings = {
				subscribed_stores: [],
			};
		}
		updateSettings(settings, userId);
	});
}

const updateSettings = (settings, userId) => {
	const subscribedStores = settings.subscribed_stores.map((s) => ({
		label: s.store_name,
		value: s.biz_location_id,
		biz_name: s.biz_name,
		biz_id: s.biz_id,
	}));
	sat_api.upStorage.setItem({
		key: `settings_${userId}`,
		value: {
			selectedStores: subscribedStores,
		},
	});
}

export const handleOrderPrint = (id, created, biz_id) => {
	const date = moment(created).format('YYYY-MM-DD');
	const printRef = firebase.database().ref(`/printed_orders/${date}/${id}`);
	printRef.once('value').then((resp) => {
		const hasPrinted = resp.val();
		if (!hasPrinted) {
			printRef.set(true);
			sat_api.print({ seamless: true, orderId: id, biz_id, });
		}
	}).catch((error) => {
		console.log(error);
	});
}

export const markPrinted = (orders, created) => {
	const date = moment(created).format('YYYY-MM-DD');
	const printRef = firebase.database().ref(`/printed_orders/${date}/`);
	const updatedObj = {}
	orders.forEach((ord) => {
		if (ord.order_state !== 'Placed') {
			updatedObj[ord.id] = true;
		}
	});
	printRef.update(updatedObj);
}

export const saveToFirebase = (settings) => {
	const userId = store.getState().loginReducer.data.user_id;
	const userSettingsRef = firebase.database().ref(`/settings/${userId}`);
	const data = {
		subscribed_stores: settings.selectedStores.map((s) => ({
			biz_location_id: s.value,
			store_name: s.label,
			biz_name: s.biz_name,
			biz_id: s.biz_id,
		})),
	}
	userSettingsRef.set(data);
}
