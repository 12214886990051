import React from 'react';

import { store } from './store.js';
import { connect } from 'react-redux';
import SwitchButton from 'react-switch';
import Moment from './clientTime';
import { connectQZTray } from '../qz-print-helper';


import {
	getOutletLogistics,
	setOutletLogistics,
	setSurge,
	getOutletPlatforms,
	fetchInActiveOrder,
	fetchActiveOrder,
	updateGeneralSettings,
	getOutletTakeaway,
	setActivePage,
	getStores,
} from './actions.js';

import { PreLoaderBg } from './reactComp.jsx';
import BizDropdown from './Inventory/BizDropdown';
import StoreDropdown from './Inventory/StoreDropDown';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { saveToFirebase } from '../firebase';

import SeamlessPrintInstructions from './SeamlessPrintInstructions/SeamlessPrintInstructions';
import {
	toggleSeamlessPrntng,
	togglePrntrSetupInstrctns
} from '../actions/printManager.js';

var _ = require('lodash');
var sat_api = require('../js/api.js');

class CSwitchButton extends React.Component {
	render() {
		return(
			<SwitchButton height={22} handleDiameter={15} width={50} {...this.props}/>
		)
	}
}


@connect((store)=>{
	return{
		loginReducer:store.loginReducer,
		appLanguage:store.appLanguage
	}
})
class AvailableLanguage extends React.Component{
	setLang(e,i){
		store.dispatch({
			type:"UPDATE_APP_LANGUAGE",
			payload:i
		})
		localStorage.setItem('appLanguage', i);
		sat_api.AXIOS_CONFIG.headers["X-Use-Lang"] = i
		Moment.locale(i)
		sat_api.MP_TRACK('change_language', {
			language:i
		})
	}
	render(){
		let langSelectorStyle = {
			border:"1px solid #ddd",
			padding:"5px 10px",
			cursor:"pointer",
			display:"inline-block",
			textTransform:"uppercase",
			marginRight:"10px"
		}
		let langSelectorStyleSelected = {
			...langSelectorStyle,
			backgroundColor: "rgb(0, 136, 0)",
			color: "#fff",
			borderColor: "rgb(0, 136, 0)"
		}
		if(this.props.loginReducer && this.props.loginReducer.loggedIn){
			const avaiableLang = this.props.loginReducer.data.biz.supported_languages.split(",");
			const avaiableLangNode = avaiableLang.map((i,d)=>{
				return(
					<span key={d} onClick={(e)=>{this.setLang(e,i)}} style={i == this.props.appLanguage.lang ? langSelectorStyleSelected : langSelectorStyle}>{i}</span>
				)
			})
			return (
				<div>{avaiableLangNode}</div>
			)
		}else {
			return null
		}
	}
}
class StoreAvailabiltyController extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			loading: true,
			error: false
		}
	}
	componentDidMount(){
		var __this = this;
		sat_api.getStoreAvailabilty({store_id: this.props.storeId, platform: this.props.platform}, function(resp){
			__this.setState({
				loading: false,
				is_available: resp.data.is_available
			})
		}, function(err){
			__this.setState({
				loading: false,
				error: err.data.message
			})
		})
	}
	toggleStoreAvailability(){
		var __this = this;
		const newAvailability = !this.state.is_available;
		sat_api.setStoreAvailabilty({
			store_id: this.props.storeId,
			platform: this.props.platform,
			availability: newAvailability
		}, function(resp){
			// console.log("success");
		}, function(err){
			__this.setState({
				error: err.data.message
			})
		})
		this.setState({
			is_available: newAvailability
		})
		sat_api.MP_TRACK('store_toggle', {
			platform: this.props.platform,
			store_id: this.props.storeId,
			enabled: newAvailability
		})
	}
	render(){
		var __this = this;
		function renderStoreToggle(){
			if(__this.state.loading){
				return (
					<PreLoaderBg no={1}/>
				)
			}else if(__this.state.error){
				return (
					<div className="text-center padding-10 UP-ERROR-TEXT bold">{__this.state.error}</div>
				)
			}else{
				return (
					<div style={{"lineHeight":"44px"}}>
						<div className="float-left">Enable store</div>
						<div className="float-right">
							<CSwitchButton
								name={__this.props.platform}
								checked={__this.state.is_available}
								onChange={(e) => __this.toggleStoreAvailability(e)}
							/>
						</div>
						<div className="clearfix"></div>
					</div>
				)
			}
		}
		return (
			<div>
				{renderStoreToggle()}
			</div>
		)
	}
}


const FoodpandaSettings = (props)=>{
	return (
		<div className="padding-10">
			<StoreAvailabiltyController platform="foodpanda" storeId={props.outlet.value}/>
		</div>

	)
}

const SwiggySettings = (props)=>{
	return (
		<div className="padding-10">
			<StoreAvailabiltyController platform="swiggy" storeId={props.outlet.value}/>
		</div>

	)
}


@connect((store)=>{
	return{
		outletLogistics:store.outletLogistics,
		outlettakeAwayStatus:store.outlettakeAwayStatus
	}
})
class ZomatoSettings extends React.Component{
	componentDidMount(){
		store.dispatch(getOutletLogistics({store_id:this.props.outlet.value}))
		store.dispatch(getOutletTakeaway({store_id:this.props.outlet.value}))
	}
	render(){
		return(
			<div className="custom-zomato-settings">
				<div className="padding-10">
					<StoreAvailabiltyController platform="zomato" storeId={this.props.outlet.value}/>
				</div>
				<div className="padding-10">
					<ZomatoSettinsStaticEl />
				</div>
				<div className="padding-10">
					<ZomatoTakeAwaySettings />
				</div>
			</div>
		)
	}
}

@connect((store)=>{
	return{
		outlettakeAwayStatus:store.outlettakeAwayStatus
	}
})
class ZomatoTakeAwaySettings extends React.Component{
	setOutletTakeawayStatus(e){

		let c_state = {...this.props.outlettakeAwayStatus.data};
		c_state['outlet_takeaway_status'] = e


		store.dispatch({
			type:"OUTLET_TAKEAWAY_DONE",
			payload:c_state
		})

		sat_api.setTakeAwayStatus({
			store_id:this.props.outlettakeAwayStatus.store_id,
			takeaway_status:e,
			reason:"Reason not mentioned"
		}, (resp)=>{
			console.log(resp)
		}, (err)=>{
			c_state['outlet_takeaway_status'] = !e
			store.dispatch({
				type:"OUTLET_TAKEAWAY_DONE",
				payload:c_state
			})
		})
		sat_api.MP_TRACK('zo_takeaway', {
			enabled: e
		})
	}
	render(){
		if(this.props.outlettakeAwayStatus.error){
			return(
				<div className="UP-ERROR-TEXT bold padding-10 text-center">TakeAway: {this.props.outlettakeAwayStatus.error.message}</div>
			)
		}
		if(this.props.outlettakeAwayStatus.loading){
			return(
				<PreLoaderBg no={1}/>
			)
		}
		if(!this.props.outlettakeAwayStatus.data){
			return null
		}


		return(
			<div className="m-t-20" style={{"paddingTop":"20px"}}>
				<div className="bold m-b-10">TakeAway status</div>
				<ZomatoSwitch
					title="Zomato takeaway status"
					data={this.props.outlettakeAwayStatus.data['zomato_takeaway_status']}
				/>
				<div style={{"lineHeight":"44px"}}>
					<div className="float-left">Outlet takeaway status</div>
					<div className="float-right">
						<CSwitchButton
							name="outlet_takeaway_status"
							checked={this.props.outlettakeAwayStatus.data['outlet_takeaway_status']}
							onChange={(e)=>this.setOutletTakeawayStatus(e)}
							/>
					</div>
					<div className="clearfix"></div>
				</div>
			</div>
		)
	}
}
class ZomatoSwitch extends React.Component{
	doNothing(){
		console.log('Nothing to do')
	}
	render(){
		return(
			<div style={{"lineHeight":"44px"}}>
				<div className="float-left">{this.props.title}</div>
				<div className="float-right">
					<CSwitchButton
						disabled={true}
						name={this.props.title.toLowerCase().split(' ').join('_')}
						checked={this.props.data}
						onChange={(e)=>{this.doNothing(e)}}
						/>
				</div>
				<div className="clearfix"></div>
			</div>
		)
	}
}

@connect((store)=>{
	return{
		outletLogistics:store.outletLogistics,
		surgeDataState:store.surgeDataState,
		activeSurgesArr:store.activeSurgesArr
	}
})
class ZomatoSettinsStaticEl extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			surge_time:0,
			surge_duration:15
		}
	}

	componentWillMount(){
		var surge_data_arr = sat_api.upStorage.getItem('active_surges'), cTime = new Date().getTime();
		if(!surge_data_arr) surge_data_arr = [];
		_.remove(surge_data_arr, (o) => {
			return o.end_time < cTime;
		})
		sat_api.upStorage.setItem({
			key: 'active_surges',
			value: surge_data_arr
		});
		store.dispatch({
			type:"SET_ACTIVE_SURGES",
			payload: surge_data_arr
		})
	}

	componentWillReceiveProps(d){
		if(d.outletLogistics.update_error && this.props.outletLogistics.update_error != d.outletLogistics.update_error){
			alert(d.outletLogistics.update_error.message);
		}

		if(d.surgeDataState.error && this.props.surgeDataState.error != d.surgeDataState.error){
			alert(d.surgeDataState.error);
		}

		if(d.surgeDataState.success && this.props.surgeDataState.success != d.surgeDataState.success){
			alert('Successfully updated');
		}
	}
	changeSettings(key){

		var previousState = this.props.outletLogistics.data;
		var settings = {...previousState};
		const newState = !previousState['self_serviceability_status'];
		settings['self_serviceability_status'] = newState;

		var payload = {
			settings:settings,
			previousState:previousState,
			store_id:this.props.outletLogistics.store_id
		}

		store.dispatch(setOutletLogistics(payload))
		sat_api.MP_TRACK('zo_logisitics', {
			enabled: newState
		})
	}
	setsurge(e,key){
		var _state = {}
		_state[key] = parseInt(e.target.value);

		this.setState(_state)
	}
	sumbitSurge(){
		var surgedata = this.state;
		store.dispatch(setSurge({
			store_id:this.props.outletLogistics.store_id,
			data:surgedata
		}))

	}
	render(){
		if(this.props.outletLogistics.error){
			return(
				<div className="UP-ERROR-TEXT bold padding-10 text-center">Logistics: {this.props.outletLogistics.error.message}</div>
			)
		}
		if(this.props.outletLogistics.loading){
			return(
				<PreLoaderBg no={1}/>
			)
		}
		if(!this.props.outletLogistics.data){
			return null
		}
		var surge_data_arr = this.props.activeSurgesArr, store_id = this.props.outletLogistics.store_id;
		var active_surge = _.find(surge_data_arr, {store_id: store_id});
		var activeSurgeStyle = {
			textAlign: 'center',
		 	color: '#008800',
			width: '100%'
		}
		return(
			<div style={{"opacity":(this.props.outletLogistics.loading || this.props.surgeDataState.loading ? 0.3 : 1)}}>
				<div className="m-t-20">
					<div className="bold m-b-10">Select surge time and duration</div>
					{active_surge ?
						<div style={activeSurgeStyle}>
							A surge time of {active_surge.surge_time} min is in force till {Moment(active_surge.end_time).format('h:mm A')}
						</div>
						: null
					}
					<div style={{"marginBottom":"10px"}}>
						<div className="float-left">Surge time :</div>
						<div className="float-right">
							<select onChange={(e)=>{this.setsurge(e,'surge_time')}}>
								<option value="0">0 min</option>
								<option value="5">5 min</option>
								<option value="10">10 min</option>
								<option value="15">15 min</option>
							</select>
						</div>
						<div className="clearfix"></div>
					</div>
					<div >
						<div className="float-left">Surge duration :</div>
						<div className="float-right">
							<select onChange={(e)=>{this.setsurge(e,'surge_duration')}}>
								<option value="15">15 min</option>
								<option value="30">30 min</option>
								<option value="45">45 min</option>
								<option value="60">60 min</option>
							</select>
						</div>
						<div className="clearfix"></div>
					</div>
					<div style={{"textAlign":"right","marginTop":"20px"}}>
						<a className="up-button small default primary" onClick={(e)=>{this.sumbitSurge(e)}}>Submit</a>
					</div>
				</div>
				<div className="m-t-20" style={{"paddingTop":"20px"}}>
					<div className="bold m-b-10">Outlet Logistics</div>
					<ZomatoSwitch
						title="Zomato logistics enabled"
						data={this.props.outletLogistics.data['zomato_logistics_enabled']}
					/>
					<ZomatoSwitch
						title="Zomato service availability"
						data={this.props.outletLogistics.data['zomato_serviceability_status']}
					/>
					<ZomatoSwitch
						title="Self delivery enabled"
						data={this.props.outletLogistics.data['self_delivery_enabled']}
					/>

				<div
					style={{"lineHeight":"35px","marginTop":"10px" ,"paddingTop":"10px"}}
					className={(this.props.outletLogistics.data['self_delivery_enabled'] ? "":"hide-imp")}
					>
						<div className="float-left">Self serviceability status</div>
						<div className="float-right">
							<CSwitchButton
								name="self_serviceability_status"
								checked={this.props.outletLogistics.data['self_serviceability_status']}
								onChange={(e)=>this.changeSettings(e)}
								/>
						</div>
						<div className="clearfix"></div>
					</div>
				</div>
			</div>
		)
	}
}
class SettingsBoxWithHeader extends React.Component{
	render(){
		if(this.props.hide){
			return null
		}
		var styleObj = {
			"maxWidth": "100%",
			"border": "1px solid #ddd",
			"background": "#fff",
			"borderRadius": "4px",
			"marginBottom":"10px",
			"boxShadow":"1px 1px 3px 1px rgba(220, 220, 220, 0.5)",
		}
		if (this.props.zIndex) {
			styleObj["position"] = 'relative';
			styleObj["zIndex"] = this.props.zIndex;
		}
		var titleStyle = {
			"borderBottom":"1px solid #ddd"
		}
		if(this.props.subset){
			styleObj = {...styleObj, border: "none", borderRadius: "0px", boxShadow: "none", margin: "0 10px"}
			titleStyle = {...titleStyle, padding: "10px 0"}
		}
		return(
			<div style={styleObj}>
				<div className={"bold " + (this.props.subset ? "" : "padding-10 uppercase")} style={titleStyle}>
					{this.props.title}
				</div>
				{this.props.children}
			</div>
		)
	}
}

class SettingsDefaultNewOrder extends React.Component{
	constructor(props) {
	   super(props);
	}
	componentDidMount(){
		// handling it with css
		// setTimeout(()=>{
		// 	this.setState({style:{...this.state.style, "opacity":"1"}})
		// },100)
	}
	render(){
		var styleObj = {
			"lineHeight":"35px",
			"overflow":"hidden",
			"transition":"0.5s",
			"opacity":this.props.disabled ? 0.5 : 1,
			"pointerEvents":this.props.disabled ? "none" : "auto",
			"filter":this.props.disabled ? "grayscale(1)" : "grayscale(0)",
		}
		return(
			<div style={styleObj} className="up-fadeIn">
				<div className="float-left">{this.props.title}</div>
				<div className="float-right">
					<CSwitchButton
						name={this.props.type}
						checked={this.props.appSettings[this.props.type]}
						onChange={(e)=>this.props.changeSettings(this.props.type)}
						/>
				</div>
				<div className="clearfix"></div>
			</div>
		)
	}
}


@connect((store)=>{
	return {
		loginReducer: store.loginReducer,
		bizStoresReducer: store.bizStoresReducer,
	}
})
class MultiSelectStore extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedStores: [],
			settings_key: this.props.loginReducer.data.user_id,
		}
	}

	componentWillMount() {
		var settings = sat_api.getLocalSettings(this.state.settings_key);
		if (settings) {
			this.setState({
				selectedStores: settings.selectedStores,
			});
		}
	}

	addTSelectedStore = (val, selectedOption) => {
		const settings = sat_api.getLocalSettings(this.state.settings_key) || {};
		let selectedStores = settings.selectedStores ? settings.selectedStores.slice() : [];
		const { action, option, removedValue } = selectedOption;
		switch (action) {
			case 'remove-value':
				selectedStores = selectedStores.filter((s) => s.value != (option ? option.value : removedValue.value));
				break;
			case 'clear':
				selectedStores = [];
				break;
			case 'select-option':
				selectedStores.push({
					...option,
					biz_name: this.props.bizStoresReducer.selectedBiz.label,
					biz_id: this.props.bizStoresReducer.selectedBiz.value,
				});
				break;
			default:
				console.log('inside break');
				break;
		}
		this.setState({
			selectedStores: val || [],
		});
		settings.selectedStores = selectedStores;
		sat_api.upStorage.setItem({key:'settings_'+this.state.settings_key, value:settings});
		saveToFirebase(settings);
	}

	handleStoreSearch = (searchValue) => {
		const data = {
			biz_id: this.props.bizStoresReducer.selectedBiz.value,
			sq: searchValue,
		};
		return new Promise(async (resolve, reject) => {
			try {
				const resp = await sat_api.searchStores(data);
				const response = resp.data.objects.map((obj) => ({
					value: obj.biz_location_id,
					label: obj.name,
				}));
				resolve(response);
			} catch (error) {
				console.log(error);
				reject();
			}
		});
	}


	render() {
		var { stores } = this.props.bizStoresReducer;
		var _store_for_select = [];
		stores.map(o=> {
			_store_for_select.push({
				label : o.name,
				value : o.biz_location_id
			});
		});

		return (
			<div className="store-subscribe-settting">
				<div className="store-dropdown">
					<AsyncSelect
						isMulti={true}
						closeMenuOnSelect={false}
						className="inventory-control-store"
						optionClassName="needsclick"
						placeholder= 'Select store'
						onChange={this.addTSelectedStore}
						value={this.state.selectedStores}
						cacheOptions={true}
						defaultOptions={_store_for_select}
						loadOptions={this.handleStoreSearch}
					/>
				</div>
				{this.state.selectedStores.length == 0 ? <div className="padding-10 text-center" style={{"color":"rgb(0, 136, 0)"}}>{sat_api.getLabel(this.props.lang, 'you_subscribed') || "You subscribed to all stores"} </div> : null}
			</div>
		);
	}
}

@connect((store)=>{
	return {
		loginReducer: store.loginReducer,
		appSettings: store.appSettings,
		audioInteraction: store.audioInteraction,
		appLanguage: store.appLanguage,
	}
})
export default class Settings extends React.Component {
	constructor(props) {
    super(props);
		this.state = {
			showAudio: false,
		};
	}

    changeSettings(key){
		var settings = {...this.props.appSettings};
		const newState = !settings[key];

		settings[key] = newState;
		store.dispatch(updateGeneralSettings(settings))
		sat_api.MP_TRACK("notification_future_toggle", {
			enabled: newState
		})
	}

    componentDidMount() {

        store.dispatch(setActivePage({page:"settings"}))
		store.dispatch({
			type:"TOGGLE_BURGER_MENU",
			payload: false
		})
    }

	componentWillUnmount(){
		// console.log('update settings, hence fetch data again');
		store.dispatch(fetchInActiveOrder())
		store.dispatch(fetchActiveOrder())
		store.dispatch({
			type:"OUTLET_LOGISTICS_RESET",
			payload:null
		})
	}
	handleAudio =(e)=>{
		// console.log(e)
		store.dispatch({
			type:"UPDATE_AUDIO",
			payload:e
		})
		let newSettings = {
			tone:e
		}
		localStorage.setItem('global_settings', JSON.stringify(newSettings))
	}

	showAudioSelector = () => {
		this.setState({
			showAudio: true
		});
	};

	render() {
		return null;
		const { permissions } = this.props.loginReducer.data;
		const selectedTone = this.props.audioInteraction.tone;
		const isWidget = this.props.widget;
		const lang = this.props.appLanguage.lang;
		var isHQ = (this.props.loginReducer.data.flag == 'HQ');
		var widgetStyles = {
			paddingTop: this.props.widget ? '10px':'80px'
		}
		const cNAStyle = {
			marginTop: '6px',
			color: '#2980b9',
			fontWeight: 'bold',
			cursor: 'pointer'
		};
		const isMultiBiz = (this.props.loginReducer && this.props.loginReducer.data ?  this.props.loginReducer.data.multi_biz : false);
		return (
			<div className="react-components settings-page" style={widgetStyles}>
				<div className="div-table">
					<div className="div-table-row">
						<div className="div-table-cell top" style={ sat_api.isMobile ? {"display":"block"} : {"width":"50%"} }>
							{
							 	!isWidget ?
								<div className="">
									<SettingsBoxWithHeader title={sat_api.getLabel(lang, 'notification_settings') || "Notification settings"}>
										<div className="padding-10">
											<SettingsDefaultNewOrder
												type="notification"
												title={sat_api.getLabel(lang, 'new_order_notification') || "New order notification"}
												appSettings={this.props.appSettings}
												changeSettings={this.changeSettings.bind(this)}
												/>
											<SettingsDefaultNewOrder
												disabled={!this.props.appSettings.notification}
												type="notification_future"
												title={sat_api.getLabel(lang, 'notification_for_future_order') || "Notification for Future order"}
												appSettings={this.props.appSettings}
												changeSettings={this.changeSettings.bind(this)}
											/>
											{
												this.props.appSettings.notification && !this.state.showAudio
												?
												<div style={cNAStyle} onClick={this.showAudioSelector}>
													{sat_api.getLabel(lang, 'change_audio') || "Change Audio"}
												</div>
												:
												null
											}
										</div>
										{
											this.state.showAudio ?
											<div className="tone-selector">
												<div className="audio-header">{sat_api.getLabel(lang, 'select_tone') || "SELECT TONE FOR NEW ORDER"}</div>
												<div  className={(selectedTone == 'default.mp3' ? "selected" :"")+ " audio-file"}>
													<div
													onClick={()=>this.handleAudio('default.mp3')}
													className="audio-selector-btn"></div>
													<audio src="/audio/default.mp3" controls></audio>
												</div>
												<div className={(selectedTone == 'low-dang.mp3' ? "selected" :"")+ " audio-file"}>
													<div
													onClick={()=>this.handleAudio('low-dang.mp3')}
													className="audio-selector-btn"></div>
													<audio src="/audio/low-dang.mp3" controls></audio>
												</div>
												<div className={(selectedTone == 'plucky.mp3' ? "selected" :"")+ " audio-file"}>
													<div
													onClick={()=>this.handleAudio('plucky.mp3')}
													className="audio-selector-btn"></div>
													<audio src="/audio/plucky.mp3" controls></audio>
												</div>
												<div className={(selectedTone == 'vector.mp3' ? "selected" :"")+ " audio-file"}>
													<div
													onClick={()=>this.handleAudio('vector.mp3')}
													className="audio-selector-btn"></div>
													<audio src="/audio/vector.mp3" controls></audio>
												</div>
											</div>
											:
											null

										}
									</SettingsBoxWithHeader >
								</div>
								:
								null
							}
							<BillSettings/>
							{/* <AutoConfirm /> */}
						</div>
						<div className="div-table-cell top" style={sat_api.isMobile ? {"display":"block"} : {"width":"50%"}}>
							{
								isMultiBiz && !permissions.includes('satellite_not_settings_edit') &&
								<BizSettings />
							}
							{
								!permissions.includes('satellite_not_settings_edit') &&
								<StoreSettings isMultiBiz={isMultiBiz} />
							}
							<SettingsBoxWithHeader
								title={sat_api.getLabel(lang, 'subscribe_to_stores') || "Subscribe to stores"}
								hide={!isHQ || permissions.includes('satellite_not_settings_edit')}
							>
								<div className="padding-10">
									<div>
										{sat_api.getLabel(lang, 'you_get_order_notifications') || "You will get order notification from following stores"}
									</div>
									<div className="m-t-10">
										<MultiSelectStore isMultiBiz={isMultiBiz} lang={lang} />
									</div>
								</div>
							</SettingsBoxWithHeader>
							<SettingsBoxWithHeader
								title={sat_api.getLabel(lang, 'available_language') || "Available language"}
								hide={false}
							>
								<div className="padding-10">
									<div>
										{sat_api.getLabel(lang, 'choose_language') || "Choose language"}
									</div>
									<div className="m-t-10">
										<AvailableLanguage />
									</div>
								</div>
							</SettingsBoxWithHeader>
						</div>
					</div>
				</div>
				<SeamlessPrintInstructions lang={lang} />
			</div>
		);
	}
}

@connect((store)=>({
	loginReducer:store.loginReducer,
	outletPlatforms: store.outletPlatforms,
	bizStoresReducer: store.bizStoresReducer,
}))
class BizSettings extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<SettingsBoxWithHeader title="Busineess" zIndex="3">
				<div className="padding-10">
					<div className="inventory-biz-store-selector biz-settings full-width">
						<div className="flex-container">
							<BizDropdown />
						</div>
					</div>
				</div>
			</SettingsBoxWithHeader>
		);
	}
}

@connect((store)=>{
	return {
		loginReducer:store.loginReducer,
		outletPlatforms: store.outletPlatforms,
		bizStoresReducer: store.bizStoresReducer,
		appLanguage: store.appLanguage,
	}
})
class StoreSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPlatform: null,
		}
		if(this.props.loginReducer.data.flag !== 'HQ') {
			const nonHQStore = this.props.loginReducer.data.sub_admin[0];
			store.dispatch({
				type: 'OUTLET_PLATFORMS_DONE',
				payload: {
					platforms: nonHQStore.platforms,
				}
			});
			store.dispatch({
				type: 'FETCHING_STORES_DONE',
				payload: {
					objects: this.props.loginReducer.data.sub_admin,
				}
			});
			store.dispatch({
				type: 'SELECT_BIZ_STORE',
				payload: {
					label: nonHQStore.name,
					value: nonHQStore.biz_location_id,
				},
			});
		} else {
			const { selectedStore } = this.props.bizStoresReducer;
			if (selectedStore.value) {
				store.dispatch(getOutletPlatforms({
					store_id: selectedStore.value,
				}));
			}
			let defaultBiz = this.props.bizStoresReducer.selectedBiz;
			if(!defaultBiz.value || !defaultBiz.label) {
				defaultBiz = {
					label: this.props.loginReducer.data.biz.name,
					value: this.props.loginReducer.data.biz.biz_id,
				};
				this.props.dispatch({
					type: 'SELECT_BIZ',
					payload: defaultBiz,
				});
			}
			this.props.dispatch(getStores({biz_id: defaultBiz.value}));
		}
	}

	togglePlatform(platform){
		if(this.state.selectedPlatform === platform) this.setState({selectedPlatform: null})
		else{
			this.setState({selectedPlatform: platform})
		}
	}

	handleStoreSelect = (store_id) => {
		this.setState({
			selectedPlatform: null,
		});
		store.dispatch(getOutletPlatforms({
			store_id,
		}));
	}

	render() {
		const { selectedStore } = this.props.bizStoresReducer;

		var getPlatormWiseSettings = (store_data) => {
			if(store_data.loading){
				return (<div className="text-center padding-10">Loading...</div>)
			}else if(store_data.error){
				return (<div className="UP-ERROR-TEXT bold padding-10 text-center">{store_data.error.error_message}</div>);
			}else if(!store_data.platforms || store_data.platforms.length === 0){
				return (<div className="UP-ERROR-TEXT bold padding-10 text-center">{sat_api.getLabel(this.props.appLanguage.lang, 'store_not_mapped') || "Store is not mapped with any platform"}</div>)
			}
			let validPlatforms = [{
				name: 'zomato',
				component: <ZomatoSettings outlet={selectedStore} />
			},{
				name: 'swiggy',
				component: <SwiggySettings outlet={selectedStore} />
			},{
				name: 'foodpanda',
				component: <FoodpandaSettings outlet={selectedStore} />
			}]
			return (
				<div>
					{
						store_data.platforms.map((platform, i) => {
							var platformData = _.find(validPlatforms, {name: platform});
							if(platformData){
								return (
									<div key={i}>
										<div className="default-inline-container bold border-bottom margin-10 cursor-pointer" onClick={() => this.togglePlatform(platform)}>
											<div className="capitalize"> {platform} </div>
											<div className="m-r-10 float-right"><img src="/images/caret-down.svg" style={{"width": "10px"}}/></div>
										</div>
										{this.state.selectedPlatform === platform ? platformData.component : null}
									</div>
								)
							}
							return null;
						})
					}
				</div>
			);
		}


		return (
			<SettingsBoxWithHeader title={sat_api.getLabel(this.props.appLanguage.lang, 'store_settings') || "Store settings"} zIndex="2">
				<div className="padding-10">
					<div className="inventory-biz-store-selector full-width">
						<div className="flex-container">
							<StoreDropdown handleStoreSelect={this.handleStoreSelect} />
						</div>
					</div>
				</div>
				<div>
					{getPlatormWiseSettings(this.props.outletPlatforms)}
				</div>
			</SettingsBoxWithHeader>
		);
	}
}

@connect((store)=>{
	return {
		appSettings: store.appSettings,
		enableSeamlessPrinting: store.printManager.enableSeamlessPrinting,
		hasSeamlessPrintingBeenConfigured: store.printManager.hasSeamlessPrintingBeenConfigured,
		loginData: store.loginReducer.data,
		appLanguage: store.appLanguage,
	}
})
class BillSettings extends React.Component {
	changeSettings(key) {
		const settings = {...this.props.appSettings};
		const newState = !settings[key];
		settings[key] = newState;
		if (newState) {
			if (key === 'print_kot') {
				settings['print_order_slip'] = false;
			} else if (key === 'print_order_slip') {
				settings['print_kot'] = false;
			}
		}
		store.dispatch(updateGeneralSettings(settings));
		sat_api.MP_TRACK(`${key}_toggle`, {
			enabled: newState
		});
	}

	changeSeamlessPrintingSettings = event => {
		const {
			enableSeamlessPrinting,
			hasSeamlessPrintingBeenConfigured
		} = this.props;
		if (enableSeamlessPrinting) {
			store.dispatch(toggleSeamlessPrntng(false));
		} else {
			store.dispatch(togglePrntrSetupInstrctns(true));
		}
	};

	openTestPrintModal = () => {
		store.dispatch(togglePrntrSetupInstrctns(true));
	};


	render() {
		const {
			appSettings: settings,
			enableSeamlessPrinting,
			loginData
		} = this.props;

		const styleObj = {
			lineHeight: '35px',
			overflow: 'hidden',
			transition: '0.5s',
		};

		return(
			<SettingsBoxWithHeader title={sat_api.getLabel(this.props.appLanguage.lang, 'print_settings') || "Print settings"}>
				<div className="padding-10">
					<SettingsDefaultNewOrder
						type="print_order_slip"
						title={sat_api.getLabel(this.props.appLanguage.lang, 'print_order_slip') || "Print order slip"}
						appSettings={this.props.appSettings}
						changeSettings={(e)=>{this.changeSettings(e, this.props.type)}}
					/>
					<SettingsDefaultNewOrder
						type="print_kot"
						title={sat_api.getLabel(this.props.appLanguage.lang, 'print_kot') || "Print KOT"}
						appSettings={this.props.appSettings}
						changeSettings={(e)=>{this.changeSettings(e, this.props.type)}}
					/>
					{window.innerWidth > 768 ? (
						<React.Fragment>
							<div style={styleObj} className="up-fadeIn">
								<div className="float-left">
								{sat_api.getLabel(this.props.appLanguage.lang, 'enable_seamless_printing') || "Enable Seamless Printing"}
									{enableSeamlessPrinting ? (
										<span
											onClick={this.openTestPrintModal}
											className="test-print-btn"
										>
											( Test )
										</span>
									) : null}
								</div>
								<div className="float-right">
									<CSwitchButton
										name="enable_seamless_printing"
										checked={enableSeamlessPrinting}
										onChange={this.changeSeamlessPrintingSettings}
									/>
								</div>
								<div className="clearfix" />
							</div>
						</React.Fragment>
					) : null}
				</div>
			</SettingsBoxWithHeader>
		);
	}
}


@connect(
	store => {
		return {
			appSettings: store.appSettings,
			loginData: store.loginReducer.data,
			enableSeamlessPrinting: store.printManager.enableSeamlessPrinting
		};
	},
	dispatch => {
		return {
			togglePrinterSetupInstructions: showSetupInstructions =>
				dispatch(togglePrntrSetupInstrctns(showSetupInstructions))
		};
	}
)
class AutoConfirm extends React.Component {
	static getDerivedStateFromProps(props, state) {
		if (!_.isEqual(props.loginData.biz.platforms, state.platforms)) {
			return {
				platforms: props.loginData.biz.platforms
			};
		}

		return null;
	}

	constructor(props) {
		super(props);

		const platforms = this.props.loginData.biz.platforms;
		this.state = { platforms };
	}

	toggleAutoconfirm = name => {
		const {
			enableSeamlessPrinting,
			togglePrinterSetupInstructions
		} = this.props;

		const ps = [...this.state.platforms];
		const p = ps.find(i => i.name == name);
		const p_state = !p.auto_confirm_enabled;
		p.auto_confirm_enabled = p_state;

		//lets make a call to toggle

		this.setState({
			platforms: ps
		});

		if (name === 'swiggy' && p_state === true && !enableSeamlessPrinting) {
			togglePrinterSetupInstructions(true);
		}

		sat_api.toggleAutoConfirm(
			{
				name: name,
				state: p_state
			},
			data => {
				console.log('Successfully toggled state', data);

				// this.setState({
				// 	platforms: ps
				// })
			},
			err => {
				p.auto_confirm_enabled = !p_state;

				this.setState({
					platforms: ps
				});
			}
		);
	};

	render() {
		const {
			appSettings: settings,
			enableSeamlessPrinting,
			loginData
		} = this.props;

		const styleObj = {
			lineHeight: '35px',
			overflow: 'hidden',
			transition: '0.5s'
		};

		const node = this.state.platforms.map(i => {
			return (
				<div style={{ lineHeight: '44px' }} key={i.name}>
					<div className="float-left">{i.name}</div>
					<div className="float-right">
						<CSwitchButton
							name={i.name}
							checked={i.auto_confirm_enabled}
							onChange={e => this.toggleAutoconfirm(i.name)}
						/>
					</div>
					<div className="clearfix" />
				</div>
			);
		});
		return (
			<SettingsBoxWithHeader title="Order auto confirm">
				<div className="padding-10">{node}</div>
			</SettingsBoxWithHeader>
		);
	}
}
