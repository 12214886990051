import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import Moment from '../clientTime';
import { connect } from 'react-redux';
import {
	getOrderDetail,
	resetOrderDetail,
	orderStateUpdate,
	setDeliveryExec
} from '../actions.js';

import {
	FoodType,
	CurrCode,
	PrintBtn,
	Pod,
	PreLoaderBg,
	PageRefresh,
	StatusChangeconfirmModal,
	DeliveryExecModal,
	RiderTempModal,
} from '../reactComp.jsx';

import Table from '../Table/Table';

import { store } from '../store.js';
// import seamlessPrintDispatcher from '../../js/seamlessPrintDispatcher';

var sat_api = require('../../js/api.js');
var _ = require('lodash');

@connect(store => {
	return {
		orderStateReducer: store.orderStateReducer,
		loginReducer: store.loginReducer,
		reqCallBackReducer: store.reqCallBackReducer,
		appLanguage: store.appLanguage
	};
})
class OrderSummary extends Component {
	constructor(props) {
		super(props);
	}
	statusChange(data) {
		store.dispatch(
			orderStateUpdate({
				id: data.id,
				new_state: data.new_state,
				next_states: data.next_states,
				curr_state: data.curr_state,
				channel: data.channel
			})
		);
	}

	showRiderTempModal = ()=> {
		store.dispatch({
			type:"STATUS_CHANGE_MODAL",
			payload:{
				showRiderTempModal: true
			}
		})
	}

	assignDeleveryExec = ()=> {
		store.dispatch({
			type:"STATUS_CHANGE_MODAL",
			payload:{
				showDeliveryExecModal: true
			}
		})
	}

	getBizName = (bizs, biz_id) => {
		const biz = bizs.find((b) => b.biz_id == biz_id);
		return biz ? biz.name : '';
	}

	render() {
		let lang = this.props.appLanguage.lang;
		var editPermission =
			this.props.loginReducer.data.permissions.indexOf(
				'satellite_tracker_edit'
			) > -1;

		const comet_enabled = this.props.loginReducer.data.biz.comet_enabled;
		var data = this.props.data;
		var orderSummaryData = data.srvrResp.order;
		var pm = {
			cash: sat_api.getLabel(lang, 'cash') || 'Cash',
			payment_gateway: sat_api.getLabel(lang, 'online_payment') || 'Online payment',
			prepaid: 'Wallet',
			aggregator: 'Aggregator'
		};

		if (data.srvrResp.loading) {
			return <PreLoaderBg no={3} />;
		}

		let merchant_ref_id = orderSummaryData.details.merchant_ref_id
			? orderSummaryData.details.merchant_ref_id
			: false;
		let MerRefId = () => {
			if (merchant_ref_id) {
				return (
					<div className="div-table-row">
						<div className="div-table-cell">Merchant Ref ID</div>
						<div className="div-table-cell">{merchant_ref_id}</div>
					</div>
				);
			} else {
				return null;
			}
		};

		var external_ids = () => {
			var ext_ids_nodes = _.map(
				orderSummaryData.details.ext_platforms,
				(i, d) => {
					return (
						<div className="div-table-row" key={d}>
							<div className="div-table-cell">{i.name}</div>
							<div className="div-table-cell">{i.id}</div>
						</div>
					);
				}
			);
			return <div className="div-table m-b-10 border-top">{ext_ids_nodes}</div>;
		};

		// NOTE: I don't know why it's an array when I'll always be showing the first element
		let deliveryInfo = () => {
			if (
				orderSummaryData.details.ext_platforms &&
				orderSummaryData.details.ext_platforms.length > 0
			) {
				if (
					orderSummaryData.details.ext_platforms[0].delivery_type == 'delivery'
				) {
					return null;
				}
				if (
					orderSummaryData.details.ext_platforms[0].delivery_type == 'partner'
				) {
					return 'Delivery through partner';
				}
				if (
					orderSummaryData.details.ext_platforms[0].delivery_type == 'pickup'
				) {
					return sat_api.getLabel(lang, 'pickup') || 'Pickup';
				}
			}
			return null;
		};

		let channel_ico = 'web.svg';

		if (sat_api.MISC_CONFIG.CHANNEL_IMG[orderSummaryData.details.channel]) {
			channel_ico =
				sat_api.MISC_CONFIG.CHANNEL_IMG[orderSummaryData.details.channel];
		}

		var channelJSX = (function() {
			return (
				<img
					style={{ width: '20px', borderRadius: '4px' }}
					src={'/images/' + channel_ico}
				/>
			);
		})();

		const bizs = this.props.loginReducer.data.bizs || [this.props.loginReducer.data.biz];
		const multiBiz = (this.props.loginReducer && this.props.loginReducer.data ?  this.props.loginReducer.data.multi_biz : false);
		let checkRiderTemp = false;
		if (orderSummaryData.details.channel === 'zomato' && this.props.loginReducer && this.props.loginReducer.data) {
			const platforms = this.props.loginReducer.data.biz.platforms;
			const zomato = platforms.find((pl) => pl.name === 'zomato');
			checkRiderTemp = zomato && zomato.check_rider_temp ? true : false;
		}

		return (
			<div className="content  order-summary-sub-section">
				<div className="order-status-preview border-bottom div-table">
					<div className="div-table-row">
						<div className="div-table-cell secondary-text">
							{sat_api.getLabel(lang, 'status') || 'Status'}
						</div>
						<div className="div-table-cell">
							<span
								className={
									orderSummaryData.details.state.toLowerCase() +
									' order-state-button'
								}
							>
								{sat_api.getLabel(
									lang,
									orderSummaryData.details.state.toLowerCase()
								) || orderSummaryData.details.state}
							</span>
						</div>
					</div>
				</div>
				<div className="div-table">
					<div className="div-table-row">
						<div className="div-table-cell">
							{sat_api.getLabel(lang, 'order_id') || 'Order ID'}
						</div>
						<div className="div-table-cell">{orderSummaryData.details.id}</div>
					</div>
					{
						multiBiz &&
						<div className="div-table-row">
							<div className="div-table-cell">
								{sat_api.getLabel(lang, 'business') || 'Business'}
							</div>
							<div className="div-table-cell">{this.getBizName(bizs, orderSummaryData.details.biz_id)}</div>
						</div>
					}
					<MerRefId />
					<div className="div-table-row hide-in-print">
						<div className="div-table-cell">
							{sat_api.getLabel(lang, 'channel') || 'Channel'}
						</div>
						<div className="div-table-cell">
							{channel_ico ? channelJSX : orderSummaryData.details.channel}
						</div>
					</div>
					<div className="div-table-row">
						<div className="div-table-cell">
							{sat_api.getLabel(lang, 'payment_mode') || 'Payment Mode'}
						</div>
						<div className="div-table-cell">
							{pm[orderSummaryData.payment[0].option]
								? pm[orderSummaryData.payment[0].option]
								: orderSummaryData.payment[0].option}
						</div>
					</div>
					<div className="div-table-row">
						<div className="div-table-cell">
							{sat_api.getLabel(lang, 'fulfilment_mode') || 'Fulfilment Mode'}
						</div>
						<div className="div-table-cell capitalize">
							{sat_api.getLabel(lang, orderSummaryData.details.order_type) || orderSummaryData.details.order_type}
						</div>
					</div>
				</div>
				<div className="div-table">
					<div className="div-table-row">
						<div className="div-table-cell">
							{sat_api.getLabel(lang, 'delivery_info') || 'Delivery Info'}
						</div>
						{
							orderSummaryData.details.order_type !== 'pickup' ?
							<div className="div-table-cell capitalize">
							{
								deliveryInfo() ||  (!comet_enabled ? (sat_api.getLabel(lang, 'self_delivery') || "Self delivery") : (<span>Self | <AsgnPlusBtn scrollToDeliveryInfoSec={this.props.scrollToDeliveryInfoSec} data={orderSummaryData} /></span>))
							}
							</div>
							:
							<div className="div-table-cell capitalize">
								{sat_api.getLabel(lang, orderSummaryData.details.order_type) || orderSummaryData.details.order_type}
							</div>
						}
					</div>
					<div className="div-table-row">
						<div className="div-table-cell">
							{sat_api.getLabel(lang, 'outlet') || 'Outlet'}
						</div>
						<div className="div-table-cell">{orderSummaryData.store.name}</div>
					</div>
					<div className="div-table-row">
						<div className="div-table-cell">
							{sat_api.getLabel(lang, 'delivery_time') || 'Delivery Time'}
						</div>
						<div className="div-table-cell">
							{orderSummaryData.details.time_slot_end &&
							orderSummaryData.details.time_slot_start ? (
								<div>
									<div>
										{Moment(
											new Date(orderSummaryData.details.delivery_datetime)
										).format('D MMM, Y')}
									</div>
									<div>
										{sat_api.readableSlot({
											time_slot_end: orderSummaryData.details.time_slot_end,
											time_slot_start: orderSummaryData.details.time_slot_start
										})}
									</div>
								</div>
							) : (
								Moment(
									new Date(orderSummaryData.details.delivery_datetime)
								).format('D MMM, Y, h:mm a')
							)}
						</div>
					</div>
				</div>
				<div
					className={
						orderSummaryData.details.instructions == ''
							? 'hide'
							: 'm-b-10 border-top'
					}
				>
					<div className="secondary-text m-b-5">
						{sat_api.getLabel(lang, 'special_instructions') || 'Special Instruction'}:
					</div>
					<div style={{ whiteSpace: 'pre-line' }}>
						{orderSummaryData.details.instructions}
					</div>
				</div>
				<div
					className={
						(!editPermission ||
						orderSummaryData.details.state == 'Completed' ||
						orderSummaryData.details.state == 'Cancelled'
							? 'hide-imp'
							: '') +
						' buttons-container div-table  border-top status-selection-btn-container'
					}
				>
					<a
						className={
							(this.props.orderStateReducer.loading ? 'progress-status' : '') +
							' up-button default primary small'
						}
						onClick={this.statusChange.bind(this, {
							id: orderSummaryData.details.id,
							next_states: orderSummaryData.next_states,
							curr_state: orderSummaryData.details.state,
							channel: orderSummaryData.details.channel
						})}
					>
						{sat_api.getLabel(lang, 'set_status') || 'Set status'}
					</a>
					<a
						className={
							(this.props.orderStateReducer.loading ? 'progress-status' : '') +
							' up-button default dismiss small'
						}
						onClick={this.statusChange.bind(this, {
							id: orderSummaryData.details.id,
							new_state: 'cancelled',
							curr_state: orderSummaryData.details.state,
							channel: orderSummaryData.details.channel
						})}
					>
						{sat_api.getLabel(lang, 'cancel') || 'Cancel'}
					</a>
				</div>
				{
					(checkRiderTemp && orderSummaryData.details.state.toLowerCase() === 'food ready') &&
					<div>
						<a
							className={
								(this.props.orderStateReducer.loading ? 'progress-status' : '') +
								' up-button default warning small rider-temp'
							}
							onClick={this.showRiderTempModal}
						>
							SUBMIT RIDER BODY TEMP
						</a>
					</div>
				}
			</div>
		);
	}
}

@connect(store => {
	return {
		appLanguage: store.appLanguage
	};
})
class UserProfile extends React.Component {
	render() {
		var data = this.props.data;
		var srvrResp = data.srvrResp;
		let isOrderContactless = false;
		if (srvrResp.loading) {
			return <PreLoaderBg no={3} />;
		} else {
			var customerData = data.srvrResp.customer;
			let extraDetails = data.srvrResp.order.details.extras ? data.srvrResp.order.details.extras : undefined;
			if (extraDetails && extraDetails.length > 0) {
				if (extraDetails[0].oms_contactless) {
					isOrderContactless = true;
				}
			}
		}

		var isPickup = srvrResp.order.details.order_type === 'pickup';
		if (isPickup) {
			var pickup_from = srvrResp.order.store.name;
		}

		let addressComponent = [
			'line_1',
			'line_2',
			'locality',
			'sub_locality',
			'city',
			'pin'
		];
		var addressComponentVal = [];
		for (let x in addressComponent) {
			if (customerData.address[addressComponent[x]]) {
				addressComponentVal.push(customerData.address[addressComponent[x]]);
			}
		}
		var addressString = addressComponentVal.join(', ');
		const lang = this.props.appLanguage.lang;

		return (
			<div className="content">
				<div className="m-b-10 border-bottom">
					<div className="secondary-text m-b-5">
						{sat_api.getLabel(lang, 'name') || 'Name'}:
					</div>
					<div>{customerData.name || "--"}</div>
				</div>
				<div className="m-b-10">
					<div className="m-b-10">
						<div className="secondary-text m-b-5">
							{sat_api.getLabel(lang, 'email') || 'Email'}:
						</div>
						<div>{customerData.email || "--"}</div>
					</div>
					<div className="m-b-10">
						<div className="secondary-text m-b-5">
							{sat_api.getLabel(lang, 'phone') || 'Phone'}:
						</div>
						<div>{customerData.phone || "--"}</div>
					</div>
					<div style={{ overflow: 'hidden' }}>
						<div className="secondary-text m-b-5">
							{sat_api.getLabel(lang, 'address') || 'Address'}:
						</div>
						<div>
							{isPickup ? 'Pickup from store ' + pickup_from : addressString}
						</div>
					</div>
					{
						isOrderContactless &&
						<div className="m-t-10 border-top">
							<div className="contactless-label-container">
								<span className="green-mark" />Opted for <span className="highlight">Contactless Delivery</span>
							</div>
						</div>
					}
					{customerData.address.pod ? (
						<Pod data={customerData.address.pod} />
					) : null}
				</div>
			</div>
		);
	}
}

@connect(store => {
	return {
		appLanguage: store.appLanguage
	};
})
class ItemWiseTaxes extends Component {
	constructor(props) {
		super(props);
		this.state = { expand: false };
	}
	expand() {
		this.setState({ expand: !this.state.expand });
	}

	expandButton() {
		if (this.state.expand) {
			return (
				<span onClick={e => this.expand(e)} className="expand-btn">
					-
				</span>
			);
		} else {
			return (
				<span onClick={e => this.expand(e)} className="expand-btn">
					+
				</span>
			);
		}
	}

	getTaxandChargeDetail(_taxes, _charges) {
		const lang = this.props.appLanguage.lang;

		if (_taxes.length > 0) {
			var taxNodes = _taxes.map((o, i) => {
				return (
					<div className="item-taxes m-b-5" key={i}>
						&bull; {o.title} ({o.rate}%): <CurrCode />
						{o.value}
					</div>
				);
			});
		} else {
			var taxNodes = 'No Taxes';
		}

		if (_charges.length > 0) {
			var chargeNodes = _charges.map((o, i) => {
				return (
					<div className="item-taxes m-b-5" key={i}>
						&bull; {o.title} : <CurrCode />
						{o.value}
					</div>
				);
			});
		} else {
			var chargeNodes = 'No Charges';
		}

		return (
			<div className="m-t-10 tax-charge-detail">
				<div className="m-b-10">
					<div className="bold m-b-5">
						{sat_api.getLabel(lang, 'taxes') || 'Taxes'}:
					</div>
					<div className="tax-charge-nodes">{taxNodes}</div>
				</div>
				<div className="m-b-10">
					<div className="bold m-b-5">
						{sat_api.getLabel(lang, 'charges') || 'Charges'}:
					</div>
					<div className="tax-charge-nodes">{chargeNodes}</div>
				</div>
			</div>
		);
	}

	render() {
		const lang = this.props.appLanguage.lang;
		var _taxes = this.props.taxes;
		var _charges = this.props.charges;
		if (!_taxes) {
			return null;
		} else {
			var totalTaxes = _.reduce(
				_taxes,
				function(s, entry) {
					return s + parseFloat(entry.value).toFixedInt(2);
				},
				0
			);
			var totalCharges = _.reduce(
				_charges,
				function(s, entry) {
					return s + parseFloat(entry.value).toFixedInt(2);
				},
				0
			);

			var taxesAndCharges = totalTaxes + totalCharges;

			return (
				<div className="item-taxes-container">
					<span style={{ cursor: 'pointer' }} onClick={e => this.expand(e)}>
						&bull;{' '}
						{sat_api.getLabel(lang, 'taxes_and_charges') || 'Taxes and charges'}{' '}
						<span style={{ marginLeft: '10px' }}>
							<CurrCode /> {taxesAndCharges}
						</span>
					</span>
					<div>
						{this.state.expand
							? this.getTaxandChargeDetail(_taxes, _charges)
							: null}
					</div>
				</div>
			);
		}
	}
}

const ItemNodeRow = (props)=>{

	const orderDetailData = props.data;
	const optionsDetail_options = orderDetailData.options_to_add
		? orderDetailData.options_to_add
		: [];

	const optionsDetail = _.map(optionsDetail_options, 'title').join(', ');

	const optionsDetail_options_to_remove = orderDetailData.options_to_remove
		? orderDetailData.options_to_remove
		: [];

	const optionsremoveDetail = _.map(
		optionsDetail_options_to_remove,
		'title'
	).join(', ');

	const if_combo_item = props.combo;

	let totalPrice = null;
	let qtyString = "";

	if (if_combo_item) {
		qtyString =
			(sat_api.getLabel(props.lang, 'quantity') || 'Qty') +
			orderDetailData.quantity;
	} else {
		totalPrice = (
			<span>
				<CurrCode /> {orderDetailData.total.toFixed(2)}
			</span>
		);
		qtyString = orderDetailData.quantity;
	}

	return (
		<div className="order-detail-items">
			<div className="tab">
				<div className="bold m-b-5">
					<FoodType type={orderDetailData.food_type} />{' '}
					{orderDetailData.title}
				</div>
				<ItemWiseTaxes
					taxes={orderDetailData.taxes}
					charges={orderDetailData.charges}
				/>
				<div className={optionsDetail_options.length > 0 ? '' : 'hide'}>
					<div className="uppercase  m-t-5 m-b-5">
						<span className="tags add">to add</span>
					</div>
					<div>{optionsDetail}</div>
				</div>
				<div
					className={optionsDetail_options_to_remove.length > 0 ? '' : 'hide'}
				>
					<div className="uppercase  m-t-5 m-b-5">
						<span className="tags remove">to remove</span>
					</div>
					<div>{optionsremoveDetail}</div>
				</div>
			</div>
			<div className="tab bold">{qtyString}</div>
			<div className="tab bold">{totalPrice}</div>
			<div className="clearfix" />
		</div>
	)
}

const ComboItemNodeRow = (props)=>{
	var orderDetailData = props.data;
	var optionsDetail_options = orderDetailData.options_to_add;

	var itemNodes = orderDetailData.items.map((o, i) => {
		return <ItemNodeRow key={i} data={o} combo={true} />;
	});

	return (
		<div className="order-detail-items">
			<div className="tab">
				<div className="bold m-b-5">{orderDetailData.title}</div>
				{itemNodes}
			</div>
			<div className="tab bold">{orderDetailData.quantity}</div>
			<div className="tab bold">
				<CurrCode /> {orderDetailData.quantity * orderDetailData.price}
			</div>
			<div className="clearfix" />
		</div>
	)
}


class Discount extends React.Component {
	render() {
		if (this.props.data.discount == 0 && !this.props.data.coupon) {
			return null;
		}
		let discountStr =
			sat_api.getLabel(this.props.lang, 'discount') || 'Discount';
		return (
			<div className="order-detail-summary-row theme-color-3-text">
				<div className="bold">
					{discountStr} {this.props.data.coupon || ''}
				</div>
				<div>
					<CurrCode /> {this.props.data.discount}
				</div>
			</div>
		);
	}
}

@connect(state => ({
	enableSeamlessPrinting: state.printManager.enableSeamlessPrinting,
}))
class TopBar extends React.Component {
	back() {
		store.dispatch({
			type: 'SHOW_HIDE_ORDER_DETAIL_MODAL',
			payload: false
		});
	}

	printBill = () => {
		sat_api.print({});
	};


	render() {
		var data = this.props.data;
		var srvrResp = data.srvrResp;
		if (srvrResp.loading) {
			return (
				<div
					className="order-detail-top-bar div-table"
					style={{ height: '38px' }}
				>
					<div className="div-table-cell" />
				</div>
			);
		} else {
			var orderSummaryData = srvrResp.order;
			var delivery_datetime = orderSummaryData.details.delivery_datetime;
			var timeLeft = sat_api.timeDiffHumanize(
				delivery_datetime - new Date().getTime()
			);
			const delivery_time_str =
				sat_api.getLabel(this.props.lang, 'delivery_time') || 'Delivery time';
			const time_left_str =
				sat_api.getLabel(this.props.lang, 'time_left') || 'Time left';
			return (
				<div>
					<div className="order-detail-top-bar div-table">
						<div className="div-table-cell" style={{ padding: 0 }}>
							<div className="printer--order-detail" onClick={this.printBill}>
								<img src="/images/printer.png" />
							</div>
						</div>
						<div
							className="div-table-cell order-detail-timeleft"
							style={{ padding: 0 }}
						>
							{timeLeft === null
								? delivery_time_str +
								  ': ' +
								  Moment(delivery_datetime).format('D MMM, Y, h:mm a')
								: time_left_str + ': ' + timeLeft}
						</div>
					</div>
				</div>
			);
		}
	}
}

@connect(store => {
	return {
		appLanguage: store.appLanguage
	};
})
class OrderDetail extends Component {
	orderSummaryRow(row) {
		return (
			<div className="order-detail-summary-row" key={row.title}>
				<div>{row.title}</div>
				<div>{row.value}</div>
			</div>
		);
	}
	render() {
		const lang = this.props.appLanguage.lang;
		var data = this.props.data;
		if (data.srvrResp.loading) {
			return <PreLoaderBg no={3} />;
		}

		const order_detail_data = data.srvrResp.order;

		var itemNodes = order_detail_data.items.map((o, i) => {
			return <ItemNodeRow key={i} data={o} lang={lang} />;
		});

		if (order_detail_data.combos) {
			var comboItemNodes = order_detail_data.combos.map((o, i) => {
				return <ComboItemNodeRow key={i} data={o} lang={lang} />;
			});
		} else {
			var comboItemNodes = null;
		}

		let chargeObjects, taxObjects;
		let orderDetails = data.srvrResp.order.details;
		let item_taxes_lang = sat_api.getLabel(lang, 'item_taxes') || 'Item Taxes';
		let item_charges_lang =
			sat_api.getLabel(lang, 'item_charges') || 'Item Charges';

		if (
			orderDetails &&
			(orderDetails.item_level_total_taxes ||
				orderDetails.order_level_total_taxes ||
				orderDetails.item_level_total_charges ||
				orderDetails.order_level_total_charges)
		) {
			taxObjects = [
				{ title: item_taxes_lang, value: orderDetails.item_level_total_taxes }
			];
			taxObjects = taxObjects.concat(orderDetails.taxes);

			chargeObjects = [
				{
					title: item_charges_lang,
					value: orderDetails.item_level_total_charges
				}
			];
			chargeObjects = chargeObjects.concat(orderDetails.charges);
		} else {
			taxObjects = [
				{ title: 'Taxes', value: order_detail_data.details.item_taxes }
			];
			chargeObjects = [
				{ title: 'Delivery', value: order_detail_data.details.delivery_charge },
				{
					title: 'Packaging',
					value: order_detail_data.details.packaging_charge
				}
			];
		}

		let taxes = taxObjects.map(this.orderSummaryRow);
		let charges = chargeObjects.map(this.orderSummaryRow);
		let wallet_credit_applied = _.find(order_detail_data.payment, {
			option: 'wallet_credit'
		});
		return (
			<div className="content order-detail-content">
				<div className="full-width">
					<div className="order-detail-header">
						<div className="tab">
							{sat_api.getLabel(lang, 'items') || 'Items'}
						</div>
						<div className="tab">
							{sat_api.getLabel(lang, 'quantity') || 'Quantity'}
						</div>
						<div className="tab">
							{sat_api.getLabel(lang, 'total') || 'Total'}
						</div>
						<div className="clearfix" />
					</div>
					{comboItemNodes}
					{itemNodes}
					<div className="order-detail-summary">
						<div className="order-detail-summary-row">
							<div>{sat_api.getLabel(lang, 'subtotal') || 'Sub Total'}</div>
							<div>
								<CurrCode /> {order_detail_data.details.order_subtotal}
							</div>
						</div>

						{taxes}
						{charges}

						<Discount data={order_detail_data.details} lang={lang} />
						<div className="dark-text-color large-text order-detail-summary-row">
							<div>{sat_api.getLabel(lang, 'total') || 'Total'}</div>
							<div>
								<CurrCode /> {order_detail_data.details.order_total}
							</div>
						</div>
						{wallet_credit_applied ? (
							<div className="order-detail-summary-row theme-color-3-text">
								<div>Credit Applied</div>
								<div>
									-<CurrCode /> {wallet_credit_applied.amount}
								</div>
							</div>
						) : null}
						{wallet_credit_applied ? (
							<div className="dark-text-color large-text order-detail-summary-row">
								<div>{sat_api.getLabel(lang, 'total') || 'Payable Amount'}</div>
								<div>
									<CurrCode />{' '}
									{order_detail_data.details.order_total -
										wallet_credit_applied.amount}
								</div>
							</div>
						) : null}
					</div>
					{order_detail_data.details.total_external_discount ? (
						<div className="order-detail-summary">
							<div className="order-detail-summary-row">
								<div>
									{sat_api.getLabel(lang, 'total') || 'Total'} ( Excluding
									external discounts )
								</div>
								<div>
									<CurrCode />{' '}
									{(
										order_detail_data.details.order_total +
										order_detail_data.details.total_external_discount
									).toFixed(2)}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

@connect(store => {
	return {
		appLanguage: store.appLanguage
	};
})
export class OrderStatusHistory extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const lang = this.props.appLanguage.lang;
		const noLogStr = sat_api.getLabel(lang, 'no_logs') || 'No Logs';
		var data = this.props.data;
		var srvrResp = data.srvrResp;
		if (srvrResp.loading) {
			return <PreLoaderBg no={2} />;
		} else {
			var statusLogData = data.srvrResp.order.status_updates;
			if (statusLogData.length == 0) {
				return (
					<div className="padding-10">
						{sat_api.getLabel(lang, 'no_data') || 'No Data'}
					</div>
				);
			}
			//const order_id = this.props.data[d].id;
			var extraConHeight = 70;
			if (window.outerWidth > 768) {
				extraConHeight = 52;
			}
			var tableHeight = Math.min(
				600,
				statusLogData.length * 50 + extraConHeight
			);

			var statusList = [];
			var winWidth = window.innerWidth;

			const getLabel = label => sat_api.getLabel(lang, label);

			const columns = [
				{
					columnKey: 'created',
					label: getLabel('created') || 'Created'
				},
				{
					columnKey: 'updated_by',
					label: getLabel('updated_by') || 'Updated By'
				},
				{
					columnKey: 'status',
					label: getLabel('status') || 'Status'
				}
			].concat(
				window.innerWidth > 320
					? {
							columnKey: 'message',
							label: getLabel('message') || 'Message'
					  }
					: []
			);

			const rows = statusLogData.map(statusLogEntry =>
				columns.reduce((acc, { columnKey }) => {
					return {
						...acc,
						[columnKey]:
							columnKey === 'created'
								? Moment(statusLogEntry.created).format('D MMM, h:mm:ss A')
								: statusLogEntry[columnKey]
					};
				}, {})
			);

			// console.log('rows', rows);

			for (var x in statusLogData) {
				var o = statusLogData[x];
				var status = [
					{
						columnLabel: sat_api.getLabel(lang, 'created') || 'Created',
						component: Moment(o.created).format('D MMM, h:mm:ss A')
					},
					{
						columnLabel: sat_api.getLabel(lang, 'updated_by') || 'Updated By',
						component: o.updated_by
					},
					{
						columnLabel: sat_api.getLabel(lang, 'status') || 'Status',
						component: o.status
					}
				];
				if (winWidth > 320) {
					status.push({
						columnLabel: sat_api.getLabel(lang, 'message') || 'Message',
						component: o.message
					});
				}
				statusList.push(status);
			}
			var tableWidth = sat_api.isMobile
				? window.innerWidth - 20
				: window.innerWidth * 0.65 - 20;

			// console.log('columns', columns);

			return (
				<Table
					width="100%"
					headerHeight="40px"
					minRowHeight="35px"
					columns={columns}
					rows={rows}
				/>
			);

			// Code can be removed //
			// return (
			// 	<UPList
			// 		list={statusList}
			// 		tableWidth={tableWidth}
			// 		noItemMessage={noLogStr}
			// 	/>
			// );
		}
	}
}

function BillRow({ t1, t2 }) {
	return (
		<div className="div-table">
			<div className="div-table-row">
				<div
					className="div-table-cell width50"
					style={{ textTransform: 'capitalize' }}
				>
					{t1}
				</div>
				<div
					className="div-table-cell width50 text-right"
					style={{ textTransform: 'capitalize' }}
				>
					{t2}
				</div>
			</div>
		</div>
	);
}

function BillRow3Col({
	optionsToAdd = [],
	taxes,
	t1,
	t2,
	t3,
	bold = false,
	charges = []
}) {
	let extra = '';
	if (bold) extra = ' bold';

	var optionsToAddMarkup = optionsToAdd.map((o, i) => (
		<div key={i}>{o.title}</div>
	));

	return (
		<div className={'div-table' + extra}>
			<div className="div-table-row">
				<div className="div-table-cell first">
					<span>{t1}</span>
					<div className="m-l-10">{optionsToAddMarkup}</div>
					<BillItemWiseTaxes data={taxes} />
					<BillItemWiseTaxes data={charges} />
				</div>
				<div className="div-table-cell second text-right">{t2}</div>
				<div className="div-table-cell third text-right">{t3}</div>
			</div>
		</div>
	);
}

function BillRow2Col({ optionsToAdd = [], t1, t2, t3 }) {
	var optionsToAddMarkup = optionsToAdd.map((o, i) => (
		<div key={i}>{o.title}</div>
	));

	return (
		<div className={(t3 || '') + ' div-table'}>
			<div className="div-table-row">
				<div className="div-table-cell width80 text-left">
					<span>{t1}</span>
					<div className="m-l-10">{optionsToAddMarkup}</div>
				</div>
				<div className="div-table-cell width20 text-right">{t2}</div>
			</div>
		</div>
	);
}

function BillItemFooterRow({ t1, t2 }) {
	return (
		<div className="item-footer-row">
			<BillRow t1={t1} t2={t2} />
		</div>
	);
}

class BillItemWiseTaxes extends Component {
	render() {
		var _taxes = this.props.data;
		if (!_taxes) {
			return null;
		} else {
			var taxNodes = _taxes.map((o, i) => {
				return (
					<div className="item-taxes" key={i}>
						{o.title} ({o.rate}%): {o.value}
					</div>
				);
			});

			return <div style={{paddingLeft: '5px'}} className="item-taxes-container">{taxNodes}</div>;
		}
	}
}

@connect(store => {
	return {
		appSettings: store.appSettings,
		appLanguage: store.appLanguage.lang,
	};
})
export class PrintBill extends React.Component {
	renderItems(items) {
		return items.map((i, o) => {
			let t1 = i.title;

			return (
				<BillRow3Col
					taxes={i.taxes}
					optionsToAdd={i.options_to_add}
					charges={i.charges}
					key={o}
					t1={t1}
					t2={
						i.quantity + ' x ' + Math.round((i.total / i.quantity) * 100) / 100
					}
					t3={i.total}
				/>
			);
		});
	}

	renderKOTItems(items) {
		return items.map((i, o) => {
			let t1 = i.title;

			return (
				<BillRow2Col
					optionsToAdd={i.options_to_add}
					key={o}
					t1={t1}
					t2={i.quantity}
				/>
			);
		});
	}

	componentWillReceiveProps(newProps) {
		if (
			this.props.orderDetail.fetched === false &&
			newProps.orderDetail.fetched
		) {
			let order = newProps.orderDetail.data.order;
			let orderDetails = order.details;

			let print_kot = newProps.appSettings.print_kot;
			let print_order_slip = newProps.appSettings.print_order_slip;
			let deliveryTime = Moment(new Date(orderDetails.delivery_datetime));
			let deliveryTimeText = `${deliveryTime.format('D MMM, h:mm A')}`;

			let up = JSON.parse(localStorage.getItem('UP'));


			window.dataForSunmiPrinterData = {
				biz_data: up.auth.biz,
				order_data: newProps.orderDetail,
				print_order_slip: print_order_slip,
				deliveryTimeText: deliveryTimeText
			};
		}
	}

	billItemFooterRow(row, i) {
		if (row.value != 0) {
			return <BillRow key={i} t1={row.title} t2={row.value} />;
		}
	}

	render() {
		const lang = this.props.appLanguage;
		if (!this.props.orderDetail.data) return null;

		let up = JSON.parse(localStorage.getItem('UP'));

		// Print data for sunmi inner printer, different bill will be generated if sunmi device is used
		let order = this.props.orderDetail.data.order;
		let orderDetails = order.details;

		let print_kot = this.props.appSettings.print_kot;
		let print_order_slip = this.props.appSettings.print_order_slip;
		let wallet_credit_applied = _.find(order.payment, {
			option: 'wallet_credit'
		});

		let customer = this.props.orderDetail.data.customer;
		const { multi_biz, bizs=[] } = up.auth;
		let biz = up.auth.biz;
		if (multi_biz) {
			biz = bizs.find((b) => b.biz_id === orderDetails.biz_id) || biz;
		}
		let biz_name = biz.name.toUpperCase();
		let { gst, tin, logo } = biz;

		let store = this.props.orderDetail.data.order.store;


		let od = Moment(new Date(orderDetails.created));
		let orderDate = `Date: ${od.format('D MMM, Y')}`;
		let orderTime = `Time: ${od.format('h:mm A')}`;

		let deliveryTime = () => {
			if (orderDetails.time_slot_end && orderDetails.time_slot_start) {
				return (
					<div>
						<div>
							{Moment(new Date(orderDetails.delivery_datetime)).format(
								'D MMM, Y'
							)}
						</div>
						<div>
							{sat_api.readableSlot({
								time_slot_end: orderDetails.time_slot_end,
								time_slot_start: orderDetails.time_slot_start
							})}
						</div>
					</div>
				);
			} else {
				return Moment(new Date(orderDetails.delivery_datetime)).format(
					'D MMM, Y, h:mm a'
				);
			}
		};

		// let deliveryTimeText = `${deliveryTime.format('D MMM, h:mm A')}`;
		// let deliveryTimeStamp = `${deliveryTime.format('h:mm A')}`;

		let deliveryTimeText = deliveryTime();
		let deliveryTimeStamp = deliveryTime();
		let deliveryDateInHeader = Moment(
			new Date(orderDetails.delivery_datetime)
		).format('D MMM, Y');

		let firstNameLastName = up.auth.name.split(' ');
		let firstName = '';
		if (firstNameLastName.length) {
			firstName = firstNameLastName[0];
		}

		window.dataForInnerPrinter = {
			biz_data: up.auth.biz,
			order_data: this.props.orderDetail,
			print_order_slip: print_order_slip,
			deliveryTimeText: deliveryTimeText,
			print_kot: print_kot
		};

		let paymentOption = order.payment.length && order.payment[0].option;

		let chargeObjects, taxObjects;

		if (
			orderDetails.item_level_total_taxes ||
			orderDetails.order_level_total_taxes ||
			orderDetails.item_level_total_charges ||
			orderDetails.order_level_total_charges
		) {
			taxObjects = [
				{ title: 'Item Taxes', value: order.details.item_level_total_taxes }
			];
			taxObjects = taxObjects.concat(order.details.taxes);

			chargeObjects = [
				{ title: 'Item Charges', value: orderDetails.item_level_total_charges }
			];
			chargeObjects = chargeObjects.concat(orderDetails.charges);
		} else {
			taxObjects = [{ title: 'Taxes', value: order.details.item_taxes }];
			chargeObjects = [
				{ title: 'Delivery', value: order.details.delivery_charge },
				{ title: 'Packaging', value: order.details.packaging_charge }
			];
		}

		let taxes = taxObjects.map((o, i) => this.billItemFooterRow(o, i));
		let charges = chargeObjects.map((o, i) => this.billItemFooterRow(o, i));
		var pm = {
			cash: sat_api.getLabel(this.props.appLanguage, 'cash') || 'Cash',
			payment_gateway: sat_api.getLabel(this.props.appLanguage, 'online_payment') || 'Online payment',
			prepaid: 'Wallet',
			aggregator: orderDetails.channel
		};
		// if(order.payment[0].option == 'cash'){
		// 	var paymentStr = `<span>`
		// }
		//  = if (pm[order.payment[0].option] || order.payment[0].option)

		var ext_channel_id = _.find(order.details.ext_platforms, {
			name: order.details.channel
		});

		var paymentStr = (() => {
			if (order.payment[0].option == 'cash') {
				return (
					<span>
						<span className="bold">
							{orderDetails.order_type !== 'pickup' ? (sat_api.getLabel(this.props.appLanguage, 'not_paid') || 'NOT PAID') : ''}
						</span> (
						{pm[order.payment[0].option]})
					</span>
				);
			} else {
				return (
					<span>
						<span className="bold">{sat_api.getLabel(this.props.appLanguage, 'paid') || 'PAID'}</span> (
						{pm[order.payment[0].option] || order.payment[0].option})
					</span>
				);
			}
		})();

		if (print_order_slip) {
			return (
				<div className="bill">
					{
					logo ?
						<div className="align-center">
							<img src={logo} style={{ maxHeight: '70px' }} />
						</div>
						:
						<h6 className="align-center" style={{ margin: '3px 0', fontSize: '1.2em' }}>
							{' '}
							{biz_name}{' '}
						</h6>
					}
					<div className="align-center">
						<h6 className="align-center"> {order.store.name} </h6>
					</div>
					<div className="m-t-10 m-b-10">
						<h3
							className="align-center"
							style={{
								margin: '3px 0',
								textTransform: 'uppercase',
								fontWeight: 'bold'
							}}
						>
							{' '}
							This is not a bill{' '}
						</h3>
					</div>
					<hr />
					<BillRow t1={`${sat_api.getLabel(lang, 'order_id') || 'Order ID'}:`} t2={orderDetails.id} />
					<BillRow t1={`${sat_api.getLabel(lang, 'payment') || 'Payment'}:`} t2={paymentStr} />
					<BillRow t1={`${sat_api.getLabel(lang, 'channel') || 'Channel'}:`} t2={order.details.channel} />
					<BillRow
						t1={`${sat_api.getLabel(lang, 'channel_id') || 'Channel ID'}:`}
						t2={(ext_channel_id && ext_channel_id.id) || 'NA'}
					/>
					<BillRow t1={`${sat_api.getLabel(lang, 'order_type') || 'Order Type'}:`} t2={order.details.order_type} />
					<BillRow
						t1={(sat_api.getLabel(lang, orderDetails.order_type) || orderDetails.order_type) + ' Time:'}
						t2={deliveryTimeText}
					/>
					<hr />
					<BillRow t1="Cust. Name:" t2={customer.name} />
					<BillRow t1="Cust. Phone:" t2={customer.phone} />
					<BillRow t1="Address:" t2="" />
					<p
						className={
							(orderDetails.order_type !== 'pickup' ? '' : 'hide') +
							' text-left'
						}
					>
						{customer.address.is_guest_mode ? '' : customer.address.tag}{' '}
						{customer.address.is_guest_mode || !customer.address.tag ? (
							''
						) : (
							<br />
						)}
						{customer.address.line_1} {customer.address.line_1 ? <br /> : ''}
						{customer.address.line_2} {customer.address.line_2 ? <br /> : ''}
						{customer.address.sub_locality}{' '}
						{customer.address.sub_locality ? <br /> : ''}
						{customer.address.city} {customer.address.city ? <br /> : ''}
						{customer.address.pin} {customer.address.pin ? <br /> : ''}
					</p>
					<p
						className={
							(orderDetails.order_type === 'pickup' ? '' : 'hide') +
							' text-left'
						}
					>
						{order.store.name}
						<br />
						{order.store.address}
					</p>
					<hr />
					<div className="items">
						<BillRow2Col key={9999} t1="Item" t2="Qty" t3="bold" />
						{this.renderKOTItems(order.items)}
					</div>
					<hr />
					<h4 className="text-left uppercase" style={{ margin: '3px 0' }}>
						{sat_api.getLabel(lang, 'special_instructions') || 'Order instructions'}
					</h4>
					<p style={{ whiteSpace: 'pre-line' }}>
						{order.details.instructions || '--'}
					</p>
					<hr />
					{order.details.discount ? (
						<BillRow t1={'Discount'} t2={order.details.discount} />
					) : null}
					{order.details.coupon ? (
						<BillRow t1={'Coupon'} t2={order.details.coupon} />
					) : null}
					{wallet_credit_applied ? (
						<div className="div-table">
							<div className="div-table-row">
								<div
									className="div-table-cell width50 bold"
									style={{ textTransform: 'capitalize' }}
								>
									Order Total
								</div>
								<div className="div-table-cell width50 text-right bold">
									<CurrCode />{' '}
									{order.details.order_total - wallet_credit_applied.amount}
								</div>
							</div>
						</div>
					) : (
						<div className="div-table">
							<div className="div-table-row">
								<div
									className="div-table-cell width50 bold"
									style={{ textTransform: 'capitalize' }}
								>
									Order Total
								</div>
								<div className="div-table-cell width50 text-right bold">
									<CurrCode />
									{order.details.order_total}
								</div>
							</div>
						</div>
					)}
					<hr />
					<div className="align-center">
						<h6 className="align-center"> {order.store.name} </h6>
						<h6>{order.store.address}</h6>
						<h6>{order.store.phone}</h6>
					</div>
				</div>
			);
		}
		return (
			<div className="bill">
				{
					logo ?
					<div className="align-center">
						<img src={logo} style={{ maxHeight: '70px' }} />
					</div>
					:
					<h6 className="align-center" style={{ margin: '3px 0', fontSize: '1.2em' }}>
						{' '}
						{biz_name}{' '}
					</h6>
				}
				<div className="align-center">
					<h6 className="align-center"> {order.store.name} </h6>
				</div>
				<hr />
				<BillRow t1={`${sat_api.getLabel(lang, 'order_id') || 'Order ID'}:`} t2={orderDetails.id} />
				<BillRow t1={`${sat_api.getLabel(lang, 'payment') || 'Payment'}:`} t2={paymentStr} />
				<BillRow t1={`${sat_api.getLabel(lang, 'ordering_platform') || 'Ordering platform'}:`} t2={order.details.channel} />
				<BillRow
					t1={`${sat_api.getLabel(lang, 'platform_id') || 'Platform ID'}:`}
					t2={(ext_channel_id && ext_channel_id.id) || 'NA'}
				/>
				<BillRow
					t1={(sat_api.getLabel(lang, orderDetails.order_type) || orderDetails.order_type) + ' Time:'}
					t2={deliveryTimeText}
				/>
				<BillRow t1={sat_api.getLabel(lang, "operator") || "Operator"} t2={firstName} />
				{gst ? <BillRow t1="GST No. " t2={gst} /> : null}
				<hr />
				<div className="items">{this.renderItems(order.items)}</div>
				<hr />
				<p style={{ whiteSpace: 'pre-line' }}>
					{order.details.instructions || 'No special instructions'}
				</p>
				<hr />
				<BillRow t1="Sub-Total" t2={order.details.order_subtotal} />

				{order.details.discount ? (
					<BillRow t1={sat_api.getLabel(lang, 'discount') || 'Discount'} t2={order.details.discount} />
				) : null}
				{order.details.coupon ? (
					<BillRow t1={sat_api.getLabel(lang, 'coupon') || 'Coupon'} t2={order.details.coupon} />
				) : null}
				{taxes}
				{charges}
				<div className="div-table">
					<div className="div-table-row">
						<div
							className="div-table-cell width50"
							style={{ textTransform: 'capitalize' }}
						>
							{sat_api.getLabel(lang, 'order_total') || 'Order Total'}
						</div>
						<div className="div-table-cell width50 text-right">
							<CurrCode />
							{order.details.order_total}
						</div>
					</div>
				</div>
				{wallet_credit_applied ? (
					<div className="div-table">
						<div className="div-table-row">
							<div
								className="div-table-cell width50"
								style={{ textTransform: 'capitalize' }}
							>
								Credit Applied
							</div>
							<div className="div-table-cell width50 text-right">
								-<CurrCode /> {wallet_credit_applied.amount}
							</div>
						</div>
					</div>
				) : null}
				{wallet_credit_applied ? (
					<div className="div-table">
						<div className="div-table-row">
							<div
								className="div-table-cell width50"
								style={{ textTransform: 'capitalize' }}
							>
								Payable Amount
							</div>
							<div className="div-table-cell width50 text-right">
								<CurrCode />{' '}
								{order.details.order_total - wallet_credit_applied.amount}
							</div>
						</div>
					</div>
				) : null}
				<hr />
				<h4 className="text-left uppercase" style={{ margin: '3px 0' }}>
					{sat_api.getLabel(lang, 'customer_details') || 'Customer details'}
				</h4>
				<BillRow t1={sat_api.getLabel(lang, 'customer') || 'Customer'} t2={`${customer.name} (${customer.phone})`} />
				<p
					className={
						(orderDetails.order_type !== 'pickup' ? '' : 'hide') + ' text-left'
					}
				>
					<div>
						{sat_api.getLabel(lang, 'delivery_address') || 'Delivery address'}:
					</div>
					{customer.address.is_guest_mode ? '' : customer.address.tag}{' '}
					{customer.address.is_guest_mode || !customer.address.tag ? (
						''
					) : (
						<br />
					)}
					{customer.address.line_1} {customer.address.line_1 ? <br /> : ''}
					{customer.address.line_2} {customer.address.line_2 ? <br /> : ''}
					{customer.address.sub_locality}{' '}
					{customer.address.sub_locality ? <br /> : ''}
					{customer.address.city} {customer.address.city ? <br /> : ''}
					{customer.address.pin} {customer.address.pin ? <br /> : ''}
				</p>

				{print_kot ? (
					<div>
						<br /> <br /> <br />
						<hr />
						<h5 className="align-center" style={{ margin: '3px 0' }}>
							{' '}
							{biz_name}{' '}
						</h5>
						<h6 className="align-center" style={{ margin: '3px 0' }}>
							{' '}
							{sat_api.getLabel(lang, 'kitchen_kot') || 'Kitchen KOT'}{' '}
						</h6>
						<hr />
						<BillRow t1={`${sat_api.getLabel(lang, 'order_id') || 'Order ID'}:`} t2={orderDetails.id} />
						<BillRow t1={`${sat_api.getLabel(lang, 'channel') || 'Channel'}:`} t2={order.details.channel} />
						<BillRow
							t1={`${sat_api.getLabel(lang, 'channel_id') || 'Channel ID'}:`}
							t2={(ext_channel_id && ext_channel_id.id) || 'NA'}
						/>
						<BillRow t1={`${sat_api.getLabel(lang, 'order_type') || 'Order Type'}:`} t2={orderDetails.id} t2={orderDetails.order_type} />
						<BillRow
							t1={(sat_api.getLabel(lang, orderDetails.order_type) || orderDetails.order_type) + ' Time:'}
							t2={deliveryTimeText}
						/>
						<BillRow t1={`${sat_api.getLabel(lang, 'outlet') || 'Outlet'}:`} t2={order.store.name} />
						<BillRow t1={`${sat_api.getLabel(lang, 'payment') || 'Payment'}:`} t2={paymentStr} />
						<hr />
						<div className="items">
							<BillRow2Col key={9998} t1={sat_api.getLabel(lang, 'item') || 'Item'} t2={sat_api.getLabel(lang, 'qty') || 'Qty'} />
							<hr />
							{this.renderKOTItems(order.items)}
						</div>
						<hr />
						{ext_channel_id && order.details.channel == 'swiggy' ? (
							<BarCode orderId={ext_channel_id.id} id="order_slip" />
						) : null}
						<hr />
						<h4 className="text-left uppercase" style={{ margin: '3px 0' }}>
							{sat_api.getLabel(lang, 'order_instructions') || 'Order instructions'}
						</h4>
						<p style={{ whiteSpace: 'pre-line' }}>
							{order.details.instructions || '--'}
						</p>
						<hr />
					</div>
				) : null}
			</div>
		);
	}
}

const keyListener = (e)=>{
	var code = e.which || e.keyCode;
	if(code == '38') {
		// load previous order
	}else if(code == '40') {
		// load next order
	}
}

@connect(store => ({
		orderData: store.orderList,
		orderDetail: store.orderDetail,
		appLanguage: store.appLanguage,
		enableSeamlessPrinting: store.printManager.enableSeamlessPrinting,
}))
export default class OrderDetailCont extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			blink: false
		}
	}
	scrollToDeliveryInfoSec = ()=>{

		this.setState({
			blink: true
		})

		setTimeout(()=>{
			this.setState({
				blink: false
			})
		},2000)
	}

	closeSlider() {
		store.dispatch({
			type: 'SHOW_HIDE_ORDER_DETAIL_MODAL',
			payload: false
		});

		store.dispatch({
			type: 'STATUS_CHANGE_MODAL',
			payload: {
				showDeliveryExecModal: false
			}
		});
	}

	printBill = () => {
		sat_api.print({});
	};

	componentDidMount() {
		store.dispatch(getOrderDetail({ id: parseInt(this.props.params.orderId) }));

		// document.addEventListener('keydown', keyListener);
		document.addEventListener('keydown', e => {
			if (e.key === 'Escape' || e.keyCode === 27) {
				this.closeSlider();
			}
		});
	}

	componentWillUnmount() {
		store.dispatch(resetOrderDetail());
		document.removeEventListener('keydown', this.closeSlider);
		// document.removeEventListener('keydown', keyListener);
	}

	componentWillReceiveProps(d) {
		if (d.params.orderId != this.props.params.orderId) {
			store.dispatch(getOrderDetail({ id: parseInt(d.params.orderId) }));

			store.dispatch({
				type: 'STATUS_CHANGE_MODAL',
				payload: {
					showDeliveryExecModal: false
				}
			});

		}
	}

	render() {
		if (this.props.orderDetail.fetched) {
			var orderDetailSRVR = this.props.orderDetail.data;
		} else {
			var orderDetailSRVR = {
				loading: true
			};
		}

		const lang = this.props.appLanguage.lang;

		return (
			<div
				onClick={e => {
					e.stopPropagation();
				}}
				className="react-components order-detail-cont"
			>
				{
					ReactDOM.createPortal(<PrintBill {...this.props} />, document.getElementById('bill'))

				}
				<DeliveryExecModal
					id={this.props.params.orderId}
					scrollPos={this.props.scrollPos}
				/>
				<StatusChangeconfirmModal
					scrollPos={this.props.scrollPos}
					showData={{ show: false }}
				/>
				{
					orderDetailSRVR.order &&
					<RiderTempModal
						order_id={orderDetailSRVR.order.details.merchant_ref_id}
						delivery_info={orderDetailSRVR.order.delivery_info}
						scrollPos={this.props.scrollPos}
					/>
				}
				<PageRefresh />
				<TopBar data={{ srvrResp: orderDetailSRVR }} lang={lang} />
				<div className="order-summary">
					<div className="col small">
						<div className="section small">
							<div className="header">
								{sat_api.getLabel(lang, 'customer_details') || 'Customer Details'}
							</div>
							<UserProfile data={{ srvrResp: orderDetailSRVR }} />
						</div>
						<div className="section small">
							<div className="header">
								{sat_api.getLabel(lang, 'order_summary') || 'Order Summary'}
							</div>
							<OrderSummary
								scrollToDeliveryInfoSec = {this.scrollToDeliveryInfoSec}
								data={{ srvrResp: orderDetailSRVR }}
							/>
						</div>
						{
							orderDetailSRVR.order ? (
								<div className="section small">
									<AggregatorInfo
										blink={this.state.blink}
										stealthUpdate={this.props.orderDetail.stealthUpdate}
										self={
											!(orderDetailSRVR.order.details.ext_platforms && orderDetailSRVR.order.details.ext_platforms.length > 0)
										}
										data={orderDetailSRVR.order}
									/>
								</div>
							) :
							null
						}
					</div>
					<div className="col large">
						<div className="section large">
							<div className="header">
								{sat_api.getLabel(lang, 'order_details') || 'Order Details'}
								<PrintBtn onClick={this.printBill} />
							</div>
							<OrderDetail data={{ srvrResp: orderDetailSRVR }} />
						</div>
					</div>
					<div className="section" id="fixed_table_container">
						<div className="header">
							{sat_api.getLabel(lang, 'order_status_log') || 'Order Status Log'}
						</div>
						<OrderStatusHistory data={{ srvrResp: orderDetailSRVR }} />
					</div>
				</div>
			</div>
		);
	}
}


@connect((store)=>{
	return{
		deliveryExecInfo:store.deliveryExecInfo,
		loginReducer: store.loginReducer
	}
})
class AggregatorInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wearingMask: undefined,
		};
	}

	assignDeleveryExec = ()=> {
		store.dispatch({
			type:"STATUS_CHANGE_MODAL",
			payload:{
				showDeliveryExecModal: true
			}
		})
	}

	unAssignDeliveryExec = ()=>{
		store.dispatch(setDeliveryExec({
			order_id: this.props.data.details.id,
			user_id: this.props.data.delivery_info.delivery_person_details.user_id,
			action: 'unassign'
		}))
	}

	handleAction = ()=>{
		const agentAssigned = (this.props.data.delivery_info && this.props.data.delivery_info.current_state != "Unassigned" && this.props.data.delivery_info.delivery_person_details);

		if(agentAssigned) {
			this.unAssignDeliveryExec()
		}else {
			this.assignDeleveryExec()
		}
	}

	handleRiderMask = (wearingMask, order_id) => {
		this.setState({
			wearingMask,
		});
		// make api call to server
		if (wearingMask) {
			const data = {
				action: 'rider_with_mask',
				order_id,
				wearing_mask: wearingMask === 'Yes' ? true : false,
			};
			sat_api.setRiderMask(data, () => {}, () => {});
		}
	}

	render() {
		const comet_enabled = this.props.loginReducer.data.biz.comet_enabled;
		let riderMaskCheck = false;
		let platformId = undefined;
		let readableKey = str => {
			return str.split('_').join(' ');
		};

		if (this.props.data.details.order_type === 'pickup') {
			return null;
		}

		if(!this.props.self) {
			var ext_ids_nodes = _.map(this.props.data.details.ext_platforms, (i, d) => {
				let keys = Object.keys(i.extras);
				if (i.name === 'zomato') {
					riderMaskCheck = true;
					platformId = i.id;
				}
				return (
					<div
						style={{
							textTransform: 'capitalize'
						}}
						className="div-table"
						key={d}
					>
						<div className="div-table-row">
							<div className="div-table-cell">Platform</div>
							<div className="div-table-cell">{i.name}</div>
						</div>
						<div className="div-table-row">
							<div className="div-table-cell">ID</div>
							<div className="div-table-cell">{i.id}</div>
						</div>
						{_.map(keys, (ii, d) => {
							return (
								<div className="div-table-row" key={d}>
									<div className="div-table-cell">{readableKey(ii)}</div>
									<div className="div-table-cell">{i.extras[ii]}</div>
								</div>
							);
						})}
					</div>
				);
			});
		}

		const agentAssigned = (this.props.data.delivery_info && this.props.data.delivery_info.current_state != "Unassigned" && this.props.data.delivery_info.delivery_person_details);
		if(!comet_enabled && this.props.self) {
			return null
		}
		return (
			<div id="delivery_info_section">
				<div style={{ background: '#295D9F' }} className={ (this.props.blink ? "header-blink" : "") +" header aggregator-delivery-info"}>
					{ this.props.self ? "Delivery agent info" :  "Aggregator Info" }
				</div>
				<div className={(this.props.stealthUpdate ? "disabled" : "") +" content  order-summary-sub-section"}>
					{
						!this.props.self && ext_ids_nodes
					}
					{
						agentAssigned ? (
						<div>
						{
							this.props.self ?
							null
							:
							<div
								style={{
									color: 'rgb(127, 143, 165)',
									marginBottom: '5px',
									marginTop: '10px',
									fontWeight: 500
								}}
							>
								Delivery Info
							</div>
						}
							<div
								style={{
									paddingTop: '0px'
								}}
								className={(this.props.self ? "":"bolder-top")+ " div-table"}
							>
								<div className="div-table-row">
									<div className="div-table-cell">Delivery person</div>
									<div className="div-table-cell capitalize">
										{this.props.data.delivery_info.delivery_person_details.name}
									</div>
								</div>
								<div className="div-table-row">
									<div className="div-table-cell">Phone</div>
									<div className="div-table-cell ">
										{ this.props.data.delivery_info.delivery_person_details.phone }
										<br />
										{
											this.props.data.delivery_info.delivery_person_details
												.alt_phone
										}
									</div>
								</div>
							</div>
							{
								riderMaskCheck &&
								<>
									<div className="rider-mask-container">
										<div>Is rider wearing mask?</div>
										{
											!this.state.wearingMask ?
											<div>
												<button
													className="up-button default primary"
													onClick={() => this.handleRiderMask('Yes', platformId)}
												>
													Yes
												</button>
												<button
													className="up-button default dismiss"
													onClick={() => this.handleRiderMask('No', platformId)}
												>
													No
												</button>
											</div>
											:
											<div>
												<span className="wearing-mask">
													{this.state.wearingMask}
												</span>
											</div>
										}
									</div>
								</>
							}
						</div>
					) :
					<div className="no-agent-assigned">No agent is assigned</div>
					}
					{
						this.props.self && (this.props.data.details.order_state != 'Completed' && this.props.data.details.order_state != 'Cancelled')?
						<div>
							<div className={(this.props.deliveryExecInfo.loading ? "disabled" : "") +" delivery-agent-action-btn"} onClick={this.handleAction}>
								{ agentAssigned ?  "Unassign Agent" : "Assign Agent" }
							</div>
						</div>
						:
						null
					}
				</div>
			</div>
		);
	}
}

class BarCode extends React.PureComponent {
	componentDidMount() {
		if (this.props.orderId) {
			const _id = '#' + (this.props.id || '') + '_swiggy_barcode';
			window.JsBarcode(_id, parseInt(this.props.orderId));
		}
	}

	render() {
		return (
			<div style={{ textAlign: 'center' }}>
				<svg
					id={(this.props.id || '') + '_swiggy_barcode'}
					className="swiggy-barcode"
				/>
			</div>
		);
	}
}


const AsgnPlusBtn =(props)=>{
	const assigned = props.data.delivery_info && props.data.delivery_info.delivery_person_details;
	return(
		<a href="#delivery_info_section" onClick={props.scrollToDeliveryInfoSec} className="assign--change-delivery-exec">
			{ assigned ? "Agent Assigned" : "Assign +" }
		</a>
	)
}
