import React, {Component} from 'react';
import { loginAction, logoutAction } from './actions.js';
import {store} from './store.js';
import { connect } from 'react-redux';
import history from './history';
import { Link } from 'react-router-dom';


var sat_api = require('../js/api.js');





@connect((store) => {
	return {
		loginReducer: store.loginReducer,
		appLanguage: store.appLanguage,
	};
})
export default class Login extends React.Component{
	constructor(props) {
	   super(props)
	   this.state = {
		   username:'',
		   password:''
	   }
	   this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleChange =(e, d)=>{
		switch (d) {
			case 'username':
				this.setState({
					username:e.target.value
				})
				break;
			case 'password':
				this.setState({
					password:e.target.value
				})
				break;
			default:
				console.log('not handled')
		}
	}
	componentWillReceiveProps(nextProps){
		if(nextProps.loginReducer.loggedIn){
			const orderBuilderPermission = (nextProps.loginReducer && nextProps.loginReducer.data) ? nextProps.loginReducer.data.permissions.includes('satellite_order_builder'): false;
			if (orderBuilderPermission) {
				history.replace('/start-order');
			}	else {
				alert('You do not have permission to access');
				store.dispatch(logoutAction());
				history.push('/login');
			}
			// const { location } = this.props
			// if(location.state && location.state.nextPathname) {
			// 	history.replace(location.state.nextPathname)
			// }else{
			// 	history.replace('/start-order')
			// }
		}
	}
	handleSubmit(e){
		let payload = this.state;
		this.props.dispatch(loginAction(payload))
		e.preventDefault();
	}
	render(){
		let server_prefix_style = {
		    "padding": "10px",
		    "background": "#c0392b",
		    "borderRadius": "4px",
		    "marginTop": "10px",
		    "display": "inline-block",
		    "marginLeft": "auto",
		    "marginRight": "auto",
		    "fontSize": "10px",
		    "position": "fixed",
		    "bottom": "10px",
		    "right": "10px",
			"textTransform":"uppercase"
		}
		let protocol_style = {
			"padding": "5px 10px",
		    "background": "#c0392b",
		    "borderRadius": "4px",
		    "marginTop": "10px",
		    "display": "inline-block",
		    "marginLeft": "auto",
		    "marginRight": "auto",
		    "fontSize": "10px",
			"textTransform":"uppercase"
		}
		var protocol = (JSON.parse(localStorage.getItem('no_https')) ? "HTTP" : "HTTPS");
		return(
			<div className="login-page">
				<div className={(sat_api.SERVER_PREFIX == 'api' ? "hide-imp" : "")} style={server_prefix_style}>Connected to {sat_api.SERVER_PREFIX}</div>
				<div className="halign-absolute login-container">
					<div className="sat-logo"></div>
					<form onSubmit={this.handleSubmit}  autoComplete="off">
						<div className="input-cont m-b-10">
							<input id="username" type="text" name="username" required value={this.state.username} onChange={(e)=>this.handleChange(e, 'username')} placeholder="hello@example.com / +919876543210"/>
							<label htmlFor="username">
								{sat_api.getLabel(this.props.appLanguage.lang, 'username') || 'Email/Phone'}
							</label>
						</div>
						<div className="input-cont m-b-10">
							<input name="password" id="password" type="password" required value={this.state.pass} onChange={(e)=>this.handleChange(e, 'password')} placeholder="password"/>
							<label htmlFor="password">
								{sat_api.getLabel(this.props.appLanguage.lang, 'password') || 'Password'}
							</label>
						</div>
						<div>
							<input type="submit" className="up-button primary full-width" value={sat_api.getLabel(this.props.appLanguage.lang, 'login') || 'Login'} />
						</div>
						<div className={(this.props.loginReducer.error ? "error" : "") + " messaging padding-10 text-center"}>
							{this.props.loginReducer.msg}
						</div>
						<div className="version-no uppercase text-center">
							<Link to="/change-log">Ver. {sat_api.VERSION_NO} {sat_api.VERSION_BUILD}</Link>
						</div>
						<div className="text-center">
							<div className={(protocol == "HTTPS" ? "hide-imp" : "")+" text-center"} style={protocol_style}>PROTOCOL: {protocol}</div>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
