import React, { Component } from 'react';

// third party
import { connect } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

// redux
import { store } from '../store.js';

// api
const sat_api = require('../../js/api.js');

@connect((store) => ({
	loginReducer: store.loginReducer,
	bizStoresReducer: store.bizStoresReducer,
}))
export default class StoreDropdownHQ extends Component {
	constructor(props) {
		super(props);
	}

	cbSelect = (selectedOption) => {
		store.dispatch({
			type: 'SELECT_BIZ_STORE',
			payload: selectedOption,
		});
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.bizStoresReducer.selectedStore &&
			nextProps.bizStoresReducer.selectedStore.value !==  this.props.bizStoresReducer.selectedStore.value
			) {
			if (nextProps.bizStoresReducer.selectedStore.value) {
				this.props.handleStoreSelect(nextProps.bizStoresReducer.selectedStore.value)
			}
		}
	}

	handleStoreSearch = (searchValue) => {
		const data = {
			biz_id: this.props.bizStoresReducer.selectedBiz.value,
			sq: searchValue,
		};
		return new Promise(async (resolve, reject) => {
			try {
				const resp = await sat_api.searchStores(data);
				const response = resp.data.objects.map((obj) => ({
					value: obj.biz_location_id,
					label: obj.name,
				}));
				resolve(response);
			} catch (error) {
				console.log(error);
				reject();
			}
		});
	}

	renderAllStores = () => {
		const { stores, selectedStore } = this.props.bizStoresReducer;
		const storesOptions = stores.map((stores) => ({
			label : stores.name,
			value : stores.biz_location_id,
		}));
		return (
			<AsyncSelect
				className="inventory-control-store"
				optionClassName="needsclick"
				onChange={this.cbSelect}
				value={selectedStore}
				cacheOptions={true}
				defaultOptions={storesOptions}
				placeholder="Select store"
				autoBlur={true}
				loadOptions={this.handleStoreSearch}
			/>
		);
	}

	renderSubscribedStores = () => {
		const { selectedStore, selectedBiz } = this.props.bizStoresReducer;
		const { selectedStores } = this.props.settings;
		let bizStores = selectedStores;
		if (selectedBiz && selectedBiz.value) {
			bizStores = selectedStores.filter((s) => s.biz_id === selectedBiz.value)
		}
		return (
			<Select
				className="inventory-control-stor"
				optionClassName="needsclick"
				onChange={this.cbSelect}
				value={selectedStore}
				options={bizStores}
				placeholder= 'Select store'
				autoBlur={true}
			/>
		);
	}

	render() {
		const { showOnlySubscribed } = this.props;
		return (
			<div className="store-dropdown-hq inp-field-custom-label">
				{
					this.props.hasLabel &&
					<div className="custom-label">Store</div>
				}
				<div>
				{
					showOnlySubscribed
					?
					this.renderSubscribedStores()
					:
					this.renderAllStores()
				}
				</div>
			</div>
		);
	}
}
