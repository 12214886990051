import React from 'react';
import { store } from '../store.js';
import { connect } from 'react-redux';

import _ from 'lodash';

import {
    getCategoryAnditemsForFirstCategory,
    getItemsByCategory,
    addItemToCart
} from '../actions';

import {
    PreLoaderBg,
    ItemCard,
    Cart,
    CartPreview,
    HorLoadAnim,
    SearchItems,
    CurrCode
} from '../reactComp';

const sat_api = require('../../js/api');

@connect((store) => {
	return {
		currentOrderPhoneno:store.currentOrderPhoneno,
		currentOrderSession:store.currentOrderSession,
		itemSearchStatus: store.itemSearchStatus
	}
})
export default class OrderingMenu  extends React.Component{
	constructor(props) {
    	super(props);
		this.state = {style:null, cartStyle:null, categoryHeight:null}
	}

	componentWillMount(){
		let custom_height = window.innerHeight - 100 - 10 - 60 -50;
		let custom_style = {
			height:custom_height+"px",
			overflowY:'auto',
			overflowX:'hidden',
			marginTop: '5px',

		}
		let cartHeight = {
			height: window.innerHeight - 100 - 44
		}

		let categoryHeight = {
			height: window.innerHeight - 100 - 44 - 20,
			overflow:"auto"
		}

		this.setState({
			style:custom_style,
			cartStyle:cartHeight,
			categoryHeight:categoryHeight
		})

		store.dispatch(getCategoryAnditemsForFirstCategory({
			location_id: this.props.currentOrderSession.store.biz_location_id,
			fulfillment_mode: this.props.currentOrderSession.delivery_mode
		}))
	}
	refreshPage(){
		window.location.reload(true)
	}

	render(){
		if(this.props.currentOrderSession.menu.error_load){
			return(
				<div className="text-center UP-ERROR-TEXT">
					<h2>{this.props.currentOrderSession.menu.error_message}</h2>
					<div><a onClick={(e)=>this.refreshPage(e)} className="up-button small default dismiss">Try again</a></div>
				</div>
			)
		}else{
			return(
				<div className="ordering-menu div-table">
					<div className="category-menu-container div-table-cell top">
						<div style={this.state.categoryHeight}>
							<CategoryMenu />
							<LastMenuPull />
						</div>
					</div>
					<OrderingMenuComp
						itemcardcontainerStyle={this.state.style}
						cartStyle={this.state.cartStyle}
						/>
				</div>
			)
		}
	}
}


@connect((store) => {
	return {
		currentOrderSession:store.currentOrderSession
	}
})
class CategoryMenu extends React.Component{
	constructor(props) {
    	super(props);
		this.state = {style:null}
	}
	pullCategory(){
		// this.props.dispatch(getCategory());
	}



	componentWillMount(){
		let category_height = window.innerHeight - 100 - 10 - 50 - 220; // header height - padding+cat header - refresh button
		let category_height_style = {
			maxHeight:category_height+"px",
			overflow:'auto'
		}

		this.setState({style:category_height_style})
		//this.props.dispatch(getCategory());
	}

	refreshMenu(){
		localStorage.removeItem('UP_CACHE');
		localStorage.removeItem('UP_CACHE_TAGS');
		window.ITEM_LOCATION_CATEGORY_CACHE = [];
		window.ITEM_LOCATION_TAGS_CACHE = [];
		store.dispatch(getCategoryAnditemsForFirstCategory({
			location_id: this.props.currentOrderSession.store.biz_location_id,
			fulfillment_mode: this.props.currentOrderSession.delivery_mode
		}))
	}

	render(){
		var refreshBtnStyle = {
			"position": "absolute",
			"right": 0,
			"width": "30px",
			"lineHeight": "37px",
			"top": "0px",
			"textAlign": "center",
			"cursor":"pointer"
		}
		if(this.props.currentOrderSession.category.error_load){
			return(
				<div className="text-center UP-ERROR-TEXT">
					<h2>Network Error</h2>
					<div><a onClick={(e)=>this.pullCategory(e)} className="up-button small default dismiss">Try again</a></div>
				</div>
			)
		}else if(this.props.currentOrderSession.category.loading || !this.props.currentOrderSession.category.data && (this.props.currentOrderSession.menu.load_in_background != true && this.props.currentOrderSession.category.load_in_background != true)){
			return(
				<div className="padding-10">
					<PreLoaderBg no={1}/>
				</div>
			)
		}

		var classNameForRefresh = (this.props.currentOrderSession.menu.loading ? 'rotate-infinite' : '')
		return(
			<div className="categories" style={{marginBottom:"20px"}}>
				<div>
					<div className="category-header relative-pos">
						Categories
						<span
							onClick={(e)=>this.refreshMenu(e)}
							style={refreshBtnStyle}
							className={classNameForRefresh+" inline-block float-left"}>&#8635;</span>
					</div>
					<div className="categories-row-container">
						<CategoryBlockRow
							selected_cat={this.props.currentOrderSession.selected_cat}
							fulfillment_mode={this.props.currentOrderSession.delivery_mode}
							location_id={this.props.currentOrderSession.store.biz_location_id}
							data={this.props.currentOrderSession.category.data}
							/>
					</div>
				</div>
			</div>
		)
	}
}


@connect((store) => {
	return {
		currentOrderSession:store.currentOrderSession
	}
})
class LastMenuPull extends React.Component{
	refreshMenu(){
		localStorage.removeItem('UP_CACHE');
		localStorage.removeItem('UP_CACHE_TAGS');
		window.ITEM_LOCATION_CATEGORY_CACHE = [];
		window.ITEM_LOCATION_TAGS_CACHE = [];
		store.dispatch(getCategoryAnditemsForFirstCategory({
			location_id: this.props.currentOrderSession.store.biz_location_id,
			fulfillment_mode: this.props.currentOrderSession.delivery_mode
		}))
		sat_api.MP_TRACK("menu_refresh",{})
	}
	render(){
		var className = (this.props.currentOrderSession.menu.loading ? 'rotate-infinite' : '');
		const refreshIcoStle = {
			"width": "16px",
			"display": "table"
		}
		const refreshIcoHolderStle= {
			position: "absolute",
		    left: "10px",
		    top: "4px"
		}
		return(
			<div className="menu-refresh secondary-text text-center">
				<div className="m-b-10">
					<a style={{position:"relative", paddingLeft:"40px"}} className="cursor-pointer small secondary-2  up-button default" onClick={(e)=>this.refreshMenu(e)}>
						<span className={className+" inline-block"} style={refreshIcoHolderStle}>
							<img src="/images/refresh-color.png" style={refreshIcoStle} />
						</span> Refresh
					</a>
				</div>
				<div className="bold m-t-10">Store: {this.props.currentOrderSession.store.name} </div>
			</div>
		)
	}
}


@connect((store) => {
	return {
		currentOrderSession:store.currentOrderSession,
		cartVisibility:store.cartVisibility,
		selectedComplexItem:store.selectedComplexItem,
		itemSearchStatus:store.itemSearchStatus
	}
})
export class OrderingMenuComp extends React.Component{
	render(){
		if(!this.props.currentOrderSession.menu.data) {
			return(
				<div className="padding-10">
					<PreLoaderBg no={4}/>
				</div>
			)
		}else{
			if(this.props.currentOrderSession.menu.data.items.length>0){
				var itemCardNodes = this.props.currentOrderSession.menu.data.items.map((o,i) => {
					var inCart = (_.find(this.props.currentOrderSession.cart.items, {id:o.id}) ? true : false);
					var cc_items = _.filter(this.props.currentOrderSession.cart.items, {id:o.id})
					var _quant = _.reduce(cc_items, function (s, entry) {
	                    return s + entry.quantity;
	                }, 0);
					return(
						<ItemCard key={i} item={o} incart={inCart} quant={_quant}/>
					)
				})
			}else{
				var itemCardNodes = (()=>{
					return(
						<div className="text-center large-text m-t-20 padding-20">{(!this.props.itemSearchStatus.done && this.props.itemSearchStatus.loading ? "Searching.." : "No item found")}</div>
					)
				})()
			}
			var styleToOverride = {
				opacity:(this.props.currentOrderSession.menu.loading ? "0.5": "1"),
				...this.props.itemcardcontainerStyle
			}
			return(
				<div className="div-table-cell top text-left item-menu-search-cart-container">
					<div className="item-menu-header relative-pos">
						<SearchItems data={this.props.currentOrderSession}/>
						<CartPreview visibility={this.props.cartVisibility.show} data={this.props.currentOrderSession}/>
						<div style={this.props.cartStyle} className={(this.props.cartVisibility.show ? "expand" : "") + " floating-cart"}><Cart /></div>
						<div className="clearfix"></div>
						<HorLoadAnim
							loading={this.props.currentOrderSession.menu.loading}
							/>
					</div>
					<div style={styleToOverride}>
						<div className="item-cards-container">
							{itemCardNodes}
						</div>
					</div>
					<div className="complex-item-operation">
						{(this.props.selectedComplexItem.show ? <AddComplexItemPanel />: null)}
					</div>
				</div>
			)
		}
	}
}


@connect((store) => {
	return{
		currentOrderSession:store.currentOrderSession
	}
})
class CategoryBlockRow extends React.Component{
	constructor(props){
		super(props)
		this.state = {active:false}
	}

	toggleSubcat(k){
		this.setState({active:k})
	}
	setCategory(e,k){
		this.toggleSubcat(this.state.active == k ? null : k)
		if(this.props.currentOrderSession && this.props.currentOrderSession.selected_cat == k){
			//do nothing
		}else{
			store.dispatch({
				type:"SET_SELECTED_CAT",
				payload:k
			})

			var {location_id, fulfillment_mode} = this.props;
			store.dispatch(getItemsByCategory({
				cat_id:k,
				location_id:location_id,
				fulfillment_mode:fulfillment_mode
			}))
		}
	}
	render(){

		var selected_cat = this.props.selected_cat
		if(!selected_cat){
			selected_cat = 'show-all'
		}

		var categories = this.props.data;

		var subcatStyle = {
			"fontSize":(this.props.subcategory ? "12px" :"14px"),
			"paddingRight":"20px"
		}

		var arrowStyle = {
			"position": "absolute",
		    "right": "0px",
		    "color": "#ddd",
		    "top": "10px",
			"transition":"180ms"
		}

		var categorynodes = categories.map((o,i) => {
			return(
				<div key={o.id} className={(this.state.active == o.id ? "active" : "") +" category-row-parent"}>
					<a  onClick={(e)=>this.setCategory(e, o.id)} className={(selected_cat == o.id ? "active" : "")+" category-row relative-pos"} style={subcatStyle}>
						{o.name}
						{o.sub_categories && o.sub_categories.length >0 ? <div style={arrowStyle} className={(this.state.active == o.id ? "rotate-180":"")+" m-r-10 float-right"}><img src="images/caret-down.svg" style={{"width": "10px"}}/></div>:null}
					</a>

					{o.sub_categories && o.sub_categories.length >0 ? <div className="subcategory-container"><CategoryBlockRow data={o.sub_categories} fulfillment_mode={this.props.fulfillment_mode} location_id={this.props.location_id} selected_cat={this.props.selected_cat} subcategory={true}/></div> : null}
				</div>
			)
		})

		return(
			<div>
				{categorynodes}
			</div>
		)

	}
}


@connect((store) => {
	return {
		currentOrderSession:store.currentOrderSession,
		selectedComplexItem:store.selectedComplexItem
	}
})
export class AddComplexItemPanel extends React.Component{
	constructor(props) {
    	super(props);
	}
	componentDidMount(){
		window.addEventListener('keydown', (e) => {
		    if (e.key === 'Escape' || e.keyCode === 27) {
		        this.closeModal()
		    }
		});
	}
	componentWillUnmount(){
		window.removeEventListener('keydown', this.closeModal);
	}
	handleEscape(e){
		console.log(e.target.value)
	}
	addComplexItem(e){
		var handling_item = this.props.selectedComplexItem.handling_item;
		var handling_item_option_groups = handling_item.option_groups;
		var selection_error = false;
		var selection_message = '';
		if(handling_item_option_groups && handling_item_option_groups.length>0){
			for(let h in handling_item_option_groups){
				var h_min = handling_item_option_groups[h].min_selectable
				var h_max = handling_item_option_groups[h].max_selectable
				var h_option_group_id = handling_item_option_groups[h].id
				var h_option_group_title = handling_item_option_groups[h].title

				var selected_from_option_group = _.filter(handling_item.options, {option_group_id:h_option_group_id})
				if(h_min != -1 && selected_from_option_group.length<h_min){
					selection_error = true;
					selection_message += `${h_option_group_title} - Select ${(h_min==h_max ? 'atmost' : 'atleast')} ${h_min}\n`
				}
			}
		}
		if(selection_error){
			alert(selection_message);
			return false;
		}
		var _cart  = {...this.props.currentOrderSession.cart};
		var d = {item:{...this.props.selectedComplexItem.handling_item, type:"complex-item"}, action:"add-to-cart"}
		var processd_data = sat_api.processCart({cart:_cart, item_data:d})
		store.dispatch(addItemToCart(processd_data));
		store.dispatch({
			type:'CLOSE_COMPLEX_ITEM',
			payload:null
		})
	}
	closeModal(e){
		store.dispatch({
			type:'CLOSE_COMPLEX_ITEM',
			payload:null
		})
	}
	render(){
		if(this.props.selectedComplexItem.handling_item){
			var handling_item = this.props.selectedComplexItem.handling_item;
			var selected_total_price = handling_item.item_price+_.reduce(handling_item.options, function(s,e){
				return s + parseFloat(e.price);
			},0)
		}else{
			var selected_total_price = 0
		}

		var seelctedItem = this.props.selectedComplexItem.itemObj.item;
		return(
			<div className="complex-item-overlay">
				<div className="complex-item-panel up-fadeIn">
					<div className="complex-item-relative-content">
						<div className="complex-item-header padding-20">
							<div>{seelctedItem.item_title}</div>
							<a className="close" onClick={(e)=>this.closeModal(e)}>x</a>
						</div>
						<div className="complex-item-desc secondary-text padding-10">
							{seelctedItem.item_desc}
						</div>
						<div className="complex-item-elements-container">
							<div className="complex-item-elements">
								<OptionGroups data={seelctedItem.option_groups}/>
							</div>
						</div>
						<div className="complex-item-footer">
							<div className="selected-items-preview">
								Total item price : <CurrCode/> {selected_total_price}
							</div>
							<div>
								<a onClick={(e)=>this.addComplexItem(e)} className="up-button default small primary">Add to cart</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


@connect((store) => {
	return {
		selectedComplexItem:store.selectedComplexItem
	}
})
export class OptionGroups extends React.Component{
	constructor(props){
		super(props)
	}
	render(){
		var viewOptions = (this.props.selectedComplexItem.view ? this.props.selectedComplexItem.view : false)
		//var viewComponent = (viewOptions? <NestedOptionsNode optionId={viewOptions.id} optionGroupId={viewOptions.optionGroupId} data={viewOptions.data}/> : null)

		var handeling_item_options = this.props.selectedComplexItem.handling_item.options;
		var optionGroups = this.props.data;
		var optionGroupsNodes = optionGroups.map((o,i)=>{
			var minMaxStr = "";
			var minStr = "";
			var maxStr = "";

			if((o.min_selectable == -1 || o.min_selectable == 0) && o.max_selectable > 0){
				minMaxStr = `Select atmost ${o.max_selectable}`
			}else{
				if(o.min_selectable > 0){
					minStr = `Select atleast: ${o.min_selectable}`
				}
				if(o.max_selectable > 0){
					maxStr = `Max selectable: ${o.max_selectable}`
				}
				minMaxStr = (minStr||"")+" "+(maxStr||"")
			}

			return(
				<div className="option-groups-row" key={o.id}>
					<a className="default-title primary">
						{o.title}
						<div className="secondary-text float-right small-text">{minMaxStr}</div>
					</a>
					<OptionsNode data={o.options} optionGroupId={o.id} type="primary-options"/>
				</div>
			)
		})
		return(
			<div className="option-groups">
				{optionGroupsNodes}
			</div>
		)
	}
}

@connect((store) => {
	return {
		selectedComplexItem:store.selectedComplexItem
	}
})
export class OptionsNode extends React.Component{
	constructor(props){
		super(props)
	}

	handleClick(e,d){

		var selectedComplexItemObj = {...this.props.selectedComplexItem.handling_item};
		sat_api.processComplexItem({handling_item:selectedComplexItemObj, payload:d})

		console.log(selectedComplexItemObj)
		store.dispatch({
			type:'HANDLE_COMPLEX_ITEM',
			payload:selectedComplexItemObj
		})
	}

	//Removed nested option handeling after commit 55a3b5d79ec5cf73dbaa882a8db876b0edc012fa
	render(){
		var selectedComplexItem = this.props.selectedComplexItem;
		var handling_item = selectedComplexItem.handling_item;

		var addData = {
			option_group_id:this.props.optionGroupId,
			type:this.props.type,
		}
		var optionsArray = this.props.data;
		var optionsNode = optionsArray.map((o,i)=>{
			return(
				<div key={o.id} className={(_.find(handling_item.options,{id : o.id}) ? "selected" : "")+" options-container"}>
					<a onClick={(e)=>this.handleClick(e,{...addData,option_id:o.id})} className="primary-options default-title secondary">
						{o.title}
						<div className={(o.price == 0 ? "hide" : "")+" float-right"}><CurrCode/>{o.price}</div>
					</a>
				</div>
			)
		})
		return(
			<div className={this.props.type+" relative-pos options-list-container"}>
				{optionsNode}
			</div>
		)
	}
}



@connect((store) => {
	return {
		selectedComplexItem:store.selectedComplexItem
	}
})
export class NestedOptionsNode extends React.Component{
	constructor(props){
		super(props)
	}

	render(){
		if(this.props.selectedComplexItem.view){
			var nestedOptionsArray = this.props.data;
			var nestedOptionsNode = nestedOptionsArray.map((o,i)=>{
				return(
					<div key={o.id} className="nested-options-container">
						<div className="default-title">{o.title}</div>
						<div className="options-container">
							<OptionsNode optionId={this.props.optionId} data={o.options} optionGroupId={this.props.optionGroupId} nestedOptionGroupId={o.id} type="secondary-options"/>
						</div>
					</div>
				)
			})
			return(
				<div className="nested-options-section nested-options-slide">
					{nestedOptionsNode}
				</div>
			)
		}else{
			return null
		}
	}
}
