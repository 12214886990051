import React, { useState, useEffect } from 'react';
import {
	PieChart,
	Pie,
	Sector,
	Cell,
	LabelList
} from 'recharts';


import {
	getChannelColor,
	getPaymentColor,
	getChannelDisplayName,
	getPaymentDisplayName
} from './const';


import { commifyNumber } from '../../js/api';
import { CurrCode } from '../reactComp';

const sat_api = require('../../js/api.js');


const ChannelLegends = (props)=>{
	const channels = props.data.channel;
	const type = props.type;

	const nodes = channels.map((i, d)=>{
		return(
			<div className="legend-node" key={d}>
				<div className="legend-color-node" style={{backgroundColor:getChannelColor(i.order_channel)}}>

				</div>
				<div className="legend-count-text">
					<div>{ getChannelDisplayName(i.order_channel) }</div>
					<div className="legend-values">
						{ type == 'no_of_orders' ? i.count : (<span>
							<CurrCode />{` ${commifyNumber(i.revenue)}`}
						</span>)}
					</div>
				</div>
			</div>
		)
	})
	return(
		<div className="legend-node-container">
			{nodes}
		</div>
	)
}

const PaymentLegends = (props)=>{
	const payments = props.data.payments;
	const type = props.type;

	const nodes = payments.map((i, d)=>{
		return(
			<div className="legend-node" key={d}>
				<div className="legend-color-node" style={{backgroundColor:getPaymentColor(i.payment_mode)}}>

				</div>
				<div className="legend-count-text">
					<div>{ getPaymentDisplayName(i.payment_mode) }</div>
					<div className="legend-values">
						{ type == 'no_of_orders' ? i.count : (<span>
							<CurrCode />{` ${commifyNumber(i.revenue)}`}
						</span>)}
					</div>
				</div>
			</div>
		)
	})
	return(
		<div className="legend-node-container">
			{nodes}
		</div>
	)
}

const PieChartAna = (props)=>{

	let containerRef = React.createRef();

	const [ channelPieData, setChannelPieData ] =  useState("revenue");
	const [ paymentPieData, setPaymentPieData ] =  useState("revenue");

	const [ chartWidth, setChartWidth ] =  useState(null);

	useEffect(()=>{
		setChartWidth(containerRef.current.clientWidth)
	}, [])

	const data1 = props.data.payments.map((i)=>{
		return {
			name: i.payment_mode,
			value: (paymentPieData == 'no_of_orders' ? i.count : i.revenue),
			displayName: getPaymentDisplayName(i.payment_mode)
		}
	})

	const data2 = props.data.channel.map((i)=>{
		return {
			name: i.order_channel,
			value: (channelPieData == 'no_of_orders' ? i.count : i.revenue),
			displayName: getChannelDisplayName(i.order_channel)
		}
	})


	return(
		<div className="analytics analytics-section">
			<div className="piechart-container">
				<div className="distribution channel-distribution" ref={containerRef}>
					<div className="header">
						{sat_api.getLabel(props.lang, "order_channel") || "Order channel"}
						<div className="switch-pie-chart">
							<span className={channelPieData == 'no_of_orders' ? 'selected' : ''} onClick={()=>{setChannelPieData('no_of_orders')}}>{sat_api.getLabel(props.lang, "volume") || "Volume"}</span>
							<span className={channelPieData == 'revenue' ? 'selected' : ''} onClick={()=>{setChannelPieData('revenue')}}>{sat_api.getLabel(props.lang, "revenue") || "Revenue"}</span>
						</div>
					</div>
					{
						chartWidth ?
						<TwoLevelPieChart lang={props.lang} data={data2} type="channel" width={chartWidth}/>
						:
						null
					}
					<div className="legends">
						<ChannelLegends data={props.data} type={channelPieData}/>
					</div>
				</div>
				<div className="distribution payment-distribution">
					<div className="header">
						{sat_api.getLabel(props.lang, "payment_mode") || "Payment mode"}
						<div className="switch-pie-chart">
							<span className={paymentPieData == 'no_of_orders' ? 'selected' : ''} onClick={()=>{setPaymentPieData('no_of_orders')}}>{sat_api.getLabel(props.lang, "volume") || "Volume"}</span>
							<span className={paymentPieData == 'revenue' ? 'selected' : ''} onClick={()=>{setPaymentPieData('revenue')}}>{sat_api.getLabel(props.lang, "revenue") || "Revenue"}</span>
						</div>
					</div>
					{
						chartWidth ?
						<TwoLevelPieChart lang={props.lang} data={data1} type="payment" width={chartWidth}/>
						:
						null
					}
					<div className="legends">
						<PaymentLegends data={props.data} type={paymentPieData}/>
					</div>
				</div>
			</div>
		</div>
	)
}


export default PieChartAna



const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value
	} = props;

	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 10) * cos;
	const my = cy + (outerRadius + 10) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 10;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
				opacity={0.6}
			/>
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} fontSize={ 13 } textAnchor={textAnchor} fill="#fff">{`${payload.displayName}`}</text>
			<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey+18} textAnchor={textAnchor} fill="#999">
				{`${commifyNumber(value)} (${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};

class TwoLevelPieChart extends React.Component{
	constructor(props) {
		super(props)
		this.state = {
			activeIndex: 0
		}
	}


	onPieEnter = (data, index)=> {
		this.setState({
			activeIndex: index,
		});
	}

	getColors = (key, type)=>{
		if(type == 'channel') {
			return getChannelColor(key)
		}else {
			return getPaymentColor(key)
		}
	}

	getTypeName = (type)=> {
		switch (type) {
			case "channel":
				return sat_api.getLabel(this.props.lang, "order_channel") || "Order channel"
				break;
			case "payment":
				return sat_api.getLabel(this.props.lang, "payment_mode") || "Payment mode"
				break;
			default:
				return type

		}
	}
	render() {
		const _cx = (this.props.width/2);
		return (
	    	<PieChart width={this.props.width} height={400}>
				<text x={_cx} y={200} textAnchor="middle" dy={10} fill={"#fff"}>
					{this.getTypeName(this.props.type)}
				</text>
		        <Pie
					dataKey="value"
					activeIndex={this.state.activeIndex}
					activeShape={renderActiveShape}
					data={this.props.data}
					cx={_cx}
					cy={200}
					innerRadius={100}
					outerRadius={140}
					onMouseEnter={this.onPieEnter}
					stroke="none"
		        >
				{
		            this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={this.getColors(entry.name, this.props.type)} />)
		        }
				</Pie>
	       </PieChart>
	    );
	}
}
