import React, { PureComponent, useState, useEffect } from 'react';

import {
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label
} from 'recharts';
import moment from 'moment';

import {
	getChannelColor,
	getPaymentColor,
	getChannelDisplayName,
	getPaymentDisplayName
} from './const';
import { CurrCode } from '../reactComp';
import { connect } from 'react-redux';


import { commifyNumber } from '../../js/api';

let CURRENCY_CODE = 'Rs.';
const getavarages = (data)=>{
	// [1, 2, 3, 4].reduce((a, b) => a + b, 0)

	const sum = data.reduce((a, b)=>{
		return a + (b.order_total+b.discount)
	}, 0)
	//
	// const cancelled = data.reduce((a, b)=>{
	// 	return a + (b.lostorder_total)
	// }, 0)

	const orderCount = data.reduce((a, b)=>{
		return a + (b.order_count)
	}, 0)

	// const orderCount = data.reduce((a, b)=>{
	// 	return a + (b.order_count)
	// }, 0)

	const length = data.length;

	return {
		avgOrderTotal: (sum/length),
		// cancelledOrderTotal: (cancelled/length),
		orderCount: parseInt(orderCount/length)
	}
}

@connect(store => {
	return {
		loginReducer: store.loginReducer
	};
})
export default class YourDay extends PureComponent {
	constructor(props) {
		super(props)
		this.yourDayContainerRef = React.createRef();
		this.state = {
			width: 0
		}
	}

	componentDidMount() {
		this.setState({
			width: this.yourDayContainerRef.current.clientWidth
		});
		if (this.props.loginReducer.loggedIn) {
			if (this.props.loginReducer.data.biz.currency_code !== 'INR') {
				CURRENCY_CODE = this.props.loginReducer.data.biz.currency_code;
			}
		}
	}
  render() {
	  const {
		  avgOrderTotal,
		  // cancelledOrderTotal,
		  orderCount
	  } = getavarages(this.props.data.timeseriesGroup);
    return (
		<div className="sankey-container analytics-section" ref={this.yourDayContainerRef}>
			<div id="sankey" style={{height:"auto"}}>
				<div className="header">Order velocity</div>
				<AreaChart
		  	  	id="your-day"
		          width={this.state.width}
		          height={420}
		          data={this.props.data.timeseriesGroup}
		          margin={{
		            top: 10, right: 30, left: 0, bottom: 30,
		          }}
		        >
		  		<XAxis

					interval={0}
		  			domain={['dataMin', 'dataMax']}
		  			type="number"
		  			dataKey="timestamp"
		  			scale="time"
		  			tick={<CustomizedXTick />}
		  		/>
		  		<YAxis
		  			tick={<CustomizedYTick />}
		  		/>

				<Tooltip content={<CustomTooltip />} />
		  		<Legend  verticalAlign="top" iconType="square" formatter={renderLegend}/>
				<Area type="monotone" dataKey="order_total" stackId="1" stroke="#48dbfb" fill="#48dbfb" />
		  		<Area type="monotone" dataKey="discount" stackId="1" stroke="#9bebfd" fill="#9bebfd" />
	        	<Area type="monotone" dataKey="lostorder_total" stackId="2" stroke="#ff0066" fill="#ff0066" />
				<ReferenceLine isFront={true} y={avgOrderTotal} stroke="#bbb">
					<Label position="left" orderCount={orderCount} avgOrderTotal={avgOrderTotal} content={renderLabel} fill="#fff" offset={10} position="insideBottom" />
				</ReferenceLine>
		        </AreaChart>
			</div>
		</div>
    );
  }
}


const legendMap = {
	discount: "Discount",
	order_total: "Order total",
	lostorder_total: "Cancelled order"
}

const renderLabel = (props) => {
	return(
		`AVG: ${props.orderCount} orders (${CURRENCY_CODE}${commifyNumber(props.avgOrderTotal)}) / Hr`
	)
}

const RenderedLabel = (props)=>{
	const style = {
		"textShadow": "0px 2px 10px #000"
	}
	console.log(props)
	return(
		<text x={props.viewBox.x} y={props.viewBox.y}>Af</text>
	)
}
const renderLabel2 = (props) => {
	return(
		`Avg cancellation/Hr: ${CURRENCY_CODE}${commifyNumber((-1)*props.cancelledOrderTotal)}`
	)
}
const renderLegend = (value, entry) => {
	return legendMap[value] ||  value
}
const CustomizedXTick = ({ payload, x, y }) => {
	return(
		<g transform={`translate(${x+15},${y})`}>
			<text x={0} y={0} dy={16} textAnchor="end" fill="#9c9c9c" fontSize={12} transform="rotate(-35)">
				{moment(payload.value).format("ddd, hA")}
			</text>
		</g>
	)
}

const CustomizedYTick = ({ payload, x, y }) => (
	<text x={x} y={y} textAnchor='middle' fill="#9c9c9c" fontSize={12} >
	{
		payload.value < 0 ?
		`${CURRENCY_CODE} ` +commifyNumber((-1*payload.value))
		:
		`${CURRENCY_CODE} ` +commifyNumber(payload.value)
	}
	</text>
)


const CustomTooltip = ({ active, payload, label }) => {
	console.log(payload)
	if (active) {
		let discount = (payload.find((i)=>i.name == "discount") && payload.find((i)=>i.name == "discount").value) || 0 ;
		let order_total = (payload.find((i)=>i.name == "order_total") && payload.find((i)=>i.name == "order_total").value) || 0;
		let cancelled = (payload.find((i)=>i.name == "lostorder_total") && payload.find((i)=>i.name == "lostorder_total").value) || 0;
		let orderCount = (payload.find((i)=>i.name == "order_count") && payload.find((i)=>i.name == "order_count").value) || 0;
		// let val = i.value;
		// if(i.name == 'order_total') {
		//
		// }
		return (
			<div className="custom-tooltip">
			<div className="date-tooltip">{moment(label).format("hA, Do (ddd)")}</div>
			<div className="labels-container">
				<div className="label">Order total: <CurrCode /> {commifyNumber(order_total+discount)} {commifyNumber(orderCount)}</div>
				<div className="label" style={{color:"#badc58", marginBottom:"10px"}}>Discount: <CurrCode /> {commifyNumber(discount)}</div>
				<div className="label" style={{color:"#c0392b"}}>Cancelled: <CurrCode /> {commifyNumber((-1*cancelled))}</div>
			</div>
			</div>
		);
	}

  return null;
};
