import React from 'react';
import { connect } from 'react-redux';
import { store } from '../store';


@connect((store)=>{
	return{
		audioInteraction: store.audioInteraction
	}
})
export default class AllowAudioChromeUX extends React.Component {
	handleClick = ()=>{
		document.querySelector('#music').play();
		store.dispatch({
			type:"HIDE_AUDIO_PROMPT",
			payload: null
		})
	}
	render() {
		if(this.props.audioInteraction.interactionRequired) {
			return(
				<div className="play-btn-container">
					<div className="content">
						<div className="audio-interaction-text">
							<img src="/images/speaker.png" />
						</div>
						<div>
							<div id="playBtn" onClick={this.handleClick}>Allow audio</div>
						</div>
					</div>
				</div>
			)
		}else {
			return null
		}
	}
}
