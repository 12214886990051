import { updateGeneralSettings } from '../app/actions';
import { store } from '../app/store';
import * as actionTypes from './actionTypes';

export const toggleLoginSeamlessPrntModal = showLoginSeamlessPrintModal => {
	return {
		type: actionTypes.TOGGLE_LOGIN_SEAMLESS_PRNT_MODAL,
		payload: { showLoginSeamlessPrintModal }
	};
};

export const togglePrntrSetupInstrctns = showSetupInstructions => {
	return {
		type: actionTypes.TOGGLE_PRNTR_SETUP_INSTRCTNS,
		payload: { showSetupInstructions }
	};
};

export const toggleSeamlessPrntng = enableSeamlessPrinting => {
	// if (enableSeamlessPrinting) {
	localStorage.setItem(
		'up-sat-enable-seamless-printing',
		enableSeamlessPrinting
	);

	// if (enableSeamlessPrinting) {
	// 	const { generalSettings } = store.getState().appSettings;

	// 	const updatedSettings = { ...generalSettings, print_kot: true };

	// 	store.dispatch(updateGeneralSettings(updatedSettings));
	// }

	// } else {
	// 	localStorage.setItem(key, value)
	// }

	console.log('started toggling');

	return {
		type: actionTypes.TOGGLE_SEAMLESS_PRNTNG,
		payload: { enableSeamlessPrinting }
	};
};

export const chgPrntInstrctnsStage = stage => {
	return {
		type: actionTypes.CHG_PRNT_INSTRCTNS_STAGE,
		payload: { stage }
	};
};

export const updateFaultyPrntrInfo = faultyPrinterInfo => {
	return {
		type: actionTypes.UPDATE_FAULTY_PRNTR_INFO,
		payload: { faultyPrinterInfo }
	};
};
