import Axios from 'axios';
import Moment from '../app/clientTime';
import { store } from '../app/store';
import SunmiData from './sunmiprint.js';
import { toggleSeamlessPrntng } from '../actions/printManager';
import history from '../app/history';
import { logoutAction, statusChange } from '../app/actions';
import {
	timeInMilli,
	miliTo12Hrs,
	upPriority,
	cmpVersion,
	commifyNumber
} from './utils';

import { getLabel } from './langHandle';
import { renderPrintData } from './renderer';

import {
	set as IDBset,
	get as IDBget,
	clear as IDBclear
} from 'idb-keyval';
const testPrintData = require('./testData.json');

const VERSION_BUILD = "beta";


const SERVER_PREFIX = process.env.REACT_APP_SERVER_PREFIX;
const VERSION_NO = process.env.REACT_APP_VERSION;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL;
const PRINTER_PORT = 16253;

const _ = require('lodash');

let __width = window.innerWidth;

if(sessionStorage.getItem('device_width')) {
	__width = parseFloat(sessionStorage.getItem('device_width'));
}

const isMobile = __width <= 768;

var ITEM_LOCATION_CATEGORY_CACHE = JSON.parse(localStorage.getItem('UP_CACHE')) || []
window.ITEM_LOCATION_CATEGORY_CACHE = ITEM_LOCATION_CATEGORY_CACHE;

var ITEM_LOCATION_TAGS_CACHE = JSON.parse(localStorage.getItem('UP_CACHE_TAGS')) || []
window.ITEM_LOCATION_TAGS_CACHE = ITEM_LOCATION_TAGS_CACHE;

Axios.interceptors.request.use(function (config) {
	try {
		const bizId = store.getState().bizStoresReducer.selectedBiz.value;
		if (bizId) {
			config.headers['X-BID'] = bizId;
		}
	}	catch (err) {
		console.log(err);
	}
	return config;
});

// add an axios response interceptor to logout the user when any http request returns 401
Axios.interceptors.response.use(function(response) {
  // do something with response and then return it
  return response;
}, function(error) {
  // handle some status codes and then return a rejected Promise object
	const { response: { status }, config } = error;
  if([401].indexOf(status) > -1) {
		const authHeader = config.headers.Authorization;
		if (authHeader.indexOf('Basic ') === 0) {
			return Promise.reject(error);
		}
		history.push('/login');
		store.dispatch(logoutAction());
		return error;
  }
  return Promise.reject(error);
});

const isLoggedin = ()=>{
	let loggedIn = false;

	if(store.getState().loginReducer) {
		if(store.getState().loginReducer.loggedIn) {
			loggedIn = true
		}else {
			loggedIn = false
		}
	}else {
		if(!upStorage.getItem('auth')) {
			loggedIn = false
		}
	}
	return loggedIn
}

const getHubAutoConfirmEnabled = (channel) => {
	// const ps = store.getState().loginReducer.data.biz.platforms;
	// const p = ps.find((i)=>i.name == channel);
	// if(p && p.auto_confirm_enabled) {
	// 	return true
	// }else {
	// 	return false;
	// }
	let hub_auto_confirm_enabled = false;
	try {
		hub_auto_confirm_enabled = store.getState().loginReducer.data.biz.hub_auto_confirm_enabled;
		return hub_auto_confirm_enabled;
	} catch(err) {
		console.error(err);
		return hub_auto_confirm_enabled;
	}
}


const updateCheck = ()=>{
	Axios.get('/version.json?time_stamp='+new Date().getTime()).then(function (response) {
		// console.log(response, typeof(response));
		let cv = cmpVersion(response.data.version, VERSION_NO);
		if(cv > 0) {
			store.dispatch({
				type:"APP_UPDATE_AVAILABLE",
				payload: {
					version:response.data.version,
					message: `New version ${response.data.version} available!`,
					show: true
				}
			})
		}
	})
}

const loggedInInfo = function(){
	if(store.getState().loginReducer && store.getState().loginReducer.loggedIn) {
		const data = store.getState().loginReducer.data;
		return {
			biz_id: data.biz.biz_id,
			username: data.name,
			biz_name: data.biz.name
		}
	}else {
		return {};
	}
}

const MP_TRACK = (event, props)=>{
	try{
		mixpanel.track(event, {
			...props,
			env: SERVER_PREFIX == 'api' ? 'prod' : SERVER_PREFIX,
			version: VERSION_NO,
			...loggedInInfo()
		});
	}catch(err) {
		console.log("mixpanel is not supported");
	}
}



let __UPCAHE = (localStorage.getItem('UP') && JSON.parse(localStorage.getItem('UP'))) || {};

var upStorage = {
	setItem: function(data, immediate = false) {
		var key = data.key;
		var value = data.value;
		__UPCAHE[key] = value;

		if(immediate) {
			console.log('writing immediately');
			localStorage.setItem('UP', JSON.stringify(__UPCAHE));
		}else {
			writeToDisk();
		}
	},
	getItem: function(key) {
		if(__UPCAHE[key]){
			return __UPCAHE[key];
		}else{
			return null;
		}
	},
	removeItem: function(key) {
		delete(__UPCAHE[key])
		localStorage.setItem('UP',JSON.stringify(__UPCAHE));
	}
}

window.S = upStorage;
window._S = __UPCAHE;

var UTILS = {
	intOnly: function(data) {
		return (/^[0-9]*$/.test(data))
	},
	emailOnly(data){
		return (/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i).test(data);
	},
	phonenoValid: function(data) {
		return true;
	},
	objToParam(data){
		var param="";
		for(var keys in data){
			if(param != "") {
				param += "&";
			}
			param += `${keys}=${data[keys]}`
		}
		return param
	}
}

var NO_HTTPS = JSON.parse(localStorage.getItem('no_https'));

if(NO_HTTPS) {
	var UP_CONFIG = {
		BASE_URL : `http://${SERVER_PREFIX}.urbanpiper.com/satellite/api/`,
		EXTERNAL_BASE_URL : `http://${SERVER_PREFIX}.urbanpiper.com/external/api/`,
		WEB_BASE_URL: `http://${SERVER_PREFIX}.urbanpiper.com/api/`,
		DELIVERY_BASE_URL: `http://${SERVER_PREFIX}.urbanpiper.com/delivery/api/`,
		AUTH : null,
		SOCKET_URL: SOCKET_URL
	}
}else {
	var UP_CONFIG = {
		BASE_URL : `https://${SERVER_PREFIX}.urbanpiper.com/satellite/api/`,
		EXTERNAL_BASE_URL : `https://${SERVER_PREFIX}.urbanpiper.com/external/api/`,
		WEB_BASE_URL: `https://${SERVER_PREFIX}.urbanpiper.com/api/`,
		DELIVERY_BASE_URL: `https://${SERVER_PREFIX}.urbanpiper.com/delivery/api/`,
		AUTH : null,
		SOCKET_URL: SOCKET_URL
	}
}

if(upStorage.getItem('jwt')) {
	UP_CONFIG.AUTH = upStorage.getItem('jwt');
}

var MISC_CONFIG = {
	COUNTRY_CODE:"+91",
	CHANNEL_IMG:{
		zomato:'zomato.svg',
		web:'web.svg',
		urbanpiper:'web.svg',
		app_android:'android.svg',
		app_ios:'ios.svg',
		satellite:'sat.svg',
		swiggy:'swiggy.png',
		ubereats:'ubereats.png',
		foodpanda:'foodpanda.png',
		scootsy:'scootsy.png',
		hungerbox:'hungerbox.png',
		swiggystore:'swiggy-stores.png',
		zomatomarket:'zomato-market.png',
	},
	CURR: {
		code:8377,
		name:"INR"
	},
	statusClassName: {
		new:{
			class:'actions-needed',
			title:'Actions Needed',
			lang_key:'actions_needed'
		},
		completed:{
			class:'completed-order',
			title:'Completed Order',
			lang_key:'completed_orders'
		},
		cancelled:{
			class:'cancelled-order',
			title:'Cancelled Orders',
			lang_key:'cancelled_orders'
		},
		other:{
			class:'other',
			title:'Orders in Progress',
			lang_key:'orders_in_progress'
		},
		future:{
			class:'future-order',
			title:'Future Order',
			lang_key:'future_orders'
		}
	},
	statusMap: {
		"Placed":{
			title:"Place",
			param:"placed"
		},
		"Food Ready":{
			title:"Mark food ready",
			param:"food ready",
			opptional:true
		},
		"Acknowledged":{
			title:"Acknowledge",
			param:"acknowledged",
			opptional:true
		},
		"Dispatched":{
			title:"Dispatch",
			param:"dispatched",
			opptional:true
		},
		"Modified":{
			title:"Modified",
			param:"modified",
			opptional:true
		},
		"Completed":{
			title:"Complete",
			param:"completed"
		},
		"Confirmed":{
			title:"Confirm",
			param:"confirmed",
			opptional:true
		},
		"Driver assigned":{
			title:"Assign Driver",
			param:"driver assigned",
			opptional:true
		},
		"Cancelled":{
			title:"Cancel",
			param:"cancelled",
		},
		"null":{
			title:"Null",
			param:"null"
		}
	},
	getRevesreParam(str) {
		let keys = Object.keys(this.statusMap);
		for(let x in keys) {
			if(this.statusMap[keys[x]].param == str) {
				return keys[x]
			}
		}
	},
	getParam(str) {
		if(this.statusMap[str]){
			return this.statusMap[str].param;
		}else{
			return null;
		}
	}
}

function getNextState(currentState) {
	const configuredStates = store.getState().loginReducer.data.biz.configured_order_states;
	const lang = store.getState().appLanguage.lang;

	const cIndex = configuredStates.indexOf(currentState);

	if((cIndex > -1) && configuredStates[cIndex+1] && MISC_CONFIG.statusMap[configuredStates[cIndex+1]]){
		return {
			key: configuredStates[cIndex+1],
			title: getLabel(lang, configuredStates[cIndex+1]) || MISC_CONFIG.statusMap[configuredStates[cIndex+1]].title,
		}
	}else {
		return {
			key: null,
			title: null
		}
	}
}
MISC_CONFIG.available_stat = []
MISC_CONFIG.optional_stat = []
for(let key in MISC_CONFIG.statusMap) {
	if(MISC_CONFIG.statusMap[key].param != 'null'){
		MISC_CONFIG.available_stat.push(MISC_CONFIG.statusMap[key].param)
		if(MISC_CONFIG.statusMap[key].opptional){
			MISC_CONFIG.optional_stat.push(MISC_CONFIG.statusMap[key].param)
		}
	}
}

function getAvailableStatusObj(arr) {
	var statusObj = []
	for(let x in arr){
		var statusName = arr[x]

		if(MISC_CONFIG.statusMap[statusName]){
			var status_title = MISC_CONFIG.statusMap[statusName].title
			var status_param = MISC_CONFIG.statusMap[statusName].param
		}else{
			var status_title =  arr[x];
			var status_param = arr[x];
		}


		statusObj.push(
			{
				key: x,
				title:status_title,
				status_param:status_param
			}
		)
	}

	return statusObj
}

const appLanguageInStorage = localStorage.getItem('appLanguage');

var AXIOS_CONFIG = {
	baseURL: UP_CONFIG.BASE_URL,
	headers: {
		"Authorization": UP_CONFIG.AUTH,
		"Content-Type": "application/json",
		"X-App-Version": VERSION_NO,
		"X-Use-Lang": appLanguageInStorage || "en"
	},
	responseType: 'json', // default
}

if(upStorage.getItem('auth') && upStorage.getItem('auth').biz.biz_id){
	AXIOS_CONFIG.headers['X-BID'] = upStorage.getItem('auth').biz.biz_id;
}


///////////////////apis/////////////////
const searchOrder = function({filter}){

	var order_tracker_url = `v1/orders/?q=${filter}`;

	return Axios({
		method:"get",
		url:UP_CONFIG.BASE_URL+order_tracker_url,
		data:JSON.stringify(filter),
		headers: AXIOS_CONFIG.headers
	})
}
const requestCallBack = function(data, callback, errCallback){
	var url = data.url
	Axios({
		method:"post",
		url:UP_CONFIG.BASE_URL+url,
		data:JSON.stringify({reason:data.reason}),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}
const fetchInActiveOrder = function(data,callback,errCallback){
	var order_tracker_url = `v1/orders/?status=completed,cancelled&limit=${data.limit}&v=${VERSION_NO}`;
	if (data.date) {
		order_tracker_url += `&dt=${data.date}`;
	}

	Axios.get(order_tracker_url, AXIOS_CONFIG)
	.then(function (response) {
		// Update track state
		// upStorage.setItem({key:'lastFetch',value:new Date().getTime()})
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}
const fetchActiveOrder = function(data,callback,errCallback){
	var order_tracker_url = `v1/orders/?status=active&limit=${data.limit}&v=${VERSION_NO}`;

	Axios.get(order_tracker_url, AXIOS_CONFIG)
	.then(function (response) {
		// Update track state
		// upStorage.setItem({key:'lastFetch',value:new Date().getTime()})
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}
const fetchDeliveryExec = function(data, callback, errCallback) {
	const { biz_location_id }  = data;
	const url = `v1/staff/?biz_location_id=${biz_location_id}&limit=40`;

	return Axios({
		method:"get",
		url:UP_CONFIG.DELIVERY_BASE_URL+url,
		data:JSON.stringify(data),
		headers: AXIOS_CONFIG.headers
	})
}
const setDeliveryExec = function(data, callback, errCallback) {
	const { order_id, user_id, action }  = data;
	const url = `v1/orders/${order_id}/delivery/`;

	const payload = {
		action,
		user_id
	}

	return Axios({
		method: "put",
		url: UP_CONFIG.BASE_URL+url,
		data: JSON.stringify(payload),
		headers: AXIOS_CONFIG.headers
	})
}



const fetchAllOrder = function(data,callback,errCallback){
	var order_tracker_url = `v1/orders/?`;
	if(data.next) {
		let nextFrag = data.next.split("/satellite/api/v1/orders/?")[1];
		order_tracker_url = order_tracker_url+nextFrag
	}else {
		if(data.filter) {
			order_tracker_url = order_tracker_url+'dt='+data.filter
		}

		if(data.limit) {
			order_tracker_url = `${order_tracker_url}&limit=${data.limit}`
		}
	}
	const settings_key = store.getState().loginReducer.data.user_id;
	const settings = getLocalSettings(settings_key) || {};
	const selectedStores = settings.selectedStores || [];
	if (selectedStores.length > 0 && selectedStores.length < 150) {
		const locations = selectedStores.map((str) => str.value).join(',');
		order_tracker_url = `${order_tracker_url}&location=${locations}`;
	}
	return new Promise((resolve, reject)=>{
		//let's not cache today's data
		let todayinStr = Moment(new Date().getTime()).format('YYYY-MM-DD');
		const cacheKey = `${order_tracker_url}_${settings_key}`;
		if(order_tracker_url.includes(todayinStr) || data.noCache){
			_getDataFromServer(resolve, reject);
		} else {
			IDBget(cacheKey).then((cache)=>{
				// console.log(cache)
				if(cache) {
					resolve(JSON.parse(cache))
				}else {
					_getDataFromServer(resolve, reject)
				}
			}).catch((err)=>{
				_getDataFromServer(resolve, reject)
			})
		}

		function _getDataFromServer(resolve, reject) {
			Axios.get(order_tracker_url, AXIOS_CONFIG).then(resp=>{
				IDBset(cacheKey, JSON.stringify(resp))
				resolve(resp);
			}).catch(err=>{
				reject(err)
			})
		}

	})
}
const fetchOrder = function(data,callback,errCallback){
	var order_tracker_url = `v1/orders/?`;
	if(data.filter){
		order_tracker_url = order_tracker_url+'status='+data.filter
	}
	if(data.limit){
		order_tracker_url = order_tracker_url+"&limit="+data.limit+"&v="+VERSION_NO
	}

	Axios.get(order_tracker_url, AXIOS_CONFIG)
	.then(function (response) {
		// Update track state
		upStorage.setItem({key:'lastFetch',value:new Date().getTime()})
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}

// v currently not being used. v //
const getReOrderPayload = function(data, callback, errCallback){
	var url = `v2/order/${data.id}/reorder/?customer_phone=${data.phone}&location_id=${data.location_id}`;

	Axios({
		method:"get",
		url:UP_CONFIG.WEB_BASE_URL+url,
		data:JSON.stringify(data),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}
const getOrderDetail = function(data, callback, errCallback){
	var url = `v1/orders/${data.id}/`;
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}
const getCustomerStatus = function(data, callback, errCallback){
	var url = `v1/userbiz/stats/${data.callid}/`
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}
const getRecommendedItem = function(data, callback, errCallback){
	var url =`v1/items/recommendations/?location_id=${data.store_id}`;
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		// console.log(response)
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}

const getCustomerStatus_v2 = function(data, callback, errCallback){
	if(data.phone){
		var caller_no = data.phone;
		var url = `v1/userbiz/stats/?phone=${caller_no}`
	}else{
		var url = `v1/userbiz/stats/?callid=${data.callid}`
	}
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}
const isDeliverable = function(data, callback, errCallback){
	getStores(data,function(d){
		callback(d)
	},function(err){
		errCallback(err)
	})

}
const getStores = function(data, callback, errCallback){
	data = {
		...data,
		limit: 10,
	};
	var url =`v1/stores/?${UTILS.objToParam(data)}`;
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}

const searchStores = function(data, callback, errCallback){
	data = {
		...data,
		limit: 10,
	};
	var url =`v1/stores/?${UTILS.objToParam(data)}`;
	return Axios.get(url, AXIOS_CONFIG);
}

const getItemOptions = function(data, callback, errCallback) {
	const url = `v1/item-options/?${UTILS.objToParam(data)}`;
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		callback(response);
	})
	.catch(function (err) {
		if (errCallback) {
			errCallback(err);
		}
	});
}

const updateItemOptionsAvailability = function(data) {
	const url = `v1/item-options/update-availability/`;
	return Axios.post(url, data, AXIOS_CONFIG);
}

const getInventoryofFirstCategoryItems = function(data,callback, errCallback){
	getCategories(data, (categories)=>{
		getItemsByCategory({
			ignorefulfillment_mode:data.ignorefulfillment_mode,
			cat_id:categories.data.categories[0].id,
			location_id:data.location_id},(items)=>{
			callback({
				categories:categories,
				items:items
			})
		}, (err)=>{
			errCallback(err)
		})
	}, (err)=>{
		if(errCallback){
			errCallback(err)
		}
	})
}
const getItemsByCategory = (data, callback, errCallback)=>{

	var cache_key = 'items_'+data.cat_id+"_"+data.location_id;
	var cached_data = _.find(window.ITEM_LOCATION_CATEGORY_CACHE, {key:cache_key});

	let fmQry = data.fulfillment_mode == 'pickup' ? '&fulfillment_mode=pickup,dinein' : '&fulfillment_modes=delivery';
	if(data.ignorefulfillment_mode){
		fmQry = '';
	}



	if(cached_data){
		var url = `v1/items/?category_id=${data.cat_id}&location_id=${data.location_id}&last_menu_pull=${cached_data.lastMenuPull}&limit=150&include_availability=true${fmQry}`
	}else{
		var url = `v1/items/?category_id=${data.cat_id}&location_id=${data.location_id}&time_stamp=${new Date().getTime()}&limit=150&include_availability=true${fmQry}`
	}

	Axios({
		method:"get",
		url:UP_CONFIG.BASE_URL+url,
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		if(response.status == 204){
			if(cached_data){
				cached_data.lastMenuPull = new Date().getTime();
				_.remove(window.ITEM_LOCATION_CATEGORY_CACHE, {key:cache_key})
				window.ITEM_LOCATION_CATEGORY_CACHE.push(cached_data)
				localStorage.setItem('UP_CACHE', JSON.stringify(window.ITEM_LOCATION_CATEGORY_CACHE))
				callback(cached_data)
			}
		}else{
			console.log('new result save cache');
			var cachedData = {
				data:response.data,
				lastMenuPull: new Date().getTime(),
				key:cache_key
			}

			_.remove(window.ITEM_LOCATION_CATEGORY_CACHE, {key:cache_key})
			window.ITEM_LOCATION_CATEGORY_CACHE.push(cachedData)
			localStorage.setItem('UP_CACHE', JSON.stringify(window.ITEM_LOCATION_CATEGORY_CACHE))
			callback(response)
		}
	})
	.catch(function (err) {
		console.log('err',err)
		if(errCallback){
			errCallback(err)

			// if(err.message){
			// 	errCallback(err)
			// }else{
			// 	errCallback({message:"Something went wrong!"})
			// }
		}
	});

}

const getItemsByTags = (data, callback, errCallback) => {
	var cache_key = 'items_'+data.tags+"_"+data.location_id;
	var cached_data = _.find(window.ITEM_LOCATION_TAGS_CACHE, {key:cache_key});

	let fmQry = data.fulfillment_mode == 'pickup' ? '&fulfillment_mode=pickup,dinein' : '&fulfillment_modes=delivery';
	if(data.ignorefulfillment_mode){
		fmQry = '';
	}

	if(cached_data) {
		var url = `v1/items/?tag=${data.tags}&location_id=${data.location_id}&last_menu_pull=${cached_data.lastMenuPull}&limit=150&include_availability=true${fmQry}`;
	} else {
		var url = `v1/items/?tag=${data.tags}&location_id=${data.location_id}&time_stamp=${new Date().getTime()}&limit=150&include_availability=true${fmQry}`;
	}

	Axios({
		method: "get",
		url: UP_CONFIG.BASE_URL+url,
		headers: AXIOS_CONFIG.headers,
	})
	.then(function (response) {
		if(response.status == 204) {
			if(cached_data) {
				cached_data.lastMenuPull = new Date().getTime();
				_.remove(window.ITEM_LOCATION_TAGS_CACHE, {key:cache_key})
				window.ITEM_LOCATION_TAGS_CACHE.push(cached_data)
				localStorage.setItem('UP_CACHE_TAGS', JSON.stringify(window.ITEM_LOCATION_TAGS_CACHE))
				callback(cached_data);
			}
		} else {
			console.log('new result save cache');
			const cachedData = {
				data: response.data,
				lastMenuPull: new Date().getTime(),
				key: cache_key,
			};
			_.remove(window.ITEM_LOCATION_TAGS_CACHE, {key: cache_key})
			window.ITEM_LOCATION_TAGS_CACHE.push(cachedData);
			localStorage.setItem('UP_CACHE_TAGS', JSON.stringify(window.ITEM_LOCATION_TAGS_CACHE));
			callback(response);
		}
	})
	.catch(function (err) {
		console.log('err',err)
		if(errCallback) {
			errCallback(err);
		}
	});
}

const getCategoryAndFrisrtCategoryItems = (data, callback, errCallback)=>{
	getCategories(data, (categories)=>{
		getItemsByCategory({
			cat_id: categories.data.categories[0].id,
			location_id: data.location_id,
			fulfillment_mode: data.fulfillment_mode
		},(items)=>{
			callback({
				categories:categories,
				items:items
			})
		}, (err)=>{
			errCallback(err)
		})
	}, (err)=>{
		if(errCallback){
			errCallback(err)
		}
	})
}
const searchItemsForInventory = function(data, callback, errCallback){
	var url = `v2/search/items/?keyword=${data.kw}&location_id=${data.location_id}&biz_id=${data.biz_id}&include_availability=true&fulfillment_mode=pickup,dinein,delivery`
	Axios({
		method:"get",
		url:UP_CONFIG.WEB_BASE_URL+url,
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		response.keyword = data.kw;
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}
const updateAvailability = function(d, callback, errCallback){
	var url = `https://${SERVER_PREFIX}.urbanpiper.com/satellite/api/v1/items/update-availability/`
	Axios.post(url,d, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err){
		if(errCallback){
			errCallback(err)
		}
	});
}
const getCategories = function(data, callback, errCallback){
	var cache_key = 'cat_'+data.location_id;
	var cachedData = _.find(window.ITEM_LOCATION_CATEGORY_CACHE, {key:cache_key});
	if(cachedData){
		var url = `v1/categories/?last_menu_pull=${cachedData.lastMenuPull}&location_id=${data.location_id}&limit=100`
	}else{
		var url = `v1/categories/?location_id=${data.location_id}&limit=100`
	}
	Axios({
		method:"get",
		url:UP_CONFIG.BASE_URL+url,
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}
const searchItems = function(data, callback, errCallback){
	var url = `v2/search/items/?keyword=${data.kw}&location_id=${data.location_id}&biz_id=${data.biz_id}`
	Axios({
		method:"get",
		url:UP_CONFIG.WEB_BASE_URL+url,
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		response.keyword = data.kw;
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}

// external
const setSurgeData = function(data, callback, errCallback){
	var url =`v1/store/${data.store_id}/settings/zomato/surge/ `;
	Axios({
		method:"post",
		url:UP_CONFIG.BASE_URL+url,
		data:JSON.stringify(data.data),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}
const pushOrderToPos = function(data, callback, errCallback){
	var url =`v1/orders/${data.id}/push-to-pos/`;
	Axios({
		method:"post",
		url:UP_CONFIG.BASE_URL+url,
		data:JSON.stringify(data.data),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}
const getLogisticsStatus = function(data, callback, errCallback){
	var url =`v1/store/${data.store_id}/settings/zomato/logistics/ `;
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}
const setLogisticsStatus = function(data, callback, errCallback){
	var url =`v1/store/${data.store_id}/settings/zomato/logistics/ `;
	Axios({
		method:"post",
		url:UP_CONFIG.BASE_URL+url,
		data:JSON.stringify(data),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}



const loginUser = function(data, callback, errCallback) {
	var url = 'v1/auth/me/?machine_id=' + localStorage.getItem('machine_id');

	let fetchInfoOnly = false;
	let authHeader = null;

	if(data.username && data.password) {
		let { username, password } = data;
		authHeader = "Basic " + btoa(username+':'+password);
	}else {
		fetchInfoOnly = true;
		authHeader = upStorage.getItem('jwt');
	}
	Axios({
		method: "get",
		url:UP_CONFIG.BASE_URL+url,
		headers: {
			Authorization: authHeader
		}
	})
	.then(function (response) {
		if(response && response.data.biz.biz_id) {
			if(fetchInfoOnly === false) {
				UP_CONFIG.AUTH = `Bearer ${response.data.access_token}`;
				AXIOS_CONFIG.headers.Authorization = UP_CONFIG.AUTH;
				upStorage.setItem({key:'jwt',value: UP_CONFIG.AUTH});

				AXIOS_CONFIG.headers['X-BID'] = response.data.biz.biz_id

				try {
					let last_biz = localStorage.getItem('last_loggedin_biz');
					if(last_biz && last_biz == response.data.biz.biz_id) {
						// all ok
					}else {
						// lets remove cache
						IDBclear();
					}

					localStorage.setItem('last_loggedin_biz', response.data.biz.biz_id)
				}catch(err) {
					// lets not do anything
				}
			}
			callback(response)
		}else {
			console.error(response)
		}

		// Axios({
		// 	method: "get",
		// 	url: `${ANALYTICS_URL}/login`,
		// 	headers: AXIOS_CONFIG.headers
		// }).catch((err)=>{
		// 	console.log(err)
		// })

	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});


}
const orderStatus = function(data, callback, errCallback){
	var url = `v1/orders/${data.id}/status/`
	Axios({
		method:"put",
		url:UP_CONFIG.BASE_URL+url,
		data:{new_status:data.new_state, message:data.message},
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}
const getOrderPayload = function(data){
	// receives currentOrderSession object and returns formatted order payload
	var channel = data.order_channel || "satellite";
	const { order_note, payment_mode, payment_aggregator } = data.delivery_info
	let instructions = order_note;
	if(payment_mode !== 'cash') {
		instructions = order_note + `${order_note ? '\n' : ''}Payment Method: ${payment_aggregator}`;
	}
	var _payload = {
			payment_option: data.delivery_info.payment_mode,
			order_type: data.delivery_mode,
			delivery_datetime: new Date().getTime(),
			delivery_charge: (data.delivery_mode=='pickup' ? 0 : data.store.delivery_charge),
			biz_location_id: data.store.biz_location_id,
			order_subtotal: data.cart.meta.sub_total,
			order_total: data.cart.meta.total,
			channel: channel,
			items: data.cart.items,
			tax_rate: 0,
			instructions: instructions,
			phone: data.user.phone,
			external_channel_id: data.external_channel_id,
		}
		if(data.preprocessedData){
			_payload.delivery_charge = data.preprocessedData.delivery_charge;
			_payload.packaging_charge = data.preprocessedData.packaging_charge;
			_payload.sub_total = data.preprocessedData.order_subtotal;
			_payload.order_total = data.preprocessedData.order_total;
		}

		if(data.delivery_date){
			let today = new Date();
			let delivery_date_DateObj = new Date(data.delivery_date);

			let delivery_date_midnight = new Date(delivery_date_DateObj.getFullYear(), delivery_date_DateObj.getMonth(), delivery_date_DateObj.getDate(), 0,0,0);
			var dateDiff = delivery_date_midnight - new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0,0,0);

			//_payload.delivery_datetime = _payload.delivery_datetime+dateDiff
		}else{
			var dateDiff = 0
		}

		var delivery_min_offset_time = (data.store.delivery_min_offset_time ? data.store.delivery_min_offset_time : 45*60*1000)


		if(data.delivery_time){
			if(data.delivery_time.delivery_time === null){
				_payload.delivery_datetime = new Date().getTime()+delivery_min_offset_time;
			}else {
				if(!data.delivery_time.now){
					if(data.delivery_time.delivery_time){
						_payload.delivery_datetime = timeInMilli(data.delivery_time.delivery_time)+dateDiff;
					}else {
						_payload.delivery_datetime = new Date().getTime()+delivery_min_offset_time+dateDiff;
					}
				}else {
					_payload.delivery_datetime = new Date().getTime()+delivery_min_offset_time;
				}
			}
		}else {
			_payload.delivery_datetime = new Date().getTime()+delivery_min_offset_time;
		}

		if(data.slot){
			_payload.time_slot_start = data.slot.time_slot_start
			_payload.time_slot_end = data.slot.time_slot_end;
			if (data.delivery_date) {
				_payload.delivery_datetime = data.delivery_date;
			}
		}

		if(data.delivery_time && data.delivery_time.now) {
			_payload.delivery_datetime = new Date().getTime()+delivery_min_offset_time;
			delete(_payload.time_slot_start);
			delete(_payload.time_slot_end)
		}



		if(data.delivery_info.address_id) {
			_payload.address_id = data.delivery_info.address_id;
		}else{
			if(data.delivery_info.guest_address){

				if(data.delivery_info.guest_address.pincode){
					data.delivery_info.guest_address.pin = data.delivery_info.guest_address.pincode;
				}

				if(data.delivery_info.guest_address.locality){
					data.delivery_info.guest_address.sub_locality = data.delivery_info.guest_address.locality;
				}
			}
			_payload.guest_address = data.delivery_info.guest_address;

		}

		if (data.coupon.data && data.coupon.data.success) {
			var couponDetail = data.coupon.data;
			//var newTotalTax = couponDetail.total_tax;
			//var newTotal = couponDetail.order_total;

			_payload.coupon = couponDetail.code;
			_payload.discount_applied = couponDetail.value;

			_payload.order_total = couponDetail.meta.order_total+couponDetail.value;
		}

		return _payload;
}
const validateCoupon = function(data,callback,errCallback){
	var url = 'v1/coupons/'+data.code+'/';
	var code = data.code;
	Axios({
		method:"post",
		url:UP_CONFIG.WEB_BASE_URL+url,
		data:JSON.stringify(data),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		var data = {...response.data.discount,
				meta:response.data,
				code:code
			};


		if(data.success){
			callback({data:data})
		}else{
			// console.log(data)
			errCallback({
				data:{
					message:data.msg
				}
			})
		}

	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}


const preProcessOrder =  function(data, callback, errCallback){
	var url = "v1/order/?format=json&pre_proc=1&biz_id="+data.biz_id;
	var order_payload = data;
	//order_payload.phone = UP.localStorage.getItem('guest').customer_phone;

	Axios({
		method:"post",
		url:UP_CONFIG.WEB_BASE_URL+url,
		data:JSON.stringify(data),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}
const placeOrder = function(data,callback,errCallback) {
	var url = 'v1/order/?format=json&biz_id='+data.biz_id;
	Axios({
		method:"post",
		url:UP_CONFIG.WEB_BASE_URL+url,
		data:JSON.stringify(data),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}

const createUser = function(data,callback,errCallback) {
	var data = {...data};
	data.channel = 'satellite';
	var url = `v2/card/?${UTILS.objToParam(data)}`

	Axios({
		method:"post",
		url:UP_CONFIG.WEB_BASE_URL+url,
		data:JSON.stringify(data),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}

const updateUserProfile = function(data,callback,errCallback) {
	var data = {...data};
	var url = `v1/user/profile/?from_satellite=true&customer_phone=${data.user_data.customer_phone}`

	Axios({
		method:"put",
		url:UP_CONFIG.WEB_BASE_URL+url,
		data:JSON.stringify(data),
		headers: AXIOS_CONFIG.headers
	})
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err.data)
		}
	});
}
const refreshMenu = function(){
	var menuInLocalStorage = upStorage.getItem('menu');
	var cTime = new Date().getTime();

	if(menuInLocalStorage){
		_.remove(menuInLocalStorage , function(i,d){
			return ((cTime - i.fetchedTime) > (12*60*60*1000))
		})
	}
	upStorage.setItem({key:'menu',value:menuInLocalStorage})
}

///////////////////apis-end/////////////////
const timeDiffHumanize = (timeinmilli)=> {
	const lang = store.getState().appLanguage.lang;
	var sec = parseInt(timeinmilli / 1000);
	if(sec > 86400){
		var day = parseInt(sec/86400)
	}

	if(sec > 3600){
		var hour = parseInt(sec/3600);
		var min = parseInt((sec - hour * 3600) / 60);
	}else{
		var min = parseInt(sec/60);
	}

	if(timeinmilli > 0){
		if(day){
			return `${day} ${getLabel(lang, 'day') || 'day'}`;
		}else{
			if(hour){
				return `${hour} ${getLabel(lang, 'hr') || 'hr'} ${min} ${getLabel(lang, 'min') || 'min'}`
			}else{
				return `${min} ${getLabel(lang, 'min') || 'min'}`;
			}
		}
	}else{
		return '--'
	}
}

var pushNewOrder = function(data){
	var order_id = parseInt(data.order_id);
	var order_data = data.data;
	if(upStorage.getItem('orderList') != null){
		var formattedData = _.cloneDeep(upStorage.getItem('orderList'));
		order_data.timeLeft = order_data.delivery_datetime - new Date().getTime();

		if(parseInt(order_data.delivery_datetime) <= todayEndInMilli()){
			// console.log('I was called with == today', data.order_id)
			//if old order and have been updated
			if(_.find(formattedData.placed.orders, {id:order_id})){
				_.remove(formattedData.placed.orders, {id:order_id})
			}

			order_data.newOrder = true
			formattedData.placed.orders.push(order_data);

		}else if(!_.find(formattedData.future.orders, {id:order_id}) && !_.find(formattedData.placed.orders, {id:order_id})){
			// console.log('I was called with == future', data.order_id)
			order_data.newOrder = true
			formattedData.future.orders.push(order_data);
			// lets not do anything for updated future order.
			// formattedData.future.orders = _.sortBy(formattedData.future.orders, function(order) {
			// 	return order.created;
			// });
		}
		upStorage.setItem({key:'orderList', value:formattedData})
		return formattedData;
	}
}
var prepareData = {
	updateList:function(data){
		const ignoreInactive = data.ignoreInactive;
		const ignoreActive = data.ignoreActive;

		var cTime = new Date().getTime();
		let storesData = data.stores;
		var data = data.data;

		let orderArray = data.orders; // data from server


		if(!ignoreActive) {
			const hub_auto_confirm_enabled = getHubAutoConfirmEnabled();
			if(hub_auto_confirm_enabled) {
				var _placed = _.filter(orderArray, function(order){
					return (order.order_state == 'Placed' || order.order_state == 'Acknowledged') &&  order.delivery_datetime <= todayEndInMilli();
				});
				var _inp = _.filter(orderArray, function(order) {
					return order.order_state != 'Acknowledged' && order.order_state != 'Placed' && order.order_state != 'Placed' && order.order_state != 'Cancelled' && order.order_state != 'Completed';
				})
			} else {
				var _placed = _.filter(orderArray, function(order){
					return order.order_state == 'Placed' && order.delivery_datetime <= todayEndInMilli();
				});
				var _inp = _.filter(orderArray, function(order) {
					return order.order_state != 'Placed' && order.order_state != 'Placed' && order.order_state != 'Cancelled' && order.order_state != 'Completed';
				});
			}
			var _future = _.filter(orderArray, function(order){
				return order.order_state == 'Placed' && order.delivery_datetime > todayEndInMilli();
			});
		}

		if(!ignoreInactive) {
			var _completed = _.filter(orderArray, {order_state:'Completed'});
			var _cancelled = _.filter(orderArray, {order_state:'Cancelled'});
		}


		if(upStorage.getItem('orderList') != null){
			var formattedData = _.cloneDeep(upStorage.getItem('orderList'));
		}else {
			var formattedData = {
				placed:{
					orders:[]
				},
				cancelled:{
					orders:[]
				},
				completed:{
					orders:[]
				},
				other:{
					orders:[]
				},
				future:{
					orders:[]
				}
			}
		}

		if(!ignoreInactive) {
			formattedData.cancelled.orders = _cancelled
			formattedData.completed.orders = _completed
		}

		if(!ignoreActive) {
			formattedData.placed.orders = _placed
			formattedData.other.orders = _inp
			formattedData.future.orders = _future
		}

		if(upStorage.getItem('orderList') == null){
			//first fetch
			upStorage.setItem({key:'orderList',value:formattedData});
		}else{
			//data from localstorage. i.e. data from last fetch
			var orderList = upStorage.getItem('orderList');
			var seenOrderTypes = [orderList.placed.orders, orderList.future.orders];
			//data from server after formatting
			var orderTypes = [formattedData.placed.orders, formattedData.future.orders];  //mutate the formatteData

			//Now we need to find whether there is any new order.
			for(let y in orderTypes){
				let orders = orderTypes[y];
				let seenOrder = seenOrderTypes[y];
				for(let x in orders){
					const order_id = orders[x].id;
					var ifOld = _.find(seenOrder, { 'id': order_id});

					if(ifOld){
						if(ifOld.newOrder){
							//old but still unseen
							orders[x].newOrder = true;
						}
					}else{
						orders[x].newOrder = true;
					}
				}
			}
			upStorage.setItem({key:'orderList',value:formattedData});
		}
		// console.log(formattedData)
		return formattedData;
	},
	update_order_list: function(data, array=false) {
		if(upStorage.getItem('orderList')) {
			var orderList = _.cloneDeep(upStorage.getItem('orderList'));
		}else {
			var orderList = {
				placed:{
					orders:[]
				},
				cancelled:{
					orders:[]
				},
				completed:{
					orders:[]
				},
				other:{
					orders:[]
				},
				future:{
					orders:[]
				}
			}
		}

		var hub_auto_confirm_enabled = false
		try{
			hub_auto_confirm_enabled = store.getState().loginReducer.data.biz.hub_auto_confirm_enabled;
		}catch(err) {
			console.error(err);
		}

		if(array) {
			for(let d in data) {
				let _data = data[d];

				let _status = MISC_CONFIG.getParam(_data.status);
				if(!_status) {

				}

				let id = parseInt(_data.order_id);

				let orderListKeys = Object.keys(orderList);

				for(let x in orderListKeys){
					let prevOrderList = orderList[orderListKeys[x]].orders;
					var _changd_order = _.find(prevOrderList, {id:id});

					if(_changd_order) {
						_changd_order.order_state = _data.status;
						_.remove(prevOrderList,{id:id});
						break;
					}
				}

				if(!_changd_order) {
					_changd_order = _data;
				}

				if(hub_auto_confirm_enabled) {
					if(_data.status == 'Acknowledged') {
						orderList.placed.orders.push(_changd_order);
					}else {
						if(_data.status != 'Placed' && _data.status != 'Cancelled' && _data.status != 'Completed'){
							// remove if already exists
							_.remove(orderList.other.orders, {id:id});
							orderList.other.orders.push(_changd_order);
						}else{
							orderList[_status].orders.push(_changd_order);
						}
					}
				}else {
					if(_data.status != 'Placed' && _data.status != 'Cancelled' && _data.status != 'Completed'){
						// remove if already exists
						_.remove(orderList.other.orders, {id:id});
						orderList.other.orders.push(_changd_order);
					}else{
						orderList[_status].orders.push(_changd_order);
					}
				}

			}
		}else {
			// let _data = data;

			let _status = MISC_CONFIG.getParam(data.status);

			let id = parseInt(data.order_id);
			let merchant_ref_id = data.merchant_ref_id;

			let orderListKeys = Object.keys(orderList);

			for(let x in orderListKeys) {
				let prevOrderList = orderList[orderListKeys[x]].orders;
				var _changd_order = _.find(prevOrderList, {id:id});
				if(_changd_order) {
					_changd_order.order_state = data.status;
					_.remove(prevOrderList,{id:id});
					break;
				}
			}

			if(_changd_order) {

				_changd_order.merchant_ref_id = merchant_ref_id;

				let is_future = (_changd_order.order_state == 'Placed' || _changd_order.status == 'Placed') && _changd_order.delivery_datetime > todayEndInMilli()
				if(hub_auto_confirm_enabled) {
					if(data.status == 'Acknowledged') {
						orderList.placed.orders.push(_changd_order);
					}else {
						if(data.status != 'Placed' && data.status != 'Cancelled' && data.status != 'Completed'){
							// remove if already exists
							_.remove(orderList.other.orders, {id:id});
							orderList.other.orders.push(_changd_order);
						}else{
							orderList[is_future ? 'future' : _status].orders.push(_changd_order);
						}
					}
				}else {
					if(data.status != 'Placed' && data.status != 'Cancelled' && data.status != 'Completed'){
						// remove if already exists
						_.remove(orderList.other.orders, {id:id});
						orderList.other.orders.push(_changd_order);
					}else{
						orderList[is_future ? 'future' : _status].orders.push(_changd_order);
					}
				}
			}
		}

		upStorage.setItem({key:'orderList',value: orderList})
		return orderList;
	}
}



function processCart(data){
	let item_data = data.item_data
	let item = item_data.item;
	let action = item_data.action;
	let cart = data.cart;
	let sig = getSigForComplexItem(item);
	let instructions = item_data.instructions;

	// console.log(sig, cart);

	if (action == 'add-item-instructions') {
		if (item.type && item.type === "complex-item") {
			_.find(cart.items, {sig: sig}).instructions = instructions || '';
		} else {
			_.find(cart.items, {id: item.id}).instructions = instructions || '';
		}
	}

	if(action == "delete-all"){
		if(item.type && item.type == "complex-item"){
			_.remove(cart.items, {sig:sig})
		}else{
			var item_id = item.id;
			_.remove(cart.items, {id:item_id})
		}

	}else{
		if(item.type && item.type == "complex-item"){
			if(action == 'add-to-cart'){
				if(_.find(cart.items, {sig:sig})){
					_.find(cart.items, {sig:sig}).quantity += 1
				}else{
					item.quantity = 1;
					item.sig = sig;
					cart.items.push(item)
				}
			} else if (action == 'delete-from-cart') {
				if(_.find(cart.items, {sig:sig}) && _.find(cart.items, {sig:sig}).quantity >1){
					_.find(cart.items, {sig:sig}).quantity -= 1
				}else{
					if(_.find(cart.items, {sig:sig})){
						_.remove(cart.items, {sig:sig})
					}else{
						var toRemove = _.filter(cart.items, {id:item.id})
						toRemove  = toRemove[toRemove.length-1].sig;
						_.remove(cart.items, {sig:toRemove})
					}
				}
			}
		}else{
			if(action == 'add-to-cart'){
				if(_.find(cart.items, {id:item.id})){
					_.find(cart.items, {id:item.id}).quantity += 1
				}else{
					item.quantity = 1;
					item.options = []; // dummy empty array to ease computation
					cart.items.push(item)
				}
			}else if(action == 'delete-from-cart'){
				for (var x in cart.items) {
					if (cart.items[x].id == item.id) {
						if (cart.items[x].quantity == 1) {
							cart.items.splice(x, 1);
						}else{
							cart.items[x].quantity -= 1;
						}
						break;
					}
				}
			}
		}
	}


	var pricingData = getCartToPricing(cart);

	cart.meta.item_count = pricingData.item_count
	cart.meta.sub_total  = pricingData.sub_total
	cart.meta.total =  pricingData.total
	cart.meta.total_tax = pricingData.tax

	return cart;
}
function prepareCartFromReorder(reorderData){
	var cart = {
		items:[],
		combos:[],
		meta:{
			item_count:0,
			total:0,
			total_tax:0
		}
	}

	if(reorderData.items_available.length>0){
		var _item_available = reorderData.items_available
		for(let x in _item_available){
			if(_item_available[x].option_groups && _item_available[x].option_groups.length>0){
				var _option_groups = _item_available[x].option_groups;
				for(let y in _option_groups)
				_item_available[x].options = _item_available[y].option_groups[y].options
				_item_available[x].sig = getSigForComplexItem(_item_available[x])
			}else{
				_item_available[x].options = []
			}
		}
	}
	cart.items = reorderData.items_available;
	var pricingData = getCartToPricing(cart);

	cart.meta.item_count = pricingData.item_count
	cart.meta.sub_total  = pricingData.sub_total
	cart.meta.total =  pricingData.total
	cart.meta.total_tax = pricingData.tax

	return cart;
}
function getSigForComplexItem(data){
	var id = data.id;
	var item_options = data.options;
	var idArr = [];
	for(let x in item_options){
		idArr.push(item_options[x].id);
	}
	idArr.sort(function(a, b){return a-b});
	var sig = id+"_"+idArr.join('_');
	return sig;
}
function getCartToPricing(data){
	var cart = data;
	var cart_total_price = 0;
	var cart_total_base_price = 0;
	var cart_total_tax_amount = 0;

	for (let x in cart.items){
		var item = cart.items[x]
		var base_item_price = item.item_price;
		var quantity = item.quantity;

		var service_tax = item.service_tax_rate;
		var vat = item.vat_rate;
		var total_tax_per = service_tax+vat

		var total_options_price = _.reduce(item.options, function (s, entry) {
			return s + parseFloat(entry.price);
		}, 0)

		var total_base_price = (base_item_price+total_options_price) * quantity

		var total_tax_amount = (total_base_price*total_tax_per)/100;

		var total_price = total_base_price+total_tax_amount;

		cart_total_price += total_price;
		cart_total_base_price += total_base_price;
		cart_total_tax_amount += total_tax_amount;
	}
	var item_count = _.reduce(cart.items, function (s, entry){
		return s + entry.quantity;
	}, 0);

	return {
		total:parseFloat(cart_total_price.toFixed(2)),
		sub_total:parseFloat(cart_total_base_price.toFixed(2)),
		tax:parseFloat(cart_total_tax_amount.toFixed(2)),
		item_count:item_count
	}
}

function getPayableAmount(data){
		var delivery_charge = data.delivery_charge;
		var packaging_charge = data.packaging_charge;
		var discount = data.discount;
		var tax = data.tax;
		var sub_total = data.sub_total;
		var delivery_mode = data.delivery_mode;

		var payable = (delivery_mode == 'delivery' ? delivery_charge: 0 ) + packaging_charge + sub_total + tax - discount;

		return parseFloat(payable.toFixed(2));
}
function processComplexItem(data){
	var handling_item = data.handling_item;
	var payload  = data.payload;

	switch (payload.type) {
		case "primary-options":
			var option_group_id = payload.option_group_id;
			var option_id = payload.option_id;
			var option_group = _.find(handling_item.option_groups, {id:option_group_id});
			var max_selectable = option_group.max_selectable;
			var OrgOption = _.find(option_group.options, {id:option_id})
			var option = {...OrgOption, option_group_id:option_group_id, type:payload.type}
			if(max_selectable != 1){

				if(max_selectable == -1){
					var ifExists = _.find(handling_item.options, {id:option_id, option_group_id:option_group_id});
					if(ifExists){
						_.remove(handling_item.options, {id:option_id});
					}else{
						handling_item.options.push(option)
					}
				}else{
					var ifExists = _.find(handling_item.options, {id:option_id, option_group_id:option_group_id});
					if(ifExists){
						_.remove(handling_item.options, {id:option_id});
					}else{
						var count = _.filter(handling_item.options, {option_group_id:option_group_id}).length;
						if(count < max_selectable){
							handling_item.options.push(option)
						}
					}

				}

			}else{
				var ifOptionGroupExists = _.find(handling_item.options, {option_group_id:option_group_id});
				var ifExists = _.find(handling_item.options, {id:option_id, option_group_id:option_group_id});

				if(ifOptionGroupExists){

					if(ifExists){
						if(ifExists.id == option_id){
							_.remove(handling_item.options, {option_group_id:option_group_id});
						}else{
							_.remove(handling_item.options, {option_group_id:option_group_id});
							if(handling_item.options && handling_item.options.length>0){
								handling_item.options.push(option);
							}else{
								handling_item.options = [option];
							}
						}
					}else{
						_.remove(handling_item.options, {option_group_id:option_group_id});
						if(handling_item.options && handling_item.options.length>0){
							handling_item.options.push(option);
						}else{
							handling_item.options = [option];
						}
					}

				}else{
					if(handling_item.options && handling_item.options.length>0){
						handling_item.options.push(option);
					}else{
						handling_item.options = [option];
					}
				}

			}
			return handling_item
			break;
		case "secondary-options":
			var option_group_id = payload.option_group_id;
			var nested_option_group_id = payload.nested_option_group_id;
			var option_id = payload.option_id;
			var nested_option_id = payload.id;

			var option_group = _.find(handling_item.option_groups, {id:option_group_id});
			var option = _.find(option_group.options, {id:option_id})
			var nestedOptions = _.find(option.nested_option_groups, {id:nested_option_group_id})
			var max_selectable = nestedOptions.max_selectable;
			var OrgNestedOption = _.find(nestedOptions.options, {id:nested_option_id})
			var nestedOption = {...OrgNestedOption, nested_option_group_id:nested_option_group_id, option_group_id:option_group_id, type:payload.type, option_id:option_id}

			if(max_selectable != 1){

				if(max_selectable == -1){
					var ifExists = _.find(handling_item.options, {id:nested_option_id});
					if(ifExists){
						_.remove(handling_item.options, {id:nested_option_id});
					}else{
						handling_item.options.push(nestedOption)
					}
				}else{
					var ifExists = _.find(handling_item.options, {id:nested_option_id});
					if(ifExists){
						_.remove(handling_item.options, {id:nested_option_id});
					}else{
						var count = _.filter(handling_item.options, {option_group_id:option_group_id, nested_option_group_id:nested_option_group_id}).length;
						if(count < max_selectable){
							handling_item.options.push(nestedOption)
						}
					}

				}

			}else{
				var ifExists = _.find(handling_item.options, {id:nested_option_id});
				if(ifExists){
					_.remove(handling_item.options, {id:nested_option_id});
				}else{
					handling_item.options = [nestedOption];
				}
			}
		default:
			console.log('No case found!');
			break;
	}
}
function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}

function todayEndInMilli(){
	var today = new Date();
	var inMilli = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23,59,0);
	return inMilli.getTime();
}

function scrollLeft(el, scrollLeft, duration){
	if(scrollLeft >= el.scrollLeft){
		var dir = "right", distance = scrollLeft - el.scrollLeft;
	}else{
		var dir = "left", distance = el.scrollLeft - scrollLeft;
	}
	var step = (distance * 10)/duration, scrolledAmt = 0;
	var intervalId = setInterval(function(){
		if(dir == 'left'){
		   el.scrollLeft -= step;
	   } else {
		   el.scrollLeft += step;
	   }
	   scrolledAmt += step;
	   // console.log("step", step, scrolledAmt);
	   if(scrolledAmt >= distance){
		   window.clearInterval(intervalId);
	   }
	}, 10)
}

const getStorePlatorms = function(data, callback, errCallback){
	var url =`/v1/store/${data.store_id}/associated-platforms/`;
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}


const getStoreAvailabilty = function(data, callback, errCallback){
	var url =`/v1/store/${data.store_id}/availability/?${UTILS.objToParam(data)}`;
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		console.log(err);
		if(errCallback){
			errCallback(err)
		}
	});
}

const setStoreAvailabilty = function(data, callback, errCallback){
	var url = `/v1/store/${data.store_id}/availability/`;
	Axios.post(url, data, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}

const getLocalSettings = (key) => {
	const settings = upStorage.getItem('settings_'+key);
	if (!settings) {
		return {
			selectedStores: [],
		};
	}
	return settings;
}

const getTakeAwayStatus = ({store_id}, callback, errCallback)=>{
	var url = `v1/store/${store_id}/settings/zomato/takeaway-status/`;
	Axios.get(url, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}

const setTakeAwayStatus = (data, callback, errCallback)=>{
	var url = `v1/store/${data.store_id}/settings/zomato/takeaway-status/`;
	let {store_id, ...rest} = data
	Axios.post(url, rest, AXIOS_CONFIG)
	.then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}

const setRiderTemp = (data, callback, errCallback) => {
	const url = 'v1/aggregator/zomato/feature-action/';
	Axios({
		method: "post",
		url: UP_CONFIG.EXTERNAL_BASE_URL+url,
		data: JSON.stringify(data),
		headers: AXIOS_CONFIG.headers,
	}).then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}

const setRiderMask = (data, callback, errCallback) => {
	const url = 'v1/aggregator/zomato/feature-action/';
	Axios({
		method: "post",
		url: UP_CONFIG.EXTERNAL_BASE_URL+url,
		data: JSON.stringify(data),
		headers: AXIOS_CONFIG.headers,
	}).then(function (response) {
		callback(response)
	})
	.catch(function (err) {
		if(errCallback){
			errCallback(err)
		}
	});
}

function readableSlot(d){
	const lang = store.getState().appLanguage.lang;

	let time_slot_start = d.time_slot_start;
	let time_slot_end = d.time_slot_end;

	let tvFy = (time)=>{
		let timeArr = time.split(":");
		let hr = parseInt(timeArr[0]);
		if(hr>12){
			return `${(timeArr[0]-12)}:${timeArr[1]} ${getLabel(lang, 'pm') || 'PM' }`
		}else if(hr == 12){
			return `${timeArr[0]}:${timeArr[1]} ${getLabel(lang, 'pm') || 'PM' }`
		}else {
			return `${timeArr[0]}:${timeArr[1]} ${getLabel(lang, 'am') || 'AM' }`
		}
	}

	return `${tvFy(time_slot_start)} - ${tvFy(time_slot_end)}`
}

let writingToDisk = false

function writeToDisk() {
	// const __UP = obj;
 	if(!writingToDisk) {
		writingToDisk = true;
		setTimeout(()=>{
			localStorage.setItem('UP', JSON.stringify(__UPCAHE));
			writingToDisk = false;
		},250)
	}
}

function handleSeamlessPrint(options, type='main') {
	// check printer configuration
	const qzTrayConfig = JSON.parse(localStorage.getItem('qzTrayConfig'))
	const printer = qzTrayConfig[type] ? qzTrayConfig[type].value || qzTrayConfig[type] : undefined;
	if (!printer) {
		alert('Trying seamless print functionality but printers are not configured');
		return;
	}
	const printOptions = {
		colorType: 'blackwhite',
	}
	const printerConfig = window.qz.configs.create(printer, printOptions);
	// get and validate data
	const { testPrint, orderId } = options;
	let printHTMLData = '';
	if (testPrint) {
		printHTMLData = renderPrintData(testPrintData, options);
		const data = [{
			type: 'html',
			format: 'plain',
			data: printHTMLData,
		}];
		window.qz.print(printerConfig, data).catch((err) => console.log(err));
	} else {
		getOrderDetail({id: orderId}, (resp) => {
			printHTMLData = renderPrintData(resp.data, options);
			const data = [{
				type: 'html',
				format: 'plain',
				data: printHTMLData,
			}];
			window.qz.print(printerConfig, data).catch((err) => console.log(err));
		}, (err) => {
			console.log('failed to get order details for printing', err);
			return;
		});
	}
}

function seamlessPrint(orderId, biz_id) {
	const { loginReducer, appSettings } = store.getState();
	const { name: operatorName, multi_biz, bizs } = loginReducer.data;
	let { biz } = loginReducer.data;
	if (multi_biz && bizs && bizs.length > 1) {
		biz = bizs.find((b) => b.biz_id === Number(biz_id));
	}
	const { print_order_slip: printOrderSlip } = appSettings;
	const options = {
		biz: {
			name: biz.name,
			imageUrl: biz.logo,
			gstNo: biz.gst,
			tinNo: biz.tin,
			currCode: biz.currency_code
		},
		printOrderSlip,
		operator: operatorName.split(' ')[0],
		orderId,
	};
	window.requestAnimationFrame(() => {
		handleSeamlessPrint(options, 'main');
		store.dispatch(statusChange({ d: orderId }));
	});
	window.requestAnimationFrame(() => {
		handleSeamlessPrint({...options, printKot: true}, 'kitchen');
	});
}


function print(options) {
	//When loaded as widget the trackers print events should be sent to the calling window
	if (window.location.pathname.startsWith("/w/")) {
		let message_to_parent = {
			type: "__print",
			payload: SunmiData()
		}
		try {
			window.parent.postMessage(message_to_parent,"*");
		} catch {
			console.error("could not send the print information to parent window.");
		}
	} else {
		if (window.JsInterface) {
			window.JsInterface.print(SunmiData());
		} else {
			const { enableSeamlessPrinting } = store.getState().printManager;
			if (options && options.seamless && enableSeamlessPrinting) {
				seamlessPrint(options.orderId, options.biz_id);
			} else {
				window.print();
			}
		}
		// window.print();
		try {
			const ifKot = store.getState().appSettings.print_kot;
			MP_TRACK(ifKot ? "print_kot" : "print_bill",{})
		}catch(err) {
			console.error(err)
		}
	}
}

const toggleAutoConfirm = ({name, state}, onSuccess, onFail) => {
	if (name === 'swiggy') {
		Axios.post('v1/store/settings/swiggy/', {
			keys: [{
				name: "auto_confirm",
				value: state ? "1" : "0"
			}]
		}, AXIOS_CONFIG)
		.then(data => {
			store.dispatch({
				type: 'UPDATE_PLATFORM_SETTINGS',
				payload: {
					platformName: 'swiggy',
					autoConfirmEnabled: state
				}
			});
			if (!state) {
				store.dispatch(toggleSeamlessPrntng(false));
			}
			onSuccess(data);
		})
		.catch(onFail);
	}
}

function clinetSearchOrder({kw, filter}) {
	const data = store.getState().orderList.data;


	let filterLogic = (data)=>data;

	if(filter == 'id') {
		filterLogic = (data)=>{
			return _.filter(data, (i)=>	i.id && i.id.toString().includes(kw) )
		}
	} else if (filter == 'ref') {
		filterLogic = (data)=>{
			return _.filter(data, (i)=>	i.merchant_ref_id && i.merchant_ref_id.toString().includes(kw) )
		}
	}else if (filter == 'phone') {
		filterLogic = (data)=>{
			return _.filter(data, (i)=>	i.phone && i.phone.startsWith(kw) )
		}
	}else if (filter == 'name') {
		filterLogic = (data)=>{
			return _.filter(data, (i)=>	i.customer_name && i.customer_name.toLowerCase().startsWith(kw) )
		}
	}else if (filter == 'pid') {
		filterLogic = (data)=>{
			return _.filter(data, (i)=>	{
				return (i.ext_platform_id && i.ext_platform_id.toString().includes(kw)) ||
				(i.merchant_ref_id && i.merchant_ref_id.toString().includes(kw))
			})
		}
	}

	let otherOrders = filterLogic(data.other.orders);
	let completedOrders = filterLogic(data.completed.orders);
	let cancelledOrders = filterLogic(data.cancelled.orders);
	let futureOrders = filterLogic(data.future.orders);
	let placedOrders = filterLogic(data.placed.orders);

	return [...placedOrders, ...otherOrders, ...completedOrders, ...cancelledOrders, ...futureOrders]
}



// send 5.x.x error to slack
var sendToSlack = ({url, data}) => {
	fetch(url, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	});
}

Axios.interceptors.response.use(null, function(error) {
	if(error && error.config && error.config.url && error.config.url.split("urbanpiper").length > 0) {
		if(error.response === undefined) {
			sendToSlack({
				url: `https://hooks.slack.com/services/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`,
				data: {
					"channel": "sys-alerts",
					"username": "satellite",
					"icon_url":"https://test-satellite.urbanpiper.com/sat.png",
					"text": `
					Satellite Error ${error.message}
					Request URL: ${error.config.url}
					Headers: ${JSON.stringify(error.config.headers)}
					`
				}
			})
		}else {
			let error_code = error.response.status;
			error_code = error_code.toString().substr(0,1);


			if(error_code == 5) {
				sendToSlack({
					url: `https://hooks.slack.com/services/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`,
					data: {
						"channel": "sys-alerts",
						"username": "satellite",
						"icon_url":"https://test-satellite.urbanpiper.com/sat.png",
						"text": `
						Satellite Error (${error.response.status}): ${error.message} ${error.response.statusText}
						Errors : ${JSON.stringify(error.response.data)}
						Request URL: ${error.request.responseURL}
						Headers: ${JSON.stringify(error.config.headers)}
						`
					}
				})
			}
		}
		return Promise.reject(error)
	}
});





export {
	getAvailableStatusObj,
	getReOrderPayload,
	prepareCartFromReorder,
	fetchInActiveOrder,
	fetchActiveOrder,
	fetchOrder,
	upStorage,
	prepareData,
	getOrderDetail,
	orderStatus,
	timeDiffHumanize,
	getCustomerStatus_v2,
	searchItems,
	getCategories,
	getItemsByCategory,
	getItemsByTags,
	updateAvailability,
	searchItemsForInventory,
	getStores,
	searchStores,
	isDeliverable,
	processCart,
	processComplexItem,
	getCartToPricing,
	validateCoupon,
	getOrderPayload,
	placeOrder,
	getRecommendedItem,
	preProcessOrder,
	miliTo12Hrs,
	createUser,
	updateUserProfile,
	getScrollbarWidth,
	pushNewOrder,
	refreshMenu,
	requestCallBack,
	getLogisticsStatus,
	setLogisticsStatus,
	setSurgeData,
	getStorePlatorms,
	getStoreAvailabilty,
	setStoreAvailabilty,
	getCategoryAndFrisrtCategoryItems,
	getInventoryofFirstCategoryItems,
	MISC_CONFIG,
	UP_CONFIG,
	loginUser,
	VERSION_NO,
	VERSION_BUILD,
	SERVER_PREFIX,
	UTILS,
	isMobile,
	scrollLeft,
	ITEM_LOCATION_CATEGORY_CACHE,
	getLocalSettings,
	getTakeAwayStatus,
	setTakeAwayStatus,
	pushOrderToPos,
	readableSlot,
	getLabel,
	AXIOS_CONFIG,
	MP_TRACK,
	isLoggedin,
	upPriority,
	searchOrder,
	fetchAllOrder,
	clinetSearchOrder,
	seamlessPrint,
	handleSeamlessPrint,
	print,
	updateCheck,
	fetchDeliveryExec,
	setDeliveryExec,
	getNextState,
	commifyNumber,
	getItemOptions,
	updateItemOptionsAvailability,
	setRiderTemp,
	setRiderMask,
	toggleAutoConfirm,
	PRINTER_PORT,
}
