const pushToPosState = function(state = {
	show: false,
	order_id: null,
	message: "Processing request..",
	state: "idle"
}, action) {
	switch (action.type) {
		case 'UPDATE_PTP_STATE':
			return {
				...state,
				...action.payload
			}
		default:
			return state
	}
}

export default pushToPosState
