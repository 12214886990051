import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import {
	Router,
	Route,
	Redirect,
	Switch
} from 'react-router-dom';

import { store } from './app/store';
import history from './app/history';


import Settings from './app/settings.jsx';
import Login from './app/login';
import OrderListContainerParent from './app/OrderTracking';
import InventoryContainer from './app/Inventory';
import ChangeLog from './app/ChangeLog';
import Analytics from './app/Analytics';
import OrderingModule from './app/OrderTaker';
import { connectQZTray, initQZTray } from './qz-print-helper';


import {
	AppHeaderTracker,
	SideBar,
	CancelledOrdersList
} from './app/siteComps';

import {
	BarCodeHandler
} from './app/reactComp';

import AppUpadateAvailable from './app/ModalNotifications/AppUpadateAvailable';
import AllowAudioChromeUX from './app/ModalNotifications/AllowAudioChrome';
import OrderNotification from './app/ModalNotifications/OrderNotification';
import PushToPosStatus from './app/ModalNotifications/PushToPOS';
import NoInternetAccessStatus from './app/ModalNotifications/NoInternetAccessStatus';
import CrossPlatformEnhancementUX from './app/ModalNotifications/CrossPlatformEnhancement'


import {
	fetchActiveOrder,
	fetchInActiveOrder,
	pushNewOrder,
	logoutAction,
	getOrderDetail,
	updateOrderList,
	pushToPos,
	internetAccess,
	displayCrossPlatformEnhancement,
} from './app/actions';

import {
	connectSocket,
	socketlisteners,
	removeListiner,
	getUserDetails,
	trackPage
} from './socket_handlers';
import { initFirebase } from './firebase';

import { hot } from 'react-hot-loader'
import barcodeHandler from './app/Barcode';


import _ from 'lodash';



const machine_id = localStorage.getItem('machine_id');
const sat_api = require('./js/api.js');



class App extends Component {
	componentDidMount() {
		initQZTray();
		// setTimeout(()=>{
		// 	store.dispatch(displayCrossPlatformEnhancement())
		// },3000);
	}

  render() {
    return (
        <Provider store={store}>
    		<Router history={history}>
    			<Switch>
    				<Route exact path="/login" component={Login}/>
					<Route exact path="/change-log" component={ChangeLog} />
					<Route path="/w" render={(props) => (
                        !sat_api.isLoggedin() ? null : (<WidgetRoutes/>)
                    )} />

    				<Route path="/" render={(props) => (
                        !sat_api.isLoggedin() ? (
                        <Redirect to={
                            {
                                pathname: '/login',
                                state: props.location
                            }
                        }/>) : (<SecureRoutes/>)
                    )} />
    			</Switch>
    		</Router>
    	</Provider>
    );
  }
}


class WidgetRoutes extends React.Component {
	componentDidMount() {
		// initFirebase();
		// tracketInit();
	}

	componentWillUnmount() {
		removeListiner(machine_id)
	}

	documentClickHandler() {
		store.dispatch({
			type: "ALL_ORDER_FETCH_HIDE_CAL",
			payload: null
		})

		store.dispatch({
			type: "UPDATE_NAV_STATE",
			payload: {
				show_filters: false,
				show_live_search: false
			}
		})
	}

	render() {
		return(
			<div className="content--widget" id="content" onClick={this.documentClickHandler}>
				<Route path="/w/placed" render={(props)=><AppHeaderTracker widget={true} />}/>
				<Route path="/w/in-progress" render={(props)=><AppHeaderTracker widget={true} />}/>
				<Route path="/w/past-order" render={(props)=><AppHeaderTracker widget={true} />}/>
				<Route path="/w/completed" render={(props)=><AppHeaderTracker widget={true} />}/>
				<Route path="/w/cancelled" render={(props)=><AppHeaderTracker widget={true} />}/>
				<Route path="/w/future" render={(props)=><AppHeaderTracker widget={true} />}/>
				<AppUpadateAvailable />
				<Switch>
					<Route path="/w/inventory" component={ InventoryContainer } />
					<Route exact path="/w/settings" render={()=> <Settings widget={true}/>} />
					<Route exact path="/w/analytics" component={Analytics} />
					<Route exact path="/w/start-order" component={ OrderingModule } />
					<Route path="/w/:type?/:orderId?" component={ OrderListContainerParent } />
				</Switch>
				<NoInternetAccessStatus />
				{
					sat_api.isMobile ?
					null
					:
					<BarCodeHandler />
				}
				<PushToPosStatus />
				<CancelledOrdersList />
			</div>
		)
	}

}
class SecureRoutes extends React.Component{
	componentDidMount() {
		// initFirebase();
		// tracketInit();
		// const { enableSeamlessPrinting } = store.getState().printManager;
		// if (enableSeamlessPrinting) {
		// 	connectQZTray();
		// }
	}

	documentClickHandler() {
		store.dispatch({
			type: "ALL_ORDER_FETCH_HIDE_CAL",
			payload: null
		})

		store.dispatch({
			type: "UPDATE_NAV_STATE",
			payload: {
				show_filters: false,
				show_live_search: false
			}
		})
	}

	render() {
		return(
			<div id="content" onClick={this.documentClickHandler}>
                <AppHeaderTracker />
				<AppUpadateAvailable />
                <SideBar/>
				<Switch>
					<Route exact path="/start-order" component={ OrderingModule } />
					<Route exact path="/settings" component={ Settings } />
					<Route exact path="/inventory" component={ InventoryContainer } />
					<Route exact path="/analytics" component={ Analytics } />
					<Route path="/:type?/:orderId?" component={ OrderListContainerParent } />
				</Switch>
                <NoInternetAccessStatus />
				<CrossPlatformEnhancementUX/>
                <OrderNotification />
				{
					sat_api.isMobile ?
					null
					:
					<BarCodeHandler />
				}
				<AllowAudioChromeUX />
				<PushToPosStatus />
				<CancelledOrdersList />
			</div>
		)
	}
}



if(!sat_api.isMobile) {
	barcodeHandler()
}

window.addEventListener("offline", function(e) {
    store.dispatch(internetAccess(false))
});


window.addEventListener("online", function(e) {
    store.dispatch(internetAccess(true))
});

const tracketInit = ()=>{
	if(sat_api.isLoggedin()) {
		const loginData = store.getState().loginReducer.data || sat_api.upStorage.getItem('auth');

		const biz_id = loginData.biz.biz_id;
		const user_id = loginData.user_id;
		const name_of_user = loginData.name;

		let rooms = "";

		if(loginData.flag == 'HQ') {
			var query_params = `biz_id=${biz_id}&user_id=${user_id}&machine_id=${machine_id}&name_of_user=${name_of_user}&secure=true`
		}else {
			rooms = loginData.sub_admin.map((i)=>i.biz_location_id).join(",");
			var query_params = `biz_id=${biz_id}&biz_location_id=${rooms}&machine_id=${machine_id}&user_id=${user_id}&name_of_user=${name_of_user}&secure=true`
		}

		// const user_token = atob(sat_api.UP_CONFIG.AUTH.split("Basic ")[1]);

		let __socketIns = connectSocket(sat_api.UP_CONFIG.SOCKET_URL, {
			query: query_params,
			forceNew: true
		});

		const socketConnection = {
			biz_id,
			machine_id,
			user_id,
			name_of_user,
			biz_location_id: rooms,
		}
		if (loginData.multi_biz) {
			socketConnection.biz_id = loginData.bizs.map((biz) => biz.biz_id).join(',');
		}
		socketlisteners(socketConnection);
		trackPage('user_entered_url', "/");
	}else {
		console.log('User not logged in')
	}

	var settings = sat_api.getLocalSettings('general');

	if(settings){
		store.dispatch({
			type:"UPDATE_APP_SETTINGS",
			payload:settings
		})
	}
}

export default hot(module)(App);
