import React, { Component } from 'react';
import { connect } from 'react-redux';

import { store } from '../store.js';
import { updateInventory } from '../actions';
import _ from 'lodash';

@connect((store)=>{
	return{
		inventory: store.inventory
	}
})
export default class InventorySwitch extends Component{
	handleClick = ()=> {

		const stock = this.props.availability.find((i)=>i.name == this.props.platform)
		var _payload = {
			item_id: this.props.item_id,
			platform: this.props.platform,
			locations: [this.props.inventory.location_id]
		}

		if(this.props.inventory.allupdate) {
			delete(_payload.locations)
		}

		if(stock) {
			_payload.availability = !stock.is_available


			const new_state_items_data = {...this.props.inventory.data}

			const new_state_item = _.find(new_state_items_data,{id:this.props.item_id})
			const availability = _.find(new_state_item.availability, {name:this.props.platform});
			availability.is_available = !stock.is_available;

			store.dispatch({
				type: 'INVENTORY_FETCH_DONE',
				payload: new_state_items_data
			})

			store.dispatch(updateInventory(_payload))
		}else{
			console.log('No handler found!')
		}
	}

	render() {

		const stock = this.props.availability.find((i)=>i.name == this.props.platform)
		let _platform_state = "disabled";

		if(stock) {
			_platform_state = (stock.is_available ? "active" : "");
		}

		return(
			<div  className="InventorySwitch-comp">
				<div className={"switch-rail-cont "+_platform_state}>
					<div className="switch-rail" onClick={(e)=>{this.handleClick(e)}}>
						<div className={_platform_state+" switch-ball"}></div>
					</div>
				</div>
			</div>
		)
	}
}
