const globalSettings = localStorage.getItem('global_settings');
let tone = 'default.mp3';

if(globalSettings) {
	const globalSettingsJSON = JSON.parse(globalSettings);
	tone = globalSettingsJSON.tone;
}
const initialState = {
	interactionRequired: false,
	tone: tone
}
export const audioInteraction = (state = initialState, action)=>{
	switch (action.type) {
		case "HIDE_AUDIO_PROMPT":
			return {
				...state,
				interactionRequired: false
			}
			break;
		case "SHOW_AUDIO_PROMPT":
			return {
				...state,
				interactionRequired: true
			}
			break;
		case "UPDATE_AUDIO":
			return {
				...state,
				tone: action.payload
			}
			break;
		default:
			return state;

	}
}

export default audioInteraction
