import React from 'react';
import {store} from '../store.js';
import { connect } from 'react-redux';

import { dropOrder } from '../actions';

@connect((store) => {
	return {
		currentOrderSession:store.currentOrderSession
	}
})
export default class ThankyouPage extends React.Component{
	placeNew(){
		store.dispatch(dropOrder())
	}
	render(){
		return(
			<div className="padding-10 m-t-20 text-center">
				<div className="text-center m-t-10 m-b-10"><img src="/images/checked.png" /></div>
				<div className="x-large-text bold">Your order has been placed.</div>
				<div className="large-text m-t-10 m-b-10">Order ID: {this.props.currentOrderSession.successOrder.order_id}</div>
				<div className="text-center">
					<a onClick={(e)=>this.placeNew(e)}  className="up-button default large primary">
						Place a new order
					</a>
				</div>
			</div>
		)
	}
}
