import React from 'react';
import { connect } from 'react-redux';

import {
	PseudoSound
} from '../reactComp';


import _ from 'lodash';

@connect((store)=>{
	return{
		loginReducer: store.loginReducer,
		orders_list: store.orderList,
		appSettings: store.appSettings
	}
})
export default class OrderNotification extends React.Component{
    constructor(props) {
		super(props)
		this.state = {
			play:false
		}
	}

    render() {
		if(this.props.appSettings['notification'] === false) {
			return null;
		}
		let play = false
		var _order_list = this.props.orders_list;
		if(_order_list && _order_list.data && (_order_list.data.placed.orders || _order_list.data.future.orders) && this.props.loginReducer.loggedIn){
			var _placed_order_list = _order_list.data.placed.orders;
			var _future_order_list = _order_list.data.future.orders;

			var _newOrder = _.filter(_placed_order_list, { 'newOrder': true});
			var _newFutureOrder =  _.filter(_future_order_list, { 'newOrder': true});
			if(_newOrder && _newOrder.length>0){
				document.title = `* (${_newOrder.length}) New order!`
				play = true
			}else{
				if(this.props.appSettings['notification_future'] && _newFutureOrder && _newFutureOrder.length>0){
					play = true
				}else{
					document.title = "Satellite - Urbanpiper order management"
				}
			}
		}else{
			document.title = "Satellite - Urbanpiper order management"
		}

		return <PseudoSound play={play}/>
	}
}
