const orderTrackerNavState = function(state = {
    active_tab: "placed",
    search_keyword: "",
    search_filter: "pid",
    search_results: [],
	show_live_search: false,
    show_filters: false,
    loading: false,
	client_search_result: []
}, action) {
	switch (action.type) {
		case 'UPDATE_NAV_STATE':
			return { ...state, ...action.payload }
		default:
			return state
	}
}

export default orderTrackerNavState
