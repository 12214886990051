import React, { useCallback, useMemo, useEffect } from 'react';

// third party
import { connect } from 'react-redux';
import Select from 'react-select';

// redux
import { store } from '../store.js';
import { getInventoryItemsByTags } from '../actions';

var sat_api = require('../../js/api.js');

const TagsDropDown = ({ tags, selected_tag, location_id, lang }) => {
	const formattedData = useMemo(() => {
		return tags.map((tag) => ({
			value: tag,
			label: tag,
		}));
	}, [tags]);

	const handleSelect = useCallback((e, location_id) => {
		store.dispatch(getInventoryItemsByTags({
			tags: e.value,
			location_id: location_id,
		}));
		store.dispatch({
			type: "SET_SELECTED_TAGS_INVENTORY",
			payload: e,
		});

		// remove category selection
		store.dispatch({
			type: "SET_SELECTED_CAT_INVENTORY",
			payload: null,
		});
	}, []);

	useEffect(() => {
		return () => {
			// remove tag selection when this component unmounts
			store.dispatch({
				type: "SET_SELECTED_TAGS_INVENTORY",
				payload: null,
			});
		}
	}, []);

	return (
		<Select
			className="category-dropdown-inventory"
			optionClassName="needsclick"
			onChange={(e) => handleSelect(e, location_id)}
			value={selected_tag}
			options={formattedData}
			placeholder={sat_api.getLabel(lang, "select_tag") || "Select tag"}
			autoBlur={true}
		/>
	);
}
export default connect((store) => ({
	tags: store.loginReducer.data.biz.tags || [],
	selected_tag: store.inventory.selected_tags,
	location_id: store.inventory.location_id,
	lang: store.appLanguage.lang,
}))(TagsDropDown);
