import React from 'react';
import { connect } from 'react-redux';

const AppUpadateAvailable = (props)=>{

	if(!props.appUpadateAvailable.show){
		return null
	}
	return(
		<div className="app-update-available">
			{props.appUpadateAvailable.message}
			<span onClick={()=>window.location.reload()} className="app-update-available--refresh">Refresh</span>
		</div>
	)
}


export default connect((store)=>{
	return{
		appUpadateAvailable: store.appUpadateAvailable
	}
})(AppUpadateAvailable)
