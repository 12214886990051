import  io from 'socket.io-client';
import { store } from './app/store';
import {
	fetchActiveOrder,
	fetchInActiveOrder,
	pushNewOrder,
	logoutAction,
	getOrderDetail
} from './app/actions';

import _ from 'lodash';
import history from './app/history';
import { handleOrderPrint } from './firebase';


const sat_api = require('./js/api');
const machine_id = localStorage.getItem('machine_id');

var socketIns = null;

export function connectSocket(url, params) {
	socketIns = io(url, params);
	window.socketIns = socketIns;
	return socketIns
}

const showSocketError = (hide)=>{
	if(hide===false) {
		store.dispatch({
			type: "HIDE_CONECTIVITY",
			payload: null
		})
	}else {
		store.dispatch({
			type: "SHOW_CONECTIVITY_SOCKET",
			payload: null
		})
	}
}

export function socketlisteners(d) {
	store.dispatch(fetchActiveOrder())
	store.dispatch(fetchInActiveOrder())
	const user_token = sat_api.upStorage.getItem('jwt');
	console.log(user_token, d);
	socketIns.emit('authenticate', {
		user_token: user_token,
		...d,
	});
	// console.log('called')
	socketIns.on('connect', function() {
		console.log('socket connected');
		showSocketError(false)
	});

	socketIns.on('reconnect', function() {
		console.log('socket re-connected');
		showSocketError(false)
	});

	socketIns.on('disconnect', function(err) {
		console.log('socket disconnected', err);
		showSocketError();
	});

	socketIns.on('auth_failure', function(err) {
		console.log('socket disconnected', err);
		showSocketError();
	});

	socketIns.on('connect_error', function(err) {
		// alert('Failed to connect to UP server')
		showSocketError();
	});

	socketIns.on('error', function(err) {
		// alert('Failed to connect to UP server')
		showSocketError();
	});

	socketIns.on('order_placed', function(data) {

		if(data.order_id) {
			// console.log('new order placed', data.order_id);

			//check if there is any store level filter
			var settings_key = store.getState().loginReducer.data.user_id;

			var settings = sat_api.getLocalSettings(settings_key) || {};
			var selectedStores = settings.selectedStores;
			const selectedStoresFlat = selectedStores ? selectedStores.map((str) => str.value) : [];
			if(selectedStoresFlat.length>0) {
				if(selectedStoresFlat.includes(data.data.biz_location_id)) {
					store.dispatch(pushNewOrder(data));
					data.data.machine_id = machine_id;
				}
			}else {
				store.dispatch(pushNewOrder(data));
				data.data.machine_id = machine_id;
			}

			socketIns.emit('acknowledgement', data.data);
		}
	})

	socketIns.on('logout_user', function(data) {
		console.log('logout user event received')
		store.dispatch(logoutAction());
		history.push('/login')
	});

	socketIns.on('order_status', function(data={}) {
		// console.log('order status changed', data.id);

		if(store.getState().loginReducer.loggedIn) {
			let _status = sat_api.MISC_CONFIG.getParam(data.status);

			if(_status == 'placed') {
				console.error('Ignoring placed order in order_status event', data);
			}else{
				// store.dispatch(updateOrderList(data));
				var settings_key = store.getState().loginReducer.data.user_id;
				var settings = sat_api.getLocalSettings(settings_key) || {};

				var selectedStores = settings.selectedStores;
				const selectedStoresFlat = selectedStores ? selectedStores.map((str) => str.value) : [];
				if ((selectedStoresFlat.length > 0 &&
					selectedStoresFlat.includes(data.biz_location_id)) ||
					selectedStoresFlat.length === 0
				) {
					orderStatusHandle(data);
					if (data.status && data.status.toLowerCase() == "acknowledged") {
						// handle seamless printing if this order is in ["Acknowledged"]
						const { enableSeamlessPrinting } = store.getState().printManager;
						if (enableSeamlessPrinting) {
							handleOrderPrint(data.order_id, data.created, data.biz_id);
						}
					}
				}
			}
			if(store.getState().cRoute.page && store.getState().cRoute.page =='order-detail' && store.getState().cRoute.extra.order_id == parseInt(data.order_id)){
				store.dispatch(getOrderDetail({id:parseInt(data.order_id), stealth:true}))
			}
      		data['machine_id'] = machine_id;
			socketIns.emit('acknowledgement', data);
		}
	})

    _.forEach([
        'user_entered_url',
        'user_exited_url',
        'user_entered_room',
        'user_exited_room'
    ], setupEntryExitHandlers);


    socketIns.on('ping', () => {
        if(store.getState().loginReducer) {
            let {user_id} = store.getState().loginReducer.data;
            let machine_id = localStorage.getItem('machine_id');
            socketIns.emit('received_ping', {user_id, machine_id})
        }
    });
}

export function removeListiner(machine_id) {
	const data = {
		user_id: store.getState().loginReducer.data.user_id.toString(),
		machine_id
	};

	if (socketIns) {
		socketIns.emit('pre_disconnect', data);
		socketIns.disconnect();
	}

	let messages = ['new_call', 'call_ended', 'order_status',
		'ping', 'user_exited_room', 'user_entered_room', 'users_in_current_room']

	function _removeListenrs(m){
		console.log('stopped listening to '+m)
	}

	if (socketIns) {
		messages.forEach(m => {
			socketIns.removeListener(m, _removeListenrs(m));
			socketIns.off(m)
		});
	}

	store.dispatch({type: 'EMPTY_CURRENT_ROOM_USERS_LIST'})
}


function setupEntryExitHandlers(message) {
    socketIns.on(message, (data) => {
		// console.log(message, data)
        let ud = getUserDetails();
        if(ud && data.user_id === ud.user_id) {
            return;
        }
    });
}


export function trackPage(message, id) {
	try {
		let data = getUserDetails()
		data.url = id;
		socketIns.emit(message, data);
    }catch(e) {
        console.log(e);
    }
}




export function getUserDetails() {
    const loginData = store.getState().loginReducer.data;
    const { user_id, name } = loginData;
    const machine_id = localStorage.getItem('machine_id');
    return { user_id, machine_id, name };
}


var _orderStatusHandlerCache = [];
let _orderStatusHandlerCacheTimer = false;

function orderStatusHandle(data) {
	_orderStatusHandlerCache.push(data);

	if(_orderStatusHandlerCacheTimer) {
		// console.log('debouncing');
		return
	}else {
		_orderStatusHandlerCacheTimer = true;
		setTimeout(function () {
			_orderStatusHandlerCacheTimer = false;
			// console.log('will process', _orderStatusHandlerCache);
			_process(_orderStatusHandlerCache);
			_orderStatusHandlerCache = [];
		}, 1000);
	}

	function _process(d) {
		for(let i in d) {
			let o = d[i];
			o['machine_id'] = machine_id;
			socketIns.emit('acknowledgement', o);
		}

		var payload = sat_api.prepareData.update_order_list(d, true);

		store.dispatch({
			type:'FETCH_ORDERS_FULFILLED',
			payload:payload
		})
	}

	if(data.order_state == 'Cancelled') {
		let __cancelledOrderNotiData = [...store.getState().cancelledOrderNoti.orders];
		const ifExists = __cancelledOrderNotiData.find((i)=>i.id == data.id);
		if(!ifExists) {
			store.dispatch({
				type: 'UPDATE_NEW_CANCELLED_ORDER',
				payload: {
					orders: [
						data,
						...store.getState().cancelledOrderNoti.orders
					],
					show: true
				}
			})
		}
	}
}

// Set the name of the hidden property and the change event for visibility
var hidden, visibilityChange;
if(typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
	hidden = "hidden";
	visibilityChange = "visibilitychange";
}else if (typeof document.msHidden !== "undefined") {
	hidden = "msHidden";
	visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
	hidden = "webkitHidden";
	visibilityChange = "webkitvisibilitychange";
}



// If the page is hidden, pause the video;
// if the page is shown, play the video
var focusedTime = false;
function handleVisibilityChange() {
	if(document[hidden]) {
		focusedTime = new Date().getTime();
	}else {
		let cTime = new Date().getTime();
		if((cTime - focusedTime) > (5*60*1000)) {
			store.dispatch(fetchActiveOrder())
			store.dispatch(fetchInActiveOrder())
		}
	}
}


if(typeof document.addEventListener === "undefined" || hidden === undefined) {
	console.error("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
}else {
	document.addEventListener(visibilityChange, handleVisibilityChange, false);
}
