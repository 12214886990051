import React, { useState, useEffect } from 'react';
import { ORDER_CHANNELS_DISPLAY_NAME, getChannelDisplayName } from './const';
import { commifyNumber } from '../../js/api';
import { CurrCode } from '../reactComp';

const sat_api = require('../../js/api.js');

const Revenue = (props)=>{
	const meta = props.data.meta;
	const lostOrder = props.data.cancelledOrderDist;
	const platformDiscounts = props.data.platformDiscounts;

	const avgOrderValue = (meta.netRevenue/meta.completedOrderCount).toFixed(2);
	const avgDiscountValue = (meta.discount/meta.completedOrderCount).toFixed(2);

	const avgLostOrderValue = (meta.lostRevenue/meta.cancelledOrderCount).toFixed(2);

	const widthPercentage = (total, val)=>{
		return `${(val/total)*100}`
	}
	return(
		<div className="lost-revenue-analysis analytics-section">
			<div className="ana-text-container">
				<div className="text-container secondary-text">
					<div className="revenue-count-metrics">
						<div>{sat_api.getLabel(props.lang, "revenue") || "Revenue"}:</div>
						<div className="lost-revenue-count gain"><CurrCode /> {commifyNumber(meta.netRevenue)}</div>
						<div className="small-text-row small-text">
							<div>
								{sat_api.getLabel(props.lang, "order_volume") || "Order Volume"}
							</div>
							<div>{commifyNumber(meta.completedOrderCount)}</div>
						</div>
						<div className="small-text-row small-text avg-order-vol">
							<div>{sat_api.getLabel(props.lang, "avg_order_value") || "Avg Order Value"}:</div>
							<div><CurrCode /> {commifyNumber(avgOrderValue)}</div>
						</div>
					</div>
					<div className="revenue-count-metrics">
						<div>{sat_api.getLabel(props.lang, "total_discount") || "Total discount"} :</div>
						<div className="lost-revenue-count discount"><CurrCode /> {commifyNumber(meta.discount)}</div>
						<div className="small-text-row small-text avg-order-vol" style={{
							marginTop: "1px",
						    marginBottom: "20px",
							textTransform: "uppercase"
						}}>
							{parseInt((meta.discount/meta.netRevenue || 0)*100)}% {sat_api.getLabel(props.lang, "of_total_revenue") || "Of total revenue"}
						</div>
						<div className="small-text-row small-text avg-order-vol" style={{marginBottom:"10px"}}>
							<div>
							{sat_api.getLabel(props.lang, "total_aggr_discount") || "Total Aggregator discount"}
							</div>
							<div><CurrCode /> {commifyNumber(meta.platform_discount)}</div>
						</div>
						<div style={{
							padding: "10px",
						    borderLeft: "1px solid #6a6a84",
						    marginBottom: "20px",
							paddingTop: "0.1px"
						}}>
						{
							platformDiscounts.map((i, d)=>{
								return(
									<div key={d} className="small-text-row small-text avg-order-vol">
										<div style={{color:"#6a6a84"}}>
											<span>{getChannelDisplayName(i.channel)}</span>
											<span style={{textTransform:"capitalize"}}>&nbsp;&nbsp;<CurrCode /> {commifyNumber(i.platform_discount)}</span>
										</div>
									</div>
								)
							})
						}
						</div>
						<div className="small-text-row small-text avg-order-vol">
							<div>
							{sat_api.getLabel(props.lang, "total_merch_discount") || "Total Merchant discount"}
							</div>
							<div><CurrCode /> {commifyNumber(meta.merchant_discount)}</div>
						</div>
					</div>
				</div>
				<div className="lost-revenue-chart">
					<div className="revenue-count-metrics bordered">
						<div>{sat_api.getLabel(props.lang, "lost_revenue") || "Lost Revenue"}:</div>
						<div className="lost-revenue-count lost"><CurrCode /> {commifyNumber(meta.lostRevenue)}</div>
						<div className="small-text-row avg-order-vol">
							<div>{sat_api.getLabel(props.lang, "order_volume") || "Order Volume"}</div>
							<div>{commifyNumber(meta.cancelledOrderCount)}</div>
						</div>
						<div className="small-text-row avg-order-vol">
							<div>{sat_api.getLabel(props.lang, "avg_order_value") || "Avg Order Value"}: </div>
							<div><CurrCode /> {commifyNumber(avgLostOrderValue)}</div>
						</div>
					</div>
					{
						lostOrder.map((i, d)=>{
							return(
								<div className="lost-revenue-chart-row" key={d}>
									<div className="label">{getChannelDisplayName(i.order_channel)}</div>
									<div className="bar">
										<div className="progress-bar" style={{
											width: widthPercentage(meta.lostRevenue, i.revenue)+"%"
										}}>
											<CurrCode />{commifyNumber(i.revenue)}
										</div>
									</div>
								</div>

							)
						})
					}
				</div>
			</div>
		</div>
	)
}


export default Revenue
