import React from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

const sat_api = require('../../js/api.js');

const AnaHeader = (props)=>{
	const {
		minDateRange,
		from,
		handleChangeFrom,
		to,
		handleChangeTo,
		fetch,
		selectedStore,
		stores,
		storeAnaSelect,
		lang,
	} = props;
	return(
		<div className="analytics-header">
			<div className="date-time-selector">
				<div className="from-date-picker header-section">
					<div>
						{sat_api.getLabel(lang, 'from') || 'From'}
					</div>
					<div>
						<DatePicker
							minDate={ minDateRange }
							maxDate={ new Date() }
							selected={ from }
							onChange={ handleChangeFrom }
							className="analytics-range-selector"
							showTimeSelect
							dateFormat="dd/MM, h:mm aa"
						/>
					</div>
				</div>
				<div className="to-date-picker header-section">
					<div>
						{sat_api.getLabel(lang, 'to') || 'To'}
					</div>
					<div>
						<DatePicker
							selected={ to }
							onChange={ handleChangeTo }
							showTimeSelect
							minTime={ from }
							maxTime= { moment().endOf('day').valueOf() }
							className="analytics-range-selector"
							dateFormat="dd/MM, h:mm aa"
							maxDate={ new Date() }
							minDate={ from }
						/>
					</div>
				</div>
				<div className="header-section">
					<div className="view-ana-button" onClick={ fetch }>
						{sat_api.getLabel(lang, 'view_label') || 'View'}
					</div>
				</div>
			</div>
			<div>
				<Select
					className="store-selector-analytics"
					value={ selectedStore }
					options={ stores }
					placeholder="Select a store"
					onChange={ storeAnaSelect }
					autoBlur={ true }
				/>
			</div>
		</div>
	)
}


export default AnaHeader;
