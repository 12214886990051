import React from 'react';
import { connect } from 'react-redux';
import { store } from '../store';
import { pushToPos } from '../actions';

const sat_api = require('../../js/api.js');
//--table elements start--//
@connect(store => {
	return {
		biz: store.loginReducer.data.biz,
		pushToPosState: store.pushToPosState
	};
})
export default class OrderId extends React.PureComponent {
	pushOrder(e) {
		if(this.props.pushToPosState.state === 'in-progress') {
			return;
		}
		e.stopPropagation();
		let orderData = this.props.orderData;

		store.dispatch(pushToPos(orderData))
	}

	render() {
		const { orderData, priorityClassName, ...props } = this.props;

		if (sat_api.MISC_CONFIG.CHANNEL_IMG[orderData.channel]) {
			var channel_ico = sat_api.MISC_CONFIG.CHANNEL_IMG[orderData.channel];
		} else {
			var channel_ico = 'web.svg';
		}
		var channelJSX = (function() {
			return (
				<img
					style={{ width: '20px', borderRadius: '4px' }}
					src={'/images/' + channel_ico}
				/>
			);
		})();

		if (orderData.newOrder && orderData.order_state != 'Cancelled') {
			var rippleClassName = 'new-order';
		} else if (
			orderData.order_state == 'Cancelled' ||
			orderData.order_state == 'Completed'
		) {
			var rippleClassName = 'hide-dot';
		} else {
			rippleClassName = priorityClassName;
		}
		if (orderData.merchant_ref_id) {
			var syncClass = 'synced';
		} else {
			var syncClass = '';
		}

		const posToBeDisabled = this.props.pushToPosState.state === 'in-progress'
		const showPTPBtn = !orderData.merchant_ref_id && orderData.order_state != 'Cancelled' && this.props.biz.pos_push_order_enabled

		let _order_push_style = {
			position: 'absolute',
			left: '-26px',
			width: '20px',
			top: '7px',
			opacity: posToBeDisabled ? '0.5' : '1',
			pointerEvents: posToBeDisabled ? 'none' : 'all'
		};

		return (
			<div style={{ position: 'relative' }}>
				{
					showPTPBtn ? (
					<img
						title="Push to POS"
						onClick={e => {
							this.pushOrder(e);
						}}
						style={_order_push_style}
						src="/images/exc.svg"
					/>
				) : null}
				<span className={syncClass + ' order-id-cont default-inline-container'}>
					<div>{channel_ico ? channelJSX : orderData.channel}</div>
					<div className="m-l-5">
						{orderData.ext_platform_id || orderData.merchant_ref_id || orderData.id}
					</div>
					<span className={rippleClassName + ' ripple'} />
				</span>
			</div>
		);
	}
}
