import { applyMiddleware, createStore, combineReducers} from 'redux';
import * as re from './reducer.js';

import loginReducer from '../reducers/login';
import orderTrackerNavState from '../reducers/orderTrackerNavState';
import barcodeSatus from '../reducers/barcode';
import burgerMenu from '../reducers/burgerMenu';
import audioInteraction from '../reducers/audioInteraction';
import appUpadateAvailable from '../reducers/appUpdate';
import deliveryExecInfo from '../reducers/deliveryExec';
import pushToPosState from '../reducers/pushToPosState';
import cancelledOrderNoti from '../reducers/cancelledOrderNoti';
import internetAccessStatus from '../reducers/networkError';
import bizStoresReducer from '../reducers/bizStores';
import printManagerReducer from '../reducers/printManager';

import thunk from 'redux-thunk';


const reducer = combineReducers({
	orderList: re.orderListReducer,
	orderDetail: re.orderDetailReducer,
	cRoute: re.cRouteReducer,
	orderStateReducer: re.orderStateReducer,
	gReducer: re.gReducer,
	loginReducer: loginReducer,
	callReducer: re.callReducer,
	currentOrderPhoneno: re.currentOrderPhoneno,
	currentOrderSession: re.currentOrderSession,
	deliveryFeasibility: re.deliveryFeasibility,
	selectedComplexItem: re.selectedComplexItem,
	cartVisibility: re.cartVisibility,
	placingOrder: re.placingOrder,
	recommendedItems: re.recommendedItems,
	preprocessedData: re.preprocessedData,
	storeSelection: re.storeSelection,
	usersInUrl: re.usersInUrl,
	userSync: re.userSync,
	itemSearchStatus: re.itemSearchStatus,
	deliveryDatePicker: re.deliveryDatePicker,
	reqCallBackReducer: re.reqCallBackReducer,
	activeOrderDetail: re.activeOrderDetail,
	inventory: re.inventory,
	inventorySearch: re.inventorySearch,
	outletLogistics: re.outletLogistics,
	surgeDataState: re.surgeDataState,
	outletPlatforms: re.outletPlatforms,
	activeSurgesArr: re.activeSurgesArr,
	displayCrossPromotionEnhancement: re.displayCrossPromotionEnhancement,
	appSettings: re.appSettings,
	outlettakeAwayStatus: re.outlettakeAwayStatus,
	bizInfo: re.bizInfo,
	disablePushOrder: re.disablePushOrder,
	appLanguage: re.appLanguage,
	allOrderList: re.allOrderList,
	orderTrackerNavState: orderTrackerNavState,
	barcodeSatus: barcodeSatus,
	burgerMenu: burgerMenu,
	audioInteraction: audioInteraction,
	appUpadateAvailable: appUpadateAvailable,
	deliveryExecInfo: deliveryExecInfo,
	pushToPosState: pushToPosState,
	cancelledOrderNoti: cancelledOrderNoti,
	internetAccessStatus: internetAccessStatus,
	bizStoresReducer: bizStoresReducer,
	printManager: printManagerReducer,
})

const rootReducer = (state, action) => {
	if(action.type === 'LOGOUT') {
		state = undefined;
	}
	return reducer(state, action);
}

const middleWare = applyMiddleware(thunk)
export const store = createStore(rootReducer, middleWare);

window.store = store;
