const internetAccessStatus = function(state= {show:false}, action){
	switch (action.type) {
		case "HIDE_CONECTIVITY":
			return {
                ...state,
                show:false,
                message: ""
            }
		case "SHOW_CONECTIVITY":
			return {
                ...state,
                show:true,
                message: "No internet connection"
            }
        case "SHOW_CONECTIVITY_SOCKET":
			return {
                ...state,
                show:true,
                message: "Failed to fetch orders real-time."
            }
		default:
			return state
	}
}


export default internetAccessStatus
