import { AXIOS_CONFIG } from '../api';

const ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_URL;


const getHeader = ()=>{
	return {
		"Content-Type": "application/json",
		"Authorization": AXIOS_CONFIG.headers.Authorization
	}
}

export const getOrderHistoricalData = async({from, to})=>{
	const resp = await fetch(`${ANALYTICS_URL}/orders?from=${from}&to=${to}`, {
		headers: getHeader(),
		mode: 'cors'
	});
	const respJson = await resp.json();
	return respJson;
}

export const getBizSumaryForDay = async({from, to, store_id})=>{
	const resp = await fetch(`${ANALYTICS_URL}/orderSummary?from=${from}&to=${to}&store_id=${store_id}`, {
		headers: getHeader(),
		mode: 'cors'
	});

	const respJson = await resp.json();
	return respJson;
}

export const getSankeyData = async({from, to, store_id})=>{
	const resp = await fetch(`${ANALYTICS_URL}/sankey?from=${from}&to=${to}&store_id=${store_id}`, {
		headers: getHeader()
	});

	const respJson = await resp.json();
	return respJson;
}

export const getLocationData = async({ from, to })=>{
	const resp = await fetch(`${ANALYTICS_URL}/stores?from=${from}&to=${to}`, {
		headers: getHeader()
	});

	const respJson = await resp.json();
	return respJson;
}
