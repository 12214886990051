import React from 'react';
import { connect } from 'react-redux';
import { store } from '../store';
import { pushToPos } from '../actions';

@connect((store)=>{
	return {
		pushToPosState: store.pushToPosState
	}
})

export default class PushToPosStatus extends React.PureComponent {

	closeDialog = ()=>{
		store.dispatch({
			type: "UPDATE_PTP_STATE",
			payload: {
				show: false
			}
		})
	}

	retry = ()=>{
		store.dispatch(pushToPos({
			id: this.props.pushToPosState.order_id
		}));
	}

	render() {
		if(this.props.pushToPosState.show === false)  {
			return null
		}

		return(
			<div className="push-to-pos-status--container">
				<div className={this.props.pushToPosState.state + " header"}>
					{this.props.pushToPosState.message}
					<div className="cross" onClick={this.closeDialog}><img src="/images/cross.png" /></div>
				</div>
				<div className="body">
					<div className="order-id">ID: {this.props.pushToPosState.order_id}</div>
					{
						this.props.pushToPosState.state == 'failed' ?
						<React.Fragment>
							<div className="retry-push-to-pos" onClick={this.retry}>Retry</div>
							<div style={{padding:"10px", color:"red"}}>{this.props.pushToPosState.error_message}</div>
						</React.Fragment>
						:
						null
					}
				</div>

			</div>
		)
	}
}
