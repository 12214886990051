export const deliveryExecInfo = (state = {
	delivery_agents:[],
	loading: false
}, action)=>{
	switch (action.type) {
		case "SET_DELIVERY_EXEC_INFO":
			return {
				...action.payload
			}
			break;
		case "UPDATE_DELIVERY_EXEC_INFO_ORDER_INIT":
			return {
				...state,
				loading: true
			}
			break;
		case "UPDATE_DELIVERY_EXEC_INFO_ORDER_DONE":
			return {
				...state,
				loading: false
			}
		default:
			return state;

	}
}

export default deliveryExecInfo
