import React from 'react';

const OrderStatus = props => {
	const { orderState } = props;
	return (
		<div>
			<span
				className={
					orderState && orderState.toLowerCase() + ' order-status-indicator'
				}
			>
				{orderState || '--'}
			</span>
		</div>
	);
};


export default OrderStatus;
