import React, {Component} from 'react';
import { connect } from 'react-redux';
import { store } from './store';
import { Link } from 'react-router-dom';
import { logoutAction, fetchAllOrder } from './actions';
import history from './history';
import Moment from './clientTime';
import { PreLoaderBg, RefreshList } from './reactComp';
import Calendar from 'react-calendar'
import _ from 'lodash';
import ReactToolTip from 'react-tooltip';

const sat_api =  require('../js/api.js');


@connect((store)=>{
	return {
		orderList: store.orderList,
		orderTrackerNavState: store.orderTrackerNavState,
		allOrderList: store.allOrderList,
		cRoute: store.cRoute,
		appLanguage: store.appLanguage
	}
})
export class AppHeaderTracker extends React.PureComponent {
	onChangeDate= (d)=> {
		// console.log(d)
		const _date = this.props.allOrderList.dateFilter;

		let noCache = false;
		if(_date > new Date().getTime()) {
			noCache = true
		}

		store.dispatch(fetchAllOrder(new Date(d).getTime(), null, noCache))
	}

	showHideCalender(e) {
		e.stopPropagation()
		store.dispatch({
			type: "ALL_ORDER_FETCH_SHOW_CAL",
			payload: null
		})
	}

	handleBurgerMenu() {
		store.dispatch({
			type:"TOGGLE_BURGER_MENU",
			payload: true
		})
	}


	render() {
		return null;
		const widget = this.props.widget;
		const lang = this.props.appLanguage.lang;
		const statusMap = [];
		const orderData = this.props.orderList.data;
		const status = this.props.orderTrackerNavState.active_tab;
        for(let x in orderData) {
            if(x == 'placed') {
                statusMap[0] = {
                    key: sat_api.getLabel(lang, "placed") || "Placed",
                    count: orderData[x].orders.length,
                    path: 'placed',
                    selected: (status == 'placed')
                }
            }

            if(x == 'other') {
                statusMap[1] = {
                    key: sat_api.getLabel(lang, "in_progress") || "In progress",
                    count: orderData[x].orders.length,
                    path: 'in-progress',
                    selected: (status == 'in-progress')
                }
            }

            if(x == 'completed') {
                statusMap[2] = {
                    key: sat_api.getLabel(lang, "completed") || "Completed",
                    count: orderData[x].orders.length,
                    path: 'completed',
                    selected: (status == 'completed')
                }
            }

            if(x == 'cancelled') {
                statusMap[3] = {
                    key: sat_api.getLabel(lang, "cancelled") || "Cancelled",
                    count: orderData[x].orders.length,
                    path: 'cancelled',
                    selected: (status == 'cancelled')
                }
            }

            if(x == 'future') {
                statusMap[4] = {
                    key: sat_api.getLabel(lang, "future_order") || "Future",
                    count: orderData[x].orders.length,
                    path: 'future',
                    selected: (status == 'future')
                }
            }
		}
		const widgetStyles = (widget && localStorage.getItem("widgetStyles")) ? JSON.parse(localStorage.getItem("widgetStyles") || "{}") : {}


		return(
			<div className={(widget ? "app-header--widget": "") + " app-header"} style={widgetStyles}>
				<div
				onClick={this.handleBurgerMenu}
				className="burger-menu--header">
					<img src="/images/mobile/burger.svg" />
				</div>
				<OrderListHeader
					widget={widget}
					statusMap={statusMap}
					orderTrackerNavState={this.props.orderTrackerNavState}
					cRoute={this.props.cRoute}
					allOrderList={this.props.allOrderList}
					showHideCalender={this.showHideCalender}
					widgetStyles={widgetStyles}
					lang={lang}
				/>
				{
					this.props.allOrderList.showCalender ?
					<div className="calender--holder" onClick={(e)=>e.stopPropagation()}>
						<Calendar
							onChange={this.onChangeDate}
							value={new Date(this.props.allOrderList.dateFilter)}
							locale={lang}
						/>
					</div>
					:
					null
				}
			</div>
		)
	}
}


class OrderListHeader extends React.PureComponent {
    constructor(props) {
        super(props);
		this.state = {
			kw: ""
		}
		this.searchInputRef = React.createRef();
    }


	dayNavHandler = (type)=>{
		let _date = this.props.allOrderList.dateFilter + 86400000;

		if(type == 'prev') {
			_date = this.props.allOrderList.dateFilter - 86400000;
		}


		let noCache = false;
		if(_date > new Date().getTime()) {
			noCache = true
		}
		store.dispatch(fetchAllOrder(_date, null, noCache))
	}


	liveSearchDebounced = _.debounce((kw, filter)=>{
		if(kw) {
			const searchResult = sat_api.clinetSearchOrder({
				filter,
				kw
			});

			store.dispatch({
				type: "UPDATE_NAV_STATE",
				payload: {
					client_search_result: searchResult,
					show_live_search: true,
					live_search_kw: kw
				}
			})
		}
	}, 250);

	liveSearch = (k)=>{
		const kw = k.target.value;
		this.setState({kw})
		let filter = this.props.orderTrackerNavState.search_filter;
		this.liveSearchDebounced(kw, filter)
	}

	searchPure = async (kw)=>{
		let searchString = `${this.props.orderTrackerNavState.search_filter}:${kw}`
		store.dispatch({
			type:"UPDATE_NAV_STATE",
			payload: {
				loading: true,
				show_live_search: false
			}
		})

		try {
			const resp = await sat_api.searchOrder({ filter:searchString });


			store.dispatch({
				type:"UPDATE_NAV_STATE",
				payload: {
					search_keyword: kw,
					search_results: resp.data.orders,
					loading: false
				}
			})
		}catch(err) {
			console.error(err);
			store.dispatch({
				type:"UPDATE_NAV_STATE",
				payload: {
					loading: false
				}
			})

			alert('Sorry, failed to search!')
		}
	}
	search = (k)=> {
		if(k.key == 'Enter' && k.target.value) {
			let kw = k.target.value;
			this.searchPure(kw)
		}
	}




	showSearchFilters = (e)=> {
		e.stopPropagation()
		store.dispatch({
			type: "UPDATE_NAV_STATE",
			payload: {
				show_filters: !this.props.orderTrackerNavState.show_filters,
				show_live_search: false
			}
		})
	}

	resetSearch =()=>{
		store.dispatch({
			type: "UPDATE_NAV_STATE",
			payload: {
				search_keyword: "",
				search_results: [],
				show_live_search: false
			}
		})

		this.setState({
			kw: ""
		})
	}

	searchPastHandler = ()=>{
		this.searchPure(this.searchInputRef.current.value)
	}

	render() {
        const  {
            statusMap,
			orderTrackerNavState,
			cRoute,
			allOrderList,
			showHideCalender,
			widget,
			widgetStyles
		} = this.props
		return(
			<React.Fragment>
				<div className={" margin-auto order-list-header" } style={widgetStyles}>
					{
						orderTrackerNavState.search_keyword ?
						<SearchResultHeader
						 	filter={orderTrackerNavState.search_filter}
							clearSearch={this.resetSearch}
							kw={orderTrackerNavState.search_keyword}
						/>
						:
						<TabNavigation
							widget={this.props.widget}
							dayNavHandler={this.dayNavHandler}
							statusMap={statusMap}
							cRoute={cRoute}
							allOrderList={allOrderList}
							showHideCalender={showHideCalender}
							widgetStyles={widgetStyles}
						/>
					}
					{
						cRoute && cRoute.page == 'active-tracker' || cRoute.page == 'past-order' ?
						<div className={(this.props.orderTrackerNavState.search_keyword ? "transform-left" : "") +" right-nodes"}>
		                    <div className="order-list-search">
								<div className={(this.props.orderTrackerNavState.show_filters ? "active" : "")+" active-search-filter"} onClick={(e)=>this.showSearchFilters(e)}>
									{getDisplayNameFromFilter(orderTrackerNavState.search_filter)}
								</div>
								{
									orderTrackerNavState.show_filters ?
									<div className="search-filters">
										<AvailableSearchFilter active_filter={orderTrackerNavState.search_filter}/>
									</div>
									:
									null
								}
								{
									orderTrackerNavState.show_live_search ?
									<div className="live-search-result">
										<ClientSideSearchResult searchPastHandler={this.searchPastHandler}/>
									</div>
									:
									null
								}
		                        <input
									ref={this.searchInputRef}
									value={this.state.kw}
									onChange={this.liveSearch}
									onKeyPress={this.search}
		                            type="text"
		                            placeholder={sat_api.getLabel(this.props.lang, "search_orders") || "Search Orders"}
		                        ></input>
								{
									orderTrackerNavState.search_keyword ||  orderTrackerNavState.show_live_search?
									<ResetSearch resetSearch={this.resetSearch}/>
									:
									null
								}
								{
									orderTrackerNavState.loading ?
									<SearchSpinner/>
									:
									null
								}
		                    </div>
							<div className="order-list-refresh">
		                        <RefreshList/>
		                    </div>
		                </div>
						:
						null
					}
				</div>
			</React.Fragment>
		)
	}
}





const BizLogo = (props)=>{
	return(
		<img src={props.data.logo}></img>
	)
}


@connect((store)=>{
	return{
		appLanguage: store.appLanguage,
		loginReducer: store.loginReducer
	}
})
class UserInfo extends React.PureComponent{
	logout() {
		store.dispatch(logoutAction());
		history.push('/login')
    }

	render(){
		let last_login_str = sat_api.getLabel(this.props.appLanguage.lang, "last_login") || "Last login";
		let logout_str = sat_api.getLabel(this.props.appLanguage.lang, "logout") || "Logout";
		const multiBiz = (this.props.loginReducer && this.props.loginReducer.data ?  this.props.loginReducer.data.multi_biz : false);
		const bizs = this.props.loginReducer.data && this.props.loginReducer.data.bizs ? this.props.loginReducer.data.bizs : [];
		return (
			<div className="app-header--right">
				<div className="user-info-content">
					<div>
						{this.props.loginReducer.data.name}
					</div>
					<div className="last-login">
						{multiBiz && '[Multi-biz User]'}
					</div>
					<div className="last-login">
						{this.props.loginReducer.data.biz.name}
					</div>
					{
						multiBiz &&
						<div data-tip data-for="multi-biz">
							+{bizs.length - 1} More
						</div>
					}
					<ReactToolTip
						id="multi-biz"
						effect="solid"
						type="info"
					>
						{
							bizs.map((biz) => <div key={biz.biz_id}>{biz.name}</div>)
						}
					</ReactToolTip>
					<div className="last-login" style={{fontSize:"9.6px"}}>
						{last_login_str} - { Moment.duration(new Date().getTime() - this.props.loginReducer.data.last_login).humanize() }
					</div>
				</div>
				<div>
					<div onClick={this.logout.bind(this)} className="logout-button">
						{logout_str}
					</div>
				</div>
			</div>
		);
	}
}




@connect((store) => {
	return {
		route: store.cRoute,
		orderData: store.orderList.data,
		loginReducer: store.loginReducer,
		appLanguage: store.appLanguage,
		burgerMenu: store.burgerMenu
	}
})
export class SideBar extends React.PureComponent{
	constructor(props){
		super(props);
	}

	menuToggle(){
		store.dispatch({
			type: "TOGGLE_BURGER_MENU",
			payload: !this.props.burgerMenu.open
		});
	}

	render(){
        const lang = this.props.appLanguage.lang;
        const route =  this.props.route.page;

		var orderBuilderPermission = (this.props.loginReducer && this.props.loginReducer.data ?  this.props.loginReducer.data.permissions.indexOf('satellite_order_builder') > -1 : false);
		const multiBiz = (this.props.loginReducer && this.props.loginReducer.data ?  this.props.loginReducer.data.multi_biz : false);
		if(orderBuilderPermission && !multiBiz){
			var startOrder = (function(){
				return(
					<div className="nav-link-header">
						<Link to="/start-order"  className={ route == 'start-order' ?  "active" : null }>
							<span>{sat_api.getLabel(lang, 'start_order') || "Start Order"}</span>
						</Link>
					</div>
				)
			})()
		}else{
			var startOrder =  null
		}

		var inventoryPermission = (this.props.loginReducer && this.props.loginReducer.data ?  this.props.loginReducer.data.permissions.indexOf('satellite_inventory_control') > -1 : false)

		if(inventoryPermission){
			var inventory = (()=>{
				return(
					<div className="nav-link-header">
						<Link to="/inventory"  className={route == 'inventory' ?  "active" : null}>
							<span>{sat_api.getLabel(lang, 'inventory') || "Inventory"}</span>
						</Link>
					</div>
				)
			})()
		}else{
			var inventory = null;
		}

		var gearImg = function(){
			return(
				<img style={{"height":" 20px","position":" absolute", "left": "60px", "top": "13px"}} src="/images/gear.svg" />
			)
		}
		return(
			<div>
				<div className={"burger-overlay " + (this.props.burgerMenu.open ? "active" : "")} onClick={()=>this.menuToggle()}></div>
				<div className={(this.props.burgerMenu.open ? "active" : "")+" sidebar no-scrollbar"}>
                    {/* <div className="nav-link-header-nested-container">
                        <div className="nav-link-header">
                            <Link to="/placed" className={(route == 'active-tracker' || route == 'past-order') ?  "active" : null}>{sat_api.getLabel(lang, 'order_tracker') || "Order Tracker"}</Link>
                        </div>
                        <div className="nav-link">
                            <Link to="/placed"  className={(route == 'active-tracker' ? "active" : null) +" linkComp"}>
															{sat_api.getLabel(lang, 'active_orders') || "Active Orders"}
                            </Link>
                            <Link to="/past-order"  className={(route == 'past-order' ? "active" : null) +" linkComp"}>
															{sat_api.getLabel(lang, 'past_orders') || "Past Orders"}
                            </Link>
                        </div>
                    </div> */}
					{ startOrder }
					{/* { inventory } */}
					{/* {
						!multiBiz &&
						<div className="nav-link-header">
							<Link to="/analytics" className={(route == 'analytics' ? "active" : null) +" small settings-page-nav"}>
								{sat_api.getLabel(lang, 'analytics') || "Analytics"}
							</Link>
						</div>
					} */}
					{/* <div className="nav-link-header">
						<Link to="/settings" className={(route == 'settings' ? "active" : null) +" small settings-page-nav"}>
							{sat_api.getLabel(lang, 'settings') || "Settings"}
						</Link>
					</div> */}
					<div style={{borderTop:"1px solid #294252"}}></div>
					<div className="nav-link-header">
						<UserInfo />
					</div>
					<div className="version-no">
						<Link to="/change-log">Ver. {sat_api.VERSION_NO} {sat_api.VERSION_BUILD}</Link>
					</div>
				</div>
			</div>
		)
	}
}


const OrderCounter = (props)=>{
	if(props.count !==null && props.count > 0){
		return(
			<div className="counter-in-sidebar">{props.count}</div>
		)
	}else {
		return null
	}
}



export const GeoyLoader = ()=>{
	return(
		<div class="gooey">
			<span class="dot"></span>
			<div class="dots">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	)
}

export const FixedTableLoader = (props)=>{
	return(
		<div style={{border:"1px solid #ddd"}}>
			<PreLoaderBg no={5} styleClass={["fixed-table-loader", "m-b-5"]} />
		</div>
	)
}


const SearchResultHeader = (props)=>{
	if(!props.kw){
		return null
	}

	return(
		<div className="search-result-in-header">
			<div>Search result(s) for {getDisplayNameFromFilter(props.filter)}: {props.kw}</div>
			<div onClick={props.clearSearch} className="clear-search">Clear</div>
		</div>
	)
}


class AvailableSearchFilter extends React.Component {
	getDisplayableFilters() {
		let active_filter = this.props.active_filter;
		let allFilters = ['pid','id', 'ref', 'phone','name','store'];
		return allFilters.filter((i)=>i != active_filter)
	}
	handleActiveFilter(i) {
		store.dispatch({
			type:"UPDATE_NAV_STATE",
			payload: {
				search_filter:i,
				show_filters:false,
				show_live_search: false
			}
		})
	}
	render() {
		let filters = this.getDisplayableFilters();
		let nodes = filters.map((i)=>{return(<div onClick={()=>this.handleActiveFilter(i)} key={i}>{getDisplayNameFromFilter(i)}</div>)});
		return(
			<React.Fragment>
				{nodes}
			</React.Fragment>
		)
	}
}



@connect((store)=>{
	return{
		orderTrackerNavState: store.orderTrackerNavState
	}
})
class ClientSideSearchResult extends React.Component {
	openModal = (id)=>{
		store.dispatch({
			type:'SHOW_HIDE_ORDER_DETAIL_MODAL',
			payload: parseInt(id)
		});
	}

	getMatchingText = (content, match)=>{
		const _content = content.toString();
		const _match = match.toString();

		const res = _content.replace(_match, `<span class="highlight">${_match}</span>`);
		return res;
	}
	render() {
		const sr = this.props.orderTrackerNavState.client_search_result;
		const sr_filter =  this.props.orderTrackerNavState.search_filter;
		const kw = this.props.orderTrackerNavState.live_search_kw;

		if(sr.length > 0) {
			const nodes = this.props.orderTrackerNavState.client_search_result.map((i)=>{

				if(sat_api.MISC_CONFIG.CHANNEL_IMG[i.channel]){
					var channel_ico = sat_api.MISC_CONFIG.CHANNEL_IMG[i.channel]
				}else{
					var channel_ico = "web.svg";
				}

				let __cont = i.id;
				if(sr_filter == 'pid') {
					__cont = i.ext_platform_id || i.merchant_ref_id
				}

				if(sr_filter == 'ref') {
					__cont = i.merchant_ref_id
				}

				return (
					<div
					 	onClick={()=>this.openModal(i.id)}
						className="search-result-block" key={i.id}>
						<div className="order-id-block">
							<div>
								<img style={{'width':'20px', 'borderRadius':'4px', marginRight:"10px"}} src={"/images/"+channel_ico} />
							</div>
							<div>
							<div dangerouslySetInnerHTML={{__html:this.getMatchingText(__cont, kw)}}></div>
							</div>
						</div>
						<div className={i.order_state+" order-status"}>{i.order_state}</div>
					</div>
				)
			})
			return(
				<React.Fragment>
					{nodes}
					<div
					onClick={this.props.searchPastHandler}
					className="see-more-search-results">Search in past orders</div>
				</React.Fragment>
			)
		}else {
			return(
				<div className="no-result-text">
					Hit enter to continue
				</div>
			)
		}
	}
}

function getDisplayNameFromFilter(filter) {
	const lang = store.getState().appLanguage.lang;
	switch (filter) {
		case 'id':
			return sat_api.getLabel(lang, "upiper_id") || "UPiper ID";
		case 'pid':
			return sat_api.getLabel(lang, "platform_id") || "Platform ID";
		case 'phone':
			return sat_api.getLabel(lang, "phone") || "Phone";
		case 'name':
			return sat_api.getLabel(lang, "name") || "Name";
		case 'ref':
			return sat_api.getLabel(lang, "pos_id") || "POS ID";
		case 'store':
			return sat_api.getLabel(lang, "store") || "Store";
		default:
			return sat_api.getLabel(lang, "order_id") || "Order ID";

	}
}

const ResetSearch = (props)=>{
	return(
		<div
		onClick={()=>props.resetSearch()}
		className="reset-search"><img src="/images/cancel.png" /></div>
	)
}

const SearchSpinner = (props)=>{
	return(
		<div className="reset-search"><img className="rotate-infinite" src="/images/half-circle.png" /></div>
	)
}

const TabNavigation = (props) => {
	const _nodes = props.statusMap.map((i,d)=>{
		const link = props.widget ?  `/w/${i.path}` : `/${i.path}`;
		const { widgetStyles } = props;
		return(
			<Link to={link} key={d} className={ i.path+ " default-inline-container tab-navigation"+ (i.selected ? " selected" : "")}>
				<div style={widgetStyles}>{i.key}</div>
				<div className="counter-container" style={widgetStyles}>
					<span className="counter-ui">{i.count}</span>
				</div>
			</Link>
		)
	})

	if(props.cRoute.page == 'past-order') {
		return (
			<div className={(props.allOrderList.loading ? "disabled" :"") +" tab-navigation-container tab-navigation-container--date-filter"}>
				<div className="date-nav" onClick={()=>props.dayNavHandler('prev')}>&larr; </div>
				<div onClick={(e)=>props.showHideCalender(e)} className="calender-opener">
					{Moment(props.allOrderList.dateFilter).format('Do MMM, YY')}
				</div>
				<div className="date-nav" onClick={()=>props.dayNavHandler('next')}> &rarr;</div>
 		   </div>
		);
	} else {
		return (
			<div className="tab-navigation-container">
 			  {_nodes}
 		  </div>
		);
	}
}

const closeNewCancelledDailog = ()=>{
	store.dispatch({
		type: "UPDATE_NEW_CANCELLED_ORDER",
		payload: {
			show: false
		}
	})
}


const openCancelledOrer = (id)=>{
	const list = store.getState().cancelledOrderNoti.orders;
	_.remove(list, (i)=>i.id == id);
	store.dispatch({
		type: "UPDATE_NEW_CANCELLED_ORDER",
		payload: {
			orders: list
		}
	})

	store.dispatch({
		type: 'SHOW_HIDE_ORDER_DETAIL_MODAL',
		payload: id
	});

	const cRoute = store.getState().cRoute.page;
	if(cRoute != 'active-tracker') {
		history.push('/placed');
	}
}


const _CancelledOrdersList = (props)=>{
	const count = props.cancelledOrderNoti.orders.length;
	const visible = props.cancelledOrderNoti.show;

	const show = (count != 0 && visible === true);

	const nodes = props.cancelledOrderNoti.orders.map((i, d)=>{
		let channel_ico = "web.svg";

		if(sat_api.MISC_CONFIG.CHANNEL_IMG[i.channel]){
			channel_ico = sat_api.MISC_CONFIG.CHANNEL_IMG[i.channel]
		}

		return(
			<div key={d} className="cancelled-order-row" onClick={()=>{openCancelledOrer(i.id||i.order_id)}}>
				<div>
					<img style={{'width':'20px', 'borderRadius':'4px', marginRight:"10px"}} src={"/images/"+channel_ico} />
				</div>
				<div>
					{i.id || i.order_id}
				</div>
			</div>
		)
	})
	return(
		<div className={(show ? "visible" : "") + " cancelled-order-list"}>
			<div className="header">
				<div className="text">
					Order Cancelled <span className="cancelled-order-count">{count}</span>
					<div className="img close" onClick={closeNewCancelledDailog}>
						<img src="/images/cancel.png" />
					</div>
				</div>
			</div>
			<div className="node-container">
				{nodes}
			</div>
		</div>
	)
}

export const CancelledOrdersList = connect(
  store => ({
    cancelledOrderNoti: store.cancelledOrderNoti
  })
)(_CancelledOrdersList);
