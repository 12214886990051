String.prototype.capitalize = function(){
    return this.replace( /(^|\s)([a-z])/g , function(m,p1,p2){ return p1+p2.toUpperCase(); } );
};

String.prototype.padStart = function padStart(targetLength,padString) {
    targetLength = targetLength >> 0; //truncate if number or convert non-number to 0;
    padString = String((typeof padString !== 'undefined' ? padString : ' '));
    if (this.length > targetLength) {
        return String(this);
    }
    else {
        targetLength = targetLength-this.length;
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
        }
        return padString.slice(0,targetLength) + String(this);
    }
};

String.prototype.padEnd = function padEnd(targetLength,padString) {
    targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
    padString = String((typeof padString !== 'undefined' ? padString : ' '));
    if (this.length > targetLength) {
        return String(this);
    }
    else {
        targetLength = targetLength-this.length;
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
        }
        return String(this) + padString.slice(0,targetLength);
    }
};

const MONTHS = [
    'Jan', "Feb", 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const SunmiPrinterData = {
    // Centering, row creation all are based on this length
    ROW_LENGTH: 32,
    seperator: function(str){
        return ''.padEnd(SunmiPrinterData.ROW_LENGTH, '-') + '\n';
    },
    centerAlign: function(str){
        str = str + '';
        if(str.length > SunmiPrinterData.ROW_LENGTH) return str;
        let padStartAmt = (SunmiPrinterData.ROW_LENGTH - str.length)/2;
        return ''.padStart(padStartAmt) + str + '\n';
    },
    generateRow: function(leftString, rightString) {
        leftString = leftString + "  ";
        rightString = " " + rightString;
        
        let divideRatio = leftString.length/(leftString.length + rightString.length);
        let leftColLen = parseInt(divideRatio * SunmiPrinterData.ROW_LENGTH);
        let rightColLen = SunmiPrinterData.ROW_LENGTH - leftColLen;
        if(leftString.length <= leftColLen){
            var leftColArr = [ leftString ];
        }else{
            let trimedLeftString = leftString.trim();
            var leftColArr = trimedLeftString.match(new RegExp(`.{1,${leftColLen - 2}}`, 'g'));
        }
        if(rightString.length <= rightColLen){
            var rightColArr = [ rightString ];
        }else{
            let trimedRightString = rightString.trim();
            var rightColArr = trimedRightString.match(new RegExp(`.{1,${rightColLen - 2}}`, 'g'));
        }
        let rowString = ``;
        for(let i=0; i<leftColArr.length || i<rightColArr.length; i++){
            let tempLeftString = leftColArr[i] || '', tempRightString = rightColArr[i] || '';
            rowString += `${tempLeftString.padEnd(leftColLen)}${tempRightString.padStart(rightColLen)}\n`;
        }
        return rowString;
    }
}




export default ()=>{
    let textToPrint = `\n\n`;
    let { biz_data, order_data, print_order_slip, deliveryTimeText, print_kot } = window.dataForSunmiPrinterData;

    let orderInfo = order_data.data.order;
    let orderDetails = orderInfo.details;
    let delivery_time = new Date(orderDetails.delivery_datetime);
    let formattedDTime = delivery_time.getHours() + ':' + (delivery_time.getMinutes() + '').padStart(2, '0');
    let customerData = order_data.data.customer;

    let {gst, tin} = biz_data;

    let pm = {
		cash:'Cash',
		payment_gateway:'Online payment',
		prepaid: 'Wallet',
		aggregator: orderDetails.channel
	}

    let paymentStr = (()=>{
		if(orderInfo.payment[0].option == 'cash') {
			return (
				"PAID"
			)
		}else {
			return null
		}

	})()

    // let ext_channel_id = _.find(orderDetails.ext_platforms,{name:orderDetails.channel})

    if(print_order_slip) {
        textToPrint += SunmiPrinterData.centerAlign("This is not a bill");
        textToPrint += SunmiPrinterData.seperator();



        textToPrint += SunmiPrinterData.generateRow('Order ID:', orderDetails.id);
        textToPrint += SunmiPrinterData.generateRow('Payment:', (paymentStr|| "")+" "+orderInfo.payment[0].option.split('_').join(' ').capitalize());
        textToPrint += SunmiPrinterData.generateRow('Channel:', orderDetails.channel.capitalize());

        textToPrint += SunmiPrinterData.generateRow("Order Type:", orderDetails.order_type.capitalize());
        textToPrint += SunmiPrinterData.generateRow(orderDetails.order_type+" Time:", deliveryTimeText);
        textToPrint += SunmiPrinterData.seperator();
        textToPrint += SunmiPrinterData.generateRow("Cust. Name:", customerData.name);
        textToPrint += SunmiPrinterData.generateRow("Cust. Phone:", customerData.phone);
        if(orderDetails.order_type != 'pickup'){
            let addressArr = [], address = customerData.address;
            address.line_1 && addressArr.push(address.line_1);
            address.line_2 && addressArr.push(address.line_2);
            address.sub_locality && addressArr.push(address.sub_locality);
            address.city && addressArr.push(address.city);
            address.pin && addressArr.push(address.pin);
            textToPrint += `${address.tag ? (address.tag + '\n') : ''}${addressArr.join(', ')}\n`;
        }else{
            textToPrint +=
                `${orderInfo.store.name}\n${orderInfo.store.address}\n`
        }
        textToPrint += SunmiPrinterData.seperator();
        textToPrint += SunmiPrinterData.seperator();

        for(let x in orderInfo.items) {
            let item = orderInfo.items[x];
            let itemName = `${item.title}(${item.quantity})`;
            let price = item.total;

            textToPrint += `${SunmiPrinterData.generateRow(itemName, price)}\n`;
            let optionsToAdd = item.options_to_add;

            if(optionsToAdd && optionsToAdd.length > 0) {
                textToPrint += 'Options:\n';
                for(let o in optionsToAdd) {
                    let optionName = optionsToAdd[o].title;
                    let optionPrice = optionsToAdd[o].price;
                    textToPrint += `${SunmiPrinterData.generateRow(optionName, optionPrice)}\n`;
                }
                textToPrint += '\n';
            }
        }

        textToPrint += SunmiPrinterData.seperator();

        // Order Instructions if any
        if(orderDetails.instructions){
            textToPrint += 'Order Instructions:\n';
            textToPrint += `${orderDetails.instructions || "--"}\n`;
        }

        textToPrint += SunmiPrinterData.seperator();

        textToPrint += SunmiPrinterData.generateRow('Sub total', orderDetails.order_subtotal);
        if(orderDetails.discount) textToPrint += SunmiPrinterData.generateRow('Discount', orderDetails.discount);
        if(orderDetails.coupon) textToPrint += SunmiPrinterData.generateRow('Coupon', orderDetails.coupon);

        textToPrint += SunmiPrinterData.generateRow('Total taxes', orderDetails.item_level_total_taxes + orderDetails.order_level_total_taxes);
        textToPrint += SunmiPrinterData.generateRow('Total charges', orderDetails.item_level_total_charges + orderDetails.order_level_total_charges);
        textToPrint += SunmiPrinterData.generateRow('Order total', orderDetails.order_total);

        let wallet_credit_applied = orderInfo.payment.find(function(o){return o.option == 'wallet_credit'});
        if(wallet_credit_applied) textToPrint += SunmiPrinterData.generateRow('Credit applied', wallet_credit_applied.amount);
        if(wallet_credit_applied) textToPrint += SunmiPrinterData.generateRow('Payable amount', orderDetails.order_total - wallet_credit_applied.amount);

        textToPrint += SunmiPrinterData.seperator();
        textToPrint += SunmiPrinterData.centerAlign(orderInfo.store.name);
        textToPrint += SunmiPrinterData.centerAlign(orderInfo.store.phone || "");
        textToPrint += '\n\n\n'
    }else {
        // Delivery Info
        textToPrint += SunmiPrinterData.generateRow(orderDetails.order_type.toUpperCase(), formattedDTime);
        textToPrint += SunmiPrinterData.seperator();
        // Address Info


        if(orderDetails.order_type != 'pickup'){
            let addressArr = [], address = customerData.address;
            address.line_1 && addressArr.push(address.line_1);
            address.line_2 && addressArr.push(address.line_2);
            address.sub_locality && addressArr.push(address.sub_locality);
            address.city && addressArr.push(address.city);
            address.pin && addressArr.push(address.pin);
            textToPrint += `${address.tag ? (address.tag + '\n') : ''}`+`${addressArr.join(', ')}\n`;
        }else{
            textToPrint +=
                `${orderInfo.store.name}\n${orderInfo.store.address}\n`
        }
        textToPrint += SunmiPrinterData.seperator();
        // Biz name
        textToPrint += SunmiPrinterData.centerAlign(biz_data.name.toUpperCase());
        textToPrint += SunmiPrinterData.seperator();

        // Biz tax and gst info

        if(gst) textToPrint += SunmiPrinterData.generateRow('GST No.', gst);
        if(tin) textToPrint += SunmiPrinterData.generateRow('TIN No.', tin);

        // Basic order info

        textToPrint += SunmiPrinterData.generateRow('Order ID:', orderDetails.id);
        textToPrint += SunmiPrinterData.generateRow('Payment:', orderInfo.payment[0].option.split('_').join(' ').capitalize());
        textToPrint += SunmiPrinterData.generateRow('Channel:', orderDetails.channel.capitalize());

        let ext_channel_id = orderDetails.ext_platforms.find(function(o){
            return o.name == orderDetails.channel;
        })
        if(ext_channel_id) textToPrint += SunmiPrinterData.generateRow('Channel ID:', ext_channel_id.id);

        // Delivery/pickup time
        let orderDate = delivery_time.getDate() + ' ' + MONTHS[delivery_time.getMonth()];
        textToPrint += SunmiPrinterData.generateRow((orderDetails.order_type + ' time').capitalize(), `${orderDate}, ${formattedDTime}`);

        textToPrint += SunmiPrinterData.seperator();

        // Customer Info
        textToPrint += SunmiPrinterData.generateRow('Cust. Name:', customerData.name);
        textToPrint += SunmiPrinterData.generateRow('Cust. Phone:', customerData.phone);

        textToPrint += SunmiPrinterData.seperator();

        for(let x in orderInfo.items){
            let item = orderInfo.items[x];
            let itemName = `${item.title}(${item.quantity})`;
            let price = item.total;
            textToPrint += `${SunmiPrinterData.generateRow(itemName, price)}\n`;

            let optionsToAdd = item.options_to_add;

            if(optionsToAdd && optionsToAdd.length > 0) {
                textToPrint += 'Options:\n';
                for(let o in optionsToAdd) {
                    let optionName = optionsToAdd[o].title;
                    let optionPrice = optionsToAdd[o].price;
                    textToPrint += `${SunmiPrinterData.generateRow(optionName, optionPrice)}\n`;
                }
                textToPrint += '\n';
            }
        }

        textToPrint += SunmiPrinterData.seperator();

        // Order price split

        textToPrint += SunmiPrinterData.generateRow('Sub total', orderDetails.order_subtotal);
        if(orderDetails.discount) textToPrint += SunmiPrinterData.generateRow('Discount', orderDetails.discount);
        if(orderDetails.coupon) textToPrint += SunmiPrinterData.generateRow('Coupon', orderDetails.coupon);

        textToPrint += SunmiPrinterData.generateRow('Total taxes', orderDetails.item_level_total_taxes + orderDetails.order_level_total_taxes);
        textToPrint += SunmiPrinterData.generateRow('Total charges', orderDetails.item_level_total_charges + orderDetails.order_level_total_charges);
        textToPrint += SunmiPrinterData.generateRow('Order total', orderDetails.order_total);

        let wallet_credit_applied = orderInfo.payment.find(function(o){return o.option == 'wallet_credit'});
        if(wallet_credit_applied) textToPrint += SunmiPrinterData.generateRow('Credit applied', wallet_credit_applied.amount);
        if(wallet_credit_applied) textToPrint += SunmiPrinterData.generateRow('Payable amount', orderDetails.order_total - wallet_credit_applied.amount);

        textToPrint += SunmiPrinterData.seperator();

        // Order Instructions if any
        if(orderDetails.instructions){
            textToPrint += 'Order Instructions:\n';
            textToPrint += `${orderDetails.instructions}\n`;
        }

        textToPrint += SunmiPrinterData.seperator();

        // Footer with store info
        textToPrint += SunmiPrinterData.centerAlign(orderInfo.store.name);
        textToPrint += SunmiPrinterData.centerAlign(orderInfo.store.phone || "");

        textToPrint += '\n\n\n'

        if(print_kot){
            textToPrint += '\n\n\n';
            textToPrint += SunmiPrinterData.centerAlign(biz_data.name.toUpperCase());
            textToPrint += SunmiPrinterData.centerAlign("Kitchen KOT");
            textToPrint += SunmiPrinterData.seperator();

            textToPrint += SunmiPrinterData.generateRow('Order ID:', orderDetails.id);
            textToPrint += SunmiPrinterData.generateRow('Payment:', orderInfo.payment[0].option.split('_').join(' ').capitalize());
            textToPrint += SunmiPrinterData.generateRow('Channel:', orderDetails.channel.capitalize());
            textToPrint += SunmiPrinterData.generateRow(orderDetails.order_type+" Time:", deliveryTimeText);
            textToPrint += SunmiPrinterData.centerAlign(orderInfo.store.name);
            SunmiPrinterData.generateRow('Payment:', (paymentStr|| "")+" "+orderInfo.payment[0].option.split('_').join(' ').capitalize());
            textToPrint += SunmiPrinterData.seperator();
            for(let x in orderInfo.items){
                let item = orderInfo.items[x];
                let itemName = `${item.title}(${item.quantity})`;
                let price = item.total;
                textToPrint += `${SunmiPrinterData.generateRow(itemName, price)}\n`;

                let optionsToAdd = item.options_to_add;

                if(optionsToAdd && optionsToAdd.length > 0) {
                    textToPrint += 'Options:\n';
                    for(let o in optionsToAdd) {
                        let optionName = optionsToAdd[o].title;
                        let optionPrice = optionsToAdd[o].price;
                        textToPrint += `${SunmiPrinterData.generateRow(optionName, optionPrice)}\n`;
                    }
                    textToPrint += '\n';
                }

            }

            textToPrint += SunmiPrinterData.seperator();
            textToPrint += '\n\n\n';
        }
    }


	return textToPrint;
}
