import React, { useState, useEffect } from 'react';
import { ResponsiveSankey } from '@nivo/sankey'
import { ORDER_CHANNELS_DISPLAY_NAME, getChannelDisplayName } from './const';
import { commifyNumber } from '../../js/api';
import _ from 'lodash';

const sat_api = require('../../js/api.js');

const createSankeyNodes = (sankeyData)=>{
	const tempDict = {};
	const nodes = [
		{ id: 'Completed' },
		{ id: 'Others' },
		{ id: 'Cancelled' }
	];
	for(let x in sankeyData) {
		const i = sankeyData[x];

		const channel = i.order_channel;

		if(!tempDict[channel]){
			nodes.push({
				id: ORDER_CHANNELS_DISPLAY_NAME[i.order_channel] || i.order_channel
			})
			tempDict[channel] = true;
		}
	}

	return nodes;
}
const groupOrderState = (order_state)=> {
	switch (order_state) {
		case "Completed":
			return "Completed"
			break;
		case "Cancelled":
			return "Cancelled"
			break;
		default:
			return "Others"

	}
}
const createSankeyLinks = (sankeyData)=>{
	let links = [];


	for(let i in sankeyData) {
		const node = sankeyData[i];

		let target = groupOrderState(node.order_state);

		if(target == 'Others') {
			// if exists, just update.
			let ifOthers = _.find(links, {target: "Others"});
			if(ifOthers) {
				ifOthers.value = ifOthers.value + node.count;
			}else {
				links.push({
					"source": ORDER_CHANNELS_DISPLAY_NAME[node.order_channel] || node.order_channel,
					"target": target,
					"value": node.count
				})
			}
		}else {
			links.push({
				"source": ORDER_CHANNELS_DISPLAY_NAME[node.order_channel] || node.order_channel,
				"target": target,
				"value": node.count
			})
		}
	}
	return links;
}
// console.log(createSankeyLinks)


const SankeyChart = (props)=>{
	const sankeyData = props.data;
	return(
		<div className="sankey-container analytics-section">
			<div id="sankey">
				<div className="header">
				{sat_api.getLabel(props.lang, "order_distribution_label") || "Order distribution across order state and order channel"}
				</div>
				<MyResponsiveSankey data={sankeyData}/>
			</div>
		</div>
	)
}


export default SankeyChart;





class MyResponsiveSankey extends React.Component{
	render() {
		const data = this.props.data;
		const __data = {
		  "nodes": createSankeyNodes(data),
		  "links": createSankeyLinks(data)
		}

		console.log('sankey rerendering')

		return(
		    <ResponsiveSankey
				id="sankey_data"
		        data={__data}
		        margin={{ top: 40, right: 50, bottom: 40, left: 50 }}
		        align="justify"
		        nodeOpacity={1}
		        nodeThickness={18}
		        nodeInnerPadding={3}
		        nodeSpacing={200}
		        nodeBorderWidth={0}
		        linkOpacity={1}
		        linkHoverOthersOpacity={0.1}
		        enableLinkGradient={true}
		        labelPosition="outside"
		        labelOrientation="horizontal"
		        labelPadding={16}
		        labelTextColor={{ from: 'color', modifiers: [ [ 'brighter', 1 ] ] }}
		        animate={true}
				label={node=>`${node.id} (${node.value})`}
		        motionStiffness={140}
		        motionDamping={13}
				layout={"vertical"}
		    />
		)
	}
}
