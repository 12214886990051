import { toggleLoginSeamlessPrntModal } from '../actions/printManager';

import Moment from './clientTime';
import { store } from './store.js'
import { removeListiner } from '../socket_handlers';
import { markPrinted } from '../firebase';

var sat_api = require('../js/api.js');
var _ = require('lodash');
var PubSub = require('pubsub-js');


export function pushToPos(orderData) {
	return function(dispatch) {
		const { id } = orderData;
		window.clearTimeout(window.ptpTimer)

		store.dispatch({
			type: "UPDATE_PTP_STATE",
			payload: {
				order_id: id,
				state: "in-progress",
				show: true,
				message: "Processing request..",
				error_message: ""
			}
		})
		sat_api.pushOrderToPos(
			{ id: id },
			resp => {
				alert(resp.data.message);

				store.dispatch({
					type: "UPDATE_PTP_STATE",
					payload: {
						order_id: id,
						state: "success",
						show: true,
						message: "Successfully pushed to POS"
					}
				})

				window.ptpTimer = setTimeout(()=>{
					store.dispatch({
						type: "UPDATE_PTP_STATE",
						payload: {
							show: false
						}
					})
				}, 2000)

				store.dispatch(getOrderDetail({ id: parseInt(id), stealth: true }));

				try {
					sat_api.MP_TRACK('push_order', {
						order_id: id,
						channel: orderData.channel
					});
				}catch(err) {
					console.error(err)
				}
			},
			err => {
				console.log(err)
				let errStr = (err && err.response && err.response.data && (err.response.data.message || err.response.data.error_message)) || 'Failed to push';
				store.dispatch({
					type: "UPDATE_PTP_STATE",
					payload: {
						order_id: id,
						state: "failed",
						show: true,
						message: "Failed to push",
						error_message: errStr
					}
				})
			}
		);
	}
}
export function setDeliveryExec(d) {
	return function(dispatch) {
		store.dispatch({
			type: 'UPDATE_DELIVERY_EXEC_INFO_ORDER_INIT',
			payload: null
		});

		sat_api.setDeliveryExec(d).then((resp)=>{

			store.dispatch({
				type: 'UPDATE_DELIVERY_EXEC_INFO_ORDER_DONE',
				payload: null
			});

			store.dispatch({
				type: 'STATUS_CHANGE_MODAL',
				payload: {
					showDeliveryExecModal: false
				}
			});

			// patch order detail based on the action;
			store.dispatch(getOrderDetail({ id: parseInt(d.order_id), stealth: true }));

		}).catch((err)=>{
			let str = err.response && err.response.data && err.response.data.message;
			alert(str || 'Request failed, try again');
			store.dispatch({
				type: 'UPDATE_DELIVERY_EXEC_INFO_ORDER_DONE',
				payload: null
			});
		})
	}
}

export function handleQuickAction(d) {
	return function(dispatch) {
		const { id } = store.getState().disablePushOrder;

		store.dispatch({
			type: 'UPDATE_DISABLE_PUSH_ORDER',
			payload: id.concat(d.id)
		});

		sat_api.orderStatus(
			{
				id: d.id,
				new_state: d.new_state
			},
			function() {
				store.dispatch({
					type: 'UPDATE_DISABLE_PUSH_ORDER',
					payload: id.filter(individualId => individualId !== d.id)
				});

				store.dispatch(getOrderDetail({ id: parseInt(d.id), stealth: true }));

				try {
					var __payload = null;

					const __allList = store.getState().orderList.data;

					const __placed = __allList.placed.orders;
					const __other = __allList.other.orders;

					let _op = __placed.find(i => i.id == d.id);
					let _oo = __other.find(i => i.id == d.id);

					let __input = _oo || _op;

					// let new_status = sat_api.MISC_CONFIG.statusMap.find((i)=>i.param == data.new_state))
					__input.status = sat_api.MISC_CONFIG.getRevesreParam(d.new_state);
					__input.order_state = __input.status;
					__input.order_id = __input.id;

					__payload = sat_api.prepareData.update_order_list(__input);

					if (__payload) {
						dispatch({
							type: 'FETCH_ORDERS_FULFILLED',
							payload: __payload
						});
					}
				} catch (err) {
					console.log(err, 'instant update failed');
				}
			},
			function(err) {
				store.dispatch({
					type: 'UPDATE_DISABLE_PUSH_ORDER',
					payload: id.filter(individualId => individualId !== d.id)
				});
				if (err.response && err.response.data && err.response.data.message) {
					alert(err.response.data.message);
				} else {
					alert('Something went wrong, try agian!');
				}
			}
		);
	};
}

export function handleBarcode(d) {
	return function(dispatch) {
		store.dispatch({
			type: 'BARCODE_SCANNED_INIT',
			payload: d
		});

		//mark food ready
		sat_api.orderStatus(
			{
				id: d,
				new_state: 'Food Ready'
			},
			function() {
				store.dispatch({
					type: 'BARCODE_SCANNED_FINISHED',
					payload: null
				});

				store.dispatch(getOrderDetail({ id: parseInt(d), stealth: true }));

				try {
					var __payload = null;

					const __allList = store.getState().orderList.data;

					const __placed = __allList.placed.orders;
					const __other = __allList.other.orders;

					let _op = __placed.find(i => i.id == d);
					let _oo = __other.find(i => i.id == d);

					let __input = _oo || _op;

					// let new_status = sat_api.MISC_CONFIG.statusMap.find((i)=>i.param == data.new_state))
					__input.status = sat_api.MISC_CONFIG.getRevesreParam('food ready');
					__input.order_id = __input.id;

					__payload = sat_api.prepareData.update_order_list(__input);

					if (__payload) {
						dispatch({
							type: 'FETCH_ORDERS_FULFILLED',
							payload: __payload
						});
					}
				} catch (err) {
					console.log(err, 'instant update failed');
				}
			},
			function(err) {
				//console.log('Something went wrong! Try Again!');
				store.dispatch({
					type: 'BARCODE_SCANNED_FAILED',
					payload: null
				});
				if (err.data && err.data.message) {
					alert(
						'Failed: ' +
							(err.data.message || 'Something went wrong, try agian!')
					);
				} else {
					alert('Something went wrong, try agian!');
				}
			}
		);
	};
}

export function fetchAllOrder(d, meta, noCache) {
	if (meta) {
		return function(dispatch) {
			dispatch({
				type: 'ALL_ORDER_FETCH_LOADING_MORE',
				payload: null
			});

			sat_api
				.fetchAllOrder({ next: meta.next, noCache: noCache })
				.then(resp => {
					const oldData = _.cloneDeep(store.getState().allOrderList.data);
					const newOrders = [...oldData.orders, ...resp.data.orders];
					const newData = {
						...resp.data,
						orders: newOrders
					};

					console.log(newData);
					dispatch({
						type: 'ALL_ORDER_FETCHED',
						payload: {
							data: newData,
							dateFilter: d
						}
					});
				})
				.catch(err => {
					alert('Sorry, failed to load data!');
					console.log(err);
				});
		};
	} else {
		const __date = Moment(d).format('YYYY-MM-DD');
		return function(dispatch) {
			dispatch({
				type: 'ALL_ORDER_FETCH_LOADING',
				payload: null
			});

			sat_api
				.fetchAllOrder({ filter: __date, noCache: noCache })
				.then(resp => {
					dispatch({
						type: 'ALL_ORDER_FETCHED',
						payload: {
							data: resp.data,
							dateFilter: d
						}
					});
				})
				.catch(err => {
					alert('Sorry, failed to load data!');
					console.log(err);
				});
		};
	}
}

export function setSurge(d) {
	return function(dispatch) {
		dispatch({
			type: 'SET_SURGE_INIT',
			payload: null
		});

		sat_api.setSurgeData(
			{
				store_id: d.store_id,
				data: d.data
			},
			function(resp) {
				var surge_data_arr = _.cloneDeep(
					sat_api.upStorage.getItem('active_surges')
				);
				if (!surge_data_arr) surge_data_arr = [];
				var old_surge_info = _.find(surge_data_arr, { store_id: d.store_id });
				if (old_surge_info) {
					old_surge_info.surge_time = d.data.surge_time;
					old_surge_info.end_time =
						new Date().getTime() + d.data.surge_duration * 60 * 1000;
				} else {
					surge_data_arr.push({
						store_id: d.store_id,
						surge_time: d.data.surge_time,
						end_time: new Date().getTime() + d.data.surge_duration * 60 * 1000
					});
				}
				sat_api.upStorage.setItem({
					key: 'active_surges',
					value: surge_data_arr
				});
				dispatch({
					type: 'SET_ACTIVE_SURGES',
					payload: surge_data_arr
				});
				dispatch({
					type: 'SET_SURGE_DONE',
					payload: null
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}
				dispatch({
					type: 'SET_SURGE_FAILED',
					payload: errMsg
				});
			}
		);
	};
}
export function setOutletLogistics(d) {
	return function(dispatch) {
		dispatch({
			type: 'SET_OUTLET_LOGISTICS_UPDATE_DONE',
			payload: d.settings
		});

		sat_api.setLogisticsStatus(
			{
				service_availability: d.settings.self_serviceability_status,
				store_id: d.store_id
			},
			function(resp) {
				//updated in background
			},
			function(err) {
				console.log(err);
				dispatch({
					type: 'SET_OUTLET_LOGISTICS_UPDATE_FAILED',
					payload: {
						resp: d.previousState,
						error: err.data
					}
				});
			}
		);
	};
}
export function getOutletPlatforms(d) {
	return function(dispatch) {
		dispatch({
			type: 'OUTLET_PLATFORMS_INIT',
			payload: d
		});
		sat_api.getStorePlatorms(
			d,
			function(resp) {
				// console.log(resp);
				dispatch({
					type: 'OUTLET_PLATFORMS_DONE',
					payload: resp.data
				});
			},
			function(err) {
				console.log(err);
				dispatch({
					type: 'OUTLET_PLATFORMS_FAILED',
					payload: { error: err.data }
				});
			}
		);
	};
}
export function getOutletLogistics(d) {
	return function(dispatch) {
		dispatch({
			type: 'OUTLET_LOGISTICS_INIT',
			payload: d.store_id
		});
		sat_api.getLogisticsStatus(
			d,
			function(resp) {
				dispatch({
					type: 'OUTLET_LOGISTICS_DONE',
					payload: resp.data.data
				});
			},
			function(err) {
				dispatch({
					type: 'OUTLET_LOGISTICS_FAILED',
					payload: err.data
				});
			}
		);
	};
}

export function getOutletTakeaway(d) {
	return function(dispatch) {
		dispatch({
			type: 'OUTLET_TAKEAWAY_INIT',
			payload: d.store_id
		});
		sat_api.getTakeAwayStatus(
			d,
			function(resp) {
				dispatch({
					type: 'OUTLET_TAKEAWAY_DONE',
					payload: resp.data.data
				});
			},
			function(err) {
				dispatch({
					type: 'OUTLET_TAKEAWAY_FAILED',
					payload: err.data
				});
			}
		);
	};
}

export function searchItemsForInventory(d) {
	return function(dispatch) {
		dispatch({
			type: 'INVENTORY_SEARCH_INIT',
			payload: d.kw
		});

		sat_api.searchItemsForInventory(
			d,
			function(resp) {
				// passing kw within a closure will be a better idea instead of calling getState on store
				var kwToSearch = store.getState().inventorySearch.kw;
				if (resp.keyword == kwToSearch) {
					dispatch({
						type: 'INVENTORY_SEARCH_DONE',
						payload: null
					});

					dispatch({
						type: 'INVENTORY_FETCH_DONE',
						payload: resp.data.items
					});

					sat_api.MP_TRACK('inv_item_searched', {
						keyword: kwToSearch
					});
				} else {
					console.log('rejecting for', resp.keyword, kwToSearch);
				}
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}
				dispatch({
					type: 'INVENTORY_SEARCH_FAILED',
					payload: errMsg
				});
			}
		);
	};
}
export function updateInventory(d) {
	return function(dispatch) {
		dispatch({
			type: 'UPDATE_INVENTORY_INIT',
			payload: d
		});
		sat_api.updateAvailability(
			d,
			function(resp) {
				dispatch({
					type: 'UPDATE_INVENTORY_DONE',
					payload: resp.data
				});
				sat_api.MP_TRACK('toggle_availability', {
					platform: d.platform,
					action: d.availability ? 'stock_in' : 'stock_out',
					item_id: d.item_id
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					alert(err.data.message);
				} else {
					alert('Something went wrong, try again!');
				}

				//reverse effect incase of error.

				var inventory = store.getState().inventory;
				var item = _.find(inventory.data, { id: d.item_id });
				_.find(item.availability, {
					name: d.platform
				}).is_available = !d.availability;

				dispatch({
					type: 'INVENTORY_FETCH_DONE',
					payload: inventory.data
				});
			}
		);
	};
}
export function getInventory(d) {
	return function(dispatch) {
		dispatch({
			type: 'INVENTORY_FETCH_INIT',
			payload: { location_id: d.location_id }
		});

		d.ignorefulfillment_mode = true;
		sat_api.getInventoryofFirstCategoryItems(
			d,
			function(resp) {
				dispatch({
					type: 'INVENTORY_FETCH_DONE',
					payload: resp.items.data.items
				});

				dispatch({
					type: 'INVENTORY_CAT_FETCH_DONE',
					payload: resp.categories.data.categories
				});
				dispatch({
					type: 'SET_SELECTED_CAT_INVENTORY',
					payload: {
						value: resp.categories.data.categories[0].id,
						label: resp.categories.data.categories[0].name,
					},
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}
				dispatch({
					type: 'INVENTORY_FETCH_FAILED',
					payload: errMsg
				});
			}
		);
	};
}

export function getInventoryItemsByCat(d) {
	return function(dispatch) {
		dispatch({
			type: 'INVENTORY_FETCH_INIT',
			payload: { location_id: d.location_id }
		});
		d.ignorefulfillment_mode = true;
		sat_api.getItemsByCategory(
			d,
			resp => {
				dispatch({
					type: 'INVENTORY_FETCH_DONE',
					payload: resp.data.items
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}
				dispatch({
					type: 'INVENTORY_FETCH_FAILED',
					payload: errMsg
				});
			}
		);
	};
}

export function getInventoryItemsByTags(d) {
	return function(dispatch) {
		dispatch({
			type: 'INVENTORY_FETCH_INIT',
			payload: { location_id: d.location_id }
		});
		d.ignorefulfillment_mode = true;
		sat_api.getItemsByTags(
			d,
			resp => {
				dispatch({
					type: 'INVENTORY_FETCH_DONE',
					payload: resp.data.items,
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}
				dispatch({
					type: 'INVENTORY_FETCH_FAILED',
					payload: errMsg
				});
			}
		);
	};
}

export function reqCallback(d) {
	return function(dispatch) {
		dispatch({
			type: 'CALLBACK_REQ_INIT',
			payload: null
		});

		sat_api.requestCallBack(
			d,
			function(resp) {
				dispatch({
					type: 'CALLBACK_REQ_DONE',
					payload: null
				});
			},
			function(err) {
				//alert('Callback requested failed');
				dispatch({
					type: 'CALLBACK_REQ_FAILED',
					payload: err.data
				});
			}
		);
	};
}
export function reOrder(d) {
	return function(dispatch) {
		dispatch({
			type: 'REORDER_INIT',
			payload: null
		});
		sat_api.getReOrderPayload(
			d,
			function(resp) {
				if (resp.data.items_available.length == 0) {
					dispatch({
						type: 'REORDER_FAILED',
						payload: {
							message: 'Currently no item available to reorder'
						}
					});
					return false;
				}
				var cart = sat_api.prepareCartFromReorder(resp.data);
				dispatch({
					type: 'REORDER_SUCCESS',
					payload: cart
				});

				dispatch({
					type: 'ORDER_STARTED',
					payload: null
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}
				dispatch({
					type: 'REORDER_FAILED',
					payload: errMsg
				});
			}
		);
	};
}
export function pushNewOrder(d) {
	return function(dispatch) {
		var payload = sat_api.pushNewOrder(d);
		dispatch({
			type: 'FETCH_ORDERS_FULFILLED',
			payload: payload
		});
	};
}
export function updateOrderList(d) {
	return function(dispatch) {
		var payload = sat_api.prepareData.update_order_list(d);
		dispatch({
			type: 'FETCH_ORDERS_FULFILLED',
			payload: payload
		});
	};
}

export function fetchInActiveOrder(filter) {
	return function(dispatch) {
		const startTime = new Date().getTime();
		const today = Moment().format('YYYY-MM-DD');
		var data = { ...data };
		data.limit = 250;
		data.date = today;
		dispatch({
			type: 'FETCH_ORDERS_INIT',
			payload: null
		});
		sat_api.fetchInActiveOrder(
			data,
			function(d) {
				var orders = d.data.orders;

				//check if there is any store level filter
				var store_details = store.getState().loginReducer.data.sub_admin;
				var settings_key = store.getState().loginReducer.data.user_id;
				var settings = sat_api.getLocalSettings(settings_key) || {};
				var selectedStores = settings.selectedStores;
				const selectedStoresFlat = selectedStores ? selectedStores.map((str) => str.value) : [];
				if (selectedStoresFlat.length > 0) {
					var filteredOrders = orders.filter(x =>
						selectedStoresFlat.includes(x.biz_location_id)
					);
				} else {
					var filteredOrders = orders;
				}

				d.data.orders = filteredOrders;

				var payload = sat_api.prepareData.updateList({
					filter: data.filter,
					data: d.data,
					stores: store_details,
					ignoreActive: true
				});

				dispatch({
					type: 'UPDATE_DISABLE_PUSH_ORDER',
					payload: []
				});

				dispatch({
					type: 'FETCH_ORDERS_FULFILLED',
					payload: payload
				});
				const endTime = new Date().getTime();
				sat_api.MP_TRACK('tracker_list', {
					num_orders: orders.length,
					time_to_load: (endTime - startTime) / 1000
				});
			},
			function(err) {
				console.log(err);
			}
		);
	};
}

export function fetchActiveOrder(filter) {
	return function(dispatch) {
		const startTime = new Date().getTime();
		var data = { ...data };
		data.limit = 250;
		dispatch({
			type: 'FETCH_ORDERS_INIT',
			payload: null
		});
		sat_api.fetchActiveOrder(
			data,
			function(d) {
				var orders = d.data.orders;

				//check if there is any store level filter
				var store_details = store.getState().loginReducer.data.sub_admin;
				var settings_key = store.getState().loginReducer.data.user_id;
				var settings = sat_api.getLocalSettings(settings_key) || {};
				var selectedStores = settings.selectedStores;
				const selectedStoresFlat = selectedStores ? selectedStores.map((str) => str.value) : [];
				if (selectedStoresFlat.length > 0) {
					var filteredOrders = orders.filter(x =>
						selectedStoresFlat.includes(x.biz_location_id)
					);
				} else {
					var filteredOrders = orders;
				}
				// if seamless printing is enabled then
				// mark orders as printed in firebase
				// if they are not in placed or acknowledged state
				const { enableSeamlessPrinting } = store.getState().printManager;
				if (enableSeamlessPrinting) {
					window.requestAnimationFrame(() => {
						markPrinted(filteredOrders);
					});
				}

				d.data.orders = filteredOrders;
				var payload = sat_api.prepareData.updateList({
					filter: data.filter,
					data: d.data,
					stores: store_details,
					ignoreInactive: true
				});

				dispatch({
					type: 'UPDATE_DISABLE_PUSH_ORDER',
					payload: []
				});

				dispatch({
					type: 'FETCH_ORDERS_FULFILLED',
					payload: payload
				});

				const endTime = new Date().getTime();
				sat_api.MP_TRACK('tracker_list', {
					num_orders: orders.length,
					time_to_load: (endTime - startTime) / 1000
				});
			},
			function(err) {
				console.log(err);
			}
		);
	};
}

export function orderStateUpdate(data) {
	// console.log(data)
	if (data.changeStatus) {
		return function(dispatch) {
			dispatch({
				type: 'STATUS_CHANGE_MODAL',
				payload: { showModal: false }
			});
			dispatch({
				type: 'FETCH_ORDER_STATUS_INIT',
				payload: {}
			});
			sat_api.orderStatus(
				data,
				function(d) {
					dispatch({
						type: 'FETCH_ORDER_STATUS_FULFILLED',
						payload: d
					});
					dispatch(getOrderDetail({ id: parseInt(data.id), stealth: true }));

					try {
						var __payload = null;

						const __allList = store.getState().orderList.data;

						const __placed = __allList.placed.orders;
						const __other = __allList.other.orders;

						let _op = __placed.find(i => i.id == data.id);
						let _oo = __other.find(i => i.id == data.id);

						let __input = _oo || _op;

						// let new_status = sat_api.MISC_CONFIG.statusMap.find((i)=>i.param == data.new_state))
						__input.status = sat_api.MISC_CONFIG.getRevesreParam(
							data.new_state
						);
						__input.order_id = __input.id;

						__payload = sat_api.prepareData.update_order_list(__input);

						if (__payload) {
							dispatch({
								type: 'FETCH_ORDERS_FULFILLED',
								payload: __payload
							});
						}
					} catch (err) {
						console.log(err, 'instant update failed');
					}

					sat_api.MP_TRACK('order_set_status', {
						order_id: data.id,
						channel: data.channel,
						curr_state: data.curr_state,
						new_state: data.new_state
					});
				},
				function(err) {
					//console.log('Something went wrong! Try Again!');
					if (err.data && err.data.message) {
						alert(err.data.message || 'Something went wrong, try agian!');
					} else {
						alert('Something went wrong, try agian!');
					}
					dispatch({
						type: 'FETCH_ORDER_STATUS_FAILED',
						payload: err
					});
				}
			);
		};
	} else {
		var newData = { ...data };
		newData.showModal = true;
		return function(dispatch) {
			dispatch({
				type: 'STATUS_CHANGE_MODAL',
				payload: newData
			});
		};
	}
}

export function getOrderDetail(data) {
	return function(dispatch) {
		if (data.stealth === true) {
			dispatch({
				type: 'FETCH_ORDER_STATUS_RESET',
				payload: null
			});
		} else {
			dispatch({
				type: 'FETCH_ORDER_DETAIL_INIT',
				payload: null
			});
		}
		sat_api.getOrderDetail(
			data,
			function(d) {
				dispatch({
					type: 'FETCH_ORDER_DETAIL_FULFILLED',
					payload: d.data
				});

				dispatch({
					type: 'FETCH_ORDER_DETAIL_AFTER_STATUS_FULFILLED',
					payload: null
				});


				var __payload = sat_api.prepareData.update_order_list({
					order_id: d.data.order.details.id,
					status: d.data.order.details.order_state,
					merchant_ref_id: d.data.order.details.merchant_ref_id
				});

				dispatch({
					type: 'FETCH_ORDERS_FULFILLED',
					payload: __payload
				});

				sat_api.fetchDeliveryExec({
					biz_location_id: d.data.order.store.id
				}).then((resp)=>{

					store.dispatch({
						type: "SET_DELIVERY_EXEC_INFO",
						payload: resp.data
					})

				})

				sat_api.MP_TRACK('view_order_detail', {
					order_id: d.data.order.details.id,
					channel: d.data.order.details.channel
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong! Refresh your page.';
				}
				dispatch({
					type: 'FETCH_ORDER_DETAIL_FAILED',
					payload: { msg: errMsg, id: data.id }
				});
			}
		);
	};
}

export function resetOrderDetail() {
	return function(dispatch) {
		dispatch({
			type: 'RESET_ORDER_DETAIL',
			payload: null
		});
	};
}

export function statusChange(data) {
	return function(dispatch) {
		var d = data.d;
		var currnet_state_data = _.cloneDeep(store.getState().orderList.data);

		var orderObj = _.find(currnet_state_data.placed.orders, { id: d });

		if (orderObj && orderObj.newOrder) {
			delete orderObj.newOrder;
			sat_api.upStorage.setItem({
				key: 'orderList',
				value: currnet_state_data
			});
		} else {
			var orderObjinFuture = _.find(currnet_state_data.future.orders, {
				id: d
			});
			if (orderObjinFuture && orderObjinFuture.newOrder) {
				delete orderObjinFuture.newOrder;
				sat_api.upStorage.setItem({
					key: 'orderList',
					value: currnet_state_data
				});
			}
		}
		dispatch({
			type: 'FETCH_ORDERS_FULFILLED',
			payload: currnet_state_data
		});
	};
}

export function setActivePage(data) {
	return function(dispatch) {
		dispatch({ type: 'SET_ACTIVE_LINK', payload: data });
	};
}

export function updateSettings(data) {
	return function(dispatch) {
		dispatch({ type: 'UPDATE_SESSION_SETTINGS', payload: data });
	};
}

export function updateGeneralSettings(data) {
	sat_api.upStorage.setItem({ key: 'settings_general', value: data });
	return function(dispatch) {
		dispatch({ type: 'UPDATE_APP_SETTINGS', payload: data });
	};
}
export function displayCrossPlatformEnhancement(data){
	return function(dispatch){
		dispatch({type:'DISPLAY_CROSS_PLATFORM_ENHANCEMENT',payload:null})
		localStorage.setItem("displayedCrossPlatformPromotion", 1);
	};
}
export function loginAction(data) {
	return function(dispatch) {
		dispatch({ type: 'LOGIN_INIT', payload: null });

		sat_api.loginUser(
			data,
			function(d) {
				//let formatted_data = {...d}
				let data = d.data;
				data.version = sat_api.VERSION_NO;
				sat_api.upStorage.setItem({ key: 'auth', value: d.data });

				var ifMenuExists = sat_api.upStorage.getItem('biz_id_for_current_menu');

				if (ifMenuExists && ifMenuExists != d.data.biz.biz_id) {
					sat_api.upStorage.removeItem('menu');
					sat_api.upStorage.removeItem('category');
				}
				dispatch({ type: 'LOGIN_SUCCESS', payload: d.data });
				dispatch({
					type: 'SELECT_BIZ',
					payload: {
						label: d.data.biz.name,
						value: d.data.biz.biz_id,
					},
				});
				sat_api.MP_TRACK('login', {
					username: d.data.name,
					hq_user: d.data.flag == 'HQ'
				});
				dispatch(toggleLoginSeamlessPrntModal(true));
				try {
					mixpanel.identify(d.data.user_id);
					mixpanel.people.set({
						name: d.data.name,
						user_type: d.data.flag,
						user_id: d.data.user_id
					});

					gtag('set', {
						'user_id': d.data.user_id
					});

				} catch (err) {
					console.error(err);
				}
			},
			function(err) {
				console.log(err);
				console.log('LOGIN_FAIL');
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Invalid credentials';
				}

				dispatch({ type: 'LOGIN_FAIL', payload: errMsg });

				sat_api.MP_TRACK('login_failed', {
					username: data.username
				});
			}
		);
	};
}
export function syncUserData() {
	return function(dispatch) {
		dispatch({ type: 'USER_DATA_SYNC_INIT', payload: null });
		sat_api.loginUser(
			{},
			function(d) {
				let formatted_data = { ...d };
				let data = formatted_data.data;
				data.version = sat_api.VERSION_NO;
				sat_api.upStorage.setItem({ key: 'auth', value: d.data });
				dispatch({ type: 'LOGIN_SUCCESS', payload: d.data });
				dispatch({ type: 'USER_DATA_SYNC_FINISHED', payload: null });
			},
			function(err) {
				// dispatch({type:'LOGIN_FAIL',
				// 	payload:err
				// })
				dispatch({ type: 'USER_DATA_SYNC_FAILED', payload: null });
			}
		);
	};
}
export function logoutAction(data) {
	return function(dispatch) {
		sat_api.upStorage.removeItem('basic_auth');
		sat_api.upStorage.removeItem('auth');
		sat_api.upStorage.removeItem('orderList');

		localStorage.removeItem('address.delivery_info');
		localStorage.removeItem('UP');
		const machine_id = localStorage.getItem('machine_id');
		removeListiner(machine_id);
		dispatch({ type: 'LOGOUT', payload: null });
		dispatch({ type: 'DROP_ORDER', payload: null });
		dispatch({ type: 'HIDE_BARCODE_SCANNED_SECTION', payload: null });
		PubSub.publish('AUTH', { type: 'LOGOUT' });
	};
}

export function newCall(data) {
	return function(dispatch) {
		dispatch({ type: 'CALL_RECEIVED', payload: data });
		sat_api.getCustomerStatus_v2(
			data,
			function(d) {
				dispatch({
					type: 'FETCH_CUSTOMER_SATUS_FULFILLED',
					payload: d.data
				});
				dispatch({
					type: 'USER_OBJ_FETCH_DONE',
					payload: d.data
				});
			},
			function(err) {
				//alert('Something went wrong! Refresh your page.')
				console.log(err);
				dispatch({
					type: 'FETCH_CUSTOMER_SATUS_FAILED',
					payload: { msg: 'Network Connectivity Issue' }
				});
			}
		);
	};
}

export function dismissCallModal(data) {
	return function(dispatch) {
		dispatch({ type: 'DIMSMISS_CALL_MODAL', payload: null });
	};
}

export function setReceivedPhoneno(data) {
	return function(dispatch) {
		dispatch({
			type: 'PHONE_NO_RECEIVED',
			payload: data
		});
	};
}

export function startOrderSession(data) {
	return function(dispatch) {
		dispatch({
			type: 'USER_OBJ_FETCH_INIT',
			payload: null
		});

		sat_api.getCustomerStatus_v2(
			data,
			function(d) {
				dispatch({
					type: 'USER_OBJ_FETCH_DONE',
					payload: d.data
				});
			},
			function(err) {
				//do not show or throw any error
				dispatch({
					type: 'USER_OBJ_FETCH_FAILED',
					payload: { msg: 'Network Connectivity Issue', id: data.id }
				});
			}
		);
	};
}

export function dropOrder() {
	return function(dispatch) {
		dispatch({
			type: 'DROP_ORDER',
			payload: null
		});
		dispatch({
			type: 'HIDE_CART',
			payload: null
		});
		dispatch({
			type: 'CLOSE_COMPLEX_ITEM',
			payload: null
		});
		localStorage.removeItem('address.delivery_info');
	};
}

export function switchOrderMode(d) {
	return function(dispatch) {
		dispatch({
			type: 'SWITCH_ORDER_MODE',
			payload: d
		});
	};
}

export function getStores(data) {
	return function(dispatch) {
		dispatch({
			type: 'FETCHING_STORES_INIT',
			payload: null
		});

		sat_api.getStores (
			data,
			function(d) {
				dispatch({
					type: 'FETCHING_STORES_DONE',
					payload: d.data
				});
				// set default biz store after fetching stores
				if(d.data.objects.length > 0) {
					dispatch({
						type: 'SELECT_BIZ_STORE',
						payload: {
							value: d.data.objects[0].biz_location_id,
							label: d.data.objects[0].name,
						},
					});
				}
			},
			function() {
				dispatch({
					type: 'FETCHING_STORES_FAILED',
					payload: null
				});
			}
		);
	};
}
export function checkDeliveryFeasibility(_data) {
	return function(dispatch) {
		dispatch({
			type: 'FEASIBILITY_CHECK_INIT',
			payload: null
		});

		sat_api.isDeliverable(
			_data,
			function(resp) {
				var data = { user_locality: _data.name, ...resp.data };
				if (data.objects.length > 0) {
					dispatch({
						type: 'FEASIBILE_LOCATION',
						payload: data
					});
					dispatch({
						type: 'STORE_RECEIVED',
						payload: data.objects[0]
					});
				} else {
					dispatch({
						type: 'NOT_FEASIBILE_LOCATION',
						payload: _data
					});
					dispatch({
						type: 'STORE_RESET',
						payload: null
					});
				}
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}

				dispatch({
					type: 'FEASIBILITY_CHECK_FAILED',
					payload: errMsg
				});
			}
		);
	};
}

export function getCategoryAnditemsForFirstCategory(data) {
	return function(dispatch) {
		dispatch({
			type: 'MENU_LOADING_INIT',
			payload: data
		});

		sat_api.getCategoryAndFrisrtCategoryItems(
			data,
			resp => {
				var { categories, items } = resp;

				dispatch({
					type: 'SET_SELECTED_CAT',
					payload: categories.data.categories[0].id
				});

				var menu = {};
				menu.items = items.data.items;
				menu.location_id = data.location_id;
				menu.fulfillment_mode = data.fulfillment_mode;

				menu.fetchedTime = new Date().getTime();

				dispatch({
					type: 'MENU_LOADED',
					payload: menu
				});

				dispatch({
					type: 'CATEGORY_LOADED',
					payload: categories.data.categories
				});

				dispatch({
					type: 'BIZ_INFO_LOADED',
					payload: categories.data.biz
				});
			},
			err => {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}
				dispatch({
					type: 'MENU_LOADING_FAILED',
					payload: errMsg
				});
			}
		);
	};
}

export function getItemsByCategory(data) {
	return function(dispatch) {
		dispatch({
			type: 'MENU_LOADING_INIT',
			payload: null
		});

		sat_api.getItemsByCategory(
			data,
			resp => {
				var menu = {};
				menu.items = resp.data.items;
				menu.location_id = data.location_id;
				menu.fulfillment_mode = data.fulfillment_mode;

				menu.fetchedTime = new Date().getTime();

				dispatch({
					type: 'MENU_LOADED',
					payload: menu
				});
			},
			err => {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}
				dispatch({
					type: 'MENU_LOADING_FAILED',
					payload: errMsg
				});
			}
		);
	};
}

export function itemSearchFromServer(data) {
	return function(dispatch) {
		dispatch({
			type: 'ITEM_SEARCH_INIT',
			payload: data
		});
		dispatch({
			type: 'SET_SELECTED_CAT',
			payload: null
		});
		sat_api.searchItems(
			data,
			function(d) {
				if (data.kw == store.getState().itemSearchStatus.data.kw) {
					let searched_category_item = {};
					searched_category_item.items = d.data.items;

					dispatch({
						type: 'MENU_LOADED',
						payload: searched_category_item
					});

					dispatch({
						type: 'ITEM_SEARCH_FINISHED',
						payload: data
					});

					sat_api.MP_TRACK('order_item_searched', {
						keyword: data.kw
					});
				} else {
					console.log('rejecting result for', data.kw);
				}
			},
			function(err) {
				dispatch({
					type: 'ITEM_SEARCH_FAILED',
					payload: null
				});
			}
		);
	};
}
export function clearCart() {
	return function(dispatch) {
		dispatch({
			type: 'CLEAR_CART',
			payload: null
		});
	};
}

export function addItemToCart(data) {
	return function(dispatch) {
		dispatch({
			type: 'ITEM_ADDED',
			payload: data
		});
	};
}

export function addComplexItemToCart(data) {
	return function(dispatch) {
		dispatch({
			type: 'ADD_NEW_ITEM_INIT',
			payload: data
		});
	};
}

export function handleCoupon(data) {
	return function(dispatch) {
		dispatch({
			type: 'COUPON_INIT',
			payload: null
		});
		sat_api.validateCoupon(
			data,
			function(d) {
				dispatch({
					type: 'COUPON_ADDED',
					payload: { ...d.data, code: data.code }
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}

				dispatch({
					type: 'COUPON_FAILED',
					payload: { message: errMsg }
				});
			}
		);
	};
}

export function placeOrder(data) {
	return function(dispatch) {
		dispatch({
			type: 'ORDER_PLACE_INIT',
			payload: null
		});

		sat_api.placeOrder(
			data,
			function(d) {
				dispatch({
					type: 'ORDER_PLACED',
					payload: d.data
				});
				localStorage.removeItem('address.delivery_info');
			},
			function(err) {
				if (err && err.response && err.response.data && err.response.data.message) {
					var errMsg = err.response.data.message;
				} else {
					var errMsg = 'Something went wrong, try again.';
				}

				dispatch({
					type: 'ORDER_FAILED',
					payload: null
				});
				store.dispatch({
					type: 'ORDER_VALIDATION_ERROR',
					message: errMsg
				});
			}
		);
	};
}

export function getReco(data) {
	return function(dispatch) {
		dispatch({
			type: 'RECO_FETCH_INIT',
			payload: null
		});
		sat_api.getRecommendedItem(
			data,
			function(d) {
				// console.log(d)
				dispatch({
					type: 'RECO_FETCH_SUCCESS',
					payload: d.data
				});
			},
			function(err) {
				dispatch({
					type: 'RECO_FETCH_FAILED',
					payload: err.data
				});
			}
		);
	};
}

export function createNewUser(data) {
	return function(dispatch) {
		dispatch({
			type: 'CREATE_USER_INIT',
			payload: null
		});
		var { customer_phone } = { ...data };
		customer_phone = customer_phone.toString();
		data.customer_phone = customer_phone;
		sat_api.createUser(
			data,
			function(d) {
				var payload = {
					avarage_order_value: 0,
					cvi: 0,
					email: '',
					last_order_details: null,
					name: d.data.customer_name,
					order_count: 0,
					order_history: [],
					phone: d.data.customer_phone,
					saved_addresses: [],
					total_order_value: 0,
					prepaid_balance: d.data.total_balance
				};
				dispatch({
					type: 'CREATE_USER_SUCCESS',
					payload: payload
				});
				dispatch({
					type: 'ORDER_STARTED',
					payload: null
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					alert(err.data.message);
				} else {
					alert('Something went wrong, try again!');
				}
				dispatch({
					type: 'CREATE_USER_FAILED',
					payload: null
				});
			}
		);
	};
}

export function setCustomerName(data) {
	return function(dispatch) {
		dispatch({
			type: 'STARTED_SAVING_CUSTOMER_NAME'
		});
		var { prefetched_user_obj, ...formatted_data } = data;

		sat_api.updateUserProfile(
			formatted_data,
			function(d) {
				prefetched_user_obj.name =
					data.user_data.first_name + ' ' + data.user_data.last_name;
				dispatch({
					type: 'FINISHED_SAVING_CUSTOMER_NAME',
					payload: prefetched_user_obj
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Something went wrong, try again!';
				}
				dispatch({
					type: 'ERROR_SAVING_CUSTOMER_NAME',
					payload: errMsg
				});
			}
		);
	};
}

export function setCustomerDetail(data) {
	return function(dispatch) {
		dispatch({
			type: 'STARTED_SAVING_CUSTOMER_NAME'
		});
		var { prefetched_user_obj, ...formatted_data } = data;
		sat_api.updateUserProfile(
			formatted_data,
			function(d) {
				prefetched_user_obj.name =
					data.user_data.first_name + ' ' + data.user_data.last_name;
				dispatch({
					type: 'FINISHED_SAVING_CUSTOMER_NAME',
					payload: prefetched_user_obj
				});
			},
			function(err) {
				dispatch({
					type: 'ERROR_SAVING_CUSTOMER_NAME',
					payload: {
						error_message: err.message
					}
				});
			}
		);
	};
}

export function preProcessOrder(data) {
	return function(dispatch) {
		dispatch({
			type: 'PRE_PROCESS_INIT',
			payload: null
		});
		sat_api.preProcessOrder(
			data,
			function(d) {
				dispatch({
					type: 'PRE_PROCESS_SUCCESS',
					payload: d.data
				});
			},
			function(err) {
				if (err && err.data && err.data.message) {
					var errMsg = err.data.message;
				} else {
					var errMsg = 'Unknown error.';
				}
				dispatch({
					type: 'PRE_PROCESS_FAILED',
					payload: errMsg
				});
			}
		);
	};
}

export function internetAccess(data) {
	return function(dispatch) {
		if (data) {
			dispatch({
				type: 'HIDE_CONECTIVITY',
				payload: null
			});
		} else {
			dispatch({
				type: 'SHOW_CONECTIVITY',
				payload: null
			});
		}
	};
}
