import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from '../store.js';

import { MP_TRACK } from '../../js/api';

var sat_api = require('../../js/api.js');

@connect((store)=>{
	return {
		lang: store.appLanguage.lang,
	}
})
export default class RememberHQAction extends React.Component{
	performInAllStore() {
		let _allUpdate = !this.props.data.allupdate;
		store.dispatch({
			type:'INVENTORY_UPDATE_SETTINGS',
			payload:_allUpdate
		})
		MP_TRACK("toggle_all_store_action", {
			enable: _allUpdate
		})
	}
	render() {
		var allupdate = this.props.data.allupdate;
		return(
			<div className="remember-in-hq-checkbox-container">
				<span className={(allupdate ? "active" : "")+" remember-in-hq-checkbox"} onClick={(e)=>{this.performInAllStore(e)}}>
					<span className="active-block"></span>
				</span>
				{sat_api.getLabel(this.props.lang, "perform_action_across_all_stores") || "Perform action across all stores"}
			</div>
		)
	}
}
