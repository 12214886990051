export const CHANNEL_COLORS = {
	'zomato' : '#cb202d',
	'swiggy' : '#f48024',
	'ubereats': '#1fbad6',
	'app_android': '#2ecc71',
	'app_ios': '#95a5a6',
	'web': '#34495e',
	'satellite': '#673AB7'
}

export const ORDER_CHANNELS_DISPLAY_NAME = {
	'web': 'Web',
	'app_ios': 'iOS',
	'app_android': 'Android',
	'satellite': 'Satellite',
	'ubereats': 'Uber',
	'zomato': 'Zomato',
	'swiggy': 'Swiggy'
}

export const PAYMENT_COLORS = {
	'paytm': '#1fbad6',
	'payment_gateway': '#2ecc71',
	'aggregator': '#95a5a6',
	'cash': '#34495e',
	'prepaid': '#f48024',
	'paypal': '#2980b9',
	'simpl': '#12CBC4'
}

export const PAYMENT_MODES_DISPLAY_NAME = {
	'cash': 'Cash',
	'prepaid': 'Wallet',
	'payment_gateway': 'Online payment',
	'paytm': 'PayTM',
	'simpl': 'Simpl',
	'paypal': 'Paypal',
	'aggregator': 'Aggregator'
}

export const getChannelColor = (channel)=>{
	return CHANNEL_COLORS[channel] || '#2980b9';
}

export const getPaymentColor = (payment_mode)=>{
	return PAYMENT_COLORS[payment_mode] || '#2980b9';
}

export const getChannelDisplayName = (channel)=>{
	return ORDER_CHANNELS_DISPLAY_NAME[channel] || channel
}

export const getPaymentDisplayName = (payment)=>{
	return PAYMENT_MODES_DISPLAY_NAME[payment] || payment
}
