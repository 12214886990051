const sat_api = require('../js/api.js');
const auth = sat_api.upStorage.getItem('auth');

const bizStoresReducer = (state = {
	selectedStore: {
		value: '',
		label: '',
	},
	selectedBiz: auth && auth.biz ?
		{
			label: auth.biz.biz_name,
			value: auth.biz.biz_id,
		}
		:
		{
			label: '',
			value: '',
		},
	stores: [],
	loading: false,
	error: false,
}, action) => {
	switch (action.type) {
		case 'FETCHING_STORES_INIT':
			return {
				...state,
				stores: [],
				loading: true,
			};
		case 'FETCHING_STORES_DONE':
			return {
				...state,
				stores: action.payload.objects,
				loading: false,
			};
		case 'FETCHING_STORES_FAILED':
			return {
				...state,
				loading: false,
				error: action.error,
			};
		case 'SELECT_BIZ':
			return {
				...state,
				selectedBiz: action.payload,
			};
		case 'SELECT_BIZ_STORE':
			return {
				...state,
				selectedStore: action.payload,
			};
		default:
			return state;
	}
}
export default bizStoresReducer;
