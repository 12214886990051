import React from 'react';
import { connect } from 'react-redux';

import { store } from '../store.js';
import { getInventory } from '../actions';
import { MP_TRACK } from '../../js/api';

var sat_api = require('../../js/api.js');

const refresh = (location_id)=>{
	localStorage.removeItem('UP_CACHE');
	localStorage.removeItem('UP_CACHE_TAGS');
	window.ITEM_LOCATION_CATEGORY_CACHE = [];
	window.ITEM_LOCATION_TAGS_CACHE = [];
	store.dispatch(getInventory({
		location_id: location_id,
	}));
	MP_TRACK("inv_refresh",{});
}



const RefreshInventory = (props) => {

	const location_id = props.inventory.location_id;

	let styleObj = {
		"display": "inline-block",
		"padding": "8px 20px",
		"border": "1px solid #55a8f1",
		"borderRadius": "4px",
		"color": "#55a8f1",
		"textTransform": "uppercase",
		"cursor": "pointer",
		"float": "right",
		"fontSize": "10px"
	}

	if(props.inventory.loading) {
		styleObj.opacity = "0.5"
		styleObj.cursor = "default"
	}

	if(props.inventory.data) {
		return <div style={styleObj} onClick={()=>{refresh(location_id)}}>
			{sat_api.getLabel(props.lang, "refresh") || "Refresh"}
		</div>
	}else{
		return null
	}
}

export default connect((store)=>{
	return{
		inventory: store.inventory,
		lang: store.appLanguage.lang,
	}
})(RefreshInventory)
