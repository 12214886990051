import React from 'react';
import { store } from '../store.js';
import { connect } from 'react-redux';
import Geosuggest from 'react-geosuggest';
import Select from 'react-select';
import _ from 'lodash';

import {
    startOrderSession,
    createNewUser,
    setReceivedPhoneno,
    switchOrderMode,
    checkDeliveryFeasibility
} from '../actions';


import {
    TippyComponent
} from '../reactComp';

const sat_api = require('../../js/api');

@connect((store) => {
	return {
		currentOrderPhoneno:store.currentOrderPhoneno,
		currentOrderSession:store.currentOrderSession,
		loginReducer:store.loginReducer
	}
})
export default class StartOrder extends React.Component{
	constructor(props) {
    	super(props);
	}

	render(){
		return(
			<div className="start-order-user-info">
				<UsernoInput
					currentOrderSession={this.props.currentOrderSession}
					currentOrderPhoneno={this.props.currentOrderPhoneno}
					biz={this.props.loginReducer.data.biz}
					/>
				<BrowseLocation
					currentOrderSession={this.props.currentOrderSession}
					currentOrderPhoneno={this.props.currentOrderPhoneno}
					/>
				<StartOrderWithMobileNo
					currentOrderSession={this.props.currentOrderSession}
					currentOrderPhoneno={this.props.currentOrderPhoneno}
					biz={this.props.loginReducer.data.biz}
					/>
				<div className="m-t-20" style={{height:"100px"}}>&nbsp;</div>
			</div>
		)
	}
}



const UsernoInput = (props)=>{
    return(
        <div className="start-ordering-container padding-10 text-center">
            <div className="mobile-icon-holder">
                <img src="/images/phone.png"></img>
            </div>
            <div className="small-text m-b-10 m-t-10 secondary-text">
                Enter Mobile Number
            </div>
            <div className="text-center">
                <MobileNoInput
                    user={props.currentOrderSession.user}
                    phoneno={props.currentOrderPhoneno.phone_no}
                    biz={props.biz}
                />
            </div>
        </div>
    )
}



@connect((store) => {
	return {
		deliveryFeasibility:store.deliveryFeasibility,
		storeSelection:store.storeSelection
	}
})
class BrowseLocation extends React.Component {
	render(){
		const _helper_text = this.props.deliveryFeasibility;
		const _helper_text_status = _helper_text.active;
		const _helper_text_error = _helper_text.error;
		const _helper_text_loading = _helper_text.loading;
		const _helper_text_msg = _helper_text.text;
		const locationSelectorStatus = this.props.storeSelection.active;
		return(
			<div>
				<div className="browse-location text-center m-t-10">
					<DeliveryTypeSelection/>
				</div>
				<div className="browse-location div-table m-t-20">
					<StoreSelection/>
					<div className="div-table-cell location-selector-cont">
						<LocationSelector currentOrderSession={this.props.currentOrderSession}/>
					</div>
				</div>
				<div className="messaging m-t-10 m-b-10 text-center">
					<div className={(_helper_text_status ? " " : "hide")+(_helper_text_loading ? "secondary-text blink-highlight" : "")+(_helper_text_error ? " error" : "") }>
						{_helper_text_msg}
					</div>
				</div>
				<StoreName />
			</div>
		)
	}
}


class StartOrderWithMobileNo extends React.Component {
	phoenValid(phone_no) {
		if(phone_no.length >= 9 && sat_api.UTILS.phonenoValid(phone_no)){
			return true;
		}else{
			return false;
		}
	}

	proceed() {
		if(this.phoenValid(this.props.currentOrderPhoneno.phone_no) && this.props.currentOrderSession.store){
			if(this.props.currentOrderSession.user){
				store.dispatch({
					type:'ORDER_STARTED',
					payload:null
				})

				sat_api.MP_TRACK('order_started',{
					phone: this.props.currentOrderSession.user.phone,
					store_id: this.props.currentOrderSession.store.biz_location_id,
					store_name: this.props.currentOrderSession.store.name,
					fulfilment_mode: this.props.currentOrderSession.delivery_mode
				})
			}else{
				store.dispatch(createNewUser({
					customer_phone:this.props.biz.isd_code+this.props.currentOrderPhoneno.phone_no,
				}))
				sat_api.MP_TRACK('order_started',{
					phone: (this.props.biz.isd_code+this.props.currentOrderPhoneno.phone_no),
					store_id: this.props.currentOrderSession.store.biz_location_id,
					store_name: this.props.currentOrderSession.store.name,
					fulfilment_mode: this.props.currentOrderSession.delivery_mode
				})
			}
		}
	}

	getAddressString = (addressObject) => {
		let addressComponent = [
			'line_1',
			'line_2',
			'locality',
			'sub_locality',
			'city',
			'pin'
		];
		var addressComponentVal = [];
		for (let x in addressComponent) {
			if (addressObject[addressComponent[x]]) {
				addressComponentVal.push(addressObject[addressComponent[x]]);
			}
		}
		return addressComponentVal.join(', ');
	}

	selectAddress = (e, address) => {
		store.dispatch(checkDeliveryFeasibility({
			lat: address.lat,
			lng: address.lng,
			name: address.sub_locality,
		}));
	}

	renderAddresses = () => {
		const userLoading = this.props.currentOrderSession.userloading;
		const user = this.props.currentOrderSession.user;
		if (userLoading || !user) {
			return null;
		}
		if (user && user.saved_addresses.length === 0) {
			return (
				<div className="order-taker-user-addresses">
					<div className="no-address-found">
						No saved addresses found
					</div>
				</div>
			);
		}
		return (
			<div className="order-taker-user-addresses">
				<div className="address-found-header">
					Saved addresses ({user.saved_addresses.length} found)
				</div>
				<div className="saved-addresses">
				{user.saved_addresses.map((addr, i) => {
					return (
						<div className="saved-address-row" key={i}>
							<div className="address-value">
								<div className="address-tag">
									{addr.tag}
								</div>
								<div className="secondary-text">
									{this.getAddressString(addr)}
								</div>
							</div>
							<input onClick={(e) => this.selectAddress(e, addr)} type="button" className="active" value="Use address" />
						</div>
					)
				})}
				</div>
			</div>
		);
	}

	render() {
		let _btnClass="inactive"

		if(this.phoenValid(this.props.currentOrderPhoneno.phone_no) && this.props.currentOrderSession.store){
			_btnClass="active"
		}

		if(this.props.currentOrderSession.userloading){
			_btnClass += " progress-status"
		}

		if(this.props.currentOrderSession.orderStarted){
			return false;
		}

		return(
			<React.Fragment>
				<div className="text-center">
					<input onClick={(e)=>this.proceed(e)} className={_btnClass} type="button" value="Start Order" ></input>
				</div>
				{this.renderAddresses()}
			</React.Fragment>
		);
	}
}
class MobileNoInput extends React.Component {
	constructor(props) {
    	super(props);
		this.state = {
			active:(this.props.phoneno ? true : false),
			valid:true,
			phoneno:(this.props.phoneno ? this.props.phoneno : "")
		};
	}
	componentWillReceiveProps(d){
		//console.log(d)
		this.setState({
			active:(d.phoneno ? true : false),
			valid:true,
			phoneno:(d.phoneno ? d.phoneno : "")
		});
	}
	onFocus(){
		this.setState({active:true})
	}
	onBlur(e){
		const contactInputState = {}
		if(!e.target.value){
			contactInputState.active=false
		}
		if(!sat_api.UTILS.phonenoValid(e.target.value)){
			contactInputState.valid=false
		}
		this.setState(contactInputState)
	}

	receivePhoneNo(e){
		var phone_no = e.target.value;
		this.setState({phoneno:phone_no})
		store.dispatch(setReceivedPhoneno({phone_no:phone_no}))
		if(phone_no && sat_api.UTILS.phonenoValid(phone_no) && phone_no.length>=9){
			this.setState({valid:true})
			store.dispatch(startOrderSession({
				phone:this.props.biz.isd_code+phone_no
			}))
		}else {
			this.setState({valid:false})
		}
	}
	fetchUserInfo(e){
		var phone_no = e.target.value;
		if(this.props.user){

		}else{
			if(e.nativeEvent.keyCode === 13) {
				if(phone_no && sat_api.UTILS.phonenoValid(phone_no) && phone_no.length>=9){
					store.dispatch(startOrderSession({
						phone:this.props.biz.isd_code+phone_no
					}))
				}
			}
		}
	}
	focusInput(){
		this.nameInput.focus()
	}
	render() {
		let disabled=false

		if(store.getState().currentOrderSession.orderStarted){
			disabled=true
		}

		return(
			<div className={ (!this.state.valid ? "invalid " : "")+(this.state.active ? "active " : "")+"contact-no-input m-t-20"}>
				<div className="prefix" onClick={(e)=>this.focusInput(e)}>{this.props.biz.isd_code}</div>
				<div className="input-cont">
					<input
						ref={(input) => { this.nameInput = input }}
						disabled={disabled}
						value={this.state.phoneno}
						maxLength="10"
						type="text"
						onKeyPress={(e)=>this.fetchUserInfo(e)}
						onChange={(e)=>this.receivePhoneNo(e)}
						onFocus={ (e)=>this.onFocus(e) }
						onBlur={ (e)=>this.onBlur(e) }></input>
				</div>
			</div>
		)
	}
}


@connect((store) => {
	return {
		storeSelection:store.storeSelection,
		currentOrderSession:store.currentOrderSession,
		loginReducer:store.loginReducer
	}
})
class StoreSelection extends React.Component{
	constructor(props){
		super(props)
	}
	switchLocationSelector(){
		var hq = (this.props.loginReducer.data.sub_admin.length > 1)
		if(!hq){
			return false;
		}
		store.dispatch({
			type:'STORE_RESET',
			payload:null
		})
		var locationSelectorStatus = this.props.storeSelection.active;
		if(!locationSelectorStatus){
			store.dispatch({
				type:'SELECT_STORE_DROPDOWN',
				payload:null
			})
		}else{
			store.dispatch({
				type:'SELECT_LOCATION_GEO',
				payload:null
			})
		}
	}
	render(){
		var locationSelectorStatus = this.props.storeSelection.active;
		var hq = (this.props.loginReducer.data.sub_admin.length > 1)
		return(
			<div className={(locationSelectorStatus ? "right" : "")+(hq ? " hq" : " single-store")+" store-selection-type"}>
				<div className={(locationSelectorStatus ? "" : "selected")+" float-left store-type-button search-store-btn"} onClick={(e)=>this.switchLocationSelector(e)}>
					<img src="/images/location.png" />
				</div>
				<div className={(locationSelectorStatus ? "selected" : "")+" float-left store-type-button select-store-btn"} onClick={(e)=>this.switchLocationSelector(e)} >
					<img src="/images/store.png" />
				</div>
				<div className="clearfix"></div>
			</div>
		)
	}
}
@connect((store) => {
	return {
		deliveryFeasibility:store.deliveryFeasibility,
		storeSelection:store.storeSelection,
		currentOrderSession:store.currentOrderSession
	}
})
class DeliveryTypeSelection extends React.Component{
	switchDeliveryMode(e,r){
		if(!this.props.currentOrderSession.orderStarted){

			if(!this.props.deliveryFeasibility.loading){
				store.dispatch(switchOrderMode(r))
				if(store.getState().loginReducer.data.sub_admin.length==1){
					var _store = store.getState().loginReducer.data.sub_admin[0];
					store.dispatch({
						type:'STORE_RECEIVED',
						payload:_store
					})
					store.dispatch({
						type:'FEASIBILE_LOCATION',
						payload:_store
					})
				}
			}
		}
	}

	render(){
		var delivery_mode_class = (this.props.currentOrderSession.delivery_mode == 'delivery' ? "" : "right")
		return(
			<div className={delivery_mode_class+" order-mode"}>
				<div className={(this.props.currentOrderSession.delivery_mode == 'delivery' ? "selected" : "")+" float-left order-mode-button"} onClick={(e)=>this.switchDeliveryMode(e,"delivery")}>
					Delivery
				</div>
				<div className={(this.props.currentOrderSession.delivery_mode == 'delivery' ? "" : "selected")+" float-left order-mode-button"} onClick={(e)=>this.switchDeliveryMode(e,"pickup")} >
					Pickup
				</div>
				<div className="clearfix"></div>
			</div>
		)
	}
}


@connect((store) => {
	return {
		storeSelection:store.storeSelection
	}
})
class LocationSelector extends React.Component {
	constructor(props) {
    	super(props);
		this.state = {
			selectedOption: null
		}
	}
	componentDidMount() {
		// // console.log('Mounting..')
		const associatedStore = store.getState().loginReducer.data.sub_admin;
		if(associatedStore.length==1) {
			var _store = associatedStore[0];

			if(_store.closing_day || _store.temporarily_closed || !_store.is_active || !_store.ordering_enabled){
				store.dispatch({
					type:'STORE_NOT_ACTIVE',
					payload:_store.name
				})
				store.dispatch({
					type:'STORE_RESET',
					payload:null
				})
				return false;
			}

			store.dispatch({
				type:'STORE_RECEIVED',
				payload:_store
			})
		}
	}

	onSuggestSelect(suggest) {
		if(!suggest) {
			return
		}

		if(suggest.location) {
			this.textInput.blur()
			store.dispatch(checkDeliveryFeasibility({...suggest.location,name:suggest.label}));
		}else {
			console.log('Something wrong')
			store.dispatch({
				type:'FESIBILITY_CHECK_FAILED',
				payload:null
			})
		}
	}

	cbSelect = (selectedOption)=> {
		if(!selectedOption) return;

		this.setState({
			selectedOption
		})

		var store_id = selectedOption.value;
		var _store = _.find(store.getState().loginReducer.data.sub_admin, {biz_location_id:parseInt(store_id)});

		if(_store.closing_day || _store.temporarily_closed || !_store.is_active || !_store.ordering_enabled){

			store.dispatch({
				type:'STORE_NOT_ACTIVE',
				payload:_store.name
			})
			store.dispatch({
				type:'STORE_RESET',
				payload:null
			})
			return false;
		}
		store.dispatch({
			type:'STORE_RECEIVED',
			payload:_store
		})
		store.dispatch({
			type:"FEASIBILE_LOCATION",
			payload:_store.name
		})

	}
	render(){
		var store_name = (this.props.currentOrderSession.store ? this.props.currentOrderSession.store.name : "");
		if(store.getState().loginReducer.loggedIn && store.getState().loginReducer.data.sub_admin.length == 1){
			if(store.getState().loginReducer.data.sub_admin.length == 1 && this.props.currentOrderSession.delivery_mode=="pickup"){
				return(
					<TippyComponent title="Store can not be changed for pickup" classname="pickup-error-tippy">
						<div className="padding-10">
							{this.props.currentOrderSession.store.name.substring(0,50)+".."}
						</div>
					</TippyComponent>
				)
			}
			return (
				<div>
					<Geosuggest ref={(input) => { this.textInput = input; }}   placeholder="Enter deliverable area" onSuggestSelect={(e)=>this.onSuggestSelect(e)}/>
				</div>
			)
		}else{
			var storeSelectorStatus = this.props.storeSelection;
			if(!storeSelectorStatus.active){
				return(
					<div>
						<Geosuggest
							autocomplete="off"
							ref={(input) => { this.textInput = input; }}

							placeholder="Search location"
							onSuggestSelect={(e)=>this.onSuggestSelect(e)}/>
					</div>
				)
			}else{
				var _stores = store.getState().loginReducer.data.sub_admin;
				var _store_for_select = []
				_stores.map(o=> {
					_store_for_select.push({
						label : o.name,
						value : o.biz_location_id
					})
				})
				var selected_store = (this.props.currentOrderSession.store ? this.props.currentOrderSession.store : null)

				var selected_store_id = (selected_store ? selected_store.biz_location_id : "");
				return (<div><Select
					styles={
						{
							control: (provided, state)=>({
								...provided,
								border:"0px"
							}),

						}
					}
					optionClassName="needsclick"
					value={this.state.selectedOption ? this.state.selectedOption : ""}
					options={_store_for_select}
					placeholder="Search store"
	  				onChange={this.cbSelect}
					autoBlur={true}
					/></div>)
			}
		}
	}
}


@connect((store) => {
	return {
		loginReducer:store.loginReducer
	}
})
class StoreName extends React.Component{
	constructor(props) {
    	super(props);
	}

	render() {
		var _storeArr = this.props.loginReducer.data.sub_admin;
		var _store = _storeArr[0];
		if(_storeArr.length == 1) {
			return(
				<div className="text-center m-t-10 m-b-20">
					<span className="bold">Store:</span> {_store.name}
				</div>
			)
		}else {
			return null
		}
	}
}
