import React from 'react';
import { isMobile } from '../../js/api';

const IS_MOBILE = isMobile;

export const checkShowColumn = (sortOrder, numColumnsToShow) =>
	numColumnsToShow && IS_MOBILE ? sortOrder <= numColumnsToShow : true;

const hoverElementClickHandler = event => event.stopPropagation();

const HoverElementContainer = ({ children }) => (
	<div
		key="hover-element-container"
		className="hover-element-container"
		onClick={hoverElementClickHandler}
		// {...other}
	>
		{children}
	</div>
);

const tableCache = [];

export default function Table({
	width,
	minHeight,
	columns,
	headerHeight,
	minRowHeight,
	minWidth,
	sortBy,
	sortDirection,
	rows,
	numOrdersToShow,
	onRowClick,
	onSortChange,
	hoverElement,
	hoverElementRenderer,
	minColumnWidthGetter,
	numMobileColumns,
	activeOrderRow,
	multiBiz,
}) {

	const getColumnWidthRatio = columnKey => {
		const cacheKey = `${columnKey}-${minWidth}`

		if(tableCache[cacheKey]) {
			// console.log('returning from cache')
			return tableCache[cacheKey];
		}

		const _w = minColumnWidthGetter && minWidth
			? minColumnWidthGetter(columnKey) / minWidth
			: 1 / columns.length;
		tableCache[cacheKey] = _w;
		return _w;
	};

	const getColumnWidth = columnKey => {
		const columnWidthRatio = getColumnWidthRatio(columnKey);

		if (typeof width === 'number') {
			return columnWidthRatio * width;
		}

		return columnWidthRatio * 100 + '%';
	};

	return (
		<div
			className="custom-table-container"
			style={{ width, minHeight: minHeight || 0 }}
		>
			<div className="custom-table">
				<div
					className="custom-table-header-row"
					style={{ height: headerHeight || '50px' }}
				>
					{columns.reduce(
						(acc, { label, columnKey, sortable, sortOrder }, columnIndex) => {
							const columnWidth = getColumnWidth(columnKey);

							const sortClickEvent = onSortChange
								? {
										onClick: event => onSortChange(event, columnKey)
								  }
								: {};

							return checkShowColumn(sortOrder, numMobileColumns)
								? acc.concat(
										<div
											key={columnKey}
											className="custom-table-header-column"
											style={{ width: columnWidth }}
										>
											{sortable ? (
												<div
													className={`sortable-col${
														columnIndex === 0
															? ' custom-table-header-row-first-cell'
															: ''
													}`}
													{...sortClickEvent}
												>
													{label}
													<img
														alt="sort icon"
														src={`/images/sort${
															sortBy === columnKey
																? `-${sortDirection === 'ASC' ? 'up' : 'down'}`
																: ''
														}.svg`}
														width="12px"
													/>
												</div>
											) : (
												<div
													className={
														columnIndex === 0
															? 'custom-table-header-row-first-cell'
															: ''
													}
												>
													{label}
												</div>
											)}
										</div>
								  )
								: acc;
						},
						[]
					)}
				</div>
				<div className="custom-table-body">
					{rows.slice(0, numOrdersToShow).map((row, rowIndex) => {
						const clickHandler = onRowClick
							? { onClick: event => onRowClick(event, row, rowIndex) }
							: {};

						return (
							<div
								key={rowIndex}
								className={(row.__order_id == (activeOrderRow && activeOrderRow.show) ? "highlight-row " :" ") + "custom-table-body-row"}
								{...clickHandler}
								style={{ width, minHeight: minRowHeight || '60px' }}
							>
								{columns.reduce(
									(acc, { columnKey, sortOrder }, columnIndex) => {
										if (checkShowColumn(sortOrder, numMobileColumns)) {
											const columnWidth = getColumnWidth(columnKey);

											return acc.concat(
												<div
													key={columnKey}
													style={{ width: columnWidth }}
													className="custom-table-body-column-container"
												>
													<span
														className={`custom-table-body-column${
															columnIndex === 0
																? ' custom-table-body-row-first-cell'
																: ''
														}`}
													>
														{row[columnKey]}
													</span>
													{
														multiBiz && columnKey === 'biz_location_name' &&
														<span className="custom-table-body-column meta-info">
															{row.biz_name}
														</span>
													}
												</div>
											);
										}

										return acc;
									},
									[]
								)}

								{hoverElementRenderer ? (
									<HoverElementContainer>
										{hoverElementRenderer(row, rowIndex)}
									</HoverElementContainer>
								) : null}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
