import './SeamlessPrintInstructions.scss';

import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { chgPrntInstrctnsStage } from '../../actions/printManager';
import {
	togglePrntrSetupInstrctns,
	toggleSeamlessPrntng
} from '../../actions/printManager';
import * as sat_api from '../../js/api.js';
import { qzTrayConfigDefault } from '../../qz-print-helper';

const setDisablePrinterTimeout = (check, callback) => {
	let timeoutId;

	if (check) {
		timeoutId = setTimeout(() => {
			callback();
		}, 1000);
	}

	return function() {
		if (timeoutId) {
			clearInterval(timeoutId);
		}
	};
};

const mapStateToProps = state => {
	return {
		showSetupInstructions: state.printManager.showSetupInstructions,
		stage: state.printManager.stage,
		orderList: state.orderList.data,
		loginReducer: state.loginReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		changePrintInstructionsStage: stage =>
			dispatch(chgPrntInstrctnsStage(stage)),
		toggleSeamlessPrinting: enableSeamlessPrinting =>
			dispatch(toggleSeamlessPrntng(enableSeamlessPrinting)),
		togglePrinterSetupInstructions: showSetupInstructions =>
			dispatch(togglePrntrSetupInstrctns(showSetupInstructions))
	};
};

function SeamlessPrintInstructions({
	showSetupInstructions,
	stage,
	orderList,
	loginReducer,
	changePrintInstructionsStage,
	toggleSeamlessPrinting,
	togglePrinterSetupInstructions,
	lang,
}) {
	const [testPrintBtnBusy, setTestPrintBtnState] = useState(false);

	const [printManagerState, setPrintManagerState] = useState('');

	const [disableMainPrintDuration, setDisableMainPrintDuration] = useState(0);

	const qzTrayConfig = JSON.parse(localStorage.getItem('qzTrayConfig'));
	const [printerConfig, setPrinterConfig] = useState({
		main: qzTrayConfig ? qzTrayConfig.main : '',
		kitchen: qzTrayConfig ? qzTrayConfig.kitchen : '',
	});
	const [printerOptions, setPrinterOptions] = useState([]);

	const findPrinters = async () => {
		try {
			const data = await window.qz.printers.find();
			const dataMapped = data.map((printer) => ({
				label: printer,
				value: printer,
			}));
			setPrinterOptions(dataMapped);
		}	catch (err) {
			alert('There was an error while finding list of printers');
			console.log(err);
		}
	}

	useEffect(() => {
		const clearFunction = setDisablePrinterTimeout(
			disableMainPrintDuration,
			() => setDisableMainPrintDuration(disableMainPrintDuration - 1)
		);

		return clearFunction;
	}, [disableMainPrintDuration]);

	const [
		disableKitchenPrintDuration,
		setDisableKitchenPrintDuration
	] = useState(0);

	useEffect(() => {
		const clearFunction = setDisablePrinterTimeout(
			disableKitchenPrintDuration,
			() => setDisableKitchenPrintDuration(disableKitchenPrintDuration - 1)
		);

		return clearFunction;
	}, [disableKitchenPrintDuration]);

	const verifyPrintersClickHandler = () => {
		changePrintInstructionsStage('verify');

		printHandler('main');
		printHandler('kitchen');
	};

	const printHandler = (printerType) => {
		const { biz, name: operatorName } = loginReducer.data;
		const firstName = operatorName.split(' ')[0];
		const options = {
			biz: {
				name: biz.name,
				imageUrl: biz.logo,
				gstNo: biz.gst,
				tinNo: biz.tin,
				currCode: biz.currency_code
			},
			operator: firstName,
			printKot: printerType === 'kitchen',
			testPrint: true,
		};
		sat_api.handleSeamlessPrint(options, printerType);
		// Axios.get(`http://localhost:${sat_api.PRINTER_PORT}/`).then(res => {
		// 	if (!res) {
		// 		setPrintManagerState('Print manager utility is probably not running');
		// 		return;
		// 	}
		// 	if (res.data.satellitePrinter) {
		// 		if (!res.data.faultyPrinterInfo.errorMessage) {
		// 			Axios.post(`http://localhost:${sat_api.PRINTER_PORT}/print`, options)
		// 				.then(res => setPrintManagerState(''))
		// 				.catch(err => {
		// 					if (!ignoreError && err.response.status === 405) {
		// 						setPrintManagerState(
		// 							'One of your printers is malfunctioning. Please try again.'
		// 						);
		// 					}
		// 				});
		// 		} else {
		// 			setPrintManagerState(
		// 				'One of your printers is malfunctioning. Please try again.'
		// 			);
		// 		}
		// 	} else {
		// 		setPrintManagerState('Incorrect port');
		// 	}
		// });

		// if (printerType === 'main' && !disableMainPrintDuration) {
		// 	setDisableMainPrintDuration(10);
		// } else if (printerType === 'kitchen' && !disableKitchenPrintDuration) {
		// 	setDisableKitchenPrintDuration(10);
		// }
	};

	if (!showSetupInstructions) {
		return null;
	}

	const confirmVerificationHandler = event => {
		changePrintInstructionsStage('instructions');
		togglePrinterSetupInstructions(false);
		toggleSeamlessPrinting(true);

		localStorage.setItem('up-sat-enable-seamless-printing', true);
		localStorage.setItem('up-sat-seamless-configured', true);
	};

	const cancelVerificationHandler = event => {
		changePrintInstructionsStage('instructions');
		setPrintManagerState('');
		togglePrinterSetupInstructions(false);

		// sat_api.toggleAutoConfirm(
		// 	{
		// 		name: 'swiggy',
		// 		state: false
		// 	},
		// 	data => {
		// 		console.log('Successfully toggled state', data);
		// 	},
		// 	err => {
		// 		console.error('Unable to toggle state', err);
		// 	}
		// );
	};

	const checkPrintClientConnection = () => {
		if (!window.qz) {
			setPrintManagerState('Print plugin was not loaded, please refresh the page or make sure adblock is not blocking it');
			return;
		}
		const qzTrayConfig = JSON.parse(localStorage.getItem('qzTrayConfig')) || qzTrayConfigDefault;
		if (!window.qz.websocket.isActive()) {
			setTestPrintBtnState(true);
			setPrintManagerState('');
			window.qz.websocket.connect().then(() => {
				qzTrayConfig.connected = true;
				localStorage.setItem('qzTrayConfig', JSON.stringify(qzTrayConfig));
				setTestPrintBtnState(false);
				changePrintInstructionsStage('verify');
				findPrinters();
			}).catch((err) => {
				if (err && err.message === 'An open connection with QZ Tray already exists') {
					qzTrayConfig.connected = true;
					localStorage.setItem('qzTrayConfig', JSON.stringify(qzTrayConfig));
					setTestPrintBtnState(false);
					changePrintInstructionsStage('verify');
					findPrinters();
					return;
				}
				console.log(err);
				qzTrayConfig.connected = false;
				localStorage.setItem('qzTrayConfig', JSON.stringify(qzTrayConfig));
				setTestPrintBtnState(false);
				setPrintManagerState(
					sat_api.getLabel(lang, 'connection_failed_qz') || "Connection to print manager failed. Please run print manager and try again."
				);
			});
		} else {
			qzTrayConfig.connected = true;
			localStorage.setItem('qzTrayConfig', JSON.stringify(qzTrayConfig));
			setTestPrintBtnState(false);
			changePrintInstructionsStage('verify');
			findPrinters();
		}
	};

	const PrinterError = (
		<div
			className={`print-manager-state-text${
				stage === 'verify' ? ' text-center' : ''
			}`}
		>
			{printManagerState}
		</div>
	);

	const selectPrinter = (type, printer) => {
		const qzTrayConfig = JSON.parse(localStorage.getItem('qzTrayConfig')) || qzTrayConfigDefault;
		qzTrayConfig[type] = printer;
		localStorage.setItem('qzTrayConfig', JSON.stringify(qzTrayConfig));
		setPrinterConfig({
			...printerConfig,
			[type]: printer,
		});
	}

	return (
		<div>
			<div className="SeamlessPrintInstructions-Backdrop" />

			<div className="SeamlessPrintInstructions">
				<div
					className={`SeamlessPrintInstructions-Slides${
						stage === 'verify' ? ' second-slide' : ''
					}`}
				>
					<div>
						<div className="header">
							{sat_api.getLabel(lang, 'setup_instruction') || "Setup instruction for seamless printing"}
						</div>
						<div className="SeamlessPrintInstructions-Steps">
							<p>
								<b>1.</b> {sat_api.getLabel(lang, 'setup_instruction_1') || "Download and install the print manager"}
								{' '}
								<a
									href="/sat-print-2.0.exe"
									download
									target="_blank"
								>
									Windows
								</a>
							</p>
							<p>
								<b>2.</b> {sat_api.getLabel(lang, 'setup_instruction_2') || "Reload Satellite"}
							</p>
							<p>
								<b>3.</b> {sat_api.getLabel(lang, 'setup_instruction_3') || "Configure and print a test bill to verify it"}
							</p>
						</div>

						<div className="text-center button-container">
							<a
								className="up-button default dismiss"
								onClick={cancelVerificationHandler}
							>
								Cancel
							</a>
							<a
								className={
									(testPrintBtnBusy ? 'disabled' : '') +
									' up-button default primary'
								}
								onClick={checkPrintClientConnection}
							>
								{testPrintBtnBusy ? 'Wait..' : 'Next'}
							</a>
						</div>

						{PrinterError}
					</div>

					<div className="SeamlessPrintInstructions-TestSection">
						<div className="header">
							Configure and test your printers
						</div>
						<div className="SeamlessPrintInstructions-TestPrintContainer">
							<div style={{position: 'relative', zIndex: 3}} className="SeamlessPrintInstructions-TestPrint">
								<div className="full-width">
									<PrinterDropdown
										label="Main printer"
										selectPrinter={(printer) => selectPrinter('main', printer)}
										selectedPrinter={printerConfig.main}
										printerOptions={printerOptions}
									/>
								</div>
								<div
									className={`print-button${
										disableMainPrintDuration ? ' disabled' : ''
									}`}
									onClick={event => printHandler('main')}
								>
									⎙&nbsp;Test
								</div>
							</div>

							<div style={{position: 'relative', zIndex: 2}} className="SeamlessPrintInstructions-TestPrint">
								<div className="full-width">
									<PrinterDropdown
										label="Kitchen printer"
										selectPrinter={(printer) => selectPrinter('kitchen', printer)}
										selectedPrinter={printerConfig.kitchen}
										printerOptions={printerOptions}
									/>
								</div>
								<div
									className={`print-button${
										disableKitchenPrintDuration ? ' disabled' : ''
									}`}
									onClick={event => printHandler('kitchen')}
								>
									⎙&nbsp;Test
								</div>
							</div>
						</div>
						<div className="text-left SeamlessPrintInstructions-TestAction button-container">
							<a
								className="up-button default dismiss"
								onClick={cancelVerificationHandler}
							>
								Cancel
							</a>
							<a
								className="up-button default primary"
								onClick={confirmVerificationHandler}
							>
								It's working
							</a>
						</div>

						{PrinterError}
					</div>
				</div>
			</div>
		</div>
	);
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SeamlessPrintInstructions);

const PrinterDropdown = ({
	label, selectPrinter, selectedPrinter, printerOptions
}) => (
	<div className="inp-field-custom-label">
		<div className="custom-label">{label}</div>
		<div>
			<Select
				className="inventory-control-store custom-label-name"
				optionClassName="needsclick"
				onChange={selectPrinter}
				value={selectedPrinter}
				options={printerOptions}
				placeholder={`Select ${label}`}
				autoBlur={true}
				simpleValue={true}
			/>
		</div>
	</div>
);
