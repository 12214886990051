import React from 'react';
import ReactDOM from 'react-dom';
import './css/main.scss';
import App from './App';
import history from './app/history';
// import * as serviceWorker from './serviceWorker';
var PubSub = require('pubsub-js');
var sat_api = require('./js/api.js');



ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

PubSub.subscribe('outer_events', function (t, data) {
	console.log('called', data.type)
	switch (data.type) {
		case "modal-added":
			document.getElementsByTagName('body')[0].setAttribute('style' , 'overflow-y:hidden')
			break;
		case "modal-removed":
			document.getElementsByTagName('body')[0].setAttribute('style' , 'overflow-y:auto')
			break;
		default:

	}
})

let widgetContainerConnectionState = sessionStorage.getItem('__widget_login'); // undefined || 1

window.onmessage = function(e){
    if (e.data && e.data.type == '__widget_login') {
		skipLogin(e.data.data);
		processLogin(e.data.data);
		setWidth(e.data.device);
	}
	if (e.data && e.data.type == '__widget_styles_init') {
		if(e.data.data){
		localStorage.setItem("widgetStyles",JSON.stringify(e.data.data))
		}
	}
	if (e.data && e.data.type == '__widget_styles_change') {
		if(e.data.data){
		localStorage.setItem("widgetStyles",JSON.stringify(e.data.data))
		window.location.reload();
		}
    }
	else if(e.data && e.data.type == '__no_handler_for_print'){
		window.print();
	}
};

const setWidth = (device)=> {
	if(device && device.width) {
		sessionStorage.setItem('device_width', device.width);
	}
}
const skipLogin = (_data)=>{
	const { basic_auth, ...data } = _data;
	const savedAuth = sat_api.upStorage.getItem('basic_auth');
	if(basic_auth == savedAuth) {
		console.log('skipping login')
		return
	}
}
const processLogin = (_data)=>{
	const { basic_auth, ...data } = _data;

	data.version = sat_api.VERSION_NO;
	sat_api.upStorage.setItem({ key: 'auth', value: data }, true);
	sat_api.upStorage.setItem({ key:'jwt', value: basic_auth}, true);

	var ifMenuExists = sat_api.upStorage.getItem('biz_id_for_current_menu');

	if (ifMenuExists && ifMenuExists != data.biz.biz_id) {
		sat_api.upStorage.removeItem('menu');
		sat_api.upStorage.removeItem('category');
	}

	sessionStorage.setItem('__widget_login', 1);
	window.location.reload();
}

// send only after login is successfull, and relaod is done.
if(widgetContainerConnectionState == 1) {
	console.log('sending message from satellite')
	// sessionStorage.setItem('__widget_login', 2);
	// send the message back to iframe
	window.parent.postMessage({
		type:'__widget_login',
		data: true
	}, '*');
}
 