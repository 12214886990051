import Moment from './clientTime';
var PubSub = require('pubsub-js');


const appLanguageInStorage = localStorage.getItem('appLanguage');

export const appLanguage = (state = {
	lang:  appLanguageInStorage || "jp"
}, action)=>{
	switch (action.type) {
		case "UPDATE_APP_LANGUAGE":
			return {
				lang:action.payload
			 }
			break;
		default:
			return state;

	}
}




export const disablePushOrder = (state = {
	id:[]
}, action)=>{
	switch (action.type) {
		case "UPDATE_DISABLE_PUSH_ORDER":
			return {
				id:action.payload
			 }
			break;
		default:
			return state;

	}
}

export const bizInfo = (state = {
	data:null
}, action)=>{
	switch (action.type) {
		case "BIZ_INFO_LOADED":
			return { data:action.payload }
			break;
		default:
			return state;

	}
}

export const appSettings = function(state = {
	notification:true,
	notification_future:true,
	print_kot:false,
	print_order_slip:false
}, action){
	switch (action.type) {
		case "UPDATE_APP_SETTINGS":
			return {...state, ...action.payload};
			break;
		default:
			return state;
	}
}


export const surgeDataState = function(state = {loading:false, error:null, success:false}, action) {
	switch (action.type) {
		case 'SET_SURGE_INIT':
			return {...state, loading:true, error:false, success:false}
			break;
		case 'SET_SURGE_DONE':
			return {...state, loading:false, error:false, success:true}
			break;
		case 'SET_SURGE_FAILED':
			return {...state, loading:false, error:action.payload,success:false}
			break;
		default:
			return state
	}
}

export const outletPlatforms = function(state={loading: false, store_id: null, platforms: null, error: false}, action) {
	switch (action.type) {
		case "OUTLET_PLATFORMS_INIT":
			return {...state, loading:true, store_id: action.payload.id, platforms:null, error:false};
			break;
		case "OUTLET_PLATFORMS_DONE":
			return {...state, loading:false, platforms: action.payload.platforms, error:false};
		case "OUTLET_PLATFORMS_FAILED":
			return {...state, loading:false, platforms: null, error:action.payload.error};
		default:
			return state;
	}
}

export const outlettakeAwayStatus = function(state = {loading:false, data:null, error:null, update_error:null}, action) {
	switch (action.type) {
		case "OUTLET_TAKEAWAY_INIT":
			return {...state, loading:true, data:null, error:null, update_error:null, store_id:action.payload}
			break;
		case "OUTLET_TAKEAWAY_DONE":
			return {...state, loading:false, data:action.payload, error:false, update_error:null}
			break;
		case "OUTLET_TAKEAWAY_FAILED":
			return {...state, loading:false, error:action.payload, update_error:null}
			break;
		default:
			return state;
	}
}

export const outletLogistics = function(state = {loading:false, data:null, error:null, update_error:null}, action) {
	switch (action.type) {
		case "OUTLET_LOGISTICS_INIT":
			return {...state, loading:true, data:null, error:null, update_error:null, store_id:action.payload}
			break;
		case "OUTLET_LOGISTICS_DONE":
			return {...state, loading:false, data:action.payload, error:false, update_error:null}
			break;
		case "OUTLET_LOGISTICS_FAILED":
			return {...state, loading:false, error:action.payload, update_error:null}
			break;
		case "OUTLET_LOGISTICS_RESET":
			return {...state, loading:false, error:null, data:null, update_error:null}
			break;
		case "SET_OUTLET_LOGISTICS_UPDATE_INIT":
			return {...state, loading:true, update_error:null}
		case "SET_OUTLET_LOGISTICS_UPDATE_DONE":
			return {
				...state,
				loading:false,
				error:false,
				data:action.payload,
				update_error:null
			}
		case "SET_OUTLET_LOGISTICS_UPDATE_FAILED":
			return {...state, loading:false, error:false, data:action.payload.resp, update_error:action.payload.error}
		default:
			return state;
	}
}

export const inventorySearch = function(state = {loading:false, kw:"", active:false}, action) {
	switch (action.type) {
		case "INVENTORY_SEARCH_ENABLE":
			return {...state, active:true};
			break;
		case "INVENTORY_SEARCH_DISABLE":
			return {...state, active:false, kw:""};
			break;
		case "INVENTORY_SEARCH_INPUT":
			return {
				...state,
				kw: action.payload,
			};
		case "INVENTORY_SEARCH_INIT":
			return {...state, loading:true, kw:action.payload}
			break;
		case "INVENTORY_SEARCH_DONE":
			return {...state, loading:false, msg:action.payload}
			break;
		default:
			return state;
	}
}
export const inventory = function(state = {
	loading:false,
	error:false,
	data:null,
	location_id:null,
	allupdate:false,
	selected_cat:null,
	selected_tags: null,
	categories:[],
	error_message:"Unknown error, try again!"
}, action){
	switch (action.type) {
		case "SET_SELECTED_CAT_INVENTORY":
			return  {...state, selected_cat:action.payload}
			break;
		case "SET_SELECTED_TAGS_INVENTORY":
			return  {...state, selected_tags:action.payload}
		case "INVENTORY_SERVER_COPY":
			return  {...state, loading:false, error:false, data_copy:action.payload}
			break;
		case "INVENTORY_FETCH_INIT":
			return {...state, loading:true,location_id:action.payload.location_id, data:null}
			break;
		case "INVENTORY_FETCH_DONE":
			return {...state, loading:false, error:false, data:action.payload}
			break;
		case "INVENTORY_CAT_FETCH_DONE":
			return {...state, loading:false, error:false, categories:action.payload}
			break;
		case "INVENTORY_FETCH_FAILED":
			return {...state, loading:false, error:true, error_message:action.payload}
			break;
		case "INVENTORY_UPDATE_SETTINGS":
			return {...state, allupdate:action.payload}
		default:
			return state;
	}
}

export const activeOrderDetail = function(state = {show:false}, action) {
	switch (action.type) {
		// NOTE: bad code ahead
		case "SHOW_HIDE_ORDER_DETAIL_MODAL":
			if(action.payload) {
				PubSub.publish('outer_events', {type:'modal-added'});
			}else {
				PubSub.publish('outer_events', {type:'modal-removed'});
			}
			return {
				...state,
				show:action.payload
			}
			break;
		default:
			return state;

	}
}
export const reqCallBackReducer = function(state = {loading:false, showModal:false, error:false, url:null, showNoti:false}, action) {
	switch(action.type) {
		case 'SHOW_REQUEST_CALLBACK_MODAL':
			return {...state,loading:false, showModal:true, url:action.payload.url, error:false}
			break;
		case 'HIDE_REQUEST_CALLBACK_MODAL':
			return {...state,loading:false, showModal:false}
			break;
		case 'CALLBACK_REQ_INIT':
			return {...state,loading:true, error:false}
			break;
		case 'CALLBACK_REQ_FAILED':
			return {...state,loading:false, error:true}
			break;
		case 'CALLBACK_REQ_DONE':
			return {...state,loading:false, error:false, showModal:false, showNoti:true}
			break;
		case 'CALLBACK_REQ_RESET':
			return {...state,showNoti:false}
			break;
		default:
			return state;
	}
}
export const itemSearchStatus = function(state={loading:false}, action) {
	switch(action.type) {
		case 'ITEM_SEARCH_INIT':
			return {...state,loading:true, done:false, data:action.payload}
			break;
		case 'ITEM_SEARCH_FAILED':
			return {...state,loading:false, done:false}
			break;
		case 'ITEM_SEARCH_FINISHED':
			return {...state,loading:false,done:true}
			break;
		case 'RESET_SERVER_SEARCH_STATUS':
			return {...state,loading:false,done:false}
		default:
			return state;
	}
}

const INITAL_ORDER_LIST_STATE = {
	dateFilter: (new Date().getTime() - 86400000),
	loading:true,
	showCalender:false,
	data:{
		orders:[]
	},
	loading_more: false
}

export const allOrderList = function(state = INITAL_ORDER_LIST_STATE, action){
	switch(action.type) {
		case 'RESET_ORDER_LIST':
			return INITAL_ORDER_LIST_STATE;
		case 'ALL_ORDER_FETCHED':
			return {
				...state,
				data: action.payload.data,
				dateFilter: action.payload.dateFilter,
				loading:false,
				showCalender: false,
				loading_more: false
			}
			break;
		case 'ALL_ORDER_FETCH_LOADING_MORE':
			return {
				...state,
				loading_more: true
			}
			break;
		case 'ALL_ORDER_FETCH_LOADING':
			return {
				...state,
				loading: true,
				showCalender: false
			}
			break;
		case 'ALL_ORDER_FETCH_SHOW_CAL':
			return {
				...state,
				showCalender: true
			}
			break;
		case 'ALL_ORDER_FETCH_HIDE_CAL':
			return {
				...state,
				showCalender: false
			}
			break;
		default:
			return state;
	}
}

export const orderListReducer = function(state = {
	loading:true
}, action){
	switch(action.type) {
		case 'FETCH_ORDERS_FULFILLED':
			return {
				...state,
				fetched:true,
				data:action.payload,
				loading:false
			}
			break;
		case 'FETCH_ORDERS_INIT':
			return {...state,loading:true}
			break;
		default:
			return state;
	}
}

export const orderDetailReducer = function(state = {}, action){
	switch(action.type) {
		case 'FETCH_ORDER_STATUS_RESET':
			return Object.assign({}, state, {
				stealthUpdate:true
			})
			break;
		case 'FETCH_ORDER_DETAIL_INIT':
			return Object.assign({}, state, {
				fetched:false, data:action.payload
			})
			break;
		case 'FETCH_ORDER_DETAIL_FULFILLED':
			return Object.assign({}, state, {
				fetched:true,
				data:action.payload,
				stealthUpdate:false
			})
			break;
		case 'RESET_ORDER_DETAIL':
			return Object.assign({}, state, {
				fetched:false, data:null, stealthUpdate:false
			})
			break;
		default:
			return state;
	}
}

export const cRouteReducer = function(state = { page:'index' }, action) {
	switch (action.type) {
		case 'SET_ACTIVE_LINK':
			return Object.assign({}, state, action.payload)
			break;
		default:
			return state;
	}
}



export const orderStateReducer = function(state = {loading:false, new_state:null, id:null}, action) {
	switch (action.type) {
		case 'STATUS_CHANGE_MODAL':
			return Object.assign({}, state, action.payload)
			break;
		case 'FETCH_ORDER_STATUS_INIT':
			return Object.assign({}, state,{loading:true})
			break;
		case 'FETCH_ORDER_STATUS_FULFILLED':
			return Object.assign({}, state,{fetchDetail:true, loading:true})
			break;
		case 'FETCH_ORDER_STATUS_FAILED':
			return Object.assign({}, state,{success:false, loading:false})
			break;
		case 'FETCH_ORDER_DETAIL_AFTER_STATUS_FULFILLED':
			return Object.assign({}, state,{fetchDetail:false, loading:false})
			break;
		default:
			return state;
	}
}

export const gReducer = function(state = {active:false, msg:''}, action){
	switch (action.type) {
		case 'FETCH_ORDER_DETAIL_FAILED':
			return Object.assign({}, state, {active:true , msg:action.payload.msg , id:action.payload.id})
			break;
		case 'FETCH_ORDER_DETAIL_FULFILLED':
			return Object.assign({}, state, {active:false , msg:'' , id:''})
			break;
		default:
			return state

	}
}



export const callReducer = function(state = {
		show:false,
		modalactive:false,
		caller_data:{
			contact_info:{
				name:"Unknown caller",
				phone:"No number"
			},
			order_count:0,
			total_order_value:0,
			average_order_value:0,
			cvi:0,
		}
	}, action){

	switch (action.type) {
		case 'CALL_RECEIVED':
			return {...state, show:true, data:action.payload}
			break;
		case 'OPEN_CALLMODAL':
			return {...state, modalactive:true}
		case 'MINIMIZE_CALLMODAL':
			return {...state, modalactive:false}
		case 'FETCH_CUSTOMER_SATUS_FULFILLED':
			return {...state, caller_data:action.payload}
			break;
		case 'DIMSMISS_CALL_MODAL':
			return {...state, show:false, modalactive:false, data:null, caller_data:{
				contact_info:{
					name:"Unknown caller",
					phone:"No number"
				},
				order_count:0,
				total_order_value:0,
				average_order_value:0,
				cvi:0,
			}}
			break;
		default:
			return state

	}
}
export const currentOrderPhoneno = function(state = {phone_no:""}, action){
	switch (action.type) {
		case 'PHONE_NO_RECEIVED':
			return {...action.payload}
			break;
		case "DROP_ORDER":
			return {phone_no:""}
			break;
		default:
			return state

	}
}

export const recommendedItems = function(state = {load:false,error:false,items:null},action){
	switch (action.type) {
		case "RECO_FETCH_INIT":
			return {...state,load:true}
			break;
		case "RECO_FETCH_SUCCESS":
			return {...state,load:false, error:false, items:action.payload}
			break;
		case "RECO_FETCH_FAIL":
			return {...state,load:false, error:true, items:action.payload}
			break;
		default:
			return state
	}
}
export const currentOrderSession = function(state = {
		selected_cat:null,
		menu:{
			loading:false,
			error_message:"Unknown error, try again!"
		},
		order_channel:"satellite",
		external_channel_id:"",
		category:{loading:false},
		nextPage:"init",
		store_id:null,
		orderStarted:false,
		delivery_mode:"delivery",
		user:null,
		userloading:false,
		cart:{items:[],combos:[],meta:{item_count:0, total:0, total_tax:0}},
		delivery_info:{address_id:null, guest_address:null, payment_mode:"cash", order_note:"", payment_aggregator: "paytm"},
		coupon:{
			code:null,
			error:false,
			data:null
		},
		delivery_time:null,
		delivery_date:new Date().getTime(),
		reorder:{
			loading:false,
			data:null,
			msg:null,
			error:false
		}
	},action){
	switch (action.type) {
		case "CREATE_USER_INIT":
			return {...state,user:null,userloading:true,userNotFound:false}
			break;
		case "CREATE_USER_SUCCESS":
			return {...state,user:action.payload,userloading:true,userNotFound:false}
			break;
		case "CREATE_USER_FAILED":
			return {...state,user:null,userloading:false,userNotFound:false}
			break;
		case "COUPON_INIT":
			return {...state,coupon:{loading:true}}
			break;
		case "COUPON_FAILED":
			return {...state,coupon:{error:true, loading:false, data:action.payload}}
			break;
		case "COUPON_ADDED":
			return {...state,coupon:{error:false, loading:false, data:action.payload}}
			break;
		case "COUPON_RESET":
			return {...state,coupon:{
				code:null,
				error:false,
				data:null
			}}
			break;
		case "PRE_PROCESS_INIT":
			return {...state,coupon:{
				code:null,
				error:false,
				data:null
			}}
			break;
		case "USER_OBJ_FETCH_INIT":
			return {...state,userloading:true,userNotFound:false}
			break;
		case "USER_OBJ_FETCH_FAILED":
			return {...state,user:null,userloading:false,userNotFound:true}
			break;
		case "USER_OBJ_FETCH_DONE":
			var new_state = {...state}
			new_state.user = action.payload
			new_state.userloading = false
			return new_state
			break;
		case "DROP_ORDER":
			return {...state,
				order_channel:"satellite",
				external_channel_id:"",
				selected_cat:null,
				nextPage:"init",
				delivery_mode:"delivery",
				orderStarted:false,
				order_channel: null,
				user:null,
				userloading:false,
				cart:{items:[],combos:[],meta:{item_count:0, total:0, total_tax:0}},
				delivery_info:{address_id:null, guest_address:null, payment_mode:"cash", order_note:""},
				store:null,
				coupon:{
					code:null,
					error:false,
					data:null
				},
				delivery_time:null,
				delivery_date:new Date().getTime(),
				reorder:{
					loading:false,
					data:null,
					msg:null,
					error:false
				},
				savingCustomerNameStatus:""
			}
			break;
		case "ORDER_PLACED":
			return {...state,
				order_channel:"satellite",
				external_channel_id:"",
				selected_cat:null,
				nextPage:"thankyou",
				delivery_mode:"delivery",
				orderStarted:false,
				user:null,
				userloading:false,
				cart:{items:[],combos:[],meta:{item_count:0, total:0, total_tax:0}},
				delivery_info:{address_id:null, guest_address:null, payment_mode:"cash", order_note:""},
				coupon:{
					code:null,
					error:false,
					data:null
				},
				delivery_time:null,
				delivery_date:new Date().getTime(),
				successOrder:action.payload,
				savingCustomerNameStatus:""
			}
			break;
		case "START_CHECKOUT":
			return {...state,nextPage:"checkout"}
			break;
		case "ORDER_STARTED":
			return {...state,orderStarted:true,nextPage:'menu'}
			break;
		case "SWITCH_ORDER_MODE":
			return {...state,delivery_mode:action.payload}
			break;
		case "STORE_RECEIVED":
			return {...state,store:action.payload}
			break;
		case "STORE_RESET":
			return {...state,store:null}
			break;
		case "ORDER_STEPS_PROCEED":
			return {...state,nextPage:'checkout'}
			break;
		case "MENU_LOADING_INIT":
			return {...state,menu:{...state.menu,loading:true,error_load: false}}
			break;
		case "MENU_LOADING_FAILED":
			return {...state,menu:{loading:false, error_load: true, error_message:action.payload}}
			break;
		case "MENU_LOADED":
			return {...state,menu:{loading:false,data:action.payload, error_load: false}}
			break;
		case "CATEGORY_LOADED":
			return {...state,category:{loading:false,data:action.payload, error_load: false}}
			break;
		case "CATEGORY_LOADING_FAILED":
			return {...state,category:{loading:false,data:null, error_load: true}}
			break;
		case "CATEGORY_LOADING_INIT":
			return {...state,category:{loading:true,data:null, error_load: false, ...action.payload, ...state.category}}
			break;
		case "ITEM_ADDED":
			return {...state,cart:action.payload}
			break;
		case "CLEAR_CART":
			return {...state, order_channel: null, cart:{items:[],combos:[],meta:{item_count:0, total:0, total_tax:0}}}
			break;
		case "SET_DELIVERY_INFO":
			return {...state,delivery_info:action.payload}
			break;
		case "SET_DELIVERY_TIME":
			return {...state, delivery_time:action.payload}
			break;
		case "SET_DELIVERY_SLOT":
			return {...state, slot:action.payload}
			break;
		case "SET_DELIVERY_DATE":
			return {...state,delivery_date:action.payload}
			break;
		case "CHANGE_ORDER":
			return {...state,nextPage:"menu"}
			break;
		case "REORDER_INIT":
			return {...state, reorder:{loading:true, error:false, msg:null, data:null}}
			break;
		case "REORDER_SUCCESS":
			return {...state, reorder:{loading:false, error: false, data:action.payload, msg:null}}
			break;
		case "REORDER_FAILED":
			return {...state, reorder:{loading:false, error: true, msg:action.payload, data:null}}
			break;
		case "SET_CUSTOMER_NAME":
			var new_state = {...state}
			new_state.user.name = action.customer_name;
			return new_state
			break;
		case 'SET_EXTERNAL_CHANNEL_ID':
			return {...state, external_channel_id: action.payload}
			break;
		case 'SET_ORDER_CHANNEL':
			return {...state, order_channel: action.payload}
			break;
		case 'STARTED_SAVING_CUSTOMER_NAME':
			return {...state, savingCustomerName: true, savingCustomerNameStatus:"Saving.."}
			break;
		case 'FINISHED_SAVING_CUSTOMER_NAME':
			return {...state, savingCustomerName: false, savingCustomerNameStatus: "Saved!"}
			break;
		case 'ERROR_SAVING_CUSTOMER_NAME':
			return {...state, savingCustomerName: false, savingCustomerNameStatus:action.payload.error_message}
			break;
		case 'RESET_SAVING_CUSTOMER_NAME':
			return {...state, savingCustomerName: false, savingCustomerNameStatus:""}
			break;
		case 'SET_SELECTED_CAT':
			return {...state, selected_cat:action.payload}
		default:
			return state
	}
}

export const deliveryFeasibility = function(state = {loading:false, error:false, active:false}, action){
	switch (action.type) {
		case 'FEASIBILITY_CHECK_INIT':
			return {...state,loading:true, error:false, active:true, text:"Checking delivery feasibility.."}
			break;
		case 'FEASIBILE_LOCATION':
			return {...state,loading:false, store:action.payload, error:false , active:false}
			break;
		case 'NOT_FEASIBILE_LOCATION':
			return {...state,store:null, loading:false, text:action.payload.name+" is out of delivery area.", error:true, active:true,data:action.payload}
			break;
		case 'STORE_NOT_ACTIVE':
			return {...state,store:null, loading:false, text:action.payload+" is not active for ordering", error:true, active:true,data:action.payload}
			break;
		case 'FEASIBILITY_CHECK_FAILED':
			return {...state,store:null, loading:false, text:action.payload, error:true , active:true}
			break;
		case "DROP_ORDER":
			return {loading:false, error:false, active:false}
			break;
		default:
			return state

	}
}

export const selectedComplexItem = function(state={itemObj:null, show:false} ,action){
	switch (action.type) {
		case "ADD_NEW_ITEM_INIT":
			var itemClone = {...action.payload.item}
			itemClone.options = [];
			PubSub.publish('outer_events', {type:'modal-added'});
			return {show:true,itemObj:action.payload, handling_item:itemClone}
			break;
		case "ITEM_ADDED":
			return {show:false,itemObj:null}
			break;
		case "HANDLE_COMPLEX_ITEM":
			return {...state,handling_item:action.payload}
			break;
		case "SET_COMPLEX_ITEM_VIEW":
			return {...state,view:action.payload}
			break;
		case "CLOSE_COMPLEX_ITEM":
			PubSub.publish('outer_events', {type:'modal-removed'});
			return {itemObj:null, show:false}
			break;
		default:
			return state
			break;

	}
}

export const cartVisibility = function(state={show:false}, action){
	switch (action.type) {
		case "SHOW_CART":
			return {show:true}
			break;
		case "HIDE_CART":
			return {show:false}
			break;
		default:
			return state
			break;
	}
}

export const placingOrder = function(state={loading:false, error:false}, action){
	switch (action.type) {
		case "ORDER_PLACE_INIT":
			return {...state, loading:true}
			break;
		case "ORDER_PLACED":
			return {...state, loading:false, error:false, data:action.payload}
			break;
		case "ORDER_FAILED":
			return {...state, loading:false, error:true}
			break;
		case "ORDER_VALIDATION_ERROR":
			return {...state, loading:false, error:true, errorMessage: action.message}
			break;
		case "RESET_ORDER_ERRORS":
			return {...state, loading:false, error:true, errorMessage: "", data: {}}
			break;
		default:
			return state;

	}
}

export const preprocessedData = function(state={loading:false, error:false}, action){
	switch (action.type) {
		case "PRE_PROCESS_INIT":
			return {...state, loading:true}
			break;
		case "PRE_PROCESS_SUCCESS":
			return {...state, loading:false, error:false, data:action.payload}
			break;
		case "PRE_PROCESS_FAILED":
			return {...state, loading:false, error:true, data:action.payload}
			break;
		default:
			return state;
	}
}

export const storeSelection = function(state={active:false}, action){
	switch (action.type) {
		case "SELECT_STORE_DROPDOWN":
			return {active:true}
			break;
		case "SELECT_LOCATION_GEO":
			return {active:false}
			break;
		default:
			return state;

	}
}


export const usersInUrl = function(state=[], action) {
  let payload = action.payload;
  switch (action.type) {
    case "USER_ENTERED_URL":
        return state.concat(payload);
	    break;
    case "USER_EXITED_URL":
		return state.filter((u) => {
            return u.user_id != payload.user_id
        });
		break;
	default:
		return state;
  }
}

export const userSync = function(state={syncing:false}, action){
	switch (action.type) {
		case "USER_DATA_SYNC_INIT":
			return {...state, syncing:true}
			break;
		case "USER_DATA_SYNC_FINISHED":
			return {...state, syncing:false}
			break;
		case "USER_DATA_SYNC_FAILED":
			return {...state, syncing:false}
			break;
		default:
			return state
	}
}

export const deliveryDatePicker = function(state={show:false}, action){
	switch (action.type) {
		case "SHOW_DATE_PICKER":
			return {...state, show:true, selectedDate: action.payload.selectedDate}
			break;
		case "HIDE_DATE_PICKER":
			return {...state, show:false, selectedDate: null}
			break;
		default:
			return state
	}
}


export const activeSurgesArr = function(state = [], action){
	switch (action.type) {
		case 'SET_ACTIVE_SURGES':
			return action.payload.slice();
			break;
		default:
			return state;
	}
}

export const displayCrossPromotionEnhancement = function(state=[],action){
	switch (action.type) {
		case 'DISPLAY_CROSS_PLATFORM_ENHANCEMENT':
			return {...state,showCrossPlatformEnhancement:true};
		case 'HIDE_CROSS_PLATFORM_ENHANCEMENT':
			return {...state,showCrossPlatformEnhancement:false};
		default:
			return state;
	}
}
