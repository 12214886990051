import _ from 'lodash';

export const extractCityPinFormGoogle = (data, callback, errCallback)=>{
	const pincode = _.find(data, function(i,d){
		return _.find(i.types, function(j,e){
			return j==='postal_code'
			})
		})

	const city = _.find(data, function(i,d){
		return _.find(i.types, function(j,e){
			return j==='locality'
			})
		})

	return {
		pincode: pincode ? pincode.short_name : "",
		city: city ? city.short_name : ""
	}
}


export const timeInMilli = (data)=> {
	var hr = Number(data.hr);
	var min = Number(data.min);
	var mer = data.mer;

	if(mer == 'PM' && hr<12) {
		var hr = hr +12;
	}
	var today = new Date();
	var selectedTimeToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hr, min, 0);
	return selectedTimeToday.getTime();
}

export const miliTo12Hrs = (data)=> {
	var time = new Date(data.timeinmilli);
	var hr = time.getHours();
	var min = time.getMinutes();
	var mer = 'AM'

	if(hr>=12) {
		mer = "PM"
	}

	if(hr>12) {
		hr = hr-12;
	}
	if(hr == 0) {
		hr = 12;
		mer = "AM"
	}


	return {
		hr:hr,
		min:min,
		mer:mer
	}
}


export const upPriority = (totalTime, timeLeft)=> {
	const frac = timeLeft/totalTime;

	if(frac < 0.3) {
		return 'high'
	}
	if(frac < 0.5) {
		return 'mid'
	}
	return 'low'
}


export const cmpVersion = (a, b)=>{
    var i, cmp, len, re = /(\.0)+[^\.]*$/;
    a = (a + '').replace(re, '').split('.');
    b = (b + '').replace(re, '').split('.');
    len = Math.min(a.length, b.length);
    for( i = 0; i < len; i++ ) {
        cmp = parseInt(a[i], 10) - parseInt(b[i], 10);
        if( cmp !== 0 ) {
            return cmp;
        }
    }
    return a.length - b.length;
}


export const commifyNumber = (num)=>{
		if (num === NaN || num === 'NaN') {
			return 0;
		}
    return new Intl.NumberFormat('en-us').format(Number(num));
}
