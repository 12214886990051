import React, { useCallback, useMemo } from 'react';

// third party
import { connect } from 'react-redux';
import Select from 'react-select';

// redux
import { store } from '../store.js';
import { getInventoryItemsByCat } from '../actions';

const CategoryDropDown = (props) => {
	const formattedData = useMemo(() => {
		return props.inventory.categories.map((category) => ({
			value: category.id,
			label: category.name,
		}))
	}, [ props.inventory.categories ]);

	const handleSelect = useCallback((e, inventory) => {
		store.dispatch(getInventoryItemsByCat({
			cat_id: e.value,
			location_id: inventory.location_id
		}));
		store.dispatch({
			type: "SET_SELECTED_CAT_INVENTORY",
			payload: e
		});

		// remove tag selection
		store.dispatch({
			type: "SET_SELECTED_TAGS_INVENTORY",
			payload: null,
		});
	}, []);

	return (
		<Select
			className="category-dropdown-inventory"
			optionClassName="needsclick"
			onChange={(e) => handleSelect(e, props.inventory)}
			value={props.inventory.selected_cat}
			options={formattedData}
			placeholder= 'Select category'
			autoBlur={true}
		/>
	);
}
export default connect((store) => ({
	inventory:store.inventory,
}))(CategoryDropDown)
