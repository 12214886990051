import _ from 'lodash';
const sat_api = require('../js/api.js');

const getLoginInitialState = () => {
  if (sat_api.upStorage.getItem('auth') && (sat_api.upStorage.getItem('auth').version == sat_api.VERSION_NO)) {
    return {
      loginInit: false,
      loggedIn: true,
      msg: '',
      data: sat_api.upStorage.getItem('auth')
    }
  } else {
    return {
      loginInit: false,
      loggedIn: false,
      msg: '',
      settings: {
        "notification": true,
        "notification_future": true,
        "print_kot": false
      }
    }
  }
}

const loginReducer = function(state = getLoginInitialState(), action){
	switch (action.type) {
		case 'LOGIN_INIT':
			return { ...state, loginInit:true, loggedIn:false, msg:'Logging in..' }
		case 'LOGIN_SUCCESS':
			return { ...state, loginInit:false, loggedIn:true, msg:'', data:action.payload }
		case 'LOGIN_FAIL':
			return { ...state, loginInit:false, loggedIn:false, msg:action.payload, error:true }
		case 'LOGOUT':
			return { ...state, loginInit:false, loggedIn:false, msg:'' }
		case 'UPDATE_SESSION_SETTINGS':
			return { ...state, settings:action.payload }
		case 'UPDATE_PLATFORM_SETTINGS':
			const authData = _.cloneDeep(sat_api.upStorage.getItem('auth'));
			const updatedData = {
				...authData,
				biz: {
					...authData.biz,
					platforms: authData.biz.platforms.map(platform =>
						platform.name === action.payload.platformName
							? {
									...platform,
									auto_confirm_enabled: action.payload.autoConfirmEnabled
							  }
							: platform
					)
				}
			};

			sat_api.upStorage.setItem('auth', updatedData);

			console.log('updatedData is ', updatedData);

			return { ...state, data: updatedData };
		default:
			return state
	}
}

export default loginReducer;
