const cancelledOrderNoti = function(state = {
	orders: [],
	show: true
}, action) {
	switch (action.type) {
		case 'UPDATE_NEW_CANCELLED_ORDER':
			return {
				...state,
				...action.payload
			}
		default:
			return state
	}
}

export default cancelledOrderNoti
