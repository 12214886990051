const sat_api = require('./js/api.js');

const CERT = `
-----BEGIN CERTIFICATE-----
MIIDsjCCApoCCQDK2irPLPSGUjANBgkqhkiG9w0BAQsFADCBmTELMAkGA1UEBhMC
SU4xEjAQBgNVBAgMCUthcm5hdGFrYTESMBAGA1UEBwwJQmVuZ2FsdXJ1MRMwEQYD
VQQKDApVcmJhbnBpcGVyMQ0wCwYDVQQLDARUZWNoMRkwFwYDVQQDDBAqLnVyYmFu
cGlwZXIuY29tMSMwIQYJKoZIhvcNAQkBFhRzYWphbkB1cmJhbnBpcGVyLmNvbTAg
Fw0yMDAyMDcxMzE0NDJaGA8yMDUxMDgwMjEzMTQ0MlowgZkxCzAJBgNVBAYTAklO
MRIwEAYDVQQIDAlLYXJuYXRha2ExEjAQBgNVBAcMCUJlbmdhbHVydTETMBEGA1UE
CgwKVXJiYW5waXBlcjENMAsGA1UECwwEVGVjaDEZMBcGA1UEAwwQKi51cmJhbnBp
cGVyLmNvbTEjMCEGCSqGSIb3DQEJARYUc2FqYW5AdXJiYW5waXBlci5jb20wggEi
MA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDN5AexB/BqmiiOdGvE9iRi/Qwm
RvkBf3WfErFmSB5oWB/29eQ4DhmrAF49pQv7lu6SAOv65RV6gJtsRtatzlICn340
tihst3zUcpbEHvm9QIXlTwmVzsdYLpCC48z9St0aOTs7Z9MvZVGJ5WlAzTgzTWYQ
dEH8wTjUHcwNaGXKeB9dqpZzA1EGQJieFjlO9JU07Q3+qGRK/3ZXa8C3507oL7Uk
sq4hULKbIM1ww/gtToNabzoevYMs0q4LkctjFyPU0zjQGzfj1TDoMdvC99RpBxBN
ZsIx8KQyTpJYHlRbfgtQy8462jEu/Rzr4f6mxkBaKKx5F/z75qw1iwRM+YtPAgMB
AAEwDQYJKoZIhvcNAQELBQADggEBAKNxneMjmQJyMYp0YCcSftm/sYG/9os2FPVF
c36RJIO6cqfNB7f9U6UY/8hyReBCj/+MYfS8v+XbTyFNa4+CjiNYUtJ6jNwnwvY9
zsh4+w3dCH7UAyo9eXJODYCkcYkj1B8JWb67a5a7OWLypZnJ3Ehl2UQcgyr/ZPjp
9JkWc5fhuopu52AoXDXD0+3OENmoHiT7dy5HWSmbNH7xHwv+aJ4uo3qZGy/7ypvB
PixIFww9DPRaecvQRsWkhuPEFltW0WJVBZzPMa0N3w2ZpWjIwQEhRBSYblmEAtXo
sqSWE6tcO9f8110l2PAxxGs6VlMcQsRlk2XmOrV/uLc9flDaXHE=
-----END CERTIFICATE-----
`;

const KEY_UPDATED = `
-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEAzeQHsQfwapoojnRrxPYkYv0MJkb5AX91nxKxZkgeaFgf9vXk
OA4ZqwBePaUL+5bukgDr+uUVeoCbbEbWrc5SAp9+NLYobLd81HKWxB75vUCF5U8J
lc7HWC6QguPM/UrdGjk7O2fTL2VRieVpQM04M01mEHRB/ME41B3MDWhlyngfXaqW
cwNRBkCYnhY5TvSVNO0N/qhkSv92V2vAt+dO6C+1JLKuIVCymyDNcMP4LU6DWm86
Hr2DLNKuC5HLYxcj1NM40Bs349Uw6DHbwvfUaQcQTWbCMfCkMk6SWB5UW34LUMvO
OtoxLv0c6+H+psZAWiiseRf8++asNYsETPmLTwIDAQABAoIBADK+OIcRBdfAFq7F
UqpAX8dlxlDIvSX5W+vQl5g1vgjJb1dWUuDd2MWkWZWnz8Q59qJVR9OWEi0W9TdC
XjaH2v56gIhNPLlOn51cgHvlhnd2J4nUaPBa/hZlo4gezK7AfK2x3T9O32WW+vmZ
aM9nxKogMT9s57Z7iJ3+mgPT7wJvF1+wS+N8CHsSYCGfbF+9LMF4ndhIDDNVOhZR
ctcPJ0qCzkygOmh783CHuhZ8uliNQVDlJsriqN1jNbJqO3d1HOduN46HNCHF30jt
0yTEYx5X8eojiIRzfwxBV3BCF2FrVvOjP2aQd+W7GNICMB8Shmzl9ehU/wesH3IE
aH5IpEECgYEA6CcQiI18RU27cM2g6evtcsf/WJvRzyDOzxkUEYakNqWEriYOcOxk
RvouoaToRyaWZyP/RexavPd0NklpPRzwh1vOoP9wuNIuHbGxabxnhO6rPXV+gD+P
unhJUjBYxBvjJtIsCboUP2KCVWSaDD+DeIdDiVRiBxeoaNlbM9rztx0CgYEA4wpa
+vGzfPaayWpEpwQIlbQ6ryisKXY+pEVjE9wml4li/qKchRalOdqWdNvaaQyvVOtm
EZze+W7em+7KokfqsdRC3ZqruN9IeAjGPsJa7m+oyMskMJN40mZ0xaVGZLO04TbS
sPKjOhnHAaWJl9n4Asr22BuJFcUg3E4NKMd3BFsCgYEApuZO0J1HVlvIAhUTNplT
IE4AmaeMcyjXBbS15NOzg0YD2V+qyixo+04p8DQqedyYDJaok5CUu337onusmS8R
DX9HUh/S3kzcsqRpM+crVTpxCcJKq9EGd7y6mcgASRzzdZlr56JLpDYiFVo6Fcwj
rKcyOqzbvNWZUafSFbm3tVECgYBlU9zQ/IhPsDvawpvLMmimb551SCC2DHjelb0n
QuzSWtWcsXRLjZTGIkFd/DUZBzlFHB4cpDd7eTjOvR8gmBotrX7+KiaTxsswDzJz
FgjBO4JHwynuqBKXTHS3NREapn2GuhIEOPYSWyjPGlMzO2uNy4mpX3iqB6WnzQng
dk0u2wKBgD42d6DdJiFt0t32hQcuIfaMiC2A+nsaGkRXslWL7Y/nAHbPakYqTB9q
L/kjFiWABQb4jQGq9sdOELOYyaxnn6apWGg2jjpANVKijkJtkilA28FcMdT0LMlj
lBWnx5HcBsOOqgOyuRsBANGmHVhGo8NmT9aHWx/mBSOyv8t0vN6h
-----END RSA PRIVATE KEY-----
`;

const strip = (key) => {
	if (key.indexOf('-----') !== -1) {
		return key.split('-----')[2].replace(/\r?\n|\r/g, '');
	}
}

export const initQZTray = () => {
	if (!window.RSAKey) {
		console.log('rsa lib not loaded');
		return;
	}
	window.qz.security.setCertificatePromise((resolve, reject) => {
		resolve(CERT);
	});
	window.qz.security.setSignaturePromise(function (toSign) {
		return function (resolve, reject) {
			try {
				var pk = new window.RSAKey();
				pk.readPrivateKeyFromPEMString(strip(KEY_UPDATED));
				var hex = pk.signString(toSign, 'sha1');
				resolve(window.stob64(window.hextorstr(hex)));
			} catch (err) {
				console.error(err);
				reject(err);
			}
		};
	});
}
window.initQZTray = initQZTray;

export const qzTrayConfigDefault = {
	connected: false,
	main: '',
	kitchen: ''
}

export const connectQZTray = () => {
	if(!window.qz) {
		console.log('qz-tray not loaded');
		return;
	}
	const qzTrayConfig = JSON.parse(localStorage.getItem('qzTrayConfig')) || qzTrayConfigDefault;
	window.qz.websocket.connect({ retries: 3, delay: 1}).then(() => {
		qzTrayConfig.connected = true;
		localStorage.setItem('qzTrayConfig', JSON.stringify(qzTrayConfig));
	}).catch((err) => {
		console.log(err);
		qzTrayConfig.connected = false;
		if (err && err.message === 'An open connection with QZ Tray already exists') {
			qzTrayConfig.connected = true;
		}
		localStorage.setItem('qzTrayConfig', JSON.stringify(qzTrayConfig))
	});
}
