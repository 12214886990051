import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleOrderPrint } from '../../firebase';

import { MISC_CONFIG } from '../../js/api';
import {
	getDefaultSortOptions,
	getFields,
	getMinColumnWidth,
	getMinTableWidth,
	getSortedData
} from '../../js/tableUtils';
import { trackPage } from '../../socket_handlers';
import { handleQuickAction, statusChange } from '../actions';
import Moment from '../clientTime';

import OrderId from '../OrderTracking/OrderId';
import OrderStatus from '../OrderTracking/OrderStatus';

import { CurrCode } from '../reactComp';
import { store } from '../store';
import Table from '../Table/Table';

const sat_api = require('../../js/api.js');

const NoResult = ({ type }) => (
	<div className="padding-20 text-center large-text">
		<div className="text-center m-b-20">
			<img
				src={`/images/${type === 'search' ? 'no-result' : 'table'}.png`}
				style={{ width: '80%', maxWidth: '500px', opacity: '0.7' }}
				alt="no result found"
			/>
			{type === 'search' ? (
				<div style={{ fontSize: '16px', color: '#828282' }}>
					Sorry, no result found!
				</div>
			) : null}
		</div>
	</div>
);

const StatusChanger = ({ disabled, onAccept, onCancel, nextStatus, ...other }) => (
	<div className="status-changer" {...other}>
		{
			nextStatus ?
			<button
				disabled={disabled}
				onClick={onAccept}
				className="status-changer-btn-accept"
			>
				{nextStatus}
			</button>
			:
			null
		}
		<button
			disabled={disabled}
			onClick={onCancel}
			className="status-changer-btn-cancel"
		>
			<img alt="cancel icon" src="/images/cross.png" width="13px" />
		</button>
	</div>
);

const getOrders = (state, orderType) => {
	const {
		active_tab,
		search_results,
		search_keyword
	} = state.orderTrackerNavState;

	if (search_keyword) {
		return search_results;
	} else {
		if (active_tab === 'past-order') {
			return state.allOrderList.data.orders;
		} else {
			return state.orderList.data[orderType].orders;
		}
	}
};

const getOrderType = active_tab => {
	if (active_tab) {
		return active_tab === 'in-progress' ? 'other' : active_tab;
	}

	return 'placed';
};

const mapStateToProps = state => {
	const { active_tab, search_keyword } = state.orderTrackerNavState;

	const orderType = getOrderType(active_tab);

	return {
		orders: getOrders(state, orderType),
		orderType: orderType,
		showSearchResults: Boolean(search_keyword),
		disabledOrderIds: state.disablePushOrder.id,
		appLanguage: state.appLanguage.lang,
		activeOrderDetail: state.activeOrderDetail,
		loginReducer: state.loginReducer,
	};
};

@connect(mapStateToProps)
class MainTable extends Component {
	static getDerivedStateFromProps(props, state) {
		if (
			props.orderType !== state.orderType ||
			props.showSearchResults !== state.showSearchResults
		) {
			const { sortBy, sortDirection } = getDefaultSortOptions(props.orderType);

			const fields = getFields(props.orderType, props.appLanguage);

			return {
				numOrdersToShow:
					props.orders <= 20 || props.orderType === 'past-order'
						? props.orders.length
						: 20,
				sortBy,
				sortDirection,
				fields,
				orderType: props.orderType,
				minTableWidth: getMinTableWidth(fields)
			};
		}

		return null;
	}

	constructor(props) {
		super(props);

		const { sortBy, sortDirection } = getDefaultSortOptions(props.orderType);

		const fields = getFields(props.orderType, props.appLanguage);

		this.state = {
			sortBy,
			sortDirection,
			curTime: new Date().getTime(),
			numOrdersToShow:
				props.orders <= 20 || props.orderType === 'past-order'
					? props.orders.length
					: 20,
			orderType: props.orderType,
			fields,
			minTableWidth: getMinTableWidth(fields),
			showSearchResults: props.showSearchResults
		};
	}

	getTableValue = (key, value, order) => {
		switch (key) {
			case 'id':
				const { curTime } = this.state;

				const timeLeft = sat_api.timeDiffHumanize(
					order.delivery_datetime - curTime
				);

				const totalTime = order.delivery_datetime - order.created;

				const priorityClassName = sat_api.upPriority(
					totalTime,
					order.delivery_datetime - curTime
				);

				return (
					<OrderId
						priorityClassName={priorityClassName}
						timeLeft={timeLeft}
						orderData={order}
					/>
				);


			case 'delivery_datetime':
				return (
					<React.Fragment>
					{
						(order.time_slot_end && order.time_slot_start) ?

						sat_api.readableSlot({
							time_slot_end: order.time_slot_end,
							time_slot_start: order.time_slot_start
						})

						:

						Moment(value).format('D MMM, h:mm A')
					}
					</React.Fragment>
				)

			case 'created':
				return Moment(value).format('D MMM, h:mm A');

			case 'order_total':
				return <React.Fragment><CurrCode/>&nbsp;{value}</React.Fragment>;

			case 'time_left':
				return sat_api.timeDiffHumanize(
					order.delivery_datetime - new Date().getTime()
				);

			case 'order_state':
				return <OrderStatus orderState={order.order_state} />;

			default:
				return value;
		}
	};

	statusChangeButtonClickHandler = (event, id, statusType, created, biz_id) => {
		event.stopPropagation();
		if (statusType == 'cancelled') {
			if (window.confirm('Are sure you want to cancel?')) {
				store.dispatch(handleQuickAction({ id, new_state: statusType }));
			}
		} else {
			if (statusType === 'acknowledged') {
				// handle seamless printing if this order is in ["Acknowledged"]
				const { enableSeamlessPrinting } = store.getState().printManager;
				if (enableSeamlessPrinting) {
					handleOrderPrint(id, created, biz_id);
				}
			}
			store.dispatch(handleQuickAction({ id, new_state: statusType }));
		}
	};

	getData = () => {
		const { fields, sortBy, sortDirection } = this.state;

		const orders = this.props.orderType !== 'past-order'
			? getSortedData(this.props.orders, sortBy, sortDirection)
			: this.props.orders;
		let bizsMap = {};
		if(this.props.loginReducer.data.biz) {
			bizsMap[this.props.loginReducer.data.biz.biz_id] = this.props.loginReducer.data.biz;
			if (this.props.loginReducer.data.multi_biz) {
				this.props.loginReducer.data.bizs.forEach((biz) => {
					bizsMap[biz.biz_id] = biz;
				});
			}
		}
		const bodyData = orders.map(order => {
			const orderData = fields.reduce(
				(reducedFields, { storeKey, columnKey }) => {
					return {
						...reducedFields,
						__order_id: order.id,
						biz_name: (order.biz_id && bizsMap[order.biz_id]) ? bizsMap[order.biz_id].name : '',
						[columnKey]:
							// also check for more than one key that represents the column like in Customer which represents both customer_name and phone
							storeKey instanceof Array
								? storeKey.reduce(
										(reducedValue, individualKey) =>
											`${reducedValue} ${order[individualKey]}`,
										''
								  )
								: this.getTableValue(columnKey, order[storeKey], order)
					};
				},
				{
					storeData: order
				}
			);

			return orderData;
		});

		return bodyData;
	};

	rowClickHandler = (event, order_id, newOrder) => {
		trackPage('user_entered_url', order_id);
		store.dispatch({
			type: 'STATUS_CHANGE_MODAL',
			payload: { showModal: false }
		});
		store.dispatch({
			type: 'SHOW_HIDE_ORDER_DETAIL_MODAL',
			payload: order_id
		});

		if(newOrder) {
			store.dispatch(statusChange({ d: order_id }));
		}
	};

	sortOptionsChangeHandler = (event, clickedSortBy) => {
		const { sortBy, sortDirection } = this.state;

		if (sortBy !== clickedSortBy) {
			this.setState({
				sortBy: clickedSortBy,
				sortDirection: 'ASC'
			});
		} else {
			this.setState({
				sortDirection: sortDirection === 'ASC' ? 'DESC' : 'ASC'
			});
		}
	};



	render() {
		const { disabledOrderIds, showSearchResults, status } = this.props;
		const multiBiz = (this.props.loginReducer && this.props.loginReducer.data ?  this.props.loginReducer.data.multi_biz : false);
		const {
			sortBy,
			sortDirection,
			fields,
			minTableWidth,
			orderType,
			numOrdersToShow
		} = this.state;

		const bodyData = this.getData();

		return !bodyData || bodyData.length === 0 ? (
			// <div className="up-list-container padding-10">
			// 	<div className="up-list-message text-center"> No Items </div>
			// </div>
			<NoResult type={showSearchResults ? 'search' : ''} />
		) : (
			<div className="main-table-container">
				<div className="main-table-white-layer" />

				<Table
					activeOrderRow={this.props.activeOrderDetail}
					width="100%"
					minHeight="530px"
					multiBiz={multiBiz}
					minWidth={minTableWidth}
					sortBy={sortBy}
					sortDirection={sortDirection}
					// numOrdersToShow={numOrdersToShow}
					numMobileColumns={3}
					columns={fields}
					rows={bodyData}
					onRowClick={(event, row, index) =>
						this.rowClickHandler(event, row.storeData.id, row.storeData.newOrder)
					}
					onSortChange={(event, columnKey) =>
						this.sortOptionsChangeHandler(event, columnKey)
					}
					minColumnWidthGetter={columnKey => getMinColumnWidth(columnKey)}
					hoverElementRenderer={(row, rowIndex) =>
						(orderType != 'cancelled' && orderType != 'completed' && status != 'past-order') ? (
							<StatusChanger
								nextStatus={sat_api.getNextState(row.storeData.order_state).title}
								disabled={disabledOrderIds.indexOf(row.storeData.id) !== -1}
								id={row.storeData.id}
								onAccept={event =>
									this.statusChangeButtonClickHandler(
										event,
										row.storeData.id,
										sat_api.MISC_CONFIG.getParam(sat_api.getNextState(row.storeData.order_state).key),
										row.storeData.created,
										row.storeData.biz_id
									)
								}
								onCancel={event =>
									this.statusChangeButtonClickHandler(
										event,
										row.storeData.id,
										'cancelled'
									)
								}
							/>
						) : null
					}
				/>
			</div>
		);
	}

	componentDidMount() {
		console.log('mounted - ' + this.props.status);
		sat_api.MP_TRACK(`tracker_view_${this.props.status}`, {});

		this.currentTimeIntervalID = setInterval(() => {
			this.setState({ curTime: new Date().getTime() });
		}, 60000);
		// let current_version = 1
		// if((localStorage.getItem("displayedCrossPlatformPromotion") || 0) < current_version){
		// 	setTimeout(()=>{
		// 		this.props.dispatch(displayCrossPlatformEnhancement())
		// 	},6000)
		// }
	}

	componentDidUpdate(prevProps, prevState) {
		const { orderType, showSearchResults, orders } = this.props;
		const { sortBy, sortDirection, data, numOrdersToShow, _orderType } = this.state;

		if(orderType !== _orderType) {
			clearTimeout(this.postLoaderTimer)
			if (numOrdersToShow < orders.length) {
				this.postLoaderTimer = setTimeout(() => {
					this.setState({ numOrdersToShow: orders.length });
				}, 250);
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.currentTimeIntervalID);
	}
}

export default MainTable;
