import React, { Component } from 'react';
import { store } from '../store.js';
import { connect } from 'react-redux';



import PieChartAna from './Pie';
import SankeyChart from './Sankey';
import Revenue from './Revenue';
import YourDay from './YourDay';
import AnaHeader from './AnalyticsHeader';


import { setActivePage } from '../actions';


import moment from 'moment'

import {
	getBizSumaryForDay,
	getSankeyData,
	getLocationData
} from '../../js/apis/analytics';

var PubSub = require('pubsub-js');
var _ = require('lodash');

const sat_api = require('../../js/api.js');


@connect((store)=>{
	return{
		loginReducer: store.loginReducer,
		appLanguage: store.appLanguage,
	}
})
export default class Analytics extends Component {
	constructor(props){
		super(props)
		this.state = {
			orders: [],
			stores: [{
				value: -1,
				label: "All Stores"
			}],
			init: false,
			analyticsData: null,
			from: moment().startOf('day').valueOf(),
			to: moment().valueOf(),
			minDateRange: moment().subtract(2, 'days').valueOf(),
			loading: false,
			selectedStore: {
				value: -1,
				label: "All Stores"
			},
			sankeyData: null,
			serverError: false,
			storeLocationData: [],
			storeLocationLoading: true,
			storeLocation_error: ""

		}
	}

	handleChangeFrom = (e)=>{
		this.setState({
			from: new Date(e).getTime()
		})
	}

	handleChangeTo = (e)=>{
		console.log('f')
		this.setState({
			to: new Date(e).getTime()
		})
	}

	fetch = ()=>{
		this.setState({
			loading: true,
			sankeyDataloading: true
		})

		const anaPayload = {
			from: this.state.from,
			to: this.state.to,
			store_id: this.state.selectedStore.value
		}

		getBizSumaryForDay(anaPayload).then((myJson)=> {
			if(myJson.success) {
				this.setState({
					analyticsData: myJson.data,
					loading: false
				})
			}else {
				this.setState({
					error: "Please try login again.",
					loading: false
				})
			}
		}).catch((err)=>{
			this.setState({
				serverError: true
			})
		})

		getSankeyData(anaPayload).then((myJson)=> {
			if(myJson.success) {
				this.setState({
					sankeyData: myJson.data,
					sankeyDataloading: false
				})
			}else {
				this.setState({
					error: "Please try login again.",
					sankeyDataloading: false
				})
			}
		})


		getLocationData(anaPayload).then((myJson)=> {

			if(myJson.success) {
				this.setState({
					storeLocationData: myJson.data,
					storeLocationLoading: false
				})
			}else {
				this.setState({
					storeLocation_error: "Please try login again.",
					storeLocationLoading: false
				})
			}
		})




	}

	componentDidMount() {
		store.dispatch(setActivePage({page: "analytics"}))

		const _stores = this.props.loginReducer.data.sub_admin;
		let _store_for_select = _stores.map(o=> {
			return {
				label : o.name,
				value : o.biz_location_id
			}
		})


		if(store.getState().loginReducer.data.flag == 'HQ') {
			const hqDefault = [{
				value: -1,
				label: "All Stores"
			}]



			_store_for_select = [...hqDefault, ..._store_for_select]

		}


		this.setState({
			stores: _store_for_select,
			selectedStore: _store_for_select[0]
		}, ()=>{
			this.fetch()
		})
    }

	storeAnaSelect = (store)=>{
		this.setState({
			selectedStore: store
		}, ()=>{
			this.fetch()
		})
	}

    render() {
			return null;
			let widgetStyles = {
				paddingTop: this.props.location.pathname === "/w/inventory" ? '10px':'80px'
			}
			if(this.state.serverError) {
				return(
					<div className="react-components analytics-page text-center" style={widgetStyles}>
						<div style={{fontSize:"20px"}}>Analytics server is not available!</div>
					</div>
				)
			}
			return (
				<div className={(this.state.loading ? "loading" : "") + " react-components analytics-page"} style={widgetStyles}>
					<div style={{minHeight:"100vh"}}>
					<AnaHeader
						minDateRange={ this.state.minDateRange }
						from={ this.state.from }
						handleChangeFrom={ this.handleChangeFrom }
						to={ this.state.to }
						handleChangeTo={ this.handleChangeTo }
						fetch={ this.fetch }
						selectedStore={ this.state.selectedStore }
						stores={ this.state.stores }
						storeAnaSelect={ this.storeAnaSelect }
						lang={this.props.appLanguage.lang}
					/>
					{
						!this.state.loading && this.state.error ?
						<div style={{fontSize: "20px", textAlign: "center"}}>
							You might not have permission to access Analytics.<br/>
							Please logout and login again.
						</div>
						:
						null
					}
					{
						this.state.analyticsData  ?
						<React.Fragment>
							<Revenue data={this.state.analyticsData} lang={this.props.appLanguage.lang} />
							{this.state.analyticsData.timeseriesGroup.length > 0 && <YourDay data={this.state.analyticsData}/>}
							<PieChartAna data={this.state.analyticsData} lang={this.props.appLanguage.lang} />
						</React.Fragment>
						:
						this.state.loading ?
						<div style={{textAlign:"center", padding: "20px"}}>Loading..</div>
						:
						null

					}
					{
						this.state.sankeyData && this.state.sankeyData.length > 0 ?
						<SankeyChart lang={this.props.appLanguage.lang} data={this.state.sankeyData}/>
						:
						null
					}
					</div>
			</div>
		);
  }
}
