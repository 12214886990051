const appUpadateAvailable = function(state = {
	show: false,
	version: null,
	message: ""
}, action) {
	switch (action.type) {
		case 'APP_UPDATE_AVAILABLE':
			return {
				...state,
				...action.payload
			}
		default:
			return state
	}
}

export default appUpadateAvailable
