import * as actionTypes from '../actions/actionTypes';

const initialState = {
	showLoginSeamlessPrintModal: false,
	enableSeamlessPrinting:
		JSON.parse(localStorage.getItem('up-sat-enable-seamless-printing')) ||
		false,
	hasSeamlessPrintingBeenConfigured:
		false || JSON.parse(localStorage.getItem('up-sat-seamless-configured')),
	showSetupInstructions: false,
	// stage: 'instructions' | 'verify'
	stage: 'instructions',
	faultyPrinterInfo: null
};

const printManagerReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.TOGGLE_LOGIN_SEAMLESS_PRNT_MODAL:
			return {
				...state,
				showLoginSeamlessPrintModal: payload.showLoginSeamlessPrintModal
			};

		case actionTypes.TOGGLE_PRNTR_SETUP_INSTRCTNS:
			return {
				...state,
				showSetupInstructions: payload.showSetupInstructions
			};

		case actionTypes.TOGGLE_SEAMLESS_PRNTNG:
			console.log('toggling');

			return {
				...state,
				enableSeamlessPrinting: payload.enableSeamlessPrinting,
				showSetupInstructions: false
			};

		case actionTypes.CHG_PRNT_INSTRCTNS_STAGE:
			return {
				...state,
				stage: payload.stage
			};

		case actionTypes.UPDATE_FAULTY_PRNTR_INFO:
			return {
				...state,
				faultyPrinterInfo: payload.faultyPrinterInfo
			};

		default:
			return state;
	}
};

export default printManagerReducer;
