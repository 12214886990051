import React from 'react';
import { connect } from 'react-redux';
import { store } from '../store';
import '../../css/CrossPlatformEnhancement.scss';


@connect((store)=>{
	return{
		displayCrossPromotionEnhancement: store.displayCrossPromotionEnhancement,
		audioInteraction: store.audioInteraction
	}
})

export default class CrossPlatformEnhancementUX extends React.Component {
	handleClick = (e)=>{
		if(e.target.id.startsWith('closeArea')){
			store.dispatch({
			type:"HIDE_CROSS_PLATFORM_ENHANCEMENT",
			payload: null
			})
		}
	}

	openDownloadLink = (e)=>{
		window.open("https://play.google.com/store/apps/details?id=com.urbanpiper.satellite&hl=en_US")
	}

	openPrime = (e)=>{
		window.open("https://prime.urbanpiper.com/auth/satellite-login");
	}

	closeModal = () => {
		store.dispatch({
			type: "HIDE_CROSS_PLATFORM_ENHANCEMENT",
			payload: null
		})
	}

	render() {
		if(this.props.displayCrossPromotionEnhancement.showCrossPlatformEnhancement
			 && (!this.props.audioInteraction.interactionRequired)
		 ) {
			return(
				<div className="crossPlatformEnhancementContainer">
				<div className="play-btn-container " onClick={this.handleClick} id="closeArea">
					<div className="content" id="closeArea1">
						<div className="card" id="closeArea2">
							<div className="card-body">
								<h1>Introducing Prime</h1>
								<div className="subtext">We are introducing Prime, which is the 2.0 version of our Satellite product</div>
								<div className="subtext">We request you to move to the new tool before <span>6th Jan, 2021</span> for uninterrupted services.</div>
								<img src="./images/prime.png" />
								<div className="setup-btn-container">
									<button
										className="btn btn-secondary setup-btn"
										onClick={this.openPrime}
									>
										Setup my prime User &rarr;
									</button>
									<button className="btn close-btn" onClick={this.closeModal}>Later</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			)
		}else {
			return null
		}
	}
}
